import React from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { Button } from '@vadiun/react-components';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { Moment } from 'moment';
import {
  ChoferPersonalFormSchema,
  choferPersonalFormSchemaInitialValues
} from '../../models/ChoferFormSchema';
import { domicilioGoogleValidation } from 'shared/components/MUIGooglePlaces';
import { FormRepeater, FormikImageUpload } from '@vadiun/react-components';
import { ChoferIdioma, IDIOMAS } from '../../models/ChoferIdioma';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import FormikAddress from 'shared/components/FormikAddress';

const Schema = Yup.object().shape({
  foto: Yup.mixed().required('La foto es requerida'),
  dni_front_photo: Yup.mixed().required('La foto es requerida'),
  dni_back_photo: Yup.mixed().required('La foto es requerida'),
  fechaNacimiento: Yup.mixed().required('La fecha de nacimiento es requerida'),
  dni: Yup.string().required('El dni es obligatorio'),
  domicilio: domicilioGoogleValidation,
  piso: Yup.number(),
  dpto: Yup.string(),
  cuit: Yup.string().required('El cuit es obligatorio'),
  telefonoEmergencia: Yup.string().required('El telefono es requerido'),
  celular: Yup.string().required('El celular es requerido'),
  mail: Yup.string()
    .email('El email es invalido')
    .required('El email es requerido'),
  idiomas: Yup.array()
});

interface Props {
  onSubmit: (value: ChoferPersonalFormSchema) => void;
  onGoBack?: () => void;
  initialValues?: ChoferPersonalFormSchema;
  goBackButtonTitle?: string;
  submitButtonTitle?: string;
}

const ChoferFormPersonal = ({
  goBackButtonTitle = 'Volver',
  submitButtonTitle = 'Guardar',
  onSubmit,
  onGoBack,
  initialValues = choferPersonalFormSchemaInitialValues()
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ submitForm, touched, errors, values }) => (
        <Form className="w-full grid gap-6">
          <div className="flex gap-8">
            <div className="w-1/5">
              <Field
                component={FormikImageUpload}
                className="aspect-w-1 aspect-h-1"
                name="foto"
              />
            </div>

            <div className="flex flex-col gap-6 ">
              <Field
                component={KeyboardDatePicker}
                label="Fecha nacimiento"
                name="fechaNacimiento"
                format="DD/MM/YYYY"
                inputVariant="outlined"
                fullWidth
              />
              <Field
                name="cuit"
                label="Cuit"
                variant="outlined"
                type="number"
                component={TextField}
                fullWidth
              />
            </div>
          </div>
          <p className="text-gray-700 font-bold text-lg">DNI</p>
          <div className="w-2/3">
            <Field
              name="dni"
              label="Número DNI"
              type="number"
              variant="outlined"
              fullWidth
              component={TextField}
            />
            <div className="flex gap-6 mt-8">
              <div className="w-1/2">
                <Field
                  component={FormikImageUpload}
                  className="aspect-w-2 aspect-h-1"
                  name="dni_front_photo"
                />
                <p className="text-xs text-gray-700 text-center mt-2">
                  Frente DNI
                </p>
              </div>
              <div className="w-1/2">
                <Field
                  component={FormikImageUpload}
                  className="aspect-w-2 aspect-h-1"
                  name="dni_back_photo"
                />
                <p className="text-xs text-gray-700 text-center mt-2">
                  Dorso DNI
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <p className="text-gray-700 font-bold text-lg col-start-1 col-end-3">
              Contacto
            </p>
            <Field
              name="mail"
              label="Mail"
              variant="outlined"
              component={TextField}
              className="col-span-2"
              fullWidth
            />
            <Field
              name="telefonoEmergencia"
              label="Telefono de emergencia"
              variant="outlined"
              component={TextField}
              fullWidth
            />
            <Field
              name="celular"
              label="Celular"
              variant="outlined"
              component={TextField}
              fullWidth
            />
            <p className="text-gray-700 font-bold text-lg col-start-1 col-end-3">
              Domicilio
            </p>
            <Field
              component={FormikAddress}
              name="domicilio"
              className="col-span-2"
            />
          </div>
          <p className="text-gray-700 font-bold text-lg">Idiomas</p>
          <FieldArray
            name="idiomas"
            render={(arrayHelpers) => (
              <FormRepeater
                arrayHelpers={arrayHelpers}
                addLabel="+ Añadir"
                createField={() => ChoferIdioma.ALEMAN}
              >
                {values.idiomas.map((value, index) => (
                  <>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Idioma</InputLabel>
                      <Field
                        component={Select}
                        name={`idiomas.${index}`}
                        label="Idioma"
                      >
                        {IDIOMAS.map((idioma) => (
                          <MenuItem value={idioma} key={idioma}>
                            {idioma}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </>
                ))}
              </FormRepeater>
            )}
          />
          <div className="flex justify-between mt-8">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="red"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button type="submit" variant="contained" color="green">
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface FormSchema {
  foto: File | undefined;
  nacimiento: Moment;
  dni: number | undefined;
  domicilio: any;
  piso: string | null;
  dpto: string | null;
  cuit: number | undefined;
  telefono: string;
  celular: string;
  email: string;
  idiomaIngles: boolean;
  idiomaPortugues: boolean;
  idiomaItaliano: boolean;
  idiomaAleman: boolean;
  idiomaOtros: boolean;
}

export default ChoferFormPersonal;
