import { domicilioValidation } from 'shared/components/FormikAddress';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';
import { ClientPrivateRateFormType } from './ClientPrivateRateFormType';

export const clientPrivateRateCategoriesFormValidation: YupShape<ClientPrivateRateFormType> =
  Yup.object().shape({
    outSitePrice: Yup.string().required('El precio out site requerido'),
    minNumberKilometers: Yup.string().required('El precio mínimo es requerido'),
    nightPlusPercentage: Yup.string().required(
      'El porcentaje de plus nocturno requerido'
    ),
    categories: Yup.array().of(
      Yup.object().shape({
        kmServiceCanceled: Yup.string().required(
          'Los km servicio cancelado son requeridos'
        ),
        hourPrice: Yup.string().required('El precio por hora es requerido'),
        linearKilometerPrices: Yup.array()
          .of(
            Yup.object().shape({
              numberKilometersFrom: Yup.string().required(
                'La cantidad de km es requerida'
              ),
              numberKilometersTo: Yup.string().required(
                'La cantidad de km es requerida'
              ),
              price: Yup.string().required('el precio es requerido')
            })
          )
          .min(1, 'Como mínimo se debe tener un elemento'),
        returnKilometerPrices: Yup.array()
          .of(
            Yup.object().shape({
              numberKilometersFrom: Yup.string().required(
                'La cantidad de km es requerida'
              ),
              numberKilometersTo: Yup.string().required(
                'La cantidad de km es requerida'
              ),
              price: Yup.string().required('el precio es requerido')
            })
          )
          .min(1, 'Como mínimo se debe tener un elemento'),
        fixedPrices: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required('El nombre requerido'),
            price: Yup.string().required('el precio es requerido')
          })
        ),
        priced: Yup.array().of(
          Yup.object().shape({
            address1: domicilioValidation,
            address2: domicilioValidation,
            kilometersRadio: Yup.string().required('El radio requerido'),
            price: Yup.string().required('El precio requerido')
          })
        )
      })
    )
  });
