import { httpClient } from 'shared/services/http/httpClient';
import {
  ClientPrivateRateFormType,
  clientPrivateRateFormTypeBuilder
} from '../components/forms/ClientPrivateRateFormType';
import {
  clientPrivateRateBuilder,
  ClientPrivateRateModel
} from '../models/ClientPrivateRateModel';

export const useClientPrivateRate = () => {
  function edit(rate: ClientPrivateRateFormType) {
    return httpClient.put(
      `private-rates`,
      clientPrivateRateFormTypeBuilder.toBackEnd(rate)
    );
  }

  async function get(): Promise<ClientPrivateRateModel> {
    const res = await httpClient.get(`private-rates`);
    return clientPrivateRateBuilder.fromBackEnd(res);
  }

  return {
    get,
    edit
  };
};
