import { Button } from '@vadiun/react-components';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import AuthLayout from 'auth/components/AuthLayout';
import { AuthContextType } from 'auth/services/AuthService';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ForgotPasswordIlustration from '../../assets/forgot-password.svg';
import Logo from '../../assets/logo.png';

interface FormSchema {
  email: string;
}

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('El email es invalido')
    .required('El email es requerido')
});
interface Props {
  authSrv: AuthContextType;
}

const ForgotPasswordPage = (props: Props) => {
  const [successEmailEmition, setSuccessEmailEmition] = useState(false);
  const forgotMutation = useSuperMutation(props.authSrv.forgotPassword, {
    showSuccessMessage: false
  });
  const history = useHistory();

  const submit = async (
    value: FormSchema,
    formikHelpers: FormikHelpers<FormSchema>
  ) => {
    await forgotMutation.mutate(value.email);
    setSuccessEmailEmition(true);
    formikHelpers.resetForm();
  };

  return (
    <AuthLayout
      ilustration={ForgotPasswordIlustration}
      title="Olvide mi contraseña"
      subtitle="No te preocupes, te enviaremos un mail para cambiar tu contraseña."
      logo={Logo}
    >
      <div className="relative flex h-full w-full max-w-xl items-center justify-center">
        <div className=" w-80">
          <h1 className="my-4 text-center text-2xl font-bold">
            ¿Olvidaste tu contraseña?
          </h1>
          <div
            className={
              'w-full transition' +
              (successEmailEmition ? ' animate-shrink' : '')
            }
          >
            <p className="font-bold text-gray-500">
              Ingresa tu email para cambiar tu contraseña
            </p>

            <Formik
              initialValues={{ email: '' }}
              validationSchema={Schema}
              onSubmit={submit}
            >
              {({ isSubmitting, submitForm }) => (
                <Form>
                  <Field
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    className="bg-white"
                    name="email"
                    component={TextField}
                  />
                  <div className="my-8 w-full">
                    <Button
                      className="w-full"
                      variant="contained"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      onClick={submitForm}
                    >
                      Enviar email
                    </Button>
                  </div>
                  <Button onClick={history.goBack} className="mt-4 w-full">
                    Volver
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
          <div
            className={
              successEmailEmition
                ? 'transition delay-300 duration-300 ease-in-out'
                : 'h-0 scale-0 transform'
            }
          >
            <p className="mb-12 text-center">
              Te hemos enviado un email con las instrucciones para cambiar tu
              contraseña
            </p>
            <Button
              className="w-full"
              variant="contained"
              onClick={() => history.goBack()}
            >
              ¡Entendido!
            </Button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
