import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { PassengerFormType } from './PassengerFormik';
import PassengerFormik from './PassengerFormik';

interface Props {
  onSubmit: (value: PassengerFormType, reset) => void;
  initialValues?: PassengerFormType;
}

function PassengerForm({
  onSubmit,
  initialValues = PassengerFormik.initialValues
}: Props) {
  return (
    <Formik<PassengerFormType>
      initialValues={initialValues}
      validationSchema={PassengerFormik.schema}
      enableReinitialize
      onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
    >
      {({ isSubmitting }) => (
        <Form className="w-full grid gap-6 grid-cols-6">
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <Field
            name="phone"
            label="Teléfono"
            type="number"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <Field
            name="email"
            label="Email"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <Field
            name="dni"
            label="Dni"
            type="number"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <Field
            name="nationality"
            label="Nacionalidad"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="has_priority"
            Label={{ label: 'Prioritario' }}
            type="checkbox"
            variant="outlined"
            className="col-span-3"
            component={CheckboxWithLabel}
            fullWidth
          />

          <div className="flex justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PassengerForm;
