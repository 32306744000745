export enum Bank {
  ICBC = 'ICBC',
  STANDARD_BANK = 'Standard Bank',
  HSBC = 'HSBC Bank',
  DEUTSCHE_BANK = 'Deutsche Bank',
  CITIBANK = 'Citibank',
  BNP_PARIBAS = 'BNP Paribas',
  BBVA = 'BBVA Banco Frances',
  SUPERVIELLE = 'Supervielle',
  SANTANDER = 'Santander R',
  SANTA_CRUZ = 'Santa Cruz',
  SAENZ = 'Saenz',
  REGIONAL_CUYO = 'Regional de Cuyo',
  PROVINCIA_NEUQUEN = 'Provincia del Neuquen',
  PROVINCIA = 'Provincia',
  PATAGONIA = 'Patagonia',
  NACION = 'Nación',
  MERIDIAN = 'Meridian',
  MARIVA = 'Mariva',
  MACRO = 'Macro',
  ITAU = 'Itaú',
  HIPOTECARIO = 'Hipotecario',
  GALICIA = 'Galicia',
  BRASIL = 'Brasil',
  TUCUMAN = 'Tucuman',
  CHUBUT = 'Chubut',
  TIERRA_DEL_FUEGO = 'Tierra del Fuego',
  SANTIAGO_DEL_ESTERO = 'Santiago del Estero',
  SAN_JUAN = 'San Juan',
  LA_PAMPA = 'La Pampa',
  FORMOSA = 'Formosa',
  CORRIENTES = 'Corrientes',
  CORDOBA = 'Córdoba',
  CREDICOP = 'Credicoop',
  COMAFI = 'Comafi',
  COLUMBIA = 'Columbia',
  BANCO_CIUDAD = 'Banco Ciudad',
  ABN_AMRO = 'ABN AMRO',
  MERCADO_PAGO = 'Mercado Pago',
  BRUBANK = 'Brubank',
  UALA = 'Uala'
}

export const BANKS = Object.values(Bank).sort();
