import { Button } from '@vadiun/react-components';
import { useEffect, useState } from 'react';
import PageHeader from '../../../../layout/components/PageHeader';

import PageContainer from '../../../../layout/components/PageContainer';
import VehiculoModeloCreate from '../components/VehiculoModeloCreate';
import VehiculoModeloTable from '../components/VehiculoModeloTable';

import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useVehiculoFabricantes from '../../fabricanteVehiculo/services/VehiculoFabricanteService';
import VehiculoModeloEdit from '../components/VehiculoModeloEdit';
import { VehiculoModeloModel } from '../models/VehiculoModelo';
import {
  VehiculoModeloFormSchema,
  vehiculoModeloFormSchemaToModel,
  vehiculoModeloModelToFormSchema
} from '../models/VehiculoModeloFormSchema';
import useVehiculoModelos from '../services/VehiculoModeloService';

function VehiculoModeloCrudPage(props: any) {
  const VehiculoModeloService = useVehiculoModelos();
  const VehiculoFabricanteService = useVehiculoFabricantes();
  const fabricantesQuery = useSuperQuery(
    VehiculoFabricanteService.getVehiculoFabricantes,
    { showSpinner: true }
  );
  const editVehiculoModelMutation = useSuperMutation(
    VehiculoModeloService.editVehiculoModelo,
    {
      showSpinner: true,
      messages: { success: { message: 'El modelo fue editado con exito' } }
    }
  );
  const addVehiculoModeloMutation = useSuperMutation(
    VehiculoModeloService.addVehiculoModelo,
    {
      messages: { success: { message: 'El modelo fue creado con exito' } }
    }
  );
  const removeVehiculoModeloMutation = useSuperMutation(
    VehiculoModeloService.removeVehiculoModelo,
    {
      showSpinner: true,
      messages: { success: { message: 'El modelo fue borrado con exito' } }
    }
  );
  const restoreVehiculoModeloMutation = useSuperMutation(
    VehiculoModeloService.restoreVehiculoModelo,
    {
      showSpinner: true
    }
  );

  const vehiculosQuery = useSuperQuery(
    VehiculoModeloService.getVehiculoModelos,
    { showSpinner: true }
  );

  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'edit'>(
    'table'
  );

  const [editingVehiculoModelo, setEditingVehiculoModelo] =
    useState<VehiculoModeloModel>();

  useEffect(() => {
    if (visiblePage === 'table') vehiculosQuery.reload();
  }, [visiblePage]);

  async function edit(
    vehiculoModeloFormSchema: VehiculoModeloFormSchema,
    reset
  ) {
    const vehiculoModeloModel = vehiculoModeloFormSchemaToModel(
      vehiculoModeloFormSchema,
      fabricantesQuery.data!
    );
    vehiculoModeloModel.id = editingVehiculoModelo!.id;
    await editVehiculoModelMutation.mutate(vehiculoModeloModel);
    reset();
    setVisiblePage('table');
  }

  async function create(VehiculoModeloValues: VehiculoModeloFormSchema, reset) {
    const VehiculoModelo = vehiculoModeloFormSchemaToModel(
      VehiculoModeloValues,
      fabricantesQuery.data!
    );
    await addVehiculoModeloMutation.mutate(VehiculoModelo);
    reset();
  }

  async function remove(VehiculoModelo: VehiculoModeloModel) {
    await removeVehiculoModeloMutation.mutate(VehiculoModelo);
    vehiculosQuery.reload();
  }

  async function restore(VehiculoModelo: VehiculoModeloModel) {
    await restoreVehiculoModeloMutation.mutate(VehiculoModelo);
    vehiculosQuery.reload();
  }

  function selectToEdit(VehiculoModelo: VehiculoModeloModel) {
    setEditingVehiculoModelo(VehiculoModelo);
    setVisiblePage('edit');
  }
  return (
    <>
      <PageHeader
        title="Modelos"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de modelos'
            : visiblePage === 'create'
            ? 'Nuevo Modelo'
            : 'Editar Modelo'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Modelo
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <VehiculoModeloTable
            VehiculoModelos={vehiculosQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
          />
        )}

        {visiblePage === 'create' && (
          <VehiculoModeloCreate
            create={create}
            fabricantes={fabricantesQuery.data!}
          />
        )}
        {visiblePage === 'edit' && editingVehiculoModelo && (
          <VehiculoModeloEdit
            editar={edit}
            fabricantes={fabricantesQuery.data!}
            initialValues={vehiculoModeloModelToFormSchema(
              editingVehiculoModelo
            )}
          />
        )}
      </PageContainer>
    </>
  );
}

export default VehiculoModeloCrudPage;
