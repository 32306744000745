import { Card } from '@material-ui/core';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import { LineChartComponent } from '../components/LineChartComponent';
import { useTransferReservationStatisticsRepository } from '../services/transferReservationStatisticsRepository';

export const ManualAndAutomaticReservationsPerMonth = () => {
  const repo = useTransferReservationStatisticsRepository();
  const reservationQuery = useSuperQuery(repo.perMonth);

  if (reservationQuery.data === undefined) {
    return <p>Cargando...</p>;
  }

  const labels = reservationQuery.data.map((fecha) => {
    return fecha.date;
  });

  const lines = [
    {
      label: 'Manual',
      values: reservationQuery.data.map((amount) => ({
        label: amount.date,
        value: amount.manualAmount
      }))
    },
    {
      label: 'Automatico',
      values: reservationQuery.data.map((amount) => ({
        label: amount.date,
        value: amount.automaticAmount
      }))
    }
  ];

  return (
    <>
      <div>
        <PageContainer>
          <div className="w-full">
            <Card>
              <div className="p-8" style={{ minHeight: 400 }}>
                <LineChartComponent lines={lines} labels={labels} />
              </div>
            </Card>
          </div>
        </PageContainer>
      </div>
    </>
  );
};
