import React from 'react';
import CostCenterForm from './CostCenterForm/CostCenterForm';
import { CostCenterFormType } from './CostCenterForm/CostCenterFormik';

interface Props {
  create: (costCenterValues: CostCenterFormType, reset) => void;
}
function CostCenterCreate({ create }: Props) {
  return <CostCenterForm onSubmit={create} />;
}

export default CostCenterCreate;

