import { FC } from 'react';
import { tw } from 'utils/tailwindUtils';
import { getStepperCompletion } from '../utils/stepper';
import Step from './Step';


export enum CompletionStatus {
  FILE,
  MAPPING,
  CHECKING,
  COMPLETE
}

interface StepperProps {
  stepsCompletion: CompletionStatus;
  className?: string;
  forceCompletion?: boolean;
}

const StepperSeparator = () => {
  return (
    <div className='flex h-7 w-full items-center justify-center'>
      <hr className='w-full border-rentennials-profileStepper-uncheck' />
    </div>
  );
};

const Stepper: FC<StepperProps> = ({ stepsCompletion, className, forceCompletion }) => {

  const calculatedCompletionStatus = getStepperCompletion(stepsCompletion);

  return (
    <div className={tw('flex h-24 w-full flex-col items-center lg:h-16', className)}>
      <div className={tw('flex w-full justify-between pt-2 lg:justify-center')}>
        <Step
          stepName={"Carga del archivo"}
          isCompleted={calculatedCompletionStatus > 0}
          isCurrent={!calculatedCompletionStatus}
        />
        <StepperSeparator />
        <Step
          stepName={"Mapeo de datos"}
          isCompleted={calculatedCompletionStatus > 1}
          isCurrent={calculatedCompletionStatus === 1}
        />
        <StepperSeparator />
        <Step
          stepName={"Validación"}
          isCompleted={calculatedCompletionStatus > 2}
          isCurrent={calculatedCompletionStatus === 2}
        />
        <StepperSeparator />
        <Step
          stepName={"Resultado"}
          isCompleted={calculatedCompletionStatus > 3 || forceCompletion}
          isCurrent={calculatedCompletionStatus === 3}
        />
      </div>
    </div>
  );
};

export default Stepper;
