export const transformExcelRows = (data: string[][]) => {
  let maxColumnSize = 0;
  const mappedColumns = data.map((rowData: string[]) => {
    if (rowData.length > maxColumnSize) {
      maxColumnSize = rowData.length;
    }
    return rowData.reduce((acc, columnData: string, index) => {
      return {
        ...acc,
        [`column` + index]: columnData
      };
    }, {} as Record<string, string>);
  });
  return {
    maxColSize: maxColumnSize,
    mappedColumns
  };
};

export const fixMissingRows = (
  excelTransformedData: {
    maxColSize: number;
    mappedColumns: Record<string, string>[];
  },
  mappingColumnsLength: number
) => {
  if (excelTransformedData.maxColSize < mappingColumnsLength) {
    const nextExcelMappedColumns = excelTransformedData.mappedColumns.map(
      (rowElement) => {
        for (
          let i = Object.keys(rowElement).length;
          i < mappingColumnsLength;
          i++
        ) {
          rowElement = {
            ...rowElement,
            [`column` + i]: ''
          };
        }
        return rowElement;
      }
    );
    return {
      maxColSize: mappingColumnsLength,
      mappedColumns: nextExcelMappedColumns
    };
  }
  return excelTransformedData;
};
