import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button, Modal } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import useApplicant from 'app/client/clientBusiness/Applicant/services/useApplicant';
import useAuthorizeds from 'app/client/clientBusiness/Authorized/services/AuthorizedService';
import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { useViajesTraslado } from 'app/viaje/services';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CancelViajeTrasladoModal from '../../viajeTraslado/components/CancelViajeTrasladoModal';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import { TransferReservationALiquidar } from '../../viajeTraslado/models/TransferReservationALiquidar';
import { CorrectClientBusinessForm } from '../components/CorrectClientBusinessForm';
import { CorrectClientBusinessFormType } from '../components/CorrectClientBusinessFormik';
import { ViajeTrasladoPendienteLiquidacionaTable } from '../components/ViajeTrasladoPendienteLiquidacionaTable';
import PlatformReservations from '../components/PlatformReservations';

/* import { exportExcel } from '../utils/excel/ExportExcel'; */

const ViajeTrasladoPendienteLiquidacionPage = () => {
  const tripRepository = useViajesTraslado();
  const clientBusinessRepository = useClientBusiness();

  const storeSettlement = useSuperMutation(tripRepository.storeSettlement, {
    onMutationFinish: (res) => {
      /* exportExcel(res?.data?.data) */
      const filename = res.data?.headers['content-disposition']
        .split('filename=')[1]
        .replaceAll('"', '');
      const url = window.URL.createObjectURL(new Blob([res.data?.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    showSpinner: true
  });
  const authorizadosService = useAuthorizeds();
  const ApplicantService = useApplicant();

  const [clientId, setClientId] = useState<number | undefined>(undefined);

  const [isCorrectModalOpen, setIsCorrectModalOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useFilters({
    dateFrom: moment().add(-7, 'days').startOf('d'),
    dateTo: moment().hour(23).minutes(59),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    sub_cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false,
    page_size: 10,
    page: 1,
    sent_reservations_platform: false,
    not_sent_reservations_platform: false,
    has_platform_id: false,
    is_event: false
  });

  const [selectedReservations, setSelectedReservations] = useState<
    TransferReservationALiquidar[]
  >([]);
  const [correctingReservation, setCorrectingReservation] = useState<
    TransferReservationALiquidar | undefined
  >(undefined);

  const autorizadosQuery = useSuperQuery(
    async () => {
      return clientId ? await authorizadosService.getAuthorizeds(clientId) : [];
    },
    undefined,
    [clientId]
  );

  const clientQuery = useSuperQuery<ClientBusinessModel | undefined>(
    async () => {
      if (clientId === undefined) return;
      return await clientBusinessRepository.get(clientId);
    },
    undefined,
    [clientId]
  );
  const solicitantesQuery = useSuperQuery<ApplicantModel[] | undefined>(
    async () => {
      if (clientId === undefined) return [];
      return await ApplicantService.getAll(clientId);
    },
    undefined,
    [clientId]
  );

  const aLiquidarQuery = useSuperQuery(
    () => tripRepository.getPendingSettlement(filters),
    { showSpinner: true },
    [filters]
  );

  const correctClientMutation = useSuperMutation(tripRepository.correctClient, {
    showSpinner: true
  });

  const liquidar = async () => {
    await storeSettlement.mutate(selectedReservations);
    setSelectedReservations([]);
    aLiquidarQuery.reload();
  };

  const correctClient = async (value: CorrectClientBusinessFormType) => {
    await correctClientMutation.mutate({
      ...value,
      id: correctingReservation!.id
    });
    aLiquidarQuery.reload();
  };

  const [isOpenReservationsToPlatform, setOpenReservationsToPlatform] =
    useState<boolean>(false);
  const [isLoadingReservationsToPlatform, setLoadingReservationsToPlatform] =
    useState<boolean>(false);

  const onCloseModalReservationsToPlatform = () =>
    setOpenReservationsToPlatform(false);

  const sendReservationsToPlatformMutation = useSuperMutation(
    tripRepository.reservationsToPlatform,
    {
      showSpinner: false
    }
  );

  const sendReservationsToPlatform = async (reservationsId: number[]) => {
    setLoadingReservationsToPlatform(true);
    await sendReservationsToPlatformMutation.mutate(reservationsId);
    setLoadingReservationsToPlatform(false);
    setSelectedReservations([]);
    onCloseModalReservationsToPlatform();
    aLiquidarQuery.reload();
  };

  const [cancelReservationModal, setCancelReservationModal] = useState<
    | {
        isOpen: false;
        reservationId: undefined;
      }
    | {
        isOpen: true;
        reservationId: number;
      }
  >({ isOpen: false, reservationId: undefined });

  const cancel = async (reservationId: number, cancelObservation: string) => {
    setCancelReservationModal({ reservationId: undefined, isOpen: false });
    await cancelReservationMutation
      .mutate(reservationId, cancelObservation)
      .then(() => aLiquidarQuery.reload());
  };

  const cancelReservationMutation = useSuperMutation(
    tripRepository.cancelReservation,
    { showSpinner: true }
  );

  const hasValidReservationsSelectedToSendPlatform = selectedReservations.every(
    (reservation) => reservation.sentPlatform === false
  );

  return (
    <>
      <PageHeader
        title="Traslados pendientes de liquidar"
        toolbar={
          <div className="flex space-x-1">
            <PlatformReservations
              isOpen={isOpenReservationsToPlatform}
              isLoading={isLoadingReservationsToPlatform}
              onOpen={() => setOpenReservationsToPlatform(true)}
              onClose={onCloseModalReservationsToPlatform}
              isDisabled={selectedReservations.length === 0}
              hasValidReservationsSelected={
                hasValidReservationsSelectedToSendPlatform
              }
              reservationsId={selectedReservations.map(({ id }) => id)}
              onSendReservationsToPlatform={sendReservationsToPlatform}
            />
            <Button
              disabled={selectedReservations.length === 0}
              variant="light"
              color="green"
              onClick={liquidar}
            >
              Liquidar
            </Button>
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtros
            </Button>
          </div>
        }
      />
      <PageContainer lessMargin>
        <ViajeTrasladoPendienteLiquidacionaTable
          data={aLiquidarQuery.data?.data ?? []}
          totalResults={aLiquidarQuery.data?.totalResults || 0}
          setSelectedRows={setSelectedReservations}
          currentPageSize={filters.page_size || 10}
          selectedReservations={selectedReservations}
          onPageSizeChange={(pageSize) =>
            setFilters((filterState) => ({
              ...filterState,
              page_size: pageSize,
              page: 1
            }))
          }
          onPageChange={(page) =>
            setFilters((filterState) => ({ ...filterState, page: page }))
          }
          onReservationShowDetails={(reservation) => (
            <>
              <div className="flex flex-col gap-4">
                <Link to={'/main/Viaje/traslado/detalle/' + reservation.id}>
                  <Button variant="light" className="w-full" color="orange">
                    Detalle
                  </Button>
                </Link>
                <Button
                  variant="light"
                  className="w-full"
                  color="green"
                  onClick={() => {
                    setCorrectingReservation(reservation);
                    setIsCorrectModalOpen(true);
                  }}
                >
                  Corregir
                </Button>
                <Button
                  variant="light"
                  className="w-full"
                  color="red"
                  onClick={() => {
                    setCancelReservationModal({
                      isOpen: true,
                      reservationId: reservation.id
                    });
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </>
          )}
        />
        <CancelViajeTrasladoModal
          isOpen={cancelReservationModal.isOpen}
          onClose={() =>
            setCancelReservationModal({
              reservationId: undefined,
              isOpen: false
            })
          }
          cancel={cancel}
          reservationId={cancelReservationModal.reservationId!}
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-lg"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <ViajeTrasladoBaseFilters
            initialValues={filters}
            onSubmit={async (filters) => {
              setFilters({ ...filters, page: 1 });
              setShowFilters(false);
            }}
          />
        </div>
      </Drawer>
      {isCorrectModalOpen && (
        <Modal
          open={isCorrectModalOpen}
          onClose={() => {
            setIsCorrectModalOpen(false);
            setClientId(undefined);
          }}
          size="lg"
          body={
            <CorrectClientBusinessForm
              autorizadosQuery={autorizadosQuery}
              clientSelected={clientQuery.data}
              getClientesByName={clientBusinessRepository.findByName}
              onClienteSelect={setClientId}
              onSubmit={(values) => {
                setIsCorrectModalOpen(false);
                correctClient(values);
              }}
              solicitantes={solicitantesQuery.data ?? []}
            />
          }
        />
      )}
    </>
  );
};

export default ViajeTrasladoPendienteLiquidacionPage;
