export interface ViajeTrasladoExpenses {
    id: number
    name: string
    price: number
    photo: string | null
    type: string
}

export enum ViajeTrasladoExpensesType {
    GASTO = 'General',
    PEAJE = 'Peaje'
}

