import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { createAntenaGrupoFormInitialValues } from './AntenaGrupoFormInitialValues';
import { AntenaGrupoFormType } from './AntenaGrupoFormType';
import { antenagrupoFormValidation } from './AntenaGrupoFormValidation';

interface Props {
  onSubmit: (value: AntenaGrupoFormType) => Promise<void>;
  initialValues?: AntenaGrupoFormType;
}

export function AntenaGrupoForm({
  onSubmit,
  initialValues = createAntenaGrupoFormInitialValues()
}: Props) {
  return (
    <Formik<AntenaGrupoFormType>
      initialValues={initialValues}
      validationSchema={antenagrupoFormValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="nombre"
            label="Nombre *"
            variant="outlined"
            className="col-span-6"
            component={TextField}
            fullWidth
          />

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
