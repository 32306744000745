import {
  choferPartialBuilder,
  ChoferPartialModel
} from 'app/chofer/models/Chofer';
import {
  applicantBuilder,
  ApplicantCrud,
  ApplicantModel
} from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import {
  authorizedBuilder,
  AuthorizedModel,
  AuthorizedModelCRUD
} from 'app/client/clientBusiness/Authorized/models/Authorized';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';
import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoEstado,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import moment, { Moment } from 'moment';
import { TransferReservationPrices } from './TransferReservationPrices';

export interface ViajeTrasladoModelLegacyDontUse {
  id: number;
  cliente: { id: number; name: string };
  canalIngreso: ViajeCanalesDeIngreso;
  plataformaId: string | null;
  prioridad: boolean;
  solicitante: ApplicantModel;
  tipoServicio: ViajeTrasladoTipoServicio;
  puntos: ViajeTrasladoPunto[];
  fechaYHoraDeSalida: Moment;
  fechaYHoraDeLlegada: Moment;
  observacionCoordinacion: string | null;
  estado: ViajeTrasladoEstado;
  state_datetime: Moment;
  choferPreAsignado: ChoferPartialModel | null;
  choferAsignado: ChoferPartialModel | null;
  autorizado?: AuthorizedModel;
  fixedRateName?: string;
  costCenter?: string;
  subCostCenter?: string;
  sector?: string;
  buyOrder?: string;
  employeeNumber?: string;
  platformApplication?: string;
  tripNumber?: string;
  data1: string;
  data2: string;
  data3: string;
  hasOutSite: boolean;
  deviation_observation: string | null;
  arrival_notification_channel: string | null;
  estimated_prices: TransferReservationPrices;
  calculated_prices?: TransferReservationPrices;
  final_prices?: TransferReservationPrices;
  pay_with_cash: boolean;
  is_event: boolean;
  event_name?: string;
  lltpais: boolean;
  customer_price?: number;
  provider_price?: number;
}

export type ViajeTrasladoCreateModelLegacyDontUse = Omit<
  ViajeTrasladoModelLegacyDontUse,
  | 'id'
  | 'choferPreAsignado'
  | 'choferAsignado'
  | 'estado'
  | 'solicitante'
  | 'autorizado'
  | 'fixedRateName'
  | 'state_datetime'
  | 'deviation_observation'
  | 'arrival_notification_channel'
  | 'estimated_prices'
  | 'calculated_prices'
> & {
  solicitante: ApplicantCrud;
  autorizado?: AuthorizedModelCRUD;
  fixedRate: RateModel['categories'][0]['fixedPrices'][0] | null;
};

export interface PasajeroTrasladoModel {
  nombreCompleto: string;
  telefono: number | string;
  mail: string;
}

export interface ViajeTrasladoPunto {
  domicilio: DomicilioModel;
  pasajeros: PasajeroTrasladoModel[];
  observacion: string | null;
  minutosEspera: number | null;
}

export const passangerBuilder = {
  toBackEnd: (pasajero: PasajeroTrasladoModel) => ({
    name: pasajero.nombreCompleto,
    phone: pasajero.telefono,
    email: pasajero.mail
  }),
  fromBackEnd: (pasajero): PasajeroTrasladoModel => ({
    nombreCompleto: pasajero.name,
    telefono: pasajero.phone,
    mail: pasajero.email
  })
};

export const locationBuilder = {
  toBackEnd: (point: ViajeTrasladoPunto) => ({
    address: domicilioBuilder.toBackEnd(point.domicilio),
    observation: point.observacion,
    minutes_waiting: point.minutosEspera,
    passengers: point.pasajeros.map(passangerBuilder.toBackEnd)
  }),
  fromBackEnd: (l): ViajeTrasladoPunto => ({
    domicilio: domicilioBuilder.fromBackEnd(l.address),
    pasajeros: l.passengers.map(passangerBuilder.fromBackEnd),
    observacion: l.observation,
    minutosEspera: l.minutesWaiting
  })
};

export const viajeTrasladoBuilderLegacyDontUse = {
  toBackEnd: (viaje: ViajeTrasladoCreateModelLegacyDontUse) => ({
    ...(viaje.lltpais
      ? {
          is_llt_pais: true,
          provider_price: viaje.provider_price,
          customer_price: viaje.customer_price
        }
      : {}),
    is_event: viaje.is_event,
    event_name: viaje.event_name,
    has_out_site: viaje.hasOutSite,
    fixed_price_name: viaje.fixedRate?.name || undefined,
    entry_channel: viaje.canalIngreso,
    platform_id: viaje.plataformaId,
    has_priority: viaje.prioridad,
    service_type: viaje.tipoServicio,
    coordination_observation: viaje.observacionCoordinacion,
    reservation_datetime:
      viaje.fechaYHoraDeLlegada.format('YYYY-MM-DD HH:mm') + ':00',
    advance_hours:
      viaje.fechaYHoraDeLlegada.diff(viaje.fechaYHoraDeSalida, 'minutes') / 60,
    hasOutSite: viaje.hasOutSite,
    pay_with_cash: viaje.pay_with_cash,
    requirement: viaje.pay_with_cash
      ? {
          applicant_name: viaje.solicitante.name,
          applicant_email: viaje.solicitante.email,
          applicant_phone: viaje.solicitante.phone
        }
      : {
          applicant_name: viaje.solicitante.name,
          applicant_email: viaje.solicitante.email,
          applicant_phone: viaje.solicitante.phone,
          cost_center: viaje.costCenter,
          sub_cost_center: viaje.subCostCenter,
          sector: viaje.sector,
          authorized_id: viaje.autorizado?.id,
          buy_order: viaje.buyOrder,
          employee_number: viaje.employeeNumber,
          platform_application: viaje.platformApplication,
          trip_number: viaje.tripNumber,
          data_1: viaje.data1,
          data_2: viaje.data2,
          data_3: viaje.data3
        },
    locations: viaje.puntos.map((point) => ({
      address: domicilioBuilder.toBackEnd(point.domicilio),
      observation: point.observacion,
      minutes_waiting: point.minutosEspera,
      passengers: point.pasajeros.map(passangerBuilder.toBackEnd)
    }))
  }),
  fromBackEnd: (viaje: any): ViajeTrasladoModelLegacyDontUse => {
    return {
      ...viaje,
      id: viaje.id,
      cliente: viaje.client,
      canalIngreso: viaje.entryChannel,
      plataformaId: viaje.platformId,
      prioridad: viaje.hasPriority,
      solicitante:
        viaje.requirements?.applicant &&
        applicantBuilder.fromBackend(viaje.requirements.applicant),
      tipoServicio: viaje.serviceType,
      fechaYHoraDeSalida: moment(viaje.reservationDatetime).subtract(
        viaje.advanceHours,
        'hours'
      ),
      fechaYHoraDeLlegada: moment(viaje.reservationDatetime),
      observacionCoordinacion: viaje.coordinationObservation,
      estado: viaje.state,
      choferPreAsignado:
        viaje.pre_assigned_driver &&
        choferPartialBuilder.fromBackEnd(viaje.pre_assigned_driver),
      choferAsignado:
        viaje.assigned_driver &&
        choferPartialBuilder.fromBackEnd(viaje.assigned_driver),
      autorizado:
        viaje.requirements?.authorized &&
        authorizedBuilder.fromBackEnd(viaje.requirements.authorized),
      fixedRateName: viaje.fixed_price_name,
      costCenter: viaje.requirements?.costCenter,
      subCostCenter: viaje.requirements?.subCostCenter,
      sector: viaje.requirements?.sector,
      buyOrder: viaje.requirements?.buyOrder,
      employeeNumber: viaje.requirements?.employeeNumber,
      platformApplication: viaje.requirements?.platformApplication,
      tripNumber: viaje.requirements?.tripNumber,
      data1: viaje.requirements?.data1,
      data2: viaje.requirements?.data2,
      data3: viaje.requirements?.data3,
      hasOutSite: viaje.has_out_site,
      puntos: viaje.locations.map(locationBuilder.fromBackEnd),
      state_datetime: moment(viaje.state_datetime),
      is_event: viaje.is_event,
      event_name: viaje.event_name,
      is_llt_pais: viaje?.lltpais,
      provider_price: viaje?.lltpais?.provider_price,
      customer_price: viaje?.lltpais?.customer_price
    };
  }
};
