import { Moment } from 'moment';
import { httpClient } from 'shared/services/http/httpClient';
import { ClientBusinessPartialModel } from '../../client/clientBusiness/models/ClientBusiness';
import { UserModel } from '../../user/models/User';
import { liquidacionBuilder } from '../models/Liquidacion';

const useLiquidacions = () => {
  // const addLiquidacion = (x: LiquidacionModelCRUD) => {
  //   return httpClient.post('liquidacionBuilders', liquidacionBuilder.toBackEnd(x));
  // };

  const getLiquidacions = async (filters?: {
    client: ClientBusinessPartialModel | null;
    oficial_cuenta: UserModel | null;
    date: Moment;
  }) => {
    const mappedFilters = !filters
      ? {}
      : {
          client_id: filters.client?.id,
          account_officer_id: filters.oficial_cuenta?.id,
          date: filters.date.format('YYYY-MM-DD'),
          page_size: 100000
        };
    let filtersString = '';
    if (filters) {
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }

    const res = await httpClient.get(
      `transfer-settlements?page_size=1000000&pending=1&${filtersString}`
    );
    const data = res.map(liquidacionBuilder.fromBackEnd);
    return data;
  };

  async function deleteReservations(info: {
    settlementId: number;
    reservas: number[];
  }) {
    return httpClient.put(`transfer-settlements/${info.settlementId}`, {
      deleted_reservation_ids: info.reservas
    });
  }

  async function deleteSettlement(settlementId) {
    return httpClient.delete(`transfer-settlements/${settlementId}`);
  }

  return {
    getLiquidacions,
    deleteReservations,
    deleteSettlement
  };
};

export default useLiquidacions;
