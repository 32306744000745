import {
  choferPartialBuilder,
  ChoferPartialModel
} from 'app/chofer/models/Chofer';
import {
  transferReservationPartialBuilder,
  TransferReservationPartialModel
} from '../../viajeTraslado/models/TransferReservationPartial';

export interface TransferReservationManagmentModel
  extends TransferReservationPartialModel {
  assigned_driver?: ChoferPartialModel;
}

export const transferReservationManagmentBuilder = {
  fromBackEnd: (reservation): TransferReservationManagmentModel => ({
    ...transferReservationPartialBuilder.fromBackEnd(reservation),
    contretemps: reservation.contretemps,
    assigned_driver:
      reservation.assigned_driver &&
      choferPartialBuilder.fromBackEnd(reservation.assigned_driver)
  })
};
