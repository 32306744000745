import { ViajeCanalesDeIngreso } from 'app/viaje/models';
import moment from 'moment';
import { ViajeTrasladoTipoServicio } from '../../viajeTraslado/models';
import { TransferReservationRepetitivePrivateFormType } from './TransferReservationRepetitivePrivateFormType';

export const createTransferReservationRepetitiveInitalValues =
  (): TransferReservationRepetitivePrivateFormType => ({
    cliente: null,
    canalIngreso: ViajeCanalesDeIngreso.TELEFONO,
    prioridad: false,
    tipoServicio: ViajeTrasladoTipoServicio.EJECUTIVO,
    observacionCoordinacion: '',
    fechaYHoraDeLlegada: moment(),
    minutosPreviosASalida: moment(),
    hasOutSite: false,
    pay_with_cash: true,
    puntos: [],
    repeatOnMonday: false,
    repeatOnTuesday: false,
    repeatOnWednesday: false,
    repeatOnThursday: false,
    repeatOnFriday: false,
    repeatOnSaturday: false,
    repeatOnSunday: false,
    repetitionFinishDate: moment(),
    repetitionInitDate: moment()
  });
