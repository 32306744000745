import { CircularProgress, IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { tw } from 'utils/tailwindUtils';
import MoreDriversRecomended from './MoreDriversRecomended';
import { DriverRecomended, DriversRecomended } from '../types/Driver';
import EmptyDriverRecomended from './EmptyDriverRecomended';
import { useEffect, useState } from 'react';
import DriverBusyMessage from './DriverRecomended/DriverBusy';
import Warning from '@material-ui/icons/Warning';

type Props = {
  isMobileOrTablet: boolean;
  isTravelSelected: boolean;
  isLoadingDriverRecomended: boolean;
  driversRecomended: DriversRecomended;
  onChangeDriverSelected: (driver: DriverRecomended) => void;
  onShowFiltersDriversRecomended: () => void;
};

const RowDriversRecomended = ({
  isMobileOrTablet,
  isLoadingDriverRecomended,
  isTravelSelected,
  driversRecomended,
  onShowFiltersDriversRecomended,
  onChangeDriverSelected
}: Props): JSX.Element => {
  const { length: sizeDrivers } = driversRecomended;
  const [codeDriver, setCodeDriver] = useState<number | undefined>();
  const [showError, setShowError] = useState<boolean>(false);

  const showErrorMessageDriverBusy = (driver: DriverRecomended) => {
    setCodeDriver(driver.code);
  };

  useEffect(() => {
    if (codeDriver === undefined) {
      return;
    }
    setShowError(true);
  }, [codeDriver]);

  return (
    <div
      className={tw('flex justify-center text-sm', {
        'justify-between': isMobileOrTablet
      })}
    >
      {isMobileOrTablet ? <p>Sugeridos</p> : null}
      <div className="w-28">
        {isTravelSelected ? (
          <div className="flex flex-col">
            <div className="flex h-full items-center justify-center">
              {isLoadingDriverRecomended ? (
                <div className="mt-auto mb-auto flex flex-col justify-center gap-2">
                  <CircularProgress
                    size={15}
                    className="ml-auto mr-auto"
                    style={{
                      color: 'gray'
                    }}
                  />
                  <p className="text-center text-xs text-gray-500">
                    Buscando..
                  </p>
                </div>
              ) : null}
            </div>
            {sizeDrivers === 0 && !isLoadingDriverRecomended ? (
              <EmptyDriverRecomended
                onShowFiltersDriversRecomended={onShowFiltersDriversRecomended}
              />
            ) : null}
            {sizeDrivers && !isLoadingDriverRecomended ? (
              <div className="mt-0 flex justify-center gap-3">
                <IconButton
                  size="small"
                  title="Filtro de sugeridos"
                  aria-label="filter by driver"
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowFiltersDriversRecomended();
                  }}
                >
                  <FilterListIcon fontSize="medium" color="disabled" />
                </IconButton>
                <MoreDriversRecomended
                  driversRecomended={driversRecomended}
                  onChangeDriverSelected={onChangeDriverSelected}
                />
              </div>
            ) : null}
            {sizeDrivers && !isLoadingDriverRecomended
              ? driversRecomended.slice(0, 5).map((driver) => {
                  const isDriverFree = driver.state === 'FREE';
                  const isDriverBusy = driver.state === 'BUSY';
                  return (
                    <DriverBusyMessage
                      isDriverBusy={isDriverBusy}
                      title="¡Ups! Vehículo Ocupado"
                      message={`El vehículo ${driver.code} ya no está disponible. Por favor, elige otra opción.`}
                      icon={
                        <Warning style={{ color: 'red' }} fontSize="small" />
                      }
                    >
                      <p
                        className={tw(
                          'hoverable cursor-pointer rounded-md text-center text-sm',
                          {
                            'cursor-not-allowed': isDriverBusy
                          }
                        )}
                        style={{
                          color: isDriverFree ? '#14B8A6' : '#FB7185'
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          isDriverFree
                            ? onChangeDriverSelected(driver)
                            : showErrorMessageDriverBusy(driver);
                        }}
                      >
                        {driver.code}
                      </p>
                    </DriverBusyMessage>
                  );
                })
              : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RowDriversRecomended;
