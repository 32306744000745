import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { Table } from 'shared/components';
import { BillerModel } from '../models/BillerModel';

interface Props {
  billers: BillerModel[];
  selectToEdit: (x: BillerModel) => void;
  delete: (x: BillerModel) => void;
  restore: (x: BillerModel) => void;
}

const ProveedorTable = (props: Props) => {
  const verifyAction = useVerifyAction();

  const columns = [
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'cuit',
      label: 'Cuit',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'bankName',
      label: 'Banco',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'isDeleted',
      label: 'Borrado',
      options: {
        display: false,
        filterOptions: {
          names: ['Si', 'No']
        },
        filterList: ['No'],
        customFilterListOptions: { render: (v) => `Borrado: ${v}` }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          props.billers[index].isDeleted ? (
            <Button onClick={() => props.restore(props.billers[index])}>
              Restaurar
            </Button>
          ) : (
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => props.selectToEdit(props.billers[index])}
                shape="circle"
                color="primary"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                onClick={() =>
                  verifyAction({
                    title: 'Estas por eliminar un facturador!',
                    body: 'Esta accion va a eliminar al facturador. ¿Estas seguro?',
                    onAccept: () => props.delete(props.billers[index])
                  })
                }
                shape="circle"
                color="red"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          )
      }
    }
  ];

  const data = props.billers.map((x) => ({
    ...x,
    bankName: x.bankAccount.bankName,
    isDeleted: x.isDeleted ? 'Si' : 'No'
  }));
  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Proveedores'}
        data={data}
        columns={columns}
      />
    </div>
  );
};
export default ProveedorTable;
