import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { useHistory } from 'react-router-dom';
interface Props {
  toggleSidebar: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Header = (props: Props) => {
  const history = useHistory();

  return (
    <div
      className="flex flex-grow shadow-lg border-b-2 border-solid border-gray-100 bg-white"
      style={{ zIndex: 510 }}
    >
      <div className="flex flex-grow justify-between px-3 text-primary items-center h-16">
        <button
          className="lg:hidden mr-4"
          onClick={(e) => props.toggleSidebar(e)}
        >
          <MenuIcon />
        </button>

        {/* <div className="flex gap-3 items-center justify-end w-full">
          <Button onClick={authService.logout}>
            <div className="flex space-x-2 items-center">
              <span>Salir</span>
              <FaSignOutAlt size="18" />
            </div>
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
