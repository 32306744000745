import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from '@vadiun/react-components';
import { ClientBusinessPartialModel } from "app/client/clientBusiness/models/ClientBusiness";
import { MUIDataTableColumnDef } from "mui-datatables";
import { FC, useMemo } from 'react';
import { Table } from "shared/components";
import { exportTripsToExcel } from '../export/exportToExcel';
import { ColumnMapping, TripCompletionType } from '../utils/types';

interface TravelCompleteProps {
  createdTrips: TripCompletionType[];
  discardedTrips: TripCompletionType[];
  mappingColumns: ColumnMapping[];
  client: ClientBusinessPartialModel;
}

const TravelComplete: FC<TravelCompleteProps> = ({
  createdTrips,
  mappingColumns,
  discardedTrips,
  client
}) => {

  const columns = useMemo(() => {
    const mappingDataColumns = mappingColumns.map(({ id, label }): MUIDataTableColumnDef => {
      return {
        name: id,
        label: label,
        options: {
          customBodyRender: (data) => {
            return <div>{data}</div>;
          },
          customHeadRender: () => {
            return (
              <th style={{ paddingRight: '20px', textAlign: 'left', minWidth: '200px' }} key={id}>
                {label}
              </th>
            );
          }
        }
      }
    });
    return mappingDataColumns;
  }, [mappingColumns])

  return (
    <div className="flex flex-col gap-y-4">

      <div className="py-4">
        <div className="border-b-2 border-dashed py-4">
          <h2 className="text-center text-2xl">Proceso de creación completo.</h2>
        </div>

      </div>
      <div className="flex justify-end items-center  gap-x-4 border-b-2 border-dashed pb-8">
        <Button
          type="button"
          disabled={!discardedTrips.length}
          onClick={() => exportTripsToExcel(discardedTrips, mappingColumns, `${client.fantasyName} cuit: ${client.cuit}`, 'DISCARDED')}
          variant="outlined"
          color="gray"
          className="w-auto"
        >
          <div className="flex gap-x-4 items-center">
            <FontAwesomeIcon icon={faFileExcel} size="2x" color="#21a366" />
            <span>Exportar descartados</span>
          </div>
        </Button>
        <Button
          type="button"
          onClick={() => exportTripsToExcel(createdTrips, mappingColumns, `${client.fantasyName} cuit: ${client.cuit}`, 'OK')}
          variant="outlined"
          color="gray"
          className="w-auto"
        >
          <div className="flex gap-x-4 items-center">
            <FontAwesomeIcon icon={faFileExcel} size="2x" color="#21a366" />
            <span>Exportar completados</span>
          </div>
        </Button>
      </div>
      <Table
        title="Viajes creados"
        options={{
          elevation: 0,
          selectableRows: 'none',
          selectToolbarPlacement: 'none',
          search: false,
          filter: false,
          sort: false,
          download: false,
          print: false,
          fixedSelectColumn: true,
          pagination: true,
          rowsPerPage: 10,
          rowsPerPageOptions: createdTrips.length > 30 ? [10, 30, createdTrips.length] : [10, 30],
        }}
        columns={columns}
        data={createdTrips}
      />

    </div>
  )

};

export default TravelComplete;