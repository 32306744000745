import { z } from 'zod';
import {
  ClientBusinessModel,
  ClientBusinessPartialModel
} from '../../../../../../client/clientBusiness/models/ClientBusiness';

export enum ClientRequirements {
  COST_CENTER = 'cost_center',
  SUB_COST_CENTER = 'sub_cost_center',
  SECTOR = 'sector',
  AUTHORIZED_ID = 'authorized_id',
  BUY_ORDER = 'buy_order',
  EMPLOYEE_NUMBER = 'employee_number',
  PLATFORM_APPLICATION = 'platform_application',
  TRIP_NUMBER = 'trip_number',
  DATA_1 = 'data_1',
  DATA_2 = 'data_2',
  DATA_3 = 'data_3'
}

export enum ColumnIdType {
  PAY_WITH_CASH = 'pay_with_cash',
  SERVICE_TYPE = 'service_type',
  FIXED_PRICE_NAME = 'fixed_price_name',
  COORDINATION_OBSERVATION = 'coordination_observation',
  HOUR = 'hour',
  DATE = 'date',
  ADVANCED_HOURS = 'advance_hours',
  DIRECTION_ONE = 'direction_one',
  DIRECTION_TWO = 'direction_two',
  DIRECTION_THREE = 'direction_three',
  PASSANGERS_DIR_ONE = 'passengers_dir_one',
  PASSANGERS_DIR_ONE_PHONE = 'passengers_dir_one_phone',
  PASSANGERS_DIR_TWO = 'passengers_dir_two',
  PASSANGERS_DIR_TWO_PHONE = 'passengers_dir_two_phone',
  PASSANGERS_DIR_THREE = 'passengers_dir_three',
  PASSANGERS_DIR_THREE_PHONE = 'passengers_dir_three_phone',
  COST_CENTER = 'cost_center',
  SUB_COST_CENTER = 'sub_cost_center',
  SECTOR = 'sector',
  AUTHORIZED_ID = 'authorized_id',
  BUY_ORDER = 'buy_order',
  EMPLOYEE_NUMBER = 'employee_number',
  PLATFORM_APPLICATION = 'platform_application',
  TRIP_NUMBER = 'trip_number',
  DATA_1 = 'data_1',
  DATA_2 = 'data_2',
  DATA_3 = 'data_3',
  ID = 'id'
}

export enum ColumnDataType {
  TEXT_FIELD = 'text_field',
  TEXT_AREA = 'text_area',
  DATE_FIELD = 'date_field',
  BOOLEAN_FIELD = 'boolean_field',
  DROPDOWN_FIELD = 'dropdown_field',
  ADDRESS_FIELD = 'address_field'
}

export type MappingType = { [key in ColumnIdType]: string | string[] };

export type MappingBEType = {
  [key in ColumnIdType]: {
    data: string;
    have_error: boolean;
  };
};

export interface BEValidationType extends MappingBEType {
  locations: {
    have_error: boolean;
    data: unknown;
  }[];
}

export interface BEMappingType extends MappingType {
  locations: unknown[];
}

export type TripCompletionType = {
  [key in ColumnIdType]: string;
};

export interface ColumnMapping {
  type: ColumnDataType;
  mandatory: boolean;
  inUse: boolean;
  id: ColumnIdType;
  label: string;
  allowMultiple: boolean;
  columnId: number[];
  groupType: 'client' | 'trip';
}

interface SetColumnType {
  type: 'SET_COLUMN_TYPE';
  payload: {
    id: ColumnIdType;
    columnId: number;
  };
}

interface RemoveColumnType {
  type: 'REMOVE_COLUMN_TYPE';
  payload: {
    id: ColumnIdType;
    columnId: number;
  };
}

interface deleteColumnType {
  type: 'DELETE_COLUMN';
  payload: {
    columnId: number;
  };
}

interface ResetType {
  type: 'RESET';
  payload: MappingDataType;
}

export type MappingReducerActionType =
  | SetColumnType
  | RemoveColumnType
  | deleteColumnType
  | ResetType;

export interface MappingDataType {
  columns: ColumnMapping[];
  columnNumber: number;
}

interface SelectTravelRowType {
  type: 'SELECT_TRAVEL_ROW_TYPE';
  payload: {
    data: MappingBEType;
    rowNumber: number;
  };
}

interface UnSelectTravelRowType {
  type: 'UN_SELECT_TRAVEL_ROW_TYPE';
}

interface ResetVerificationType {
  type: 'RESET';
}

interface SetSelectedRowsTripType {
  type: 'SET_SELECTED_ROWS_TRIPS_TYPE';
  payload: number[];
}

export type VerificationReducerActionType =
  | SelectTravelRowType
  | UnSelectTravelRowType
  | ResetVerificationType
  | SetSelectedRowsTripType;

export interface VerificationDataType {
  selectedRow?: {
    data: MappingBEType;
    rowNumber: number;
  };
  selectedRowTrips: number[];
}

export type ClientBMRequirementType = keyof Omit<
  ClientBusinessModel['reservationRequirement'],
  'id'
>;

export interface ClientRequirementType {
  type: ClientRequirements;
  label: string;
  mappingKey: ClientBMRequirementType;
}

export const TravelApplicantFormSchema = z.object({
  applicant_name: z
    .string({ required_error: 'Campo requerido' })
    .min(1, 'Campo requerido'),
  applicant_email: z
    .string()
    .email({
      message: 'Email inválido'
    })
    .optional()
    .or(z.literal('')),
  applicant_phone: z
    .string()
    .optional(),
});

//ref: https://zod.dev/?id=refine
const TravelEventFormSchema = z.object({
  is_event: z
    .boolean(),
  event_name: z
    .string()
})

export const TravelClientRequirementsFormSchema =
  TravelApplicantFormSchema.merge(TravelEventFormSchema).refine(data => data.is_event ? data.event_name !== "" : true, {
    message: 'Campo requerido',
    path: ['event_name']
  })

export type TravelClientRequirementsForm = z.infer<
  typeof TravelClientRequirementsFormSchema
>;

export type TravelApplicantForm = z.infer<
  typeof TravelApplicantFormSchema
>;
export type TravelEventForm = z.infer<
  typeof TravelEventFormSchema
>;

export interface BulkClient {
  info: ClientBusinessPartialModel;
  requirements: TravelApplicantForm;
  event: TravelEventForm;
  reservationRequirements: ClientRequirementType[];
  authorizedPersonal?: string[];
  subCostElements?: string[];
}

export interface ExcelColumn {
  header: string;
  key: ColumnIdType;
}
