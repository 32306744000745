import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { DriverTransferReservationsWithPricesAndResume } from '../models/DriverTransferReservationsWithSettlementInfo';

interface Props {
  data: DriverTransferReservationsWithPricesAndResume['data'];
  isLoading: boolean;
}

const DriverPendingSettlementMobile: React.FC<Props> = ({
  data,
  isLoading
}: Props) => {
  const filteredData = (data ?? []).map((d) => {
    return {
      ...d,
      client: d.client.name,
      locations: d.locations,
      status: d.state,
      dateTime: d.reservationDatetime.format('DD/MM/YYYY HH:mm [hs.]')
    };
  });
  const groupedData = () => {
    const grupos: { grupo: string; reservas: any[] }[] = [];

    filteredData?.forEach((item) => {
      const grupo = grupos.find(
        (grupo) => grupo.grupo === item.reservationDatetime.format('DD/MM/YYYY')
      );
      if (grupo !== undefined) {
        grupo.reservas.push(item);
      } else {
        grupos.push({
          grupo: item.reservationDatetime.format('DD/MM/YYYY'),
          reservas: [item]
        });
      }
    });
    return grupos;
  };

  return (
    <>
      {isLoading ? (
        <div className="mt-8 flex w-full items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {groupedData().length === 0 ? (
            <p className="p-4">
              No hay viajes a liquidar en el rango de fechas ingresado
            </p>
          ) : null}
          {groupedData().map((el) => (
            <div className="mb-4">
              <p className="pl-4 font-bold text-primary-500">{el.grupo}</p>

              {el.reservas.map((reserva: any) => (
                <Accordion
                  style={{ boxShadow: 'none', marginTop: 8, marginLeft: 20 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="w-full">
                      <div className="grid w-full grid-cols-3 text-sm font-light">
                        <span className="text-[#080808]">
                          {reserva.reservationDatetime.format('HH:mm [hs.]')}
                        </span>
                        <span className="text-gray-400">{reserva.client}</span>
                        <span className="text-gray-400">
                          {reserva.total_a_facturar}
                        </span>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="w-full">
                      <div className="flex w-2/3 flex-col text-sm">
                        <div className="mb-2 flex justify-between">
                          <span>Contado:</span>
                          <span>${reserva.contado}</span>
                        </div>
                        <div className="mb-2 flex justify-between">
                          <span>Cuenta Corriente:</span>
                          <span>${reserva.cuenta_corriente}</span>
                        </div>
                        <div className="mb-2 flex justify-between">
                          <span>Espera:</span>
                          <span>${reserva.total_precio_espera}</span>
                        </div>
                        <div className="mb-2 flex justify-between">
                          <span>Gastos:</span>
                          <span>${reserva.total_gastos}</span>
                        </div>
                      </div>
                      <div className="my-2 flex justify-between border-t border-b border-gray-200 py-4">
                        <span>Estado:</span>
                        <span>{reserva.state}</span>
                      </div>
                      <div className="flex justify-between border-b border-gray-200 py-4">
                        <span>ID:</span>
                        <span>{reserva.id}</span>
                      </div>
                      <div className="my-2 flex flex-col py-4 font-light">
                        <span className="pb-2">Recorrido:</span>
                        {reserva.locations.map(({ address }, index) => (
                          <div className="mb-4 flex">
                            <div className="flex flex-col items-center">
                              <div className="mt-2 h-3 w-3 rounded-full bg-primary-800" />
                              {reserva.locations.length - 1 !== index ? (
                                <div className="mt-2 h-8 w-1 rounded-3xl bg-gray-200" />
                              ) : null}
                            </div>

                            <div className="ml-6 flex flex-col">
                              <span>
                                {address.calle} {address.numero}
                              </span>
                              <span className="text-gray-400">
                                {address.localidad}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default DriverPendingSettlementMobile;
