import React from 'react';
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  Polyline,
  withGoogleMap
} from 'react-google-maps';

import {
  Marker as StaticMarker,
  StaticGoogleMap
} from 'react-static-google-map';

export interface DynamicMapProps {
  options?: google.maps.MapOptions;
  markers?: {
    lat: number;
    lng: number;
    key?: number | string | undefined;
    icon?: google.maps.Icon | undefined;
    label?: string | undefined;
    onClick?(): void;
    onMouseOver?(): void;
  }[];
  polyline?: {
    style?: 'primary' | 'secondary';
    path: { lat: number; lng: number }[];
  };
  defaultCenter?: { lat: number; lng: number };
  autofocus?: boolean;
  children?: React.ReactNode;
  directions?: google.maps.DirectionsResult;
}

export interface StaticMapProps {
  markers?: {
    lat: number;
    lng: number;
    key?: number | string | undefined;
    label?: string | undefined;
  }[];
  size: string;
  children?: React.ReactNode;
}

const polylineStyles = {
  primary: {
    geodesic: true,
    strokeColor: '#374151',
    strokeOpacity: 1.0,
    strokeWeight: 5
  },
  secondary: {
    strokeOpacity: 0,
    icons: [
      {
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 0.4,
          scale: 2
        },
        offset: '0',
        repeat: '10px'
      }
    ]
  }
};

export const StaticMap = (props: StaticMapProps) => {
  return (
    <StaticGoogleMap
      size={props.size}
      className="img-fluid"
      apiKey="AIzaSyB1HRZqZqkK2Mi25VrETY_sMGmA5aJkYuo"
    >
      {(props.markers || []).map((marker) => (
        <StaticMarker
          key={marker.key || marker.lat.toString() + marker.lng.toString()}
          location={marker}
          label={marker.label}
        />
      ))}
    </StaticGoogleMap>
  );
};

export const DynamicMap = withGoogleMap((props: DynamicMapProps) => {
  let center = props.defaultCenter;
  const autofocus = props.autofocus ?? true;
  if (center === undefined && props.markers && props.markers.length > 0) {
    center = props.markers[0];
  }
  if (
    center === undefined &&
    props.polyline &&
    props.polyline.path &&
    props.polyline.path.length > 0
  ) {
    center = props.polyline.path[0];
  }
  if (center === undefined) {
    center = {
      lat: -34.62421212014626,
      lng: -58.4893820304175
    };
  }
  // Si le paso center={undefined} al GoogleMap se muestra el mapa en gris y no funciona
  // Si en cambio lo hago destructurando un objeto funciona correctamente, totalmente absurdo pero por alguna razon
  const centerProps = autofocus ? { center } : {};
  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={center}
      {...centerProps}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: false,
        fullscreenControl: false,
        zoomControl: false,
        ...props.options
      }}
    >
      {(props.markers || []).map((marker, index) => (
        <Marker
          key={marker.key || marker.lat.toString() + marker.lng.toString()}
          position={marker}
          icon={marker.icon}
          onClick={marker.onClick}
          onMouseOver={marker.onMouseOver}
          label={
            marker.label
              ? {
                  text: marker.label,
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }
              : undefined
          }
        />
      ))}
      {props.polyline && (
        <Polyline
          path={props.polyline?.path}
          options={polylineStyles[props.polyline?.style ?? 'primary']}
        />
      )}
      {props.directions ? (
        <DirectionsRenderer directions={props.directions} />
      ) : null}
      {props.children}
    </GoogleMap>
  );
});
