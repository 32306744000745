import React from 'react';
import { Card } from 'shared/components';
import TollForm from './TollForm/TollForm';
import { TollFormType } from './TollForm/TollFormik';

interface Props {
  create: (TollValues: TollFormType, reset) => void;
}
function TollCreate({ create }: Props) {
  return (
    <Card title="Nuevo peaje" className="w-full">
      <div className="p-8">
        <TollForm onSubmit={create} />
      </div>
    </Card>
  );
}

export default TollCreate;
