import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Table } from '@vadiun/react-components';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import { TransferReservationManagmentModel } from 'app/viaje/viajeTraslado/gestion/models/TransferReservationManagment';
import LocationDetails from 'app/viaje/viajeTraslado/viajeTraslado/components/LocationDetails';
import { TransferReservationPartialModel } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPartial';
import { useState } from 'react';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';

interface Props {
  tableProps: UseTableQueryResult<TransferReservationManagmentModel[], unknown>;
  setSelectedRows: (x: number[]) => void;
  selectedRows: number[];
}

interface ExtendedTransferReservationManagmentModel
  extends TransferReservationManagmentModel {
  requirements: {
    platformApplication: string;
    costCenter: string;
  };
}

export function ReservationsFromLiquidacionTable(props: Props) {
  const data = (
    (props.tableProps.data ?? []) as ExtendedTransferReservationManagmentModel[]
  ).map((reservation) => ({
    ...reservation,
    schedule: {
      arrival: reservation.reservationDatetime,
      exit: reservation.reservationDatetime
        .clone()
        .add(-reservation.advanceHours, 'hours'),
      end: reservation.arrivalDateTime
    },
    passengers: reservation.locations
      .map((location) => location.passengers.map((passenger) => passenger.name))
      .flat()
  }));

  const [locationsDetail, setLocationsDetail] = useState<{
    isOpen: boolean;
    details?: {
      address: DomicilioModel;
      observation: string;
    }[];
  }>({ isOpen: false, details: undefined });

  const onLocationDetails = (
    locations: TransferReservationPartialModel['locations']
  ) => {
    setLocationsDetail({
      isOpen: true,
      details: locations.map((l) => ({
        address: l.address,
        observation: l.observation
      }))
    });
  };

  const columns = [
    {
      name: 'schedule',
      label: 'Horarios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="grid grid-cols-2 items-center">
              <span
                className={'title col-span-1 mr-4'}
                title="Salida de chofer"
              >
                {data[index].schedule.exit.format('HH:mm')}
              </span>
              <span
                className={'col-span-1 text-xs text-gray-400 '}
                title="Salida de chofer"
              >
                {data[index].schedule.exit.format('DD/MM')}
              </span>
              <span
                className="title col-span-1 mr-4 font-bold"
                style={{ fontSize: 16 }}
                title="Llegada a origen"
              >
                {data[index].schedule.arrival.format('HH:mm')}
              </span>
              <span
                className="col-span-1 text-xs font-bold text-gray-700"
                style={{ fontSize: 13 }}
                title="Llegada a origen"
              >
                {data[index].schedule.arrival.format('DD/MM')}
              </span>
              <span
                className="title col-span-1 mr-4 text-gray-400"
                title="Llegada a destino"
              >
                {data[index].schedule.end.format('HH:mm')}
              </span>
              <span
                className="col-span-1 text-xs text-gray-400"
                title="Llegada a destino"
              >
                {data[index].schedule.end.format('DD/MM')}
              </span>
            </div>
          );
        }
      }
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => data[index].client.name
      }
    },
    {
      name: 'puntos',
      label: 'Domicilios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col items-start">
              {data[index].locations.map((location, index) => (
                <div className="mb-1 flex items-start">
                  <div className="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    {index + 1}
                  </div>
                  <span className="ml-1">
                    {createDomicilioText(location.address)}
                  </span>
                </div>
              ))}
              <Button
                shape="circle"
                className="w-full p-2"
                onClick={() => onLocationDetails(data[index].locations)}
                color="blue"
              >
                <div className="flex items-center gap-1">Info</div>
              </Button>
            </div>
          );
        }
      }
    },
    {
      name: 'coordinationObservation',
      label: 'Obs. Coordinación'
    },
    {
      name: 'serviceType',
      label: 'Servicio',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex items-center space-x-2">
            <p>
              {data[index].serviceType === ViajeTrasladoTipoServicio.EJECUTIVO
                ? null
                : data[index].serviceType}
            </p>{' '}
            {data[index].hasPriority && (
              <FontAwesomeIcon icon={faStar} color="orange" />
            )}
          </div>
        )
      }
    },
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'platformId',
      label: 'ID Plataforma'
    },
    {
      name: 'platformApplication',
      label: 'Solicitud Plataforma',
      options: {
        customBodyRenderLite: (index) =>
          data[index].requirements.platformApplication
      }
    },
    {
      name: 'costCenter',
      label: 'Centro de Costos',
      options: {
        customBodyRenderLite: (index) => data[index].requirements.costCenter
      }
    },
    {
      name: 'passengers',
      label: 'Pasajeros',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="flex flex-col">
            {data[index].passengers.map((el) => (
              <span>{el}</span>
            ))}
          </div>
        )
      }
    },
    {
      name: 'assigned_driver',
      label: 'Chofer',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex flex-col items-center">
            <p>{data[index].assigned_driver?.codigo}</p>
            <p className="text-center text-xs">
              {data[index].assigned_driver?.nombreCompleto}
            </p>
          </div>
        )
      }
    },
    {
      name: 'state',
      label: 'Estado'
    }
  ];
  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          elevation: 0,
          search: false,
          download: false,
          viewColumns: false,
          filter: false,
          print: false,
          selectableRows: 'multiple',
          selectToolbarPlacement: 'none',
          rowsSelected: props.selectedRows.map((id) =>
            data.findIndex((d) => d.id === id)
          ),
          ...props.tableProps.serverSideOptions,
          onRowSelectionChange: (_, selection) =>
            props.setSelectedRows(selection.map((x) => data[x.dataIndex].id))
        }}
        title={''}
        columns={columns}
      />
      <Modal
        open={locationsDetail.isOpen}
        onClose={() =>
          setLocationsDetail({ isOpen: false, details: undefined })
        }
        size="md"
        body={
          <div style={{ height: 300 }}>
            <LocationDetails
              locations={(locationsDetail.details ?? []).map((l) => ({
                ...l,
                passengers: []
              }))}
            />
          </div>
        }
      />
    </div>
  );
}
