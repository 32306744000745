import React from 'react';
import Logo from 'assets/logo.png';
const Landing = () => {
  return (
    <div className="bg-gray-900 h-screen flex justify-center items-center">
      <img src={Logo} alt="logo" />
    </div>
  );
};

export default Landing;
