import { tw } from 'utils/tailwindUtils';

type Props = {
  current: any;
  isMobileOrTablet: boolean;
};

const Client = ({ current, isMobileOrTablet }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between text-sm">
        {isMobileOrTablet ? <p>Cliente</p> : null}
        <p
          className={`w-44 text-sm ${
            !current.pay_with_cash ? 'text-blue-500' : 'text-black'
          }`}
        >
          {current.client.name}
        </p>
      </div>
      <div
        className={tw('hidden flex-col gap-1', {
          flex: current.lltpais ? true : false
        })}
      >
        <div className="flex text-sm w-fit text-blue-100 font-bold px-2 py-1 bg-blue-900 rounded-lg mb-1">
          LLT-País
        </div>
        <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
          Proveedor
          <span>${Number(current?.lltpais?.provider_price ?? 0)}</span>
        </p>
        <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
          Cliente
          <span>${Number(current?.lltpais?.customer_price ?? 0)}</span>
        </p>
      </div>
    </div>
  );
};

export default Client;
