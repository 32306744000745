import { AntenaGrupoModel } from '../../models/AntenaGrupo';

export type AntenaGrupoFormType = Omit<AntenaGrupoModel, 'isDeleted' | 'id'> & {
  id?: number;
};

export const antenagrupoFormBuilder = {
  toBackEnd: (antenagrupo: AntenaGrupoFormType) => {
    return {
      id: antenagrupo.id,
      nombre: antenagrupo.nombre
    };
  },
  fromModel: (antenagrupo: AntenaGrupoModel): AntenaGrupoFormType => {
    return {
      ...antenagrupo
    };
  }
};
