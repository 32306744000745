import { TextField } from "@material-ui/core";
import { ChangeEvent, FC, useState } from "react";

interface TextMatchingInputProps {
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  data: string;
  label: string;
  hasError: boolean;
}

const TextMatchingInput: FC<TextMatchingInputProps> = ({
  onChange,
  data,
  label,
  hasError
}) => {
  const [hasFieldError, setHasFieldError] = useState<boolean>(hasError);

  return (
    <TextField
      error={hasFieldError}
      defaultValue={data}
      onChange={(e) => {
        setHasFieldError(false);
        onChange(e);
      }}
      className="w-full"
      placeholder={label}
      variant="outlined"
    />
  )

};

export default TextMatchingInput;