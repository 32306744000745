import * as faker from 'faker/locale/es';
import moment from 'moment';
import { mockedDomicilioGoogle } from 'shared/models/DomicilioGoogle';
import {
  ViajeCanalesDeIngreso,
  ViajeMensajeriaModel,
  ViajeMensajeriaPunto,
  SolicitanteMensajeriaModel,
  ViajeMensajeriaTipoServicio
} from 'app/viaje/models';

export const mockedViajeMensajeria = (): ViajeMensajeriaModel => ({
  id: faker.random.number(),
  //cliente: mockedCliente(),
  canalIngreso: ViajeCanalesDeIngreso.ABSSA,
  plataformaId: null,
  prioridad: true,
  solicitante: {
    nombreCompleto: faker.name.findName(),
    telefono: faker.random.number(),
    mail: faker.internet.email()
  },
  tipoServicio: ViajeMensajeriaTipoServicio.EJECUTIVO,
  puntos: [mockedViajeMensajeriaPunto(), mockedViajeMensajeriaPunto()],
  horaSalida: faker.random.number(),
  fechaYHora: moment(),
  observacionCoordinacion: null
});

export function mockedViajeMensajeriaPunto(): ViajeMensajeriaPunto {
  return {
    domicilio: mockedDomicilioGoogle(),
    pasajeros: [
      {
        nombreCompleto: faker.name.findName(),
        telefono: faker.random.number(),
        mail: faker.internet.email()
      }
    ],
    observacion: null,
    minutosEspera: null
  };
}

export function mockedSolicitante(): SolicitanteMensajeriaModel {
  return {
    id: faker.random.number(),
    nombreCompleto: faker.name.findName(),
    telefono: faker.random.number(),
    mail: faker.internet.email()
  };
}
