import {
  choferPartialBuilder,
  ChoferPartialModel
} from 'app/chofer/models/Chofer';
import {
  transferReservationPartialBuilder,
  TransferReservationPartialModel
} from '../../viajeTraslado/models/TransferReservationPartial';

export interface TransferReservationOpenedModel
  extends TransferReservationPartialModel {
  assigned_driver: ChoferPartialModel;
}

export const transferReservationOpenedBuilder = {
  fromBackEnd: (reservation): TransferReservationOpenedModel => ({
    ...transferReservationPartialBuilder.fromBackEnd(reservation),
    assigned_driver: choferPartialBuilder.fromBackEnd(
      reservation.assigned_driver
    )
  })
};
