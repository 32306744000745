import { ChoferTrabajando } from 'app/chofer/models/ChoferTrabajando';
import { MonitorElem, MonitorElemItem } from './MonitorElem';

interface Props {
  isActive: boolean;
  statusActive: string;
  groups: MonitorElemItem[];
  showDistrictLocalities: (districtName: string) => void;
  showDriverDetails: (driver: ChoferTrabajando) => void;
}

export function MonitorGroup({
  isActive,
  statusActive,
  groups,
  showDistrictLocalities,
  showDriverDetails
}: Props) {
  const ordering = {
    Zarate: 1,
    Escobar: 2,
    Pilar: 3,
    Tortuguitas: 4,
    'Jose C Paz': 5,
    Tigre: 6,
    'Zona norte': 7,
    CABA: 8,
    Microcentro: 9,
    'CABA II': 10,
    Meli: 11,
    Oeste: 12,
    Sur: 13
  };

  function compare(a, b) {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  }

  const sorted = groups.sort((a, b) => {
    return compare(ordering[a.name], ordering[b.name]);
  });

  return isActive ? (
    <div className="flex flex-wrap gap-3">
      {sorted.map((g) => (
        <MonitorElem
          item={g}
          key={g.name}
          showDistrictLocalities={showDistrictLocalities}
          showDriverDetails={showDriverDetails}
          isUnavailableDriver={statusActive === 'ON_BRAKE'}
        />
      ))}
      {/* {showDriversSleeping && sinTurnoAbiertoGroups.length !== 0 ? (
        <SinTurnoAbiertoMonitorElement
          isUnavailableDriver
          groups={sinTurnoAbiertoGroups}
          showDriverDetails={showDriverDetails}
        />
      ) : null} */}
      {groups.length === 0 ? (
        <p className="text-sm text-gray-400">Sin resultados.</p>
      ) : null}
    </div>
  ) : null;
}
