import { InputAdornment, TextField as MUITextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, FormRepeater } from '@vadiun/react-components';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import { Field, FieldArray, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import FormikAddress from 'shared/components/FormikAddress';
import { TransferReservationBaseLocationFormType } from './TransferReservationBaseFormTypes';
import { transferReservationBaseLocationFormValidation } from './TransferReservationBaseFormValidation';

interface Props {
  onCancel: () => void;
  onContinue: (values: TransferReservationBaseLocationFormType) => void;
  onMorePoints: (values: TransferReservationBaseLocationFormType) => void;
  initialValue: TransferReservationBaseLocationFormType;
  isEditing: boolean;
  pasajeros: PassengerModel[];
}

const ViajeTrasladoPuntoForm = (props: Props) => {
  const [actionType, setActionType] = useState<'continue' | 'morePoints'>(
    'continue'
  );

  async function onSubmit(values: TransferReservationBaseLocationFormType) {
    if (actionType === 'continue') {
      props.onContinue(values);
      return;
    }
    props.onMorePoints(values);
  }
  return (
    <div>
      <Formik
        initialValues={props.initialValue}
        validationSchema={transferReservationBaseLocationFormValidation}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ submitForm, values, touched, errors, setFieldValue }) => (
          <Form>
            <div className="mt-8 grid grid-cols-3 gap-x-4">
              <div className="col-span-3 grid grid-cols-12 gap-x-4">
                <Field
                  name={`domicilio`}
                  label={`Domicilio`}
                  variant="outlined"
                  component={FormikAddress}
                  className="col-span-12"
                  margin="normal"
                  fullWidth
                />

                <Field
                  name={`observacion`}
                  label="Observación para chofer"
                  variant="outlined"
                  component={TextField}
                  fullWidth
                  className="col-span-9"
                  margin="normal"
                />
                <Field
                  name="espera"
                  label="Espera "
                  variant="outlined"
                  component={TextField}
                  type="number"
                  fullWidth
                  className="col-span-3"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">min.</InputAdornment>
                    )
                  }}
                />
                <h5 className="mt-4 mb-2 text-gray-700">Pasajeros</h5>
                <FieldArray
                  name={`pasajeros`}
                  render={(arrayHelpersPasajeros) => (
                    <FormRepeater
                      arrayHelpers={arrayHelpersPasajeros}
                      addLabel="+ Pasajero"
                      createField={() => ({
                        nombreCompleto: '',
                        telefono: '',
                        mail: ''
                      })}
                      className="col-span-12 mb-4 px-4"
                    >
                      {values.pasajeros.map((pasajero, indicePasajero) => (
                        <div key={indicePasajero} className="flex w-full gap-4">
                          <Autocomplete
                            freeSolo
                            noOptionsText="No se han encontrado resultados"
                            loadingText="Cargando..."
                            options={props.pasajeros.map((p) => p.name)}
                            fullWidth
                            size="small"
                            blurOnSelect
                            renderOption={(option) => (
                              <span className="flex items-center gap-2">
                                {option}{' '}
                                {props.pasajeros.find((p) => p.name === option)
                                  ?.has_priority ? (
                                  <FaStar className="text-orange-500" />
                                ) : null}{' '}
                              </span>
                            )}
                            filterOptions={(options, state) => {
                              return options.filter((opt) =>
                                opt
                                  .toLowerCase()
                                  .includes(
                                    pasajero.nombreCompleto.toLowerCase()
                                  )
                              );
                            }}
                            value={pasajero.nombreCompleto}
                            onChange={(_, value) => {
                              const pasajero = props.pasajeros.find(
                                (p) => p.name === value
                              );
                              setFieldValue(
                                `pasajeros.${indicePasajero}.telefono`,
                                pasajero?.phone ?? ''
                              );
                              setFieldValue(
                                `pasajeros.${indicePasajero}.mail`,
                                pasajero?.email ?? ''
                              );
                              setFieldValue(
                                `pasajeros.${indicePasajero}.nombreCompleto`,
                                pasajero?.name ?? ''
                              );
                            }}
                            onInputChange={(_, value) => {
                              setFieldValue(
                                `pasajeros.${indicePasajero}.nombreCompleto`,
                                value
                              );
                            }}
                            renderInput={(params) => (
                              <MUITextField
                                {...params}
                                label="Nombre"
                                variant="outlined"
                                margin="none"
                                error={
                                  touched[
                                    `pasajeros.${indicePasajero}.nombreCompleto`
                                  ] &&
                                  !!errors[
                                    `pasajeros.${indicePasajero}.nombreCompleto`
                                  ]
                                }
                                helperText={
                                  errors[
                                    `pasajeros.${indicePasajero}.nombreCompleto`
                                  ]
                                }
                              />
                            )}
                          />
                          <Field
                            name={`pasajeros.${indicePasajero}.telefono`}
                            label="Teléfono"
                            variant="outlined"
                            component={TextField}
                            fullWidth
                            size="small"
                          />
                          <Field
                            name={`pasajeros.${indicePasajero}.mail`}
                            label="Mail"
                            type="email"
                            variant="outlined"
                            component={TextField}
                            fullWidth
                            size="small"
                          />
                        </div>
                      ))}
                    </FormRepeater>
                  )}
                />
              </div>
              {/*<div className="col-span-1">
                <StaticMap
                  markers={[
                    {
                      lat: values.domicilio.latitud,
                      lng: values.domicilio.longitud
                    }
                  ]}
                  containerElement={
                    <div
                      className="col-span-6"
                      style={{ height: `400px`, width: '100%' }}
                    />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                />
                </div>*/}
            </div>
            <div className="mt-12 flex justify-between">
              <Button variant="light" color="red" onClick={props.onCancel}>
                Cancelar
              </Button>
              <div className="flex space-x-4">
                {!props.isEditing && (
                  <Button
                    variant="light"
                    onClick={() => {
                      setActionType('morePoints');
                      submitForm();
                    }}
                  >
                    + Puntos
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="green"
                  onClick={() => {
                    setActionType('continue');
                    submitForm();
                  }}
                >
                  Continuar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ViajeTrasladoPuntoForm;
