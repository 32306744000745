export enum ChoferMotivoDeshabilitacion {
  VACACIONES = 'Vacaciones',
  ENFERMEDAD = 'Enfermedad',
  DOCUMENTACION_VENCIDA = 'Documentacion vencida',
  SUSPENSION = 'Suspension',
  EN_TALLER = 'Auto en taller'
}

export function motivosDeshabilitacionChofer() {
  return Object.values(ChoferMotivoDeshabilitacion).sort();
}
