import { faMap, faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import moment from 'moment';
import { mapDriverCategoryToIconColor } from '../models/ChoferCategoria';
import { ChoferEstado } from '../models/ChoferEstado';
import { ChoferTrabajando } from '../models/ChoferTrabajando';
import useChoferes from '../services/ChoferService';
import { useEffect, useState } from 'react';
import { ChoferModel } from '../models/Chofer';

type Props = {
  driver: ChoferTrabajando;
  closeShift: (id: number) => void;
  forceBreak: (id: number) => void;
  isOpened: boolean;
};

export function WorkingDriverInfo({
  driver,
  closeShift,
  forceBreak,
  isOpened
}: Props) {
  const ChoferService = useChoferes();
  const [driverData, setDriverData] = useState<ChoferModel | null>(null);
  const [loading, setLoading] = useState(true);

  const vehicleData: any = driverData?.general?.vehiculo || {};

  useEffect(() => {
    const fetchDriver = async () => {
      try {
        const updatedDriver = await ChoferService.getChofer(driver.id);
        setDriverData(updatedDriver);
      } catch (error) {
        console.error('Error al obtener datos del chofer:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver.id]);

  if (loading) {
    return (
      <p className="text-center p-9 text-gray-500">
        Cargando información del chofer...
      </p>
    );
  }

  if (!driverData) {
    return (
      <p className="text-center p-9 text-red-500">
        No se encontró información del chofer.
      </p>
    );
  }

  const functionalities = (state: ChoferEstado) => {
    const states = {
      [ChoferEstado.AVAILABLE]: null,
      [ChoferEstado.NOT_AVAILABLE]: null
    };
    return (
      <div className="flex justify-between gap-x-1">
        {states[state]}
        <Button
          disabled={isOpened}
          variant="light"
          color="blue"
          onClick={() => forceBreak(driver.id)}
        >
          Iniciar break
        </Button>
        <Button
          disabled={isOpened}
          variant="light"
          color="red"
          onClick={() => closeShift(driver.id)}
        >
          Terminar jornada
        </Button>
        <Button
          variant="light"
          color="green"
          onClick={() =>
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${driver.current_latitude},${driver.current_longitude}`
            )
          }
        >
          <div className="flex items-center">
            <FontAwesomeIcon icon={faMap} className="mr-2" />
            Maps
          </div>
        </Button>
      </div>
    );
  };

  return (
    <div className="p-9">
      <h1 className="mb-2 pb-1 text-center">
        <strong className="mr-2">#{driver.code}</strong>
        {driver.name}
      </h1>
      <FontAwesomeIcon
        icon={faMedal}
        className="absolute top-0 right-1"
        size="2x"
        color={mapDriverCategoryToIconColor(driver.category)}
      />
      <p className="text-center text-sm text-gray-700 ">
        {vehicleData?.modelo?.fabricante.nombre} / {vehicleData?.modelo?.nombre}{' '}
        - {vehicleData?.patente}{' '}
        <span className="inline-block ml-2 py-0 px-2 border-2 rounded-md">
          {vehicleData?.categoria}
        </span>
      </p>
      <div className="mb-4 flex w-full justify-center border-b-2 py-2 text-sm">
        <span className="mr-2" title="Ingresos por hora">
          Teléfono:
        </span>
        <strong className="text-gray-500">{driverData.personal.celular}</strong>
      </div>
      <div className="my-2 grid grid-cols-2 gap-x-6 gap-y-4 text-sm">
        <p className="text-gray-500">
          <span className="mr-2" title="Postnet">
            POST:
          </span>
          {driver.has_posnet ? 'SI' : 'NO'}
        </p>
        <p className="text-gray-500">
          <span className="mr-2" title="Turno abierto">
            P.M.:
          </span>
          {driver.downtown_permit_expiration === null
            ? 'NO'
            : driver.downtown_permit_expiration.isBefore(moment())
            ? 'SI'
            : `Expirado el ${driver.downtown_permit_expiration.format(
                'DD/MM/YYYY'
              )}`}
        </p>
        <p className="text-gray-500">
          <span className="mr-2" title="Turno abierto">
            T.A:
          </span>
          <strong>
            {moment()
              .hours(0)
              .minutes(0)
              .add(driver.minutes_since_work_day_started, 'minutes')
              .format('HH:mm')}{' '}
          </strong>
          hs.
        </p>
        <p className="text-gray-500">
          <span className="mr-2" title="Horas trabajadas">
            H.T:
          </span>
          <strong>
            {moment()
              .hours(0)
              .minutes(0)
              .add(driver.worked_minutes, 'minutes')
              .format('HH:mm')}{' '}
          </strong>
          hs.
        </p>
        <div>
          <span className="mr-2" title="Ingresos totales">
            I.T:
          </span>
          <strong className="mr-4 text-blue-500">
            ${driver.income_since_work_day_started}
          </strong>
        </div>
        <div>
          <span className="mr-2" title="Ingresos por hora">
            IxH:
          </span>
          <strong className="text-green-500">
            ${driver.income_for_worked_hours}
          </strong>
        </div>
        <div>
          <span className="mr-2" title="Promedio por mes">
            PROM:
          </span>
          <strong className="text-green-500">
            {driver.prom_income_current_month}
          </strong>
        </div>
        <div>
          <span className="mr-2" title="Promedio por hora">
            PxH:
          </span>
          <strong className="text-green-500">
            {driver.prom_income_per_hour}
          </strong>
        </div>

        <div>
          <span className="mr-2" title="Hora Inicio">
            Desde:
          </span>
          <strong className="text-green-500">
            {driver.shift_init_hour} hs.
          </strong>
        </div>
        <div>
          <span className="mr-2" title="Hora Fin">
            Hasta:
          </span>
          <strong className="text-green-500">
            {driver.shift_end_hour} hs.
          </strong>
        </div>
      </div>

      {driver.state === ChoferEstado.ON_BREAK ? (
        <p className="mt-4 text-sm" title="Tiempo restante">
          <span className="mr-2">T.R:</span>
          <strong
            className={
              moment().isBefore(driver.finished_break_date_time)
                ? 'text-green-500'
                : 'text-red-500'
            }
          >
            {driver.finished_break_date_time?.format('HH:mm')}
          </strong>
        </p>
      ) : null}
      <div className="mt-4">{functionalities(driver.state)}</div>
    </div>
  );
}
