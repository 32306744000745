import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, TableColumn } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { AntenaGrupoModel } from '../models/AntenaGrupo';

interface Props {
  antenagrupos: AntenaGrupoModel[];
  selectToEdit: (x: AntenaGrupoModel) => void;
  delete: (x: AntenaGrupoModel) => void;
  restore: (x: AntenaGrupoModel) => void;
}

export const AntenaGrupoTable = (props: Props) => {
  const verifyAction = useVerifyAction();

  const columns: TableColumn<AntenaGrupoModel>[] = [
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => props.selectToEdit(props.antenagrupos[index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            <Button
              onClick={() =>
                verifyAction({
                  title: 'Estas por eliminar un grupo!',
                  body: 'Esta acción va a eliminar el grupo. ¿Estas seguro?',
                  onAccept: () => props.delete(props.antenagrupos[index])
                })
              }
              shape="circle"
              color="red"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )
      }
    }
  ];

  const data = props.antenagrupos;

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Grupos de antenas'}
        data={data}
        columns={columns}
      />
    </div>
  );
};
