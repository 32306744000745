export enum VehiculoColor {
  MARRON = 'Marron',
  BORDO = 'Bordo',
  CHAMPAGNE = 'Champagne',
  CELESTE = 'Celeste',
  DORADO = 'Dorado',
  NEGRO = 'Negro',
  BLANCO = 'Blanco',
  AMARILLO = 'Amarillo',
  ROJO = 'Rojo',
  AZUL = 'Azul',
  GRIS_OSCURO = 'Gris oscuro',
  GRIS = 'Gris'
}

export const VEHICULO_COLORES = Object.values(VehiculoColor).sort();
