import { httpClient } from 'shared/services/http/httpClient';
import { antenagrupoBuilder, AntenaGrupoModel } from '../models/AntenaGrupo';

import {
  antenagrupoFormBuilder,
  AntenaGrupoFormType
} from '../components/AntenaGrupoForm/AntenaGrupoFormType';

const useAntenaGrupos = () => {
  const addAntenaGrupo = (x: AntenaGrupoFormType) => {
    return httpClient.post('/grupos', antenagrupoFormBuilder.toBackEnd(x));
  };

  const removeAntenaGrupo = (antenagrupo: AntenaGrupoModel) => {
    return httpClient.delete('/grupos/' + antenagrupo.id);
  };

  const restoreAntenaGrupo = (antenagrupo: AntenaGrupoModel) => {
    return httpClient.put('/grupos/' + antenagrupo.id + '/restore');
  };

  const updateAntenaGrupo = (antenagrupo: AntenaGrupoFormType) => {
    return httpClient.put(
      '/grupos/' + antenagrupo.id,
      antenagrupoFormBuilder.toBackEnd(antenagrupo)
    );
  };

  const getAntenaGrupos = async (): Promise<AntenaGrupoModel[]> => {
    const response = await httpClient.get('/grupos');
    return response.map(antenagrupoBuilder.fromBackEnd);
  };

  return {
    addAntenaGrupo,
    removeAntenaGrupo,
    updateAntenaGrupo,
    getAntenaGrupos,
    restoreAntenaGrupo
  };
};

export default useAntenaGrupos;
