import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { ExampleModel } from 'example/models/Example';
import useExamples from 'example/services/example-service';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import CreateExample from './CreateExample';
import EditExample from './EditExample';
import TableExample from './TableExample';

const CrudExample = () => {
  const ExampleService = useExamples();
  const exampleQuery = useSuperQuery(ExampleService.getExamples, {
    showSpinner: true
  });
  const editMutation = useSuperMutation(ExampleService.editExample, {
    showSpinner: true
  });
  const addMutation = useSuperMutation(ExampleService.addExample, {
    showSpinner: true
  });
  const removeMutation = useSuperMutation(ExampleService.removeExample, {
    showSpinner: true
  });

  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'edit'>(
    'table'
  );
  const [editingExample, setEditingExample] = useState<ExampleModel>();

  async function edit(example: ExampleModel) {
    await editMutation.mutate(example);
    exampleQuery.reload();
  }

  async function create(example: ExampleModel) {
    await addMutation.mutate(example);
    exampleQuery.reload();
  }

  async function remove(example: ExampleModel) {
    await removeMutation.mutate(example);
    exampleQuery.reload();
  }

  function selectToEdit(example: ExampleModel) {
    setEditingExample(example);
    setVisiblePage('edit');
  }

  return (
    <>
      <PageHeader
        title="Examples"
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear ejemplo
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      {visiblePage === 'table' && (
        <div className="md:my-8 md:mx-32">
          <TableExample
            examples={exampleQuery.data!}
            selectToEdit={selectToEdit}
            delete={remove}
          />
        </div>
      )}

      {visiblePage === 'create' && (
        <PageContainer>
          <CreateExample create={create} />
        </PageContainer>
      )}
      {visiblePage === 'edit' && editingExample && (
        <PageContainer>
          <EditExample edit={edit} initialValue={editingExample} />
        </PageContainer>
      )}
    </>
  );
};

export default CrudExample;
