import React from 'react';
import { Card } from 'shared/components';
import VehiculoFabricanteForm from './VehiculoFabricanteForm';
import { VehiculoFabricanteFormSchema } from '../models/VehiculoFabricanteFormSchema';

interface Props {
  create: (
    VehiculoFabricanteValues: VehiculoFabricanteFormSchema,
    reset
  ) => void;
}
function VehiculoFabricanteCreate({ create }: Props) {
  return (
    <Card title="Nueva Fabricante" className="w-full">
      <div className="p-8">
        <VehiculoFabricanteForm onSubmit={create} />
      </div>
    </Card>
  );
}

export default VehiculoFabricanteCreate;
