/* eslint-disable no-loop-func */
import { useCallback, useRef, useState } from 'react';
import * as XLSX from 'xlsx/';

export interface ExcelFileType {
  headers: string[];
  body: string[][];
  url?: string;
}

const validateFiles = (value: File) => {
  const fsMb = value.size / (1024 * 1024 + 4);
  const MAX_FILE_SIZE = 1;
  if (fsMb > MAX_FILE_SIZE) {
    return 'File size exceed 4 mb';
  }
  return true;
};

export interface FileUploadInputHanlderType {
  onClick: () => void;
}

export const useFile = (
  onError: (message: string) => void,
  onFileSelected: (excelData?: ExcelFileType | undefined) => void,
  currentExcelFile?: ExcelFileType
) => {
  const [isFileParsing, setIsFileParsing] = useState<boolean>(false);
  const [excelFile, setExcelFile] = useState<ExcelFileType | undefined>(
    currentExcelFile
  );
  const fileUploadEl = useRef<FileUploadInputHanlderType>(null);
  const handleFileUpload = () => {
    fileUploadEl?.current?.onClick();
  };

  const handleFileAdded = useCallback(
    async (file: File) => {
      const lengthValidation = validateFiles(file);
      if (typeof lengthValidation === 'string') {
        onError('Archivo muy grande');
        return;
      }
      const fileReader = new FileReader();
      const filePromise = new Promise<ExcelFileType>((resolve, reject) => {
        fileReader.onload = async (e) => {
          if (e.target?.result) {
            if (typeof e.target.result === 'string') {
              try {
                const rawCsvResults = e.target.result.split('\n');
                const headers = rawCsvResults[0].replaceAll('"', '').split(',');

                const body = rawCsvResults.slice(1).reduce((acc, value) => {
                  const parsedValue = value.replaceAll('"', '').split(',');
                  return [...acc, parsedValue];
                }, [] as string[][]);
                return resolve({
                  body,
                  headers
                });
              } catch {
                return reject(new Error('Error parsing csv file'));
              }
            }
            const buffer = fileReader?.result as ArrayBuffer;

            try {
              const workbook = XLSX.read(buffer, {
                raw: false
              });
              /* use sheet_to_json with header: 1 to generate an array of arrays */
              const data = XLSX.utils.sheet_to_json<string[]>(
                workbook.Sheets[workbook.SheetNames[0]],
                {
                  header: 1,
                  blankrows: false,
                  raw: false
                }
              );
              return resolve({
                headers: data[0],
                body: data.map((dataV) => {
                  const nextValues: string[] = [];
                  for (let i = 0; i < dataV.length; i++) {
                    nextValues.push(dataV[i] ? dataV[i] : '');
                  }
                  return nextValues;
                }),
                url: file.name
              });
            } catch {
              return reject('Error reading excel file');
            } finally {
              setIsFileParsing(false);
            }
          }
        };
        fileReader.onabort = () => {
          reject(new Error('File reading stopped'));
        };
        fileReader.onerror = () => {
          reject(new Error('Error reading file'));
        };
        if (file.type === 'text/csv') {
          fileReader.readAsText(file);
        } else {
          setIsFileParsing(true);
          fileReader.readAsArrayBuffer(file);
        }
      });
      try {
        const newFiles = await filePromise;
        setExcelFile(newFiles);
        onFileSelected(newFiles);
      } catch {
        onError('Error al adjuntar archivo de viaje');
      }
    },
    [onError, onFileSelected]
  );

  const handleFileRemove = () => {
    setExcelFile(undefined);
  };

  const handleAddFileList = async (filelist: FileList) => {
    const compressedFiles = Array.from(filelist);

    await handleFileAdded(compressedFiles[0]);
  };

  return {
    onFileRemove: handleFileRemove,
    onFileAdd: handleAddFileList,
    excelFile,
    fileUploadEl,
    isFileParsing,
    onFileUpload: handleFileUpload
  };
};
