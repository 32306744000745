import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button } from "@vadiun/react-components";
import { useSnackbar } from 'notistack';
import { FC, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { sendRecipietToEmails } from '../services/recipietService';

interface SendReceiptModalProps {
  onClose: () => void;
  reserveId: string;
  clientId: number;
}

const SendReceiptFormSchema = z.object({
  emails: z.record(z.string(), z.string({
    required_error: 'Email requerido'
  }).email({
    message: 'Email inválido',
  })),
});

type SendReceiptForm = z.infer<typeof SendReceiptFormSchema>;

const SendReceiptModal: FC<SendReceiptModalProps> = ({
  clientId,
  onClose,
  reserveId
}) => {
  const snackbar = useSnackbar();
  const [emailsIds, setEmailsIds] = useState<string[]>([uuidv4()]);

  const { control, handleSubmit, unregister, formState: { isDirty, isSubmitting } } = useForm<SendReceiptForm>({
    defaultValues: {
      emails: {
        [emailsIds[0]]: ''
      }
    },
    resolver: zodResolver(SendReceiptFormSchema),
  });

  const handleRemoveEmail = (emailId: string) => {
    unregister(`emails.${emailId}`);
    setEmailsIds(emailsIds.filter((id) => id !== emailId));
  };

  const onSubmit = async (data: SendReceiptForm) => {
    try {
      const emails = Object.values(data.emails);
      await sendRecipietToEmails(emails, clientId, reserveId);
      snackbar.enqueueSnackbar('Recibo enviado', {
        variant: 'info'
      });
      onClose();
    } catch {
      snackbar.enqueueSnackbar('Error al enviar recibo', {
        variant: 'error'
      });
    }
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  const handleModaleClose = () => {
    if (isSubmitting) {
      return;
    }
    onClose();
  }

  return (
    <Dialog open onClose={handleModaleClose} maxWidth="md" fullWidth >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Enviar Recibo
        </DialogTitle>
        <DialogContent dividers>
          <div className="w-full flex flex-col gap-y-4">
            <div className=" border-b-2 border-dashed pb-4">
              <p className="subtitle space-x-10">
                <strong className="title">Reserva:</strong>{' '}
                {reserveId}
              </p>
            </div>
            <div className="w-full flex flex-col gap-y-4 pb-10">
              <div className="flex justify-between items-center">
                <strong className="text-lg">Destinatarios</strong>
                <Button
                  variant="outlined"
                  shape="circle"
                  className="mt-2"
                  onClick={() => setEmailsIds((current) => [...current, uuidv4()])}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
              {emailsIds.map((id, index) => {
                if (index === 0) {
                  return (
                    <div className='flex' key={id}>
                      <Controller
                        control={control}
                        name={`emails.${id}`}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            error={!!error?.message}
                            helperText={error?.message}
                            {...field}
                            className="w-full"
                            label="Email"
                            placeholder="Email del destinatario del recibo"
                            variant="outlined"
                          />
                        )}
                      />
                      <div className='w-20' />
                    </div>
                  )
                }
                return (
                  <div className='flex' key={id}>
                    <Controller
                      control={control}
                      name={`emails.${id}`}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          error={!!error?.message}
                          helperText={error?.message}
                          {...field}
                          className="w-full"
                          label="Email"
                          placeholder="Email del destinatario del recibo"
                          variant="outlined"
                        />
                      )}
                    />
                    <div className='w-20 flex justify-end'>
                      <Button
                        variant="outlined"
                        shape="circle"
                        className="mt-2"
                        color='red'
                        onClick={() => handleRemoveEmail(id)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <div className='py-2 flex gap-x-4 pr-4'>
            <Button
              onClick={onClose}
              variant="outlined"
              className="w-fit h-10"
              color="gray"
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={!isDirty}
              type='submit'
              variant="contained"
              className="w-fit h-10"
              color="blue"
            >
              Enviar Recibo
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog >
  )
};

export default SendReceiptModal;