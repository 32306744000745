import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  icon?: React.ReactNode;
  label: string;
  isSelected?: boolean;
  isOpen?: boolean;
  onChildOpened?: (x: boolean) => void;
  isCollapsed: boolean;
  redirectTo?: string;
}

const SidebarItem: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.isOpen !== undefined) {
      setIsOpen(props.isOpen);
    }
  }, [props.isOpen]);

  const LinkComponent = props.redirectTo
    ? Link
    : (props: any) => (
        <div {...props} onClick={() => setIsOpen((open) => !open)} />
      );

  return (
    <>
      <LinkComponent
        className={
          'flex cursor-pointer items-center px-8 py-2 hover:text-blueGray-200 ' +
          (props.isSelected ? 'text-blueGray-200' : 'text-blueGray-500')
        }
        to={props.redirectTo}
        style={props.isSelected ? { backgroundColor: '#1a1a27' } : {}}
      >
        {props.icon ? (
          <div className={'w-6 hover:text-primary-600 '}>{props.icon}</div>
        ) : (
          <FiberManualRecordIcon style={{ width: 8 }} />
        )}
        <h1
          className={
            'soft-transition text-xs delay-75' +
            (props.isCollapsed ? ' opacity-0' : ' ml-4 opacity-100')
          }
        >
          {props.label}
        </h1>

        {isOpen
          ? props.children && (
              <ExpandMoreIcon
                className={
                  'soft-transition delay-75 ' +
                  (props.isCollapsed ? 'opacity-0' : 'opacity-100')
                }
                style={{ width: 20, marginLeft: 'auto' }}
              />
            )
          : props.children && (
              <NavigateNextIcon
                className={
                  'soft-transition ' +
                  (props.isCollapsed ? 'opacity-0' : 'opacity-100')
                }
                style={{ width: 20, marginLeft: 'auto' }}
              />
            )}
      </LinkComponent>
      {!props.isCollapsed ? (
        <div
          className="ml-8 overflow-hidden transition-all duration-500 ease-in-out"
          style={{ maxHeight: isOpen ? 1000 : 0 }}
        >
          {props.children}
        </div>
      ) : null}
    </>
  );
};

export default SidebarItem;
