
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { FileUploadInputHanlderType } from './useFile';

interface FileInputProps {
  acceptedFileTypes?: string;
  onFileAdded: (values: FileList) => Promise<void>;
}

const FileInput = forwardRef<FileUploadInputHanlderType, FileInputProps>(
  ({ onFileAdded, acceptedFileTypes }, uploadRef) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [fileInputReset, setFileInputReset] = useState<boolean>(false);
    useImperativeHandle(uploadRef, () => ({
      onClick: () => {
        inputRef.current?.click();
      },
    }));
    const handleCompressedUpload = async (filelist: FileList) => {
      try {
        setFileInputReset(true);
        await onFileAdded(filelist);
      } finally {
        setFileInputReset(false);
      }
    };
    return !fileInputReset ? (
      <input
        type={'file'}
        multiple
        hidden
        accept={acceptedFileTypes}
        ref={(e) => {
          inputRef.current = e;
        }}
        onChange={(e) => {
          handleCompressedUpload(e?.currentTarget?.files as FileList);
        }}
      />
    ) : null;
  },
);
FileInput.displayName = 'FileInput';

export default FileInput;
