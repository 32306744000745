import { Button } from '@vadiun/react-components';
import { FunctionComponent } from 'react';
import { tw } from 'utils/tailwindUtils';

type ViajeLLTPaisEditProps = {
  currentTravel: any;
  onOpenEditLLtPais: (reservation: any) => void;
};

const ViajeLLTPaisEdit: FunctionComponent<ViajeLLTPaisEditProps> = ({
  currentTravel,
  onOpenEditLLtPais
}) => {
  return (
    <Button
      variant="outlined"
      color="gray"
      className={tw('hidden', {
        block: currentTravel.lltpais // Si es LLT-pais no mostrar el botón para link de seguimiento.
      })}
      onClick={() => onOpenEditLLtPais(currentTravel)}
    >
      Editar LLT-País
    </Button>
  );
};

export default ViajeLLTPaisEdit;
