import {
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { DriverRecomended, DriversRecomended } from '../types/Driver';
import { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';

type Props = {
  driversRecomended: DriversRecomended;
  onChangeDriverSelected: (driver: DriverRecomended) => void;
};

const MoreDriversRecomended = ({
  driversRecomended,
  onChangeDriverSelected
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton
        title="Ver más sugeridos"
        size="small"
        aria-label="view more drivers"
        onClick={handleClick}
      >
        <VisibilityIcon fontSize="medium" color="disabled" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        className="ml-3"
        PaperProps={{
          style: {
            borderRadius: 10
          }
        }}
        id={id}
      >
        <TableContainer
          style={{
            maxHeight: 250
          }}
        >
          <Table aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <TableCell>
                  <p className="text-center text-gray-500">Móvil</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-500">Distancia</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-500">Plata/hora</p>
                </TableCell>
                <TableCell align="center">
                  <p className="text-gray-500">Llegada</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {driversRecomended.map((driver) => (
                <TableRow
                  key={driver.name}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <TableCell align="center" component="th" scope="driver">
                    <p
                      className={`hoverable cursor-pointer rounded-md pl-3 pr-3 text-center text-sm`}
                      style={{
                        color: driver.state === 'FREE' ? '#14B8A6' : '#FB7185'
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChangeDriverSelected(driver);
                      }}
                    >
                      {driver.code}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-gray-700">
                      {Math.ceil(driver.distance)} km
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p className="text-gray-700">
                      $ {Number(driver.ixh).toFixed(0)}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p
                      style={{
                        color: driver.state === 'FREE' ? '#14B8A6' : '#FB7185'
                      }}
                    >
                      {moment(driver?.arrival_datetime ?? new Date()).format(
                        'HH:mm'
                      )}{' '}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </div>
  );
};

export default MoreDriversRecomended;
