import {
  RateCategoryType,
  RateModelCRUD,
  RATE_CATEGORIES
} from 'app/client/clientBusiness/rates/models/RateModel';
import moment from 'moment';
import { domicilioValidation } from 'shared/components/FormikAddress';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface RateFormType extends Omit<RateModelCRUD, 'businessClientId'> {}

export type RateGeneralFormType = Omit<RateFormType, 'categories'>;

const rateGeneralFormSchema: YupShape<RateGeneralFormType> = Yup.object().shape(
  {
    baseCommission: Yup.string().required('La comisión es requerida'),
    surplusCommission: Yup.string().required(
      'La comisión excedente es requerida'
    ),
    limitPriceBaseCommission: Yup.string().required(
      'El limite de comisión excedente es requerida'
    ),
    expirationDate: Yup.mixed().required(
      'La fecha de vencimiento es requerida'
    ),
    outSitePrice: Yup.string().required('El precio out site requerido'),
    minNumberKilometers: Yup.string().required('El precio mínimo es requerido'),
    nightPlusPercentage: Yup.string().required(
      'El porcentaje de plus nocturno requerido'
    )
  }
);

const rateGeneralInitialValues: RateGeneralFormType = {
  baseCommission: '',
  surplusCommission: '',
  limitPriceBaseCommission: '',
  expirationDate: moment(),
  outSitePrice: 0,
  nightPlusPercentage: '',
  minNumberKilometers: ''
};

export type RateCategoriesFormType = {
  categories: RateFormType['categories'];
};

const rateCategoriesFormSchema = Yup.object().shape({
  categories: Yup.array().of(
    Yup.object().shape({
      kmServiceCanceled: Yup.string().required(
        'Los km servicio cancelado son requeridos'
      ),
      hourPrice: Yup.string().required('El precio por hora es requerido'),
      linearKilometerPrices: Yup.array()
        .of(
          Yup.object().shape({
            numberKilometersFrom: Yup.string().required(
              'La cantidad de km es requerida'
            ),
            numberKilometersTo: Yup.string().required(
              'La cantidad de km es requerida'
            ),
            price: Yup.string().required('el precio es requerido')
          })
        )
        .min(1, 'Como mínimo se debe tener un elemento'),
      returnKilometerPrices: Yup.array()
        .of(
          Yup.object().shape({
            numberKilometersFrom: Yup.string().required(
              'La cantidad de km es requerida'
            ),
            numberKilometersTo: Yup.string().required(
              'La cantidad de km es requerida'
            ),
            price: Yup.string().required('el precio es requerido')
          })
        )
        .min(1, 'Como mínimo se debe tener un elemento'),
      fixedPrices: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('El nombre requerido'),
          price: Yup.string().required('el precio es requerido')
        })
      ),
      priced: Yup.array().of(
        Yup.object().shape({
          address1: domicilioValidation,
          address2: domicilioValidation,
          kilometersRadio: Yup.string().required('El radio requerido'),
          price: Yup.string().required('El precio requerido')
        })
      )
    })
  )
});

const createRateCategory = (
  name: RateCategoryType
): RateCategoriesFormType['categories'][0] => {
  return {
    kmServiceCanceled: '',
    hourPrice: '',
    name,
    linearKilometerPrices: [
      {
        numberKilometersFrom: '',
        numberKilometersTo: '',
        price: ''
      }
    ],
    returnKilometerPrices: [
      {
        numberKilometersFrom: '',
        numberKilometersTo: '',
        price: ''
      }
    ],
    fixedPrices: [],
    priced: []
  };
};

const rateCategoriesInitialValues: RateCategoriesFormType = {
  categories: RATE_CATEGORIES.map((rate) => createRateCategory(rate))
};

const RateFormik = {
  general: {
    schema: rateGeneralFormSchema,
    initialValues: rateGeneralInitialValues
  },
  categories: {
    schema: rateCategoriesFormSchema,
    initialValues: rateCategoriesInitialValues
  },
  initialValues: {
    ...rateGeneralInitialValues,
    ...rateCategoriesInitialValues
  }
};

export default RateFormik;
