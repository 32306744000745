import { passengerBuilder } from 'app/client/clientBusiness/Passenger/models/Passenger';
import { domicilioBuilder } from 'app/domicilio/models/DomicilioModel';
import { ViajeCanalesDeIngreso } from 'app/viaje/models';
import moment from 'moment';
import {
  choferPartialBuilder,
  ChoferPartialModel
} from '../../../../chofer/models/Chofer';
import { TransferReservationPartialModel } from './TransferReservationPartial';
import { TransferReservationPrices } from './TransferReservationPrices';
import {
  // TransferReservationState,
  transferReservationStateBuilder
} from './TransferReservationState';

export interface TransferReservationModel
  extends TransferReservationPartialModel {
  estimated_prices: TransferReservationPrices | null;
  calculated_prices: TransferReservationPrices | null;
  final_prices: TransferReservationPrices;
  pre_assigned_driver: ChoferPartialModel | null;
  assigned_driver: ChoferPartialModel | null;
  has_out_site: boolean;
  setback: string | null;
  states: any[];
  entryChannel: ViajeCanalesDeIngreso;
  requirements: null | {
    costCenter: null | string;
    subCostCenter: null | string;
    applicant: null | {
      id: number;
      name: string;
      email: null | string;
      phone: null | string;
    };
    authorized: null | {
      id: number;
      name: string;
      email: null | string;
      phone: null | string;
    };
    buyOrder: null | string;
    employeeNumber: null | string;
    platformApplication: null | string;
    sector: null | string;
    tripNumber: null | string;
    data1: string | null;
    data2: string | null;
    data3: string | null;
  };
}

export const transferReservationBuilder = {
  fromBackEnd: (reservation): TransferReservationModel => ({
    ...reservation,
    reservationDatetime: moment(reservation.reservationDatetime),
    arrivalDateTime: moment(reservation.arrivalDateTime),
    departureDatetime: moment(reservation.departureDateTime),
    contretemps: reservation.setback,
    locations: reservation.locations?.map((location) => ({
      ...location,
      address: domicilioBuilder.fromBackEnd(location.address),
      passengers: location.passengers?.map(passengerBuilder.fromBackEnd)
    })),
    state_datetime: moment(reservation.state_datetime),
    pre_assigned_driver: reservation.pre_assigned_driver
      ? choferPartialBuilder.fromBackEnd(reservation.pre_assigned_driver)
      : null,
    assigned_driver: reservation.assigned_driver
      ? choferPartialBuilder.fromBackEnd(reservation.assigned_driver)
      : null,
    has_out_site: reservation.has_out_site,
    setback: reservation.setback,
    states: reservation.states?.map((s) =>
      transferReservationStateBuilder.fromBackEnd(s)
    ),
    is_event: reservation.is_event,
    event_name: reservation.event_name,
    is_llt_pais: reservation?.lltpais ? true : false,
    customer_price: reservation?.lltpais?.customer_price,
    provider_price: reservation?.lltpais?.provider_price
  })
};
