import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import {
  createStyles,
  createTheme,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Message,
  Snackbar,
  Spinner,
  VerifyAction
} from '@vadiun/react-components';
import { TableProvider } from '@vadiun/react-datatable';
import {
  MessageProvider,
  SnackbarProvider,
  SpinnerProvider,
  VerifyActionProvider
} from '@vadiun/react-hooks';
import {
  MessageProvider as LegacyMessageProvider,
  SnackbarProvider as LegacySnackbarProvider,
  SpinnerProvider as LegacySpinnerProvider,
  VerifyActionProvider as LegacyVerifyActionProvider
} from '@vadiun/react-hooks-legacy';
import { DriverPrivacyPolicyPage } from 'app/privacy-policy/pages/DriverPrivacyPolicyPage';
import MainAuth from 'auth/pages/MainAuth';
import { AuthContext, AuthProvider } from 'auth/services/AuthService';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import MainLayout from 'layout/pages/MainLayout';
import moment from 'moment';
import 'moment/locale/es';
import { SnackbarProvider as NotiStackProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';


moment.locale('es');

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 10000000,
      color: '#fff'
    }
  })
);
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false
    },
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 3000
    }
  }
});

const tableOptions = {
  defaultItemsPerPage: 30,
  filterLabel: 'filter',
  firstPageNro: 1,
  itemsPerPageLabel: 'page_size',
  orderDirectionAscLabel: 'asc',
  orderDirectionDescLabel: 'desc',
  orderLabel: 'order',
  orderDirectionLabel: 'direction',
  paginationLabel: 'page',
  searchLabel: 'search'
};

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: `"Poppins", sans-serif`,
      fontSize: 13
    },
    palette: {
      primary: {
        main: '#37bdf8'
      }
    },
    overrides: {
      MUIDataTableSelectCell: {
        root: {
          background: 'white'
        }
      },
      MUIDataTableBodyCell: {
        root: {
          '&.MuiTableCell-root': {
            fontSize: '13px',
            padding: '12px'
          }
        }
      }
    }
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          libInstance={moment}
          locale="es"
        >
          <NotiStackProvider>
            <LegacySpinnerProvider component={Spinner}>
              <LegacyMessageProvider component={Message}>
                <LegacySnackbarProvider component={Snackbar}>
                  <LegacyVerifyActionProvider component={VerifyAction}>
                    <SpinnerProvider component={Spinner}>
                      <MessageProvider component={Message}>
                        <SnackbarProvider component={Snackbar}>
                          <VerifyActionProvider component={VerifyAction}>
                            <AuthProvider>
                              <TableProvider
                                localization="ES"
                                options={tableOptions}
                              >
                                <AuthContext.Consumer>
                                  {({ isAuthenticated }) => (
                                    <Switch>
                                      <Route
                                        path="/app-choferes-politica-privacidad"
                                        render={() => (
                                          <DriverPrivacyPolicyPage />
                                        )}
                                      />
                                      <Route
                                        path="/auth"
                                        render={() => <MainAuth />}
                                      />
                                      {!isAuthenticated && (
                                        <Redirect to="/auth/login" />
                                      )}
                                      <Route
                                        path="/main"
                                        render={() => <MainLayout />}
                                      />
                                      <Redirect to="/main/landing" />
                                    </Switch>
                                  )}
                                </AuthContext.Consumer>
                              </TableProvider>
                            </AuthProvider>
                          </VerifyActionProvider>
                        </SnackbarProvider>
                      </MessageProvider>
                    </SpinnerProvider>
                  </LegacyVerifyActionProvider>
                </LegacySnackbarProvider>
              </LegacyMessageProvider>
            </LegacySpinnerProvider>
          </NotiStackProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
