import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, TableColumn } from '@vadiun/react-components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { DriverTransferReservationsWithPrices } from '../models/DriverTransferReservationsWithSettlementInfo';

interface Props {
  settlementsDetail: DriverTransferReservationsWithPrices[];
  chofer?: string;
}

export const DriverSettlementDetailTableComponent = ({
  settlementsDetail,
  chofer
}: Props) => {
  const data = settlementsDetail.map((d) => {
    return {
      ...d,
      client: d.client.name,
      locations: d.locations,
      status: d.state,
      total_precio_espera: Math.round(d.total_precio_espera),
      dateTime: d.reservationDatetime.format('DD/MM/YYYY HH:mm [hs.]')
    };
  });
  const columns: TableColumn<typeof data[0]>[] = [
    { name: 'dateTime', label: 'Fecha' },
    { name: 'status', label: 'Estado' },
    { name: 'client', label: 'Cliente' },
    {
      name: 'locations',
      label: 'Recorrido',
      options: {
        customBodyRenderLite: (index) => {
          const firstPoint = data[index].locations[0];
          const lastPoint =
            data[index].locations[data[index].locations.length - 1];
          return (
            <div className="flex flex-col items-center">
              <span className="title text-center text-xs">
                {createDomicilioText(firstPoint.address)}
              </span>
              <Button
                shape="circle"
                className="p-2"
                color={data[index].locations.length > 2 ? 'blue' : 'gray'}
              >
                <div className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faArrowDown} />
                  {data[index].locations.length > 2
                    ? data[index].locations.length
                    : null}
                </div>
              </Button>

              <span className="title text-center text-xs">
                {createDomicilioText(lastPoint.address)}
              </span>
            </div>
          );
        }
      }
    },
    {
      name: 'contado',
      label: 'Contado'
    },
    {
      name: 'cuenta_corriente',
      label: 'Cuenta Corriente'
    },
    {
      name: 'total_precio_espera',
      label: 'Espera'
    },
    {
      name: 'total_gastos',
      label: 'Gastos'
    },
    {
      name: 'total_a_facturar',
      label: 'Total'
    }
  ];
  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          print: false,
          download: false
        }}
        title={chofer ?? ''}
        columns={columns}
      />
    </div>
  );
};
