import { Button } from '@vadiun/react-components';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import AuthLayout from 'auth/components/AuthLayout';
import { AuthContextType } from 'auth/services/AuthService';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import LoginIlustration from '../../assets/login.svg';
import Logo from '../../assets/logo.png';

interface FormSchema {
  email: string;
  password: string;
}

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('El email es invalido')
    .required('El email es requerido'),
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(4, 'La contraseña debe tener como minimo 4 caracteres')
});

interface Props {
  authSrv: AuthContextType;
}

const LoginPage = (props: Props) => {
  const initialValues: FormSchema = {
    email: '',
    password: ''
  };
  const loginMutation = useSuperMutation(props.authSrv.login, {
    showSpinner: false
  });

  const submit = async (
    value: FormSchema,
    formikHelpers: FormikHelpers<FormSchema>
  ) => {
    await loginMutation.mutate(value);
    formikHelpers.resetForm();
  };

  return (
    <AuthLayout
      ilustration={LoginIlustration}
      logo={Logo}
      title="Bienvenido a Las Leñas Traslados"
    >
      <div className="flex max-w-xl flex-col items-center">
        <h1 className="my-4 text-center text-2xl font-bold">Login</h1>
        <p>
          {/*<span className="font-bold text-primary-600 ">Create an account</span>*/}
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={submit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              {loginMutation.isError && (
                <p className="text-center text-sm text-red-500">
                  Las credenciales son incorrectas
                </p>
              )}
              <Field
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                name="email"
                component={TextField}
              />
              <Field
                label="Password"
                type="password"
                name="password"
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                fullWidth
                className="bg-white"
                component={TextField}
              />
              <Link
                to="/auth/forgot-password"
                className="ml-auto cursor-pointer text-primary-600 underline"
              >
                Olvide mi contraseña
              </Link>
              <div className="my-8 w-full">
                <Button
                  color="primary"
                  className="w-full"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Login
                </Button>
              </div>
              {/*<p className="text-center text-gray-600 my-5 mt-10">
                Or Connect with Social Media
              </p>
              <div className="flex justify-evenly items-center space-x-2">
                <MUIButton
                  variant="contained"
                  style={{ backgroundColor: "#4267B2", color: "white" }}
                  disableElevation
                  startIcon={<FacebookIcon />}
                >
                  Facebook
                </MUIButton>
                <MUIButton
                  variant="contained"
                  style={{ backgroundColor: "#DD4B39", color: "white" }}
                  disableElevation
                  startIcon={
                    <img
                      alt=""
                      src={GoogleIcon}
                      style={{ width: 20, filter: "invert(1)" }}
                    />
                  }
                >
                  Google
                </MUIButton>
              </div>*/}
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
