import React, { FC } from 'react';

export const ProfileResumeTitle: FC = (props) => (
  <h1 className="font-medium text-gray-600">{props.children}</h1>
);
export const ProfileResumeSubtitle: FC = (props) => (
  <h1 className="text-gray-400 ellipsis text-sm font-medium mb-3">
    {props.children}
  </h1>
);

export const ProfileResumeBadge: FC<{ color: string }> = ({
  children,
  color
}) => (
  <span
    className={`bg-${color}-100 text-${color}-400  rounded-md font-semibold text-center text-xs px-2 py-1`}
  >
    {children}
  </span>
);

const ProfileResumeContainer: FC = (props) => {
  return <div className="overflow-hidden ml-2">{props.children}</div>;
};

export default ProfileResumeContainer;
