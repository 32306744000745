import React from 'react';
import { Card } from 'shared/components';
import { UserRole } from '../models/User';
import { UserForm } from './UserForm/UserForm';
import { UserFormType } from './UserForm/UserFormType';

interface Props {
  update: (UserValues: UserFormType) => Promise<void>;
  initialValues: UserFormType;
  roles: UserRole[];
}

export function UserEdit({ update, initialValues, roles }: Props) {
  return (
    <Card title="Editar usuario" className="w-full">
      <div className="p-8">
        <UserForm
          onSubmit={update}
          initialValues={initialValues}
          roles={roles}
          editing
        />
      </div>
    </Card>
  );
}
