import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/moment';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import * as Yup from 'yup';
import { Button } from '@vadiun/react-components';
import { ChoferDeshabilitacion } from '../../models/Chofer';
import moment, { Moment } from 'moment';
import {
  ChoferMotivoDeshabilitacion, motivosDeshabilitacionChofer,
} from '../../models/ChoferMotivoDeshabilitacion';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

interface Props {
  submitButtonTitle?: string;
  onSubmit: (deshabilitacionSchema: ChoferDeshabilitacion, reset) => void;
  goBackButtonTitle?: string;
  onGoBack?: () => void;
  initialValues?: ChoferDeshabilitacion;
}

const Schema = Yup.object().shape({
  fechaYHoraInicio: Yup.mixed()
    .required('La fecha y hora de inicio es obligatoria')
    .test(
      'fechaYHoraMenor',
      'La fecha y hora de inicio debe ser menor a la de fin',
      (value: Moment, context) => {
        return !!value && value.isBefore(context.parent.fechaYHoraFin);
      }
    ),
  fechaYHoraFin: Yup.mixed()
    .required('La fecha y hora de fin es obligatoria')
    .test(
      'fechaYHoraMayor',
      'La fecha y hora de fin debe ser menor a la de inicio',
      (value: Moment, context) => {
        return !!value && value.isAfter(context.parent.fechaYHoraInicio);
      }
    ),
  motivo: Yup.string()
    .required('La observacion es obligatoria')
    .oneOf(motivosDeshabilitacionChofer()),
  observacion: Yup.string()
});

function ChoferFormDeshabilitacion({
  submitButtonTitle = 'Deshabilitar',
  goBackButtonTitle = 'Volver',
  onGoBack,
  onSubmit,
  initialValues = {
    fechaYHoraInicio: moment(),
    fechaYHoraFin: moment(),
    motivo: ChoferMotivoDeshabilitacion.VACACIONES,
    observacion: '',
    finalizada: false,
    aceptada: null
  }
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ submitForm, touched, errors, values }) => (
        <Form className="w-full grid gap-6">
          <div className="grid grid-cols-2 gap-4">
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="fechaYHoraInicio"
                label="Fecha y hora de inicio"
                inputVariant="outlined"
                component={KeyboardDateTimePicker}
                fullWidth
                ampm={false}
                format="DD/MM/YYYY HH"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <Field
                name="fechaYHoraFin"
                label="Fecha y hora de fin"
                inputVariant="outlined"
                component={KeyboardDateTimePicker}
                fullWidth
                ampm={false}
                format="DD/MM/YYYY HH"
              />
            </MuiPickersUtilsProvider>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Motivo</InputLabel>
              <Field component={Select} name="motivo" label="Motivo">
                {motivosDeshabilitacionChofer().map((motivo) => (
                  <MenuItem value={motivo} key={motivo}>
                    {motivo}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Field
              name="observacion"
              label="Observacion"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          </div>

          <div className="flex justify-between mt-1">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="green"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button type="submit" variant="contained" color="green">
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ChoferFormDeshabilitacion;
