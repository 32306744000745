import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import TollCreate from '../components/TollCreate';
import TollEdit from '../components/TollEdit';
import { TollFormType } from '../components/TollForm/TollFormik';
import TollTable from '../components/TollTable';
import { TollModel } from '../models/Toll';
import useTolls from '../services/TollService';

function TollCrudPage(props: any) {
  const TollService = useTolls();
  const tollsQuery = useSuperQuery(TollService.getTolls, {
    showSpinner: true
  });
  const updateTollMutation = useSuperMutation(TollService.updateToll);
  const addTollMutation = useSuperMutation(TollService.addToll);
  const removeTollMutation = useSuperMutation(TollService.removeToll, {
    showSpinner: true
  });
  const restoreTollMutation = useSuperMutation(TollService.restoreToll, {
    showSpinner: true
  });
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );
  const [updatingToll, setEditingToll] = useState<TollModel>();

  async function update(tollValues: TollFormType, reset) {
    tollValues.id = updatingToll!.id;
    await updateTollMutation.mutate(tollValues);
    reset();
    setVisiblePage('table');
    tollsQuery.reload();
  }

  async function create(tollValues: TollFormType, reset) {
    await addTollMutation.mutate(tollValues);
    reset();
    tollsQuery.reload();
  }

  async function remove(toll: TollModel) {
    await removeTollMutation.mutate(toll);
    tollsQuery.reload();
  }

  async function restore(toll: TollModel) {
    await restoreTollMutation.mutate(toll);
    tollsQuery.reload();
  }

  function selectToEdit(toll: TollModel) {
    setEditingToll(toll);
    setVisiblePage('update');
  }
  return (
    <>
      <PageHeader
        title="Peajes"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de peajes'
            : visiblePage === 'create'
            ? 'Nuevo peaje'
            : 'Editar peaje'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear peaje
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <TollTable
            tolls={tollsQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
          />
        )}

        {visiblePage === 'create' && <TollCreate create={create} />}
        {visiblePage === 'update' && updatingToll && (
          <TollEdit update={update} initialValues={updatingToll} />
        )}
      </PageContainer>
    </>
  );
}

export default TollCrudPage;
