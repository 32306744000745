import {
  VehiculoFabricanteCRUD,
  VehiculoFabricanteModel
} from './VehiculoFabricante';

export interface VehiculoFabricanteFormSchema {
  nombre: string;
}

export function VehiculoFabricanteFormSchemaToModel(
  VehiculoFabricanteForm: VehiculoFabricanteFormSchema
): VehiculoFabricanteCRUD {
  return VehiculoFabricanteForm;
}

export function VehiculoFabricanteModelToFormSchema(
  vehiculoFabricante: VehiculoFabricanteModel
): VehiculoFabricanteFormSchema {
  return {
    nombre: vehiculoFabricante.nombre
  };
}
