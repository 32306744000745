import moment, { Moment } from 'moment';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Tooltip } from '@material-ui/core';

type Props = {
  schedule: {
    arrival: Moment;
    exit: Moment;
    end: Moment;
  };
  priceDriver: string;
};

export const ViajeTrasladoPendienteTableTimesCell = ({
  schedule,
  priceDriver
}: Props) => {
  const fechaDeReservaPosteriorALaFechaActual = schedule.arrival.isBefore(
    moment()
  );
  const fechaDeSalidaPosteriorALaFechaActual = schedule.exit.isBefore(moment());
  const fechaDeReservaEsMañana =
    schedule.arrival.format('YYYY-MM-DD') ===
    moment().add(1, 'day').format('YYYY-MM-DD');

  return (
    <div
      className="grid w-32 grid-cols-2 items-center text-xs"
      style={{ columnGap: '1.5vw' }}
    >
      <Tooltip title="Salida de chofer" arrow placement="top" className="ml-2">
        <div className="title col-span-1 mb-1 flex gap-0 items-center">
          <span
            className={
              fechaDeSalidaPosteriorALaFechaActual ? 'text-red-500' : ''
            }
          >
            {schedule.exit.format('HH:mm')}
          </span>
          <QueryBuilderIcon
            fontSize="small"
            className={
              fechaDeSalidaPosteriorALaFechaActual
                ? 'text-red-500'
                : 'text-gray-400'
            }
          />
        </div>
      </Tooltip>
      <span
        className={
          'col-span-1 text-xs text-gray-400 ' +
          (fechaDeSalidaPosteriorALaFechaActual ? 'text-red-500' : '') +
          ' mb-1'
        }
        title="Salida de chofer"
      >
        {schedule.exit.format('DD/MM')}
      </span>
      <span
        className={
          'title col-span-1 font-bold ml-2 ' +
          (fechaDeReservaEsMañana
            ? 'text-green-500'
            : fechaDeReservaPosteriorALaFechaActual
            ? 'text-red-500'
            : '')
        }
        style={{ fontSize: 16 }}
        title="Llegada a origen"
      >
        {schedule.arrival.format('HH:mm')}
      </span>
      <span
        className={
          'col-span-1 text-xs font-bold ' +
          (fechaDeReservaEsMañana
            ? 'text-green-500'
            : fechaDeReservaPosteriorALaFechaActual
            ? 'text-red-500'
            : 'text-gray-700')
        }
        style={{ fontSize: 13 }}
        title="Llegada a origen"
      >
        {schedule.arrival.format('DD/MM')}
      </span>
      <span
        className="title col-span-2 mt-1 text-gray-500 ml-2"
        title="Llegada a destino"
      >
        {Math.abs(schedule.end.diff(schedule.arrival, 'minutes'))} min.
      </span>
      <div className="mt-1 py-1 px-1 ml-2 w-fit rounded-lg bg-white text-xs text-gray-500 border-2 border-gray-200">
        <p>{priceDriver}</p>
      </div>
    </div>
  );
};
