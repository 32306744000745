import { domicilioValidation } from 'shared/components/FormikAddress';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';
import { ClientPrivateFormType } from './ClientPrivateFormType';

export const clientprivateFormValidation: YupShape<ClientPrivateFormType> =
  Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    alias: Yup.string().nullable(),
    phone1: Yup.string().nullable(),
    phone2: Yup.string().nullable(),
    email: Yup.string()
      .nullable()
      .email('Formato no válido')
      .required('el email es requerido'),
    address: domicilioValidation,
    code: Yup.string().required('El código es requerido')
  });
