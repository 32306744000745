import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { TransferReservationPricesRateType } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPrices';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { useEffect } from 'react';
import CorrectPriceFormik, { CorrectPriceFormType } from './CorrectPriceFormik';

export const OnChange = ({
  value,
  callback
}: {
  value: CorrectPriceFormType;
  callback: (value: CorrectPriceFormType) => void;
}) => {
  useEffect(() => {
    let time = setTimeout(() => {
      callback(value);
    }, 1000);

    return () => clearTimeout(time);
  }, [
    value.expenses_price,
    value.transfer_price,
    value.waiting_minutes,
    value.km,
    value.rate_type
  ]);
  return null;
};
interface Props {
  onSubmit: (value: CorrectPriceFormType, reset) => void;
  onChange: (value: CorrectPriceFormType) => void;
  initialValues?: CorrectPriceFormType;
  availableRateTypes: TransferReservationPricesRateType[];
  originalRate: TransferReservationPricesRateType | undefined;
}

function CorrectPriceForm({
  onSubmit,
  onChange,
  availableRateTypes,
  originalRate,
  initialValues = CorrectPriceFormik.initialValues
}: Props) {
  return (
    <Formik<CorrectPriceFormType>
      initialValues={initialValues}
      validationSchema={CorrectPriceFormik.schema}
      enableReinitialize
      onSubmit={(values, helpers) => onSubmit(values, helpers.resetForm)}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form className="grid w-full grid-cols-6 gap-6">
            <OnChange callback={onChange} value={values} />
            <FormControl variant="outlined" fullWidth className="col-span-6">
              <InputLabel>Tipo tarifa *</InputLabel>
              <Field component={Select} name="rate_type" label="Tipo tarifa">
                {availableRateTypes.map((type) => (
                  <MenuItem value={type} key={type}>
                    {type === originalRate ? `${type} (original)` : type}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Field
              name="waiting_minutes"
              label="Minutos de espera *"
              variant="outlined"
              className="col-span-6"
              component={TextField}
              type="number"
              fullWidth
            />

            <Field
              name="km"
              label="Kilometros *"
              type="number"
              variant="outlined"
              className="col-span-6"
              component={TextField}
              fullWidth
            />

            <Field
              name="expenses_price"
              label="Gastos *"
              type="number"
              variant="outlined"
              className="col-span-6"
              inputProps={{ step: '0.01' }}
              component={TextField}
              fullWidth
            />
            <Field
              name="observation"
              label="Observacion *"
              type="text"
              variant="outlined"
              className="col-span-6"
              component={TextField}
              multiline
              rows={2}
              fullWidth
            />
            <Field
              name="transfer_price"
              label="Precio final *"
              variant="outlined"
              className="col-span-6"
              component={TextField}
              type="number"
              inputProps={{ step: '0.01' }}
              fullWidth
            />
            <div className="col-span-6 flex justify-end">
              <Button
                type="submit"
                variant="contained"
                color="green"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Guardar
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CorrectPriceForm;
