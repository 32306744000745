import { Checkbox, Divider, FormControlLabel } from '@material-ui/core';
import { DriverShift } from 'app/chofer/models/DriverShift';
import moment from 'moment';

type Props = {
  optionsDriversRequest: DriverShift[];
  requestsSelected: number[];
  onChecked: (id: number) => void;
};

const ItemsRequests = ({
  optionsDriversRequest,
  requestsSelected,
  onChecked
}: Props): JSX.Element => {
  return (
    <div className="mt-3 h-72 overflow-y-scroll pb-5">
      {optionsDriversRequest?.map(
        ({ id, open_date_time, close_date_time, driver_name, driver_code }) => (
          <>
            <Divider />
            <div className="flex flex-col py-4">
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="medium"
                      checked={requestsSelected.some((req) => req === id)}
                      onChange={() => onChecked(id)}
                      color="primary"
                    />
                  }
                  label={
                    <p className="text-sm text-gray-400">
                      #{driver_code}
                      <span className="text-gray-600 text-lg ml-3">
                        {driver_name}
                      </span>
                    </p>
                  }
                />
              </div>
              <div className="flex justify-between text-sm mt-2">
                <p className="flex-1 text-gray-800">
                  <span className="text-gray-600">Apertura:</span>{' '}
                  {moment(open_date_time)?.format('DD/MM h:mm')}
                </p>
                <p className="flex-1 text-gray-800">
                  <span className="text-gray-600">Cierre:</span>{' '}
                  {moment(close_date_time)?.format('DD/MM h:mm')}
                </p>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default ItemsRequests;
