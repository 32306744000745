import {
  faLock,
  faPencilAlt,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, TableColumn } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { UserModel } from '../models/User';

interface Props {
  users: UserModel[];
  selectToEdit: (x: UserModel) => void;
  delete: (x: UserModel) => void;
  restore: (x: UserModel) => void;
  changePassword: (x: UserModel) => void;
}

export const UserTable = (props: Props) => {
  const verifyAction = useVerifyAction();

  const columns: TableColumn<UserModel>[] = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'roles',
      label: 'Roles',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (index) => (
          <ol className="list-disc">
            {props.users[index].roles.map((role) => (
              <li key={role} className="text-xs">
                {role}
              </li>
            ))}
          </ol>
        )
      }
    },

    {
      name: 'isDeleted',
      label: 'Borrado',
      options: {
        display: false,
        filterOptions: {
          names: ['Si', 'No']
        },
        filterList: ['No'],
        customFilterListOptions: { render: (v) => `Borrado: ${v}` }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          props.users[index].isDeleted ? (
            <Button onClick={() => props.restore(props.users[index])}>
              Restaurar
            </Button>
          ) : (
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => props.selectToEdit(props.users[index])}
                shape="circle"
                color="primary"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                onClick={() =>
                  verifyAction({
                    title: 'Estas por eliminar un usuario!',
                    body: 'Esta accion va a eliminar el usuario. ¿Estas seguro?',
                    onAccept: () => props.delete(props.users[index])
                  })
                }
                shape="circle"
                color="red"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
              <div title="Cambiar contraseña">
                <Button
                  onClick={() => props.changePassword(props.users[index])}
                  shape="circle"
                  color="primary"
                >
                  <FontAwesomeIcon icon={faLock} />
                </Button>
              </div>
            </div>
          )
      }
    }
  ];

  const data = props.users.map((x) => ({
    ...x,
    isDeleted: x.isDeleted ? 'Si' : 'No'
  }));

  return (
    <div className="w-full">
      <Table title={'Usuarios'} data={data} columns={columns} />
    </div>
  );
};
