// @ts-nocheck
import axios from 'axios';
import { useCallback, useState } from 'react';
import environment from 'environment/environment';
import { useSnackbar } from 'notistack';
import { parseArrayBufferToJson } from 'shared/helpers';

const client = axios.create({
  baseURL: environment.backEnd
});

export const useSettlements = () => {
  const [isLoading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  const getSettlementBuffer = useCallback(async (settlementId: string) => {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json, application/octet-stream',
        'Content-Type': 'application/json'
      }
    };
    return client.get(`transfer-settlements/${settlementId}/download`, config);
  }, []);

  const downloadSettlements = useCallback(
    async (settlementId: string, clientName = 'example') => {
      try {
        setLoading(true);
        const settlements = await getSettlementBuffer(settlementId);
        const blob = new Blob([settlements.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `liquidaciones-${clientName.replace(/ /g, '-')}.xlsx`;
        a.click();
        snackbar.enqueueSnackbar(
          'Archivo de liquidaciones descargado con exito',
          {
            variant: 'success'
          }
        );
      } catch (error: any) {
        const errorApiParsed = parseArrayBufferToJson(error?.response?.data);
        snackbar.enqueueSnackbar(errorApiParsed.messages[0], {
          variant: 'error'
        });
      } finally {
        setLoading(false);
      }
    },
    [getSettlementBuffer, snackbar]
  );

  return {
    isLoading,
    downloadSettlements
  };
};
