import React, { FC } from 'react';

interface Props {
  children: React.ReactNode;
}

const PageContainerMobile: FC<Props> = ({ children }) => {    
  return ( 
    <div className='px-2 h-full bg-white sm:w-full sm:space-y-4 md:flex md:p-4 md:space-x-4 md:space-y-0'>
        {children}
    </div>
  )
};

export default PageContainerMobile;
