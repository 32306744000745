// @flow
import { CircularProgress } from '@material-ui/core';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { MUIDataTableColumnDef } from 'mui-datatables';
import * as React from 'react';
import { Table } from 'shared/components';
import { TransferReservationManagmentModel } from '../../gestion/models/TransferReservationManagment';

import { TransferReservationPendingModel } from '../../pendiente/models/TransferReservationPending';
import { ViajeTrasladoPendienteTableTimesCell } from '../../pendiente/components/ViajeTrasladoPendienteTableTimesCell';
import { DriverRecomended, DriversRecomended } from '../types/Driver';
import Row from './Row';
import { useResponsiveBreakpoint } from '@vadiun/react-hooks';
import RowDriversRecomended from './RowDriversRecomended';
import { formatNumberToPrice } from 'shared/helpers';
import { useNotificationsContext } from 'shared/context/notificationContext';
import RefreshList from './list/refresh';
import moment from 'moment';
import Client from './list/client';
import Points from './list/points';
import ServiceType from './list/serviceType';
import Driver from './list/driver';
import ActionsItemCordinate from './list/actions';

interface Props {
  tableQuery: UseTableQueryResult<TransferReservationPendingModel[], unknown>;
  driversRecomended: DriversRecomended;
  idTravelSelected: number | undefined;
  isLoadingDriverRecomended: boolean;
  onChangeDriverSelected: (driver: DriverRecomended) => void;
  setIdTravelSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
  onLocationsDetails: (reservation: TransferReservationPendingModel) => void;
  onDelayNotify: (travel: TransferReservationPendingModel) => void;
  onEditReservation: (travel: TransferReservationPendingModel) => void;
  onShowDriverDetails: (driver: ChoferPartialModel) => void;
  onReservationCopy: (reservation: TransferReservationManagmentModel) => void;
  onCancelReservation: (reservation: TransferReservationPendingModel) => void;
  onReservationShowDetails: (id: number) => React.ReactNode;
  onShowFiltersDriversRecomended: () => void;
  onCloseFilters: () => void;
  onStartTravel: (selectedReservation: number, chofer: number) => void;
  onPreAssignDriver: (travel: TransferReservationPendingModel) => void;
  onAssignDriver: (travel: TransferReservationPendingModel) => void;
  onRemovePreAssigned: (selectedReservation: number) => void;
  onRefreshTable: () => Promise<void>;
}

export function ViajeTrasladoCoordinarTable({
  tableQuery,
  driversRecomended,
  idTravelSelected,
  isLoadingDriverRecomended,
  onShowFiltersDriversRecomended,
  onChangeDriverSelected,
  setIdTravelSelected,
  onLocationsDetails,
  onShowDriverDetails,
  onEditReservation,
  onReservationCopy,
  onCancelReservation,
  onReservationShowDetails,
  onDelayNotify,
  onStartTravel,
  onCloseFilters,
  onAssignDriver,
  onPreAssignDriver,
  onRemovePreAssigned,
  onRefreshTable
}: Props) {
  const { travelsTaked } = useNotificationsContext();
  const display = useResponsiveBreakpoint();
  const isMobileOrTablet =
    display === 'xs' || display === 'sm' || display === 'md';

  const checkServiceType = (val: string) => (val === 'VIP' ? 1 : 0);

  const data = (tableQuery.data ?? [])
    .map((reservation) => ({
      ...reservation,
      schedule: {
        arrival: reservation.reservationDatetime,
        exit: reservation.departureDatetime,
        end: reservation.arrivalDateTime
      },
      passengers: reservation.locations
        .map((location) =>
          location.passengers.map((passenger) => passenger.name)
        )
        .flat()
    }))
    .sort((a: any, b: any) => {
      if (
        moment(a.departureDatetime).format('DD-MM HH:mm') ===
        moment(b.departureDatetime).format('DD-MM HH:mm')
      ) {
        return (
          b.hasPriority - a.hasPriority ||
          checkServiceType(b.serviceType) - checkServiceType(a.serviceType)
        );
      } else {
        return a.departureDatetime - b.departureDatetime;
      }
    });

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'schedule',
      label: isMobileOrTablet ? ' ' : 'Horarios',
      options: {
        customBodyRender: (schedule, { rowIndex: index }) => {
          const travel = data[index];
          return (
            <div className="flex justify-between text-sm">
              {isMobileOrTablet ? <p>Horarios</p> : null}
              <ViajeTrasladoPendienteTableTimesCell
                schedule={schedule}
                priceDriver={`$${formatNumberToPrice(
                  travel?.estimated_prices?.driver_price ?? 0
                )}`}
              />
            </div>
          );
        }
      }
    },
    {
      name: 'cliente',
      label: isMobileOrTablet ? ' ' : 'Cliente',
      options: {
        customBodyRender: (value, { rowIndex: index }) => {
          const current = data[index];
          return (
            <Client current={current} isMobileOrTablet={isMobileOrTablet} />
          );
        }
      }
    },
    {
      name: 'puntos',
      label: isMobileOrTablet ? ' ' : 'Domicilios',
      options: {
        customBodyRender: (value, { rowIndex: index }) => {
          return (
            <Points
              data={data}
              index={index}
              isMobileOrTablet={isMobileOrTablet}
              onLocationsDetails={onLocationsDetails}
            />
          );
        }
      }
    },
    {
      name: 'coordinationObservation',
      label: isMobileOrTablet ? ' ' : 'Obs. Coordinación',
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-between text-sm w-32">
              {isMobileOrTablet ? <p>Obs. Coordinación</p> : null}
              <p className="text-xs">{value}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'id',
      label: isMobileOrTablet ? ' ' : 'Sugeridos',
      options: {
        setCellHeaderProps: () => ({
          style: { width: '112px', textAlign: 'center' }
        }),
        customBodyRender: (idTravel) => {
          const isTravelSelected = idTravel === idTravelSelected;
          return (
            <RowDriversRecomended
              driversRecomended={driversRecomended}
              isTravelSelected={isTravelSelected}
              isMobileOrTablet={isMobileOrTablet}
              isLoadingDriverRecomended={isLoadingDriverRecomended}
              onChangeDriverSelected={onChangeDriverSelected}
              onShowFiltersDriversRecomended={onShowFiltersDriversRecomended}
            />
          );
        }
      }
    },
    {
      name: 'serviceType',
      label: isMobileOrTablet ? ' ' : 'Servicio',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <ServiceType
            data={data}
            index={index}
            isMobileOrTablet={isMobileOrTablet}
          />
        )
      }
    },
    {
      name: 'event_name',
      label: 'Evento',
      options: {
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, { rowIndex: index }) => (
          <p className="text-gray-700 w-36 text-sm text-center">
            {data[index].event_name}
          </p>
        )
      }
    },
    {
      name: 'id',
      label: isMobileOrTablet ? ' ' : 'ID',
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-between text-sm">
              {isMobileOrTablet ? <p>ID</p> : null}
              <p className="text-xs">{value}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'platformId',
      label: isMobileOrTablet ? ' ' : 'Plataforma',
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-between text-sm">
              {isMobileOrTablet ? <p>Plataforma</p> : null}
              <p className="text-xs">{value}</p>
            </div>
          );
        }
      }
    },
    {
      name: 'preAssignedDriver',
      label: isMobileOrTablet ? ' ' : 'Chofer',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <Driver
            data={data}
            index={index}
            isMobileOrTablet={isMobileOrTablet}
            onShowDriverDetails={onShowDriverDetails}
          />
        )
      }
    },
    {
      name: 'passengers',
      label: isMobileOrTablet ? ' ' : 'Pasajeros',
      options: {
        download: false,
        customBodyRender: (value, { rowIndex: index }) => (
          <div className="flex justify-between text-sm">
            {isMobileOrTablet ? <p>Pasajeros</p> : null}
            <div className="flex flex-col">
              {data[index].passengers.map((el) => (
                <span>{el}</span>
              ))}
            </div>
          </div>
        )
      }
    },
    {
      label: isMobileOrTablet ? ' ' : 'Acciones',
      name: 'actions',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <div className="flex flex-col justify-between text-sm">
            {isMobileOrTablet ? <p>Acciones</p> : null}
            <ActionsItemCordinate
              current={data[index]}
              index={index}
              onAssignDriver={onAssignDriver}
              onCancelReservation={onCancelReservation}
              onDelayNotify={onDelayNotify}
              onEditReservation={onEditReservation}
              onPreAssignDriver={onPreAssignDriver}
              onRemovePreAssigned={onRemovePreAssigned}
              onReservationCopy={onReservationCopy}
              onReservationShowDetails={onReservationShowDetails}
              onStartTravel={onStartTravel}
            />
          </div>
        )
      }
    },
    {
      name: 'taken_by_coordination',
      label: ' ',
      options: {
        display: 'excluded'
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        components={{
          TableToolbar: () => (
            <div className="flex justify-end py-1 px-4">
              <RefreshList onRefreshTable={onRefreshTable} />
            </div>
          )
        }}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          sort: false,
          download: false,
          print: false,
          customRowRender: (data, index: number) => {
            const [, , , , , , , idTravel] = data;
            const idTravelRow = idTravel.props.children[1].props.children;
            return (
              <Row
                key={`${idTravelRow}-${index}`}
                data={data}
                idTravelSelected={idTravelSelected}
                setIdTravelSelected={setIdTravelSelected}
                travelTaked={travelsTaked?.find(
                  (travel) => travel.transfer_reservation_id === idTravelRow
                )}
                onCloseFilters={onCloseFilters}
              />
            );
          },
          serverSide: true
          // ...tableQuery.sesrverSideOptions
        }}
        columns={columns}
        data={data}
      />
    </div>
  );
}
