import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { Button, Chip } from '@vadiun/react-components';
import { createUserFormInitialValues } from './UserFormInitialValues';
import { UserFormType } from './UserFormType';
import {
  userFormCreateValidation,
  userFormEditValidation
} from './UserFormValidation';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { UserRole } from 'app/user/models/User';

interface Props {
  onSubmit: (value: UserFormType) => Promise<void>;
  initialValues?: UserFormType;
  editing: boolean;
  roles: UserRole[];
}

export function UserForm({
  onSubmit,
  initialValues = createUserFormInitialValues(),
  roles,
  editing
}: Props) {
  return (
    <Formik<UserFormType>
      initialValues={initialValues}
      validationSchema={
        editing ? userFormEditValidation : userFormCreateValidation
      }
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="email"
            label="Email *"
            variant="outlined"
            className="col-span-2"
            component={TextField}
            fullWidth
          />
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-2"
            component={TextField}
            fullWidth
          />
          {editing ? null : (
            <Field
              name="password"
              label="Contraseña *"
              variant="outlined"
              className="col-span-2"
              type="password"
              component={TextField}
              fullWidth
            />
          )}
          <FormControl variant="outlined" className="col-span-6">
            <InputLabel>Roles *</InputLabel>
            <Field
              component={Select}
              name="roles"
              label="Roles *"
              multiple
              renderValue={(selected) => (
                <div className="flex flex-wrap gap-4">
                  {selected.map((value) => (
                    <Chip key={value}>{value}</Chip>
                  ))}
                </div>
              )}
            >
              {roles.map((role) => (
                <MenuItem value={role} key={role}>
                  {role}
                </MenuItem>
              ))}
            </Field>
          </FormControl>

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
