import React from 'react';
import { Field, FieldArray, Form, Formik, FormikErrors } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, FormRepeater } from '@vadiun/react-components';
import { RATE_CATEGORIES } from 'app/client/clientBusiness/rates/models/RateModel';
import FormikAddress, {
  domicilioInitialValues
} from 'shared/components/FormikAddress';
import { clientPrivateRateCategoriesFormValidation } from './ClientPrivateRateValidation';
import { ClientPrivateRateFormType } from './ClientPrivateRateFormType';
interface Props {
  onSubmit: (
    rateFormValue: ClientPrivateRateFormType,
    reset: () => void
  ) => Promise<void>;
  initialValues: ClientPrivateRateFormType;
}

export function ClientPrivateRateForm({ onSubmit, initialValues }: Props) {
  function getError(
    errors: FormikErrors<ClientPrivateRateFormType>,
    fieldName: string,
    index: number
  ) {
    if (errors.categories === undefined) return '';
    if (errors.categories[index] === undefined) return '';
    const error = errors.categories[index][fieldName];
    return typeof error === 'string' ? error : '';
  }
  return (
    <Formik<ClientPrivateRateFormType>
      initialValues={initialValues}
      validationSchema={clientPrivateRateCategoriesFormValidation}
      enableReinitialize
      validateOnChange={false}
      onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
    >
      {({ isSubmitting, touched, errors, values }) => (
        <Form className="w-full">
          <h1 className="font-bold mb-6 text-lg">Información General</h1>
          <div className="grid gap-6 grid-cols-6">
            <Field
              name="outSitePrice"
              label="Precio Out Site*"
              variant="outlined"
              className="col-span-3"
              type="number"
              step="0.01"
              component={TextField}
              fullWidth
            />
            <Field
              name="nightPlusPercentage"
              label="Porcentaje plus nocturno*"
              variant="outlined"
              className="col-span-3"
              type="number"
              step="0.01"
              component={TextField}
              fullWidth
            />
            <Field
              className="col-span-3"
              name={`minNumberKilometers`}
              label="Km mínimos*"
              variant="outlined"
              type="number"
              component={TextField}
              fullWidth
            />
          </div>
          <h1 className="font-bold text-lg mt-12">Categorías</h1>
          {RATE_CATEGORIES.map((rate, index) => (
            <div className="border-dashed border-b-2 py-8" key={index}>
              <h1 className="font-bold mb-6">{rate.toUpperCase()}</h1>
              <div className="ml-6 grid gap-6 grid-cols-6">
                <Field
                  name={`categories.${index}.kmServiceCanceled`}
                  className="col-span-3"
                  label="Km servicio cancelado"
                  variant="outlined"
                  component={TextField}
                  fullWidth
                />
                <Field
                  name={`categories.${index}.hourPrice`}
                  className="col-span-3"
                  label="Precio por hora espera"
                  variant="outlined"
                  type="number"
                  component={TextField}
                  fullWidth
                />

                <span className="col-span-6 text-gray-700 mt-1">
                  Kilómetros lineales
                </span>
                <FieldArray
                  name={`categories.${index}.linearKilometerPrices`}
                  render={(arrayHelpers) => (
                    <FormRepeater
                      arrayHelpers={arrayHelpers}
                      addLabel="+ Añadir"
                      createField={() => ({
                        numberKilometersFrom: '',
                        numberKilometersTo: '',
                        minNumberKilometers: '',
                        price: ''
                      })}
                      className="col-span-6"
                    >
                      {values.categories[index].linearKilometerPrices.map(
                        (value, indexLinear) => (
                          <div className="flex gap-2" key={indexLinear}>
                            <Field
                              name={`categories.${index}.linearKilometerPrices.${indexLinear}.numberKilometersFrom`}
                              label="Desde"
                              variant="outlined"
                              type="number"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.linearKilometerPrices.${indexLinear}.numberKilometersTo`}
                              label="Hasta"
                              variant="outlined"
                              type="number"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.linearKilometerPrices.${indexLinear}.price`}
                              label="Precio"
                              variant="outlined"
                              type="number"
                              step="0.01"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                          </div>
                        )
                      )}
                    </FormRepeater>
                  )}
                />
                <p className="text-red-500 col-span-6">
                  {getError(errors, 'linearKilometerPrices', index)}
                </p>
                <span className="col-span-6 text-gray-700 mt-1">
                  Kilómetros con regreso
                </span>
                <FieldArray
                  name={`categories.${index}.returnKilometerPrices`}
                  render={(arrayHelpers) => (
                    <FormRepeater
                      arrayHelpers={arrayHelpers}
                      addLabel="+ Añadir"
                      createField={() => ({
                        numberKilometersFrom: '',
                        numberKilometersTo: '',
                        minNumberKilometers: '',
                        price: ''
                      })}
                      className="col-span-6"
                    >
                      {values.categories[index].returnKilometerPrices.map(
                        (value, indexReturn) => (
                          <div className="flex gap-2" key={indexReturn}>
                            <Field
                              name={`categories.${index}.returnKilometerPrices.${indexReturn}.numberKilometersFrom`}
                              label="Desde"
                              variant="outlined"
                              type="number"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.returnKilometerPrices.${indexReturn}.numberKilometersTo`}
                              label="Hasta"
                              variant="outlined"
                              type="number"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.returnKilometerPrices.${indexReturn}.price`}
                              label="Precio"
                              variant="outlined"
                              type="number"
                              step="0.01"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                          </div>
                        )
                      )}
                    </FormRepeater>
                  )}
                />
                <p className="text-red-500 col-span-6">
                  {getError(errors, 'returnKilometerPrices', index)}
                </p>
                <span className="col-span-6 text-gray-700 mt-1">Fijos</span>
                <FieldArray
                  name={`categories.${index}.fixedPrices`}
                  render={(arrayHelpers) => (
                    <FormRepeater
                      arrayHelpers={arrayHelpers}
                      addLabel="+ Añadir"
                      createField={() => ({
                        name: '',
                        price: ''
                      })}
                      className="col-span-6"
                    >
                      {values.categories[index].fixedPrices.map(
                        (value, indexFixed) => (
                          <div className="flex gap-2" key={indexFixed}>
                            <Field
                              name={`categories.${index}.fixedPrices.${indexFixed}.name`}
                              label="Nombre"
                              variant="outlined"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.fixedPrices.${indexFixed}.price`}
                              label="Precio"
                              variant="outlined"
                              type="number"
                              step="0.01"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                          </div>
                        )
                      )}
                    </FormRepeater>
                  )}
                />
                <span className="col-span-6 text-gray-700 mt-1">Tarifado</span>
                <FieldArray
                  name={`categories.${index}.priced`}
                  render={(arrayHelpers) => (
                    <FormRepeater
                      arrayHelpers={arrayHelpers}
                      addLabel="+ Añadir"
                      createField={() => ({
                        address1: domicilioInitialValues,
                        address2: domicilioInitialValues,
                        kilometersRadio: '',
                        price: ''
                      })}
                      className="col-span-6"
                    >
                      {values.categories[index].priced.map(
                        (value, indexPriced) => (
                          <div
                            className="grid grid-cols-6 gap-4 mb-6"
                            key={indexPriced}
                          >
                            <Field
                              name={`categories.${index}.priced.${indexPriced}.address1`}
                              className="col-span-6"
                              label="Domicilio 1"
                              variant="outlined"
                              component={FormikAddress}
                              size="small"
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.priced.${indexPriced}.address2`}
                              className="col-span-6"
                              label="Domicilio 2"
                              variant="outlined"
                              size="small"
                              component={FormikAddress}
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.priced.${indexPriced}.kilometersRadio`}
                              className="col-span-3"
                              label="Radio"
                              variant="outlined"
                              type="number"
                              step="0.01"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                            <Field
                              name={`categories.${index}.priced.${indexPriced}.price`}
                              className="col-span-3"
                              label="Precio"
                              variant="outlined"
                              type="number"
                              step="0.01"
                              component={TextField}
                              size="small"
                              fullWidth
                            />
                          </div>
                        )
                      )}
                    </FormRepeater>
                  )}
                />
              </div>
            </div>
          ))}

          <div className="flex justify-end col-span-6 mt-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
