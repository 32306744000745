import { ClientPrivateModel } from 'app/client/private/private/models/ClientPrivate';
import PageContainer from 'layout/components/PageContainer';
import { Card } from 'shared/components';
import { TransferReservationRepetitiveForm } from '../form/TransferReservationRepetitiveForm';
import { TransferReservationRepetitivePrivateFormType } from '../form/TransferReservationRepetitivePrivateFormType';

interface Props {
  getClientesByName: (name: string) => Promise<ClientPrivateModel[]>;
  onSubmit: (
    reservation: TransferReservationRepetitivePrivateFormType,
    reset: any
  ) => void;
  estimatePrice: (
    trasladoFormValues: TransferReservationRepetitivePrivateFormType
  ) => void;
  onOpenCreatePrivateClient: () => void;
}
export const ReservaTrasladoRepetitivePrivateCreate = ({
  onSubmit,
  estimatePrice,
  getClientesByName,
  onOpenCreatePrivateClient
}: Props) => {
  return (
    <PageContainer>
      <Card className="w-full" title="Crear reserva repetitiva particular">
        <div className="p-8">
          <TransferReservationRepetitiveForm
            onSubmit={onSubmit}
            onOpenCreatePrivateClient={onOpenCreatePrivateClient}
            onCalculateDistanceAndCost={estimatePrice}
            getClientesByName={getClientesByName}
          />
        </div>
      </Card>
    </PageContainer>
  );
};
