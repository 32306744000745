import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import useChoferes from 'app/chofer/services/ChoferService';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useEffect, useState } from 'react';
import {
  DriverIncomeFiltersForm,
  DriverIncomeFiltersFormType
} from '../components/DriverIncomeFiltersForm';
import { DriverIncomeTable } from '../components/DriverIncomeTable';
import { useDriverIncomeRepository } from '../services/DriverIncomeRepository';

export const DriverIncomePage = () => {
  const driverRepo = useChoferes();
  const driversQuery = useSuperQuery(driverRepo.getChoferes);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<DriverIncomeFiltersFormType>({
    from: null,
    to: null,
    driver_id: null,
    turnoAbierto: true
  });
  const driverIncomeRepo = useDriverIncomeRepository();
  const driverIncomeQuery = useSuperQuery(
    () => driverIncomeRepo.getIncomeReport(filters),
    { showSpinner: true },
    [filters]
  );
  const [visibleColumns, setVisibleColumns] = useState({
    average_income_per_day: false,
    average_income_per_hour: false,
    income_in_period: false,
    worked_hours_in_period: false,
    shift_open_date_time: true,
    total_days_worked: false,
    today_income: true,
    last_shift_open: false,
    today_income_per_worked_hour: true
  });

  useEffect(() => {
    const filtroUsado =
      filters.from !== null ||
      filters.to !== null ||
      filters.driver_id !== null ||
      filters.turnoAbierto !== true;
    if (filtroUsado) {
      setVisibleColumns({
        average_income_per_day: true,
        average_income_per_hour: true,
        income_in_period: true,
        worked_hours_in_period: true,
        last_shift_open: true,
        total_days_worked: true,
        shift_open_date_time: false,
        today_income: false,
        today_income_per_worked_hour: false
      });
    }
  }, [filters]);

  return (
    <>
      <PageHeader
        title="Recaudación choferes"
        toolbar={
          <Button variant="light" onClick={() => setShowFilters(true)}>
            Filtros
          </Button>
        }
      />
      <PageContainer>
        <DriverIncomeTable
          onColumnsVisibleChange={setVisibleColumns}
          incomes={driverIncomeQuery.data ?? []}
          visibleColumns={visibleColumns}
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <DriverIncomeFiltersForm
            drivers={driversQuery.data ?? []}
            initialValues={filters}
            onSubmit={async (filters) => {
              setShowFilters(false);
              setFilters(filters);
            }}
          />
        </div>
      </Drawer>
    </>
  );
};
