import { TextField as TextFieldMaterial } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { Button, FormikAutocompleteAsync } from '@vadiun/react-components';
import { Query } from '@vadiun/react-hooks-legacy';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import {
  ClientBusinessModel,
  ClientBusinessPartialModel
} from 'app/client/clientBusiness/models/ClientBusiness';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';
import ReservaTrasladoSearchSolicitante from '../../viajeTraslado/components/ReservaTrasladoSearchSolicitante';
import { TransferReservationClientRequeriments } from '../../viajeTraslado/forms/base/TransferReservationClientRequeriments';
import CorrectClientBusinessFormik, {
  CorrectClientBusinessFormType
} from './CorrectClientBusinessFormik';

interface Props {
  onSubmit: (value: CorrectClientBusinessFormType, reset) => void;
  initialValues?: CorrectClientBusinessFormType;
  getClientesByName: (name: string) => Promise<ClientBusinessPartialModel[]>;
  onClienteSelect: (clienteId: number) => void;
  autorizadosQuery: Query<AuthorizedModel[]>;
  clientSelected: ClientBusinessModel | undefined;
  solicitantes: ApplicantModel[];
}

export function CorrectClientBusinessForm({
  onClienteSelect,
  getClientesByName,
  onSubmit,
  autorizadosQuery,
  clientSelected,
  initialValues = CorrectClientBusinessFormik.initialValues,
  solicitantes
}: Props) {
  const [isSearchSolicitanteOpen, setIsSearchSolicitanteOpen] = useState(false);
  return (
    <Formik<CorrectClientBusinessFormType>
      initialValues={initialValues}
      validationSchema={CorrectClientBusinessFormik.schema(clientSelected)}
      enableReinitialize
      onSubmit={(values, helpers) => onSubmit(values, helpers.resetForm)}
    >
      {(formik) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <FormikOnFieldChange
            field="client"
            onChange={(cliente: ClientBusinessPartialModel | null) => {
              if (cliente === null || cliente === undefined) return;
              onClienteSelect(cliente.id);
            }}
          />
          <Field
            name="client"
            component={FormikAutocompleteAsync}
            fetchOptions={getClientesByName}
            fullWidth
            getOptionLabel={(cliente: ClientBusinessPartialModel) =>
              cliente.fantasyName
            }
            className="col-span-6"
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMaterial
                {...params}
                error={formik.touched['client'] && !!formik.errors['client']}
                helperText={formik.errors['client']}
                label="Cliente"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <div className="col-span-6">
            <TransferReservationClientRequeriments
              autorizadosQuery={autorizadosQuery}
              client={clientSelected}
              errors={formik.errors}
            />
          </div>
          <div className="col-span-6 grid grid-cols-12 gap-6 py-8">
            <Button
              variant="light"
              shape="circle"
              onClick={() => setIsSearchSolicitanteOpen(true)}
              className="col-span-1 self-center"
            >
              <FaSearch />
            </Button>
            <ReservaTrasladoSearchSolicitante
              isOpen={isSearchSolicitanteOpen}
              handleClose={() => setIsSearchSolicitanteOpen(false)}
              solicitantes={solicitantes}
              onSelect={(solicitante) => {
                formik.setFieldValue('solicitante.name', solicitante.name);
                formik.setFieldValue('solicitante.phone', solicitante.phone);
                formik.setFieldValue('solicitante.email', solicitante.email);
                setIsSearchSolicitanteOpen(false);
              }}
            />
            <Field
              name="solicitante.name"
              label="Solicitante"
              variant="outlined"
              component={TextField}
              className="col-span-5"
              fullWidth
            />
            <Field
              name="solicitante.phone"
              label="Telefono"
              variant="outlined"
              component={TextField}
              className="col-span-2"
              fullWidth
            />
            <Field
              name="solicitante.email"
              label="Mail"
              variant="outlined"
              component={TextField}
              fullWidth
              className="col-span-4"
            />
          </div>
          <div className="col-span-6">
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="hasOutSite"
              color="primary"
              Label={{ label: 'Out Site' }}
            />
          </div>
          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
