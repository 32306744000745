import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { ClientBusinessUserUpdateFormType } from './ClientBusinessUserUpdateFormType';
import { userFormValidation } from './ClientBusinessUserFormValidation';
import { BusinessClientUser } from '../../ClientBusinessUserCrudComponent';

interface Props {
  onSubmit: (value: ClientBusinessUserUpdateFormType) => Promise<void>;
  initialValues: BusinessClientUser;
}

export function ClientBusinessUserUpdateForm({
  onSubmit,
  initialValues
}: Props) {
  return (
    <Formik<ClientBusinessUserUpdateFormType>
      initialValues={initialValues}
      validationSchema={userFormValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, setFieldValue }) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="email"
            label="Email *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
