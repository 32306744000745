import { UserFormType } from './UserFormType';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export const userFormCreateValidation: YupShape<UserFormType> =
  Yup.object().shape({
    email: Yup.string()
      .email('El email es inválido')
      .required('El email es requerido'),
    name: Yup.string().required('El nombre es requerido'),
    password: Yup.string().required('La contraseña es requerida'),
    roles: Yup.array().min(1, 'Seleccione un rol')
  });

export const userFormEditValidation: YupShape<UserFormType> =
  Yup.object().shape({
    email: Yup.string()
      .email('El email es inválido')
      .required('El email es requerido'),
    name: Yup.string().required('El nombre es requerido'),
    password: Yup.string(),
    roles: Yup.array().min(1, 'Seleccione un rol')
  });
