import React from 'react';

interface Props {
  src: string;
  decoration?: React.ReactNode;
}

const ProfileImage = ({ decoration, src }: Props) => {
  return (
    <div className="mr-2.5 relative">
      {decoration}
      <img
        src={src}
        className="h-24 rounded-lg w-24 object-cover object-center"
      />
    </div>
  );
};

export default ProfileImage;
