import { Table, TableColumn } from '@vadiun/react-components';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import React, { useMemo } from 'react';

interface Props {
  drivers: ChoferPartialModel[];
  onSelectionChange: (ids: number[]) => void;
  facturacionCrearMovimiento: (id: number) => React.ReactNode;
  facturacionVerMovimientos: (id: number) => void;
  selectedDrivers: number[];
}

export const DriverSettlementsCreateTableComponent = ({
  drivers,
  selectedDrivers,
  onSelectionChange,
  facturacionCrearMovimiento,
  facturacionVerMovimientos
}: Props) => {
  const fixedDrivers = useMemo(() => {
    return drivers.map((d) => ({
      ...d,
      businessUnit: d.businessUnit?.name || '-'
    }))
  }, [drivers]);
  const columns: TableColumn<ChoferPartialModel>[] = [
    {
      name: 'codigo',
      label: 'Código'
    },
    {
      name: 'nombreCompleto',
      label: 'Nombre'
    },
    {
      name: 'businessUnit',
      label: 'Unidad de Negocio'
    },
    {
      name: 'billers',
      label: 'Facturadores',
      options: {
        customBodyRenderLite: (index) => (
          <div>
            {fixedDrivers[index].billers.map((biller) => (
              <div className="flex gap-2">
                <span>{biller.name}</span>
                {biller.mail ? <span>- {biller.mail}</span> : null}
              </div>
            ))}
          </div>
        )
      }
    }
    /*{
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col space-y-1">
              {facturacionCrearMovimiento(fixedDrivers[index].id)}
              <Button
                variant="light"
                color="red"
                onClick={() => facturacionVerMovimientos(fixedDrivers[index].id)}
              >
                Ver movimientos ({fixedDrivers[index].movements.length})
              </Button>
            </div>
          );
        }
      }
    }*/
  ];
  return (
    <div className="w-full">
      <Table
        data={fixedDrivers}
        options={{
          elevation: 0,
          selectableRows: 'multiple',
          selectToolbarPlacement: 'none',
          download: false,
          print: false,
          onRowSelectionChange: (_, selection) => {
            onSelectionChange(
              selection.map(({ dataIndex }) => fixedDrivers[dataIndex].id)
            );
          },
          rowsSelected: selectedDrivers.map(id => fixedDrivers.findIndex(driver => driver.id === id))
        }}
        title={''}
        columns={columns}
      />
    </div>
  );
};
