import { TableColumn } from '@vadiun/react-components';
import { Table } from 'shared/components';

import { ViajeTrasladoLog } from '../models/ViajeTrasladoLog';

interface Props {
  data: ViajeTrasladoLog[];
}

export const ViajeTrasladoModificacionesTable = (props: Props) => {
  const data = props.data.map((x) => ({
    ...x,
    fecha: x.fecha_hora.format('DD/MM/YYYY HH:mm'),
    user_name: x.user.name
  }));
  const columns: TableColumn<typeof data[number]>[] = [
    {
      name: 'fecha',
      label: 'Fecha'
    },
    {
      name: 'user_name',
      label: 'Usuario'
    },
    {
      name: 'observacion',
      label: 'Observación'
    }
  ];

  return (
    <div className="h-full w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={''}
        data={data}
        columns={columns}
      />
    </div>
  );
};
