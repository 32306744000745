export enum VehiculoCompaniaSeguro {
  ALEDONIA = 'Aledonia',
  ALLIANZ = 'Allianz',
  TRIUNFO_SEGUROS = 'Triunfo seguros',
  LA_SEGUNDA = 'La segunda',
  SURA_SA = 'Sura S.A',
  PROVINCIA = 'Provincia',
  PARANA_SEGUROS = 'Parana seguros',
  COPAN_COOP_SEGUROS = 'Copan Coop. de Seguros Ltda',
  CALEDONIA = 'Caledonia',
  AGRO_SALTA = 'Agro Salta',
  RIVADAVIA = 'Rivadavia',
  ESCUDO_SEGUROS = 'Escudo Seguros',
  LIDERAR = 'Liderar',
  LA_CAJA = 'La Caja',
  FEDERACION_PATRONAL = 'Federación Patronal',
  FEDERAL = 'Federal',
  ORBIS = 'Orbis',
  LA_NUEVA = 'La Nueva',
  AMCA = 'Amca',
  SANCOR_SEGUROS = 'Sancor Seguros'
}

export const VEHICULO_COMPANIAS_SEGURO = Object.values(
  VehiculoCompaniaSeguro
).sort();
