import { Tooltip } from '@material-ui/core';
import React from 'react';
import { DriverSettlementModel } from '../models/DriverSettlementModel';

type Props = {
  settlement: DriverSettlementModel;
};

const TD = (props: { children: React.ReactNode; className?: string }) => (
  <td className={'p-2 pr-8 ' + props.className ?? ''}>{props.children}</td>
);

export const DriverSettlementDetailResumeComponent = ({
  settlement
}: Props) => {
  const totalGastos =
    settlement.total_billed +
    settlement.commission -
    settlement.total_worked +
    settlement.total_cash -
    settlement.total_movimientos;
  return (
    <table>
      <tr>
        <TD>Cuenta corriente</TD>
        <TD>${settlement.total_current_account}</TD>
      </tr>
      <tr className="border-b-2 border-dashed border-gray-300">
        <TD>Efectivo</TD>
        <TD>${settlement.total_cash}</TD>
      </tr>
      <tr>
        <TD>Total trabajado</TD>
        <TD>${settlement.total_worked}</TD>
      </tr>
      <tr>
        <TD>Gastos</TD>
        <TD>${totalGastos}</TD>
      </tr>
      <tr className="border-b-2 border-dashed border-gray-300">
        <TD>Comision</TD>
        <TD>${settlement.commission}</TD>
      </tr>
      <Tooltip title="Total trabajado - Comisión administrativa" arrow>
        <tr>
          <TD>Cobra</TD>
          <TD>${settlement.total_worked - settlement.commission}</TD>
        </tr>
      </Tooltip>
      <Tooltip title="Comisión administrativa" arrow>
        <tr className="border-b-2 border-dashed border-gray-300">
          <TD>Paga</TD>
          <TD>${settlement.commission}</TD>
        </tr>
      </Tooltip>
      <tr className="border-b-2 border-dashed border-gray-300">
        <TD>Movimientos</TD>
        <TD>${settlement.total_movimientos}</TD>
      </tr>
      <Tooltip
        title="Total cuenta corriente + Gastos + Movimientos - Comisión administrativa "
        arrow
      >
        <tr>
          <TD className="text-red-500">Saldo a favor del chofer</TD>
          <TD>${settlement.total_billed}</TD>
        </tr>
      </Tooltip>
    </table>
  );
};
