import { Tooltip } from '@material-ui/core';
import { DriverTransferReservationsWithPricesAndResume } from '../models/DriverTransferReservationsWithSettlementInfo';

type ResumenProps = {
  resumen: DriverTransferReservationsWithPricesAndResume['resumen'];
};

const TD = (props: { children: React.ReactNode; className?: string }) => (
  <p className={'p-2 pr-8 text-sm ' + props.className ?? ''}>
    {props.children}
  </p>
);

export const ResumenPreciosViajesComponent = ({ resumen }: ResumenProps) => {
  const totalGastos =
    resumen.total_billed +
    resumen.commission -
    resumen.total_worked +
    resumen.total_cash;
  return (
    <div className="mb-8 w-full rounded-md border-2 border-gray-100 bg-white p-4 sm:border-0">
      <div className="flex justify-between">
        <TD>Cuenta corriente</TD>
        <TD>${resumen.total_current_account}</TD>
      </div>
      <div className="flex justify-between border-b-2 border-dashed border-gray-100">
        <TD>Efectivo</TD>
        <TD>${resumen.total_cash}</TD>
      </div>
      <div className="flex justify-between">
        <TD>Total trabajado</TD>
        <TD>${resumen.total_worked}</TD>
      </div>
      <div className="flex justify-between">
        <TD>Gastos</TD>
        <TD>${totalGastos}</TD>
      </div>
      <div className="flex justify-between border-b-2 border-dashed border-gray-100">
        <TD>Comision</TD>
        <TD>${resumen.commission}</TD>
      </div>
      <Tooltip title="Total trabajado - Comisión administrativa" arrow>
        <div className="flex justify-between">
          <TD>Cobra</TD>
          <TD>${resumen.total_worked - resumen.commission}</TD>
        </div>
      </Tooltip>
      <Tooltip title="Comisión administrativa" arrow>
        <div className="flex justify-between border-b-2 border-dashed border-gray-100">
          <TD>Paga</TD>
          <TD>${resumen.commission}</TD>
        </div>
      </Tooltip>

      <Tooltip
        title="Total cuenta corriente + Gastos - Comisión administrativa "
        arrow
      >
        <tr className="flex justify-between">
          <TD className="text-red-500">Saldo a favor del chofer</TD>
          <TD>${resumen.total_billed}</TD>
        </tr>
      </Tooltip>
    </div>
  );
};
