export enum VehiculoTipo {
  FLETE = 'flete',
  UTILITARIO = 'utilitario',
  MINIBUS15 = 'minibus 15',
  MINIBUS19 = 'minibus 19',
  MINIBUS24 = 'minibus 24',
  MINIVAN = 'minivan',
  BUS_MENOS_40 = 'bus -40',
  BUS_MAS_40 = 'bus +40',
  EJECUTIVO = 'ejecutivo',
  VIP = 'vip'
}

export const VEHICULO_TIPOS = Object.values(VehiculoTipo).sort();
