import { useFormikContext } from 'formik';
import React from 'react';

function FormikOnFieldChange<FormFields>({
  field,
  onChange
}: {
  field: string;
  onChange: (value: any) => void;
}) {
  const { values } = useFormikContext<FormFields>();
  React.useEffect(() => {
    onChange(values[field]);
  }, [values[field]]);
  return null;
}

export default FormikOnFieldChange;
