import React from 'react';
import { Card } from 'shared/components';
import TollForm from './TollForm/TollForm';
import { TollFormType } from './TollForm/TollFormik';

interface Props {
  update: (TollValues: TollFormType, reset) => void;
  initialValues: TollFormType;
}
function TollEdit({ update, initialValues }: Props) {
  return (
    <Card title="Editar peaje" className="w-full">
      <div className="p-8">
        <TollForm onSubmit={update} initialValues={initialValues} />
      </div>
    </Card>
  );
}

export default TollEdit;
