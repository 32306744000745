import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import { ClientPrivateModel } from 'app/client/private/private/models/ClientPrivate';

export enum ClientType {
  private = 'ParticularClient',
  business = 'BusinessClient'
}

export const isClientBusiness = (
  pet: ClientBusinessModel | ClientPrivateModel
): pet is ClientBusinessModel => {
  return pet.type === ClientType.business;
};

export const isClientPrivate = (
  pet: ClientBusinessModel | ClientPrivateModel
): pet is ClientPrivateModel => {
  return pet.type === ClientType.private;
};
