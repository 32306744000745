import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { Card } from 'shared/components';
import { BarChartComponent } from '../components/BarChartComponent';
import { useTransferReservationStatisticsRepository } from '../services/transferReservationStatisticsRepository';

export const TransferReservationPerCoordinatorPage = () => {
  const repo = useTransferReservationStatisticsRepository();
  const perOperatorQuery = useSuperQuery(repo.perCoordinator);
  const [period, setPeriod] = useState<'today' | 'lastWeek' | 'lastMonth'>(
    'today'
  );

  if (perOperatorQuery.data === undefined) {
    return <p>Cargando...</p>;
  }

  const rawData = perOperatorQuery.data[period];
  const bars = rawData.map((data) => ({
    label: data.coordinatorName,
    value: data.amount
  }));

  return (
    <div>
      <PageHeader title="Reservas por coordinador" />
      <PageContainer>
        <div className="w-full">
          <Card>
            <div className="p-8">
              <FormControl>
                <FormLabel id="gender-group">Periodo</FormLabel>
                <RadioGroup
                  row
                  defaultValue="today"
                  name="gender-group"
                  onChange={(ev, value) => setPeriod(value as any)}
                >
                  <FormControlLabel
                    value="today"
                    control={<Radio />}
                    label="Día actual"
                  />
                  <FormControlLabel
                    value="lastWeek"
                    control={<Radio />}
                    label="Últimos 7 días"
                  />
                  <FormControlLabel
                    value="lastMonth"
                    control={<Radio />}
                    label="Último mes"
                  />
                </RadioGroup>
              </FormControl>
              <BarChartComponent bars={bars} />
            </div>
          </Card>
        </div>
      </PageContainer>
    </div>
  );
};
