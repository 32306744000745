import CostCenterForm from './CostCenterForm/CostCenterForm';
import { CostCenterFormType } from './CostCenterForm/CostCenterFormik';

interface Props {
  update: (costCenterValues: CostCenterFormType, reset) => void;
  initialValues: CostCenterFormType;
}
function CostCenterEdit({ update, initialValues }: Props) {
  return <CostCenterForm onSubmit={update} initialValues={initialValues} />;
}

export default CostCenterEdit;

