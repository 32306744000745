import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FC, useState } from 'react';

interface MappingCheckboxProps {
  checkStatus: string;
  onChange: (value: '1' | '0') => void;
  label?: string;
  hasError: boolean;
}

const MappingCheckbox: FC<MappingCheckboxProps> = ({
  checkStatus,
  onChange,
  label = '',
  hasError
}) => {

  const [isChecked, setIsChecked] = useState<boolean>(checkStatus && typeof checkStatus === 'boolean' ? checkStatus : checkStatus === '1');
  const [hasFieldError, setHasFieldError] = useState<boolean>(hasError);

  return (
    <div
      style={{
        ...(hasFieldError && { borderWidth: '1px' })
      }}
      className={hasFieldError ? 'h-14 border-red-500 rounded flex justify-center items-center' : ''}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={(ev) => {
              setHasFieldError(false);
              const checked = ev.target.checked;
              setIsChecked(checked);
              onChange(checked ? '1' : '0');
            }
            }
          />
        }
        label={label}
      />
    </div>
  )

};

export default MappingCheckbox;