import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Divider,
  Drawer,
  IconButton
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@vadiun/react-components';
import { useTableQuery } from '@vadiun/react-datatable';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { ClientType } from 'app/client/main/models/ClientType';
import { useViajesTraslado } from 'app/viaje/services';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import ViajeTrasladoResumenModal from '../../viajeTraslado/components/ViajeTrasladoResumenModal';
import { TransferReservationModel } from '../../viajeTraslado/models/TransferReservation';
import {
  TransferReservationPrices,
  TransferReservationPricesRateType
} from '../../viajeTraslado/models/TransferReservationPrices';
import CorrectPriceForm from '../components/forms/CorrectPriceForm';
import { CorrectPriceFormType } from '../components/forms/CorrectPriceFormik';
import TarifaDetalle from '../components/TarifaDetalle';
import { ViajeTrasladoCorrecionTable } from '../components/ViajeTrasladoCorrecionTable';

const ViajeTrasladoCorreccionCuantaCoriente = () => {
  const tripRepository = useViajesTraslado();
  const ViajeTrasladoService = useViajesTraslado();
  const [showFilters, setShowFilters] = useState(false);
  const clientBusinessRepository = useClientBusiness();

  const correctFinalPrice = useSuperMutation(
    ViajeTrasladoService.correctFinalPriceCuentaCorriente
  );

  const [filters, setFilters] = useFilters({
    dateFrom: moment().hour(0).minutes(0),
    dateTo: moment().hour(23).minutes(59),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false
  });

  const tableQuery = useTableQuery(
    [
      'reservas',
      'a-corregir',
      {
        ...filters,
        dateFrom: filters.dateFrom.format(),
        dateTo: filters.dateTo.format()
      }
    ],
    (params: URLSearchParams) =>
      tripRepository.getACorregirCuentaCorriente(params, filters)
  );

  const [locationsDetail, setLocationsDetail] = useState<{
    isOpen: boolean;
    details: TransferReservationModel | undefined;
  }>({ isOpen: false, details: undefined });
  const [transferReservationAuditando, setTransferReservationAuditando] =
    useState<TransferReservationModel | undefined>(undefined);
  const [isCorrectPriceModalOpen, setIsCorrectPriceModalOpen] = useState(false);

  const tarifaQuery = useSuperQuery(
    async () => {
      if (
        transferReservationAuditando === undefined ||
        transferReservationAuditando.clientType === ClientType.private
      ) {
        return undefined;
      }
      return await clientBusinessRepository.getCurrentRate(
        transferReservationAuditando.client.id
      );
    },
    undefined,
    [transferReservationAuditando]
  );

  const [pricingInfo, setPricingInfo] = useState<
    TransferReservationPrices | undefined
  >(undefined);

  const showPriceConfirmation = async (form: CorrectPriceFormType) => {
    const data = {
      id: transferReservationAuditando!.id,
      waiting_minutes: form.waiting_minutes,
      km: form.km,
      transfer_price: 0,
      expenses_price: form.expenses_price,
      observation: form.observation,
      rate_type: form.rate_type
    };

    const pricingQuery = await ViajeTrasladoService.getReservationPricing(data);

    setPricingInfo(pricingQuery);
  };

  const correctTransferPrice = async (form: CorrectPriceFormType) => {
    const data = {
      id: transferReservationAuditando?.id ?? 0,
      waiting_minutes: form.waiting_minutes,
      km: form.km,
      expenses_price: form.expenses_price,
      transfer_price: form.transfer_price,
      observation: form.observation,
      rate_type: form.rate_type
    };
    await correctFinalPrice.mutate(data);
    setIsCorrectPriceModalOpen(false);
    setTransferReservationAuditando(undefined);
    setPricingInfo(undefined);
    tableQuery.refetch();
  };

  const getDefaultPrices = () => {
    if (pricingInfo) {
      return {
        waiting_minutes: pricingInfo.waiting_minutes,
        km: pricingInfo.kilometers,
        expenses_price: pricingInfo.expenses_price,
        transfer_price: pricingInfo.transfer_price,
        observation: pricingInfo.observation,
        rate_type: pricingInfo.rate_type
      };
    }
    const final_prices = transferReservationAuditando?.final_prices;
    if (final_prices) {
      return {
        waiting_minutes: final_prices.waiting_minutes,
        km: final_prices.kilometers,
        expenses_price: final_prices.expenses_price,
        transfer_price: final_prices.transfer_price,
        observation: final_prices.observation ?? '',
        rate_type: final_prices.rate_type
      };
    }

    return {
      waiting_minutes: 0,
      km: 0,
      expenses_price: 0,
      transfer_price: 0,
      observation: '',
      rate_type: TransferReservationPricesRateType.KILOMENTO_LINEAL
    };
  };

  const tarifaDelViajeSeleccionado = (tarifaQuery.data?.categories ?? []).find(
    (cat) => cat.name === transferReservationAuditando?.serviceType
  );

  const availableRateTypes: TransferReservationPricesRateType[] = [
    TransferReservationPricesRateType.KILOMENTO_LINEAL,
    TransferReservationPricesRateType.KILOMETRO_CON_REGRESO,
    TransferReservationPricesRateType.MINIMUM_PRICE,
    TransferReservationPricesRateType.CABA_CABA,
    TransferReservationPricesRateType.CABA_CABA_RETURN
  ];
  const originalRate =
    transferReservationAuditando?.calculated_prices?.rate_type;

  if (
    originalRate !== undefined &&
    !availableRateTypes.includes(originalRate)
  ) {
    availableRateTypes.push(originalRate);
  }

  return (
    <>
      <PageHeader
        title="Traslados disponibles para corregir"
        toolbar={
          <Button variant="light" onClick={() => setShowFilters(true)}>
            Filtros
          </Button>
        }
      />
      <PageContainer lessMargin>
        <ViajeTrasladoCorrecionTable
          tableQuery={tableQuery}
          onLocationsDetails={(reservation) =>
            setLocationsDetail({ isOpen: true, details: reservation })
          }
          onCorrectReservationPrices={(reservation) => {
            setIsCorrectPriceModalOpen(true);
            setTransferReservationAuditando(reservation);
          }}
          onReservationShowDetails={(id) => (
            <Link to={'/main/Viaje/traslado/detalle/' + id}>
              <Button variant="light" className="w-full" color="orange">
                Detalle
              </Button>
            </Link>
          )}
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-lg"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />

          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
          />
        </div>
      </Drawer>

      {locationsDetail.details && (
        <ViajeTrasladoResumenModal
          isOpen={locationsDetail.isOpen}
          reservation={locationsDetail.details!}
          handleClose={() =>
            setLocationsDetail({ isOpen: false, details: undefined })
          }
        />
      )}
      <Dialog
        open={isCorrectPriceModalOpen}
        onClose={() => {
          setIsCorrectPriceModalOpen(false);
          setPricingInfo(undefined);
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <div className="flex gap-4 p-8">
          <div className="flex-1">
            <Accordion
              elevation={0}
              style={{ borderWidth: 1 }}
              defaultExpanded
              className=" border-dashed border-gray-300"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                Precio sugerido
              </AccordionSummary>
              <AccordionDetails>
                <TarifaDetalle tarifaInfo={pricingInfo} />
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={0}
              defaultExpanded
              style={{ borderWidth: 1 }}
              className=" border-dashed border-gray-300"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Tarifas fijas (
                {tarifaDelViajeSeleccionado?.fixedPrices?.length ?? 0})
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {tarifaDelViajeSeleccionado?.fixedPrices.map((price) => (
                    <p key={price.name} className="text-sm">
                      {price.name}:{' '}
                      <span className="text-green-500">${price.price}</span>
                    </p>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="flex-1">
            <CorrectPriceForm
              onSubmit={correctTransferPrice}
              onChange={showPriceConfirmation}
              initialValues={getDefaultPrices()}
              availableRateTypes={availableRateTypes}
              originalRate={originalRate}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ViajeTrasladoCorreccionCuantaCoriente;
