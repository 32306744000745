import React from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const CardHeader = ({ children, className }: Props) => {
  return (
    <div
      className={overrideTailwindClasses(
        `flex justify-between py-4 px-8 items-center border-solid border-gray-100 border-b-4 ${className}`
      )}
    >
      {children}
    </div>
  );
};

export default CardHeader;
