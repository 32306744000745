import { zodResolver } from '@hookform/resolvers/zod';
import {
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useAuthorizeds from 'app/client/clientBusiness/Authorized/services/AuthorizedService';
import { ClientBusinessPartialModel } from 'app/client/clientBusiness/models/ClientBusiness';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FileSelection from '../file/TravelFileSelection';
import { ExcelFileType } from '../file/useFile';
import { ImperativeStepMovementType } from '../stepper/types';
import { transformExcelRows } from '../transformation/transformExcelRows';
import { transformReservationRequirements } from '../transformation/transformReservationRequirement';
import {
  BulkClient,
  ClientRequirements,
  ClientRequirementType,
  TravelApplicantForm,
  TravelClientRequirementsForm,
  TravelClientRequirementsFormSchema,
  TravelEventForm
} from '../utils/types';

interface TravelInitialSelectionProps {
  onStepReady: (isReady: boolean) => void;
  currentClient?: BulkClient;
  currentExcelFile?: ExcelFileType;
  onRetrieveSelectionData: (
    fileData: {
      rawFile: ExcelFileType;
      parsedFile: {
        maxColSize: number;
        mappedColumns: Record<string, string>[];
      };
    },
    client: BulkClient
  ) => void;
  onCurrentFileRemoved: (onPerfomFileRemove: () => void) => void;
  onClientChange: (onClientChanged: () => void) => void;
}

const TravelInitialSelection = forwardRef<
  ImperativeStepMovementType,
  TravelInitialSelectionProps
>(
  (
    {
      onStepReady,
      onRetrieveSelectionData,
      currentClient,
      currentExcelFile,
      onCurrentFileRemoved,
      onClientChange
    },
    ref
  ) => {
    const [fileBodyData, setFileBodyData] = useState<{
      maxColSize: number;
      mappedColumns: Record<string, string>[];
    } | null>(
      currentExcelFile ? transformExcelRows(currentExcelFile.body) : null
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { getAll, get: getSingleClient } = useClientBusiness();
    const { getAuthorizeds } = useAuthorizeds();
    const [clients, setClients] = useState<ClientBusinessPartialModel[]>([]);
    const [selectedClient, setSelectedClient] =
      useState<ClientBusinessPartialModel | null>(currentClient?.info || null);
    const [reservationRequirements, setReservationRequirements] = useState<
      ClientRequirementType[] | undefined
    >(currentClient?.reservationRequirements || undefined);
    const [excelFile, setExcelFile] = useState<ExcelFileType | undefined>(
      currentExcelFile
    );
    const [authorizedPersonal, setAuthorizedPersonal] = useState<
      string[] | undefined
    >(currentClient?.authorizedPersonal);
    const [subCostElements, setSubCostElements] = useState<
      string[] | undefined
    >(currentClient?.subCostElements || []);

    useEffect(() => {
      (async () => {
        const clients = await getAll();
        setClients(clients);
      })();
      // how it was done is insane
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileSelected = (excelData?: ExcelFileType) => {
      if (excelData) {
        onStepReady(!!selectedClient);
        setExcelFile(excelData);
        return setFileBodyData(transformExcelRows(excelData.body));
      }
      setFileBodyData(null);
      onStepReady(false);
    };

    const { control, handleSubmit, reset } =
      useForm<TravelClientRequirementsForm>({
        resolver: zodResolver(TravelClientRequirementsFormSchema),
        defaultValues: (currentClient?.requirements && currentClient.event) || {
          applicant_email: '',
          applicant_name: '',
          applicant_phone: '',
          is_event: false,
          event_name: ''
        }
      });
    const handleSetSelectedClient = async (
      client: ClientBusinessPartialModel | null
    ) => {
      if (client) {
        onStepReady(!!fileBodyData);
        const {
          reservationRequirement,
          business_client_sub_cost_centers: subCostCenter
        } = await getSingleClient(client.id);
        const rr = transformReservationRequirements(reservationRequirement);

        if (rr.some(({ type }) => type === ClientRequirements.AUTHORIZED_ID)) {
          setAuthorizedPersonal(
            ((await getAuthorizeds(client.id)) || []).map(({ name }) => name)
          );
        } else {
          setAuthorizedPersonal(undefined);
        }
        if (
          subCostCenter &&
          subCostCenter.length &&
          rr.some(({ type }) => type === ClientRequirements.SUB_COST_CENTER)
        ) {
          setSubCostElements(subCostCenter.map(({ name }) => name));
        } else {
          setSubCostElements(undefined);
        }
        reset({
          applicant_email: '',
          applicant_name: '',
          applicant_phone: '',
          is_event: false,
          event_name: ''
        });
        setReservationRequirements(rr);
        return setSelectedClient(client);
      }

      setReservationRequirements([]);
      setSubCostElements(undefined);
      setSelectedClient(null);
      onStepReady(false);
    };

    const onSubmit = (formData: TravelClientRequirementsForm) => {
      const {
        applicant_name,
        applicant_email,
        applicant_phone,
        event_name,
        is_event
      } = formData;

      const formDataApplicant: TravelApplicantForm = {
        applicant_name,
        applicant_email,
        applicant_phone
      };

      const formDataEvent: TravelEventForm = {
        event_name,
        is_event
      };

      onRetrieveSelectionData(
        {
          parsedFile: fileBodyData!,
          rawFile: excelFile!
        },
        {
          info: selectedClient!,
          reservationRequirements: reservationRequirements!,
          requirements: formDataApplicant,
          event: formDataEvent,
          authorizedPersonal,
          subCostElements
        }
      );
    };

    const handleSelectionStepCompleted = () => {
      if (fileBodyData && selectedClient) {
        handleSubmit(onSubmit)();
      }
    };

    useImperativeHandle(ref, () => ({
      onClick: handleSelectionStepCompleted
    }));

    return (
      <div className="flex flex-col gap-y-4">
        <div className="border-b-2 border-dashed pb-4">
          <Autocomplete
            open={isOpen}
            onOpen={() => {
              setIsOpen(true);
            }}
            onClose={() => {
              setIsOpen(false);
            }}
            onChange={(_, client) =>
              onClientChange(handleSetSelectedClient.bind(null, client))
            }
            getOptionLabel={(option) => option.fantasyName}
            getOptionSelected={(optionClient, selectedClient) => {
              if (!optionClient || !selectedClient) {
                return false;
              }
              return optionClient.fantasyName === selectedClient.fantasyName;
            }}
            options={clients}
            noOptionsText="No se han encontrado resultados"
            loadingText="Buscando clientes"
            loading={false}
            value={selectedClient}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cliente"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {!clients.length ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </div>

        <div className="border-b-2 border-dashed pb-4">
          <form className="flex flex-col gap-y-4">
            <div className="flex gap-x-4 justify-between">
              <Controller
                control={control}
                name="applicant_name"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    {...field}
                    className="w-full"
                    label="Solicitante"
                    placeholder="Ingrese nombre del solicitante"
                    variant="outlined"
                  />
                )}
              />
              <Controller
                control={control}
                name="applicant_email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    {...field}
                    className="w-full"
                    label="Email"
                    placeholder="Ingrese email del solicitante"
                    variant="outlined"
                  />
                )}
              />
              <Controller
                control={control}
                name="applicant_phone"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    error={!!error?.message}
                    helperText={error?.message}
                    {...field}
                    className="w-full"
                    label="Teléfono"
                    placeholder="Ingrese teléfono del solicitante"
                    variant="outlined"
                  />
                )}
              />
              <Controller
                control={control}
                name="is_event"
                render={({ field: { onChange, value } }) => (
                  <div className="flex flex-row w-full justify-between">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      }
                      label={'Evento'}
                    />
                    {value && (
                      <Controller
                        name="event_name"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error?.message}
                            helperText={error?.message}
                            className="text-gray-700 w-full text-sm text-center"
                            label="Nombre del evento"
                            placeholder="Ingrese nombre del evento"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                )}
              />
            </div>
          </form>
        </div>
        <FileSelection
          onFileSelected={handleFileSelected}
          currentExcelFile={currentExcelFile}
          onCurrentFileRemoved={onCurrentFileRemoved}
        />
      </div>
    );
  }
);

export default TravelInitialSelection;
