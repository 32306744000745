export enum VehiculoCategoria {
  BASICO = 'Basico',
  BRONCE = 'Bronce',
  PLATA = 'Plata',
  ORO = 'Oro'
}

export enum VehiculoCategoriaIconColor {
  BRONCE = 'coral',
  PLATA = 'aliceblue',
  ORO = 'gold',
  BASICO = 'black'
}

export function mapVehiculoCategoriaToIconColor(categoria) {
  switch (categoria) {
    case VehiculoCategoria.BRONCE:
      return VehiculoCategoriaIconColor.BRONCE;
    case VehiculoCategoria.PLATA:
      return VehiculoCategoriaIconColor.PLATA;
    case VehiculoCategoria.ORO:
      return VehiculoCategoriaIconColor.ORO;
  }
}
