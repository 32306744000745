import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import { FC, useState } from 'react';

interface DatePickerProps {
  dateString: string;
  onChange: (dateString: string) => void;
  hasError: boolean;
}
const DatePicker: FC<DatePickerProps> = ({
  dateString,
  onChange,
  hasError: hasCellError
}) => {
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment(dateString, "DD/MM/YYYY"));
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasFieldError, setHasFieldError] = useState<boolean>(hasCellError);

  return (
    <KeyboardDatePicker
      error={hasFieldError}
      disableToolbar
      style={{ width: '100%' }}
      format="DD/MM/yyyy"
      margin="normal"
      minDate={new Date()}
      invalidLabel="Fecha inválida"
      {...(hasError && { helperText: 'Fecha inválida' })}
      onError={(label) => {
        setHasError(!!label)
      }}
      value={currentDate}
      onChange={(momentDate) => {
        setHasFieldError(false);
        setHasError(false);
        if (momentDate) {
          setCurrentDate(momentDate);
          onChange(momentDate.format('DD/MM/yyyy'));
        }
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  )
};

export default DatePicker;