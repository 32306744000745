import React from 'react';
import { ClientBusinessUserForm } from './forms/ClientBusinessUserForm';
import { ClientBusinessUserFormType } from './forms/ClientBusinessUserFormType';

interface Props {
  create: (UserValues: ClientBusinessUserFormType) => Promise<void>;
}
export function ClientBusinessUserCreate({ create }: Props) {
  return <ClientBusinessUserForm onSubmit={create} />;
}
