import { ClientPrivateModel } from 'app/client/private/private/models/ClientPrivate';
import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import moment, { Moment } from 'moment';
import { TransferReservationBaseLocationFormType } from '../../viajeTraslado/forms/base/TransferReservationBaseFormTypes';
import {
  ReservaTrasladoRepetitivePrivateCreateModel,
  TransferReservationRepetitivePrivateModel
} from '../models/TransferReservationRepetitivePrivateModel';

export interface TransferReservationRepetitivePrivateFormType {
  cliente: ClientPrivateModel | null;
  canalIngreso: ViajeCanalesDeIngreso;
  prioridad: boolean;
  tipoServicio: ViajeTrasladoTipoServicio;
  observacionCoordinacion: string | null;
  hasOutSite: boolean;
  pay_with_cash: boolean;
  puntos: TransferReservationBaseLocationFormType[];
  fechaYHoraDeLlegada: Moment;
  minutosPreviosASalida: Moment;
  repeatOnMonday: boolean;
  repeatOnTuesday: boolean;
  repeatOnWednesday: boolean;
  repeatOnThursday: boolean;
  repeatOnFriday: boolean;
  repeatOnSaturday: boolean;
  repeatOnSunday: boolean;
  repetitionFinishDate: Moment;
  repetitionInitDate: Moment;
}

function reservaTrasladoRepetitivePrivateFormToModel(
  form: TransferReservationRepetitivePrivateFormType
): ReservaTrasladoRepetitivePrivateCreateModel {
  return {
    clienteId: form.cliente!.id,
    canalIngreso: form.canalIngreso,
    prioridad: form.prioridad,
    tipoServicio: form.tipoServicio,
    puntos: form.puntos,
    fechaYHoraDeLlegada: form.fechaYHoraDeLlegada,
    minutosPreviosASalida: form.minutosPreviosASalida,
    observacionCoordinacion: form.observacionCoordinacion,
    hasOutSite: form.hasOutSite,
    pay_with_cash: form.pay_with_cash,
    repeatOnMonday: form.repeatOnMonday,
    repeatOnTuesday: form.repeatOnTuesday,
    repeatOnWednesday: form.repeatOnWednesday,
    repeatOnThursday: form.repeatOnThursday,
    repeatOnFriday: form.repeatOnFriday,
    repeatOnSaturday: form.repeatOnSaturday,
    repeatOnSunday: form.repeatOnSunday,
    repetitionFinishDate: form.repetitionFinishDate,
    repetitionInitDate: form.repetitionInitDate
  };
}

function reservaRepetitivePrivateModelToForm(
  reserva: TransferReservationRepetitivePrivateModel,
  cliente: ClientPrivateModel
): TransferReservationRepetitivePrivateFormType {
  return {
    cliente,
    canalIngreso: reserva.entry_channel ?? '',
    prioridad: reserva.has_priority,
    tipoServicio: reserva.service_type,
    observacionCoordinacion: reserva.coordination_observation ?? '',
    hasOutSite: reserva.has_out_site,
    fechaYHoraDeLlegada: moment()
      .hours(reserva.reservation_hours)
      .minutes(reserva.reservation_minutes),
    minutosPreviosASalida: moment()
      .hours(reserva.reservation_hours)
      .minutes(reserva.reservation_minutes)
      .subtract(reserva.advanceHours, 'hours'),
    puntos: reserva.puntos.map((p) => ({
      domicilio: p.domicilio,
      espera: p.minutosEspera ? String(p.minutosEspera) : '0',
      observacion: p.observacion ?? '',
      pasajeros: p.pasajeros
    })),
    repeatOnMonday: reserva.monday,
    repeatOnTuesday: reserva.tuesday,
    repeatOnWednesday: reserva.wednesday,
    repeatOnThursday: reserva.thursday,
    repeatOnFriday: reserva.friday,
    repeatOnSaturday: reserva.saturday,
    repeatOnSunday: reserva.sunday,
    repetitionFinishDate: reserva.to_date,
    repetitionInitDate: reserva.from_date,
    pay_with_cash: reserva.pay_with_cash
  };
}

export const transferReservationRepetitivePrivateFormBuilder = {
  toModel: reservaTrasladoRepetitivePrivateFormToModel,
  toForm: reservaRepetitivePrivateModelToForm
};
