import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface CostCenterFormType {
  id?: number;
  name: string;
}

const costCenterFormSchema: YupShape<CostCenterFormType> = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
});

const costCenterInitialValues: CostCenterFormType = {
  id: 0,
  name: ''
};

const CostCenterFormik = {
  schema: costCenterFormSchema,
  initialValues: costCenterInitialValues
};

export default CostCenterFormik;
