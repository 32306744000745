import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button, Modal, Table, TableColumn } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks';
import useChoferes from 'app/chofer/services/ChoferService';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import LocationDetails from 'app/viaje/viajeTraslado/viajeTraslado/components/LocationDetails';
import { TransferReservationPartialModel } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPartial';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import {
  CoordinadorChoferFiltersComponent,
  CoordinadorChoferTableFiltersType
} from '../components/CoordinadorChoferFiltersComponent';
import { ResumenPreciosViajesComponent } from '../components/ResumenPreciosViajesComponent';
import { useDriverSettlementRepository } from '../services/driverSettlementsRepository';

export const CoordinadorChoferPendingSettlement = () => {
  const driversRepo = useChoferes();
  const driverSettlementRepo = useDriverSettlementRepository();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<CoordinadorChoferTableFiltersType>({
    from: moment().add(-24, 'hours'),
    to: moment(),
    driver_id: 0
  });

  const pendingSettlementQuery = useSuperQuery(
    ['drivers-settlement', filters],
    () => driverSettlementRepo.getPendingSettlementCoordinator(filters),
    { showSpinner: true }
  );

  const driversQuery = useSuperQuery(['drivers'], () =>
    driversRepo.getChoferes()
  );

  const [locationsDetail, setLocationsDetail] = useState<{
    isOpen: boolean;
    details?: {
      address: DomicilioModel;
      observation: string;
    }[];
  }>({ isOpen: false, details: undefined });

  const onLocationDetails = (
    locations: TransferReservationPartialModel['locations']
  ) => {
    setLocationsDetail({
      isOpen: true,
      details: locations.map((l) => ({
        address: l.address,
        observation: l.observation
      }))
    });
  };
  const data = (pendingSettlementQuery.data?.data ?? []).map((d) => {
    return {
      ...d,
      client: d.client.name,
      locations: d.locations,
      status: d.state,
      total_precio_espera: Math.round(d.total_precio_espera),
      dateTime: d.reservationDatetime.format('DD/MM/YYYY HH:mm [hs.]')
    };
  });

  const columns: TableColumn<typeof data[0]>[] = [
    { name: 'dateTime', label: 'Fecha' },
    { name: 'client', label: 'Cliente' },
    {
      name: 'locations',
      label: 'Recorrido',
      options: {
        customBodyRenderLite: (index) => {
          const firstPoint = data[index].locations[0];
          const lastPoint =
            data[index].locations[data[index].locations.length - 1];
          return (
            <div className="flex flex-col items-center">
              <span className="title text-center text-xs">
                {createDomicilioText(firstPoint.address)}
              </span>
              <Button
                shape="circle"
                className="p-2"
                onClick={() => onLocationDetails(data[index].locations)}
                color={data[index].locations.length > 2 ? 'blue' : 'gray'}
              >
                <div className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faArrowDown} />
                  {data[index].locations.length > 2
                    ? data[index].locations.length
                    : null}
                </div>
              </Button>

              <span className="title text-center text-xs">
                {createDomicilioText(lastPoint.address)}
              </span>
            </div>
          );
        }
      }
    },
    { name: 'state', label: 'Estado' },
    {
      name: 'contado',
      label: 'Contado'
    },
    {
      name: 'cuenta_corriente',
      label: 'Cuenta Corriente'
    },
    {
      name: 'total_precio_espera',
      label: 'Espera'
    },
    {
      name: 'total_gastos',
      label: 'Gastos'
    },
    {
      name: 'total_a_facturar',
      label: 'Total'
    }
  ];

  useEffect(() => {
    pendingSettlementQuery.refetch();
  }, [filters]);

  const totalSettlement = data.reduce(
    (a, v) => (a = a + v.total_a_facturar),
    0
  );

  const Resume = pendingSettlementQuery.data ? (
    <ResumenPreciosViajesComponent
      resumen={pendingSettlementQuery.data.resumen}
    />
  ) : null;

  return (
    <>
      <PageHeader
        title="Control Liquidaciones"
        toolbar={
          <div className="flex space-x-2">
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtrar
            </Button>
          </div>
        }
      />
      <PageContainer>
        <div className="w-full">
          <Table
            columns={columns}
            data={data}
            title={undefined}
            options={{
              responsive: 'standard',
              filter: false,
              search: false,
              print: false,
              download: false
            }}
          />

          <div className="mt-8 w-full sm:w-1/2">{Resume}</div>
        </div>
        <Modal
          open={locationsDetail.isOpen}
          onClose={() =>
            setLocationsDetail({ isOpen: false, details: undefined })
          }
          size="md"
          body={
            <div style={{ height: 300 }}>
              <LocationDetails
                locations={(locationsDetail.details ?? []).map((l) => ({
                  ...l,
                  passengers: []
                }))}
              />
            </div>
          }
        />
      </PageContainer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-lg"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <CoordinadorChoferFiltersComponent
            onSubmit={async (values) => setFilters(values)}
            initialValues={filters}
            drivers={driversQuery?.data || []}
          />
        </div>
      </Drawer>
    </>
  );
};
