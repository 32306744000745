import {
  fabricanteBackModelToModel,
  VehiculoFabricanteBackModel,
  VehiculoFabricanteModel
} from '../../fabricanteVehiculo/models/VehiculoFabricante';

export interface VehiculoModeloModel {
  id: number;
  nombre: string;
  fabricante: VehiculoFabricanteModel;
  isDeleted: boolean;
}

export interface VehiculoModeloCRUD
  extends Omit<VehiculoModeloModel, 'id' | 'isDeleted'> {
  id?: number;
}

export interface VehiculoModeloBackModel {
  id: number;
  name: string;
  brand_id: number;
  brand: VehiculoFabricanteBackModel;
  isDeleted: boolean;
}

export function vehiculoModeloModelToBack(modelo: VehiculoModeloCRUD) {
  return {
    name: modelo.nombre,
    brand_id: modelo.fabricante.id!
  };
}

export function vehiculoModeloBackModelToModel(
  modeloBack: VehiculoModeloBackModel
): VehiculoModeloModel {
  return {
    id: modeloBack.id,
    nombre: modeloBack.name,
    fabricante: fabricanteBackModelToModel(modeloBack.brand),
    isDeleted: modeloBack.isDeleted
  };
}
