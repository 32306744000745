import * as Yup from 'yup';
import {
  CANALES_DE_VIAJE,
  TIPOS_SERVICIO_VIAJE_TRASLADO
} from 'app/viaje/models';
import { transferReservationBaseLocationFormValidation } from 'app/viaje/viajeTraslado/viajeTraslado/forms/base/TransferReservationBaseFormValidation';

export const transferReservationPrivateBatchLocationFormValidation =
  transferReservationBaseLocationFormValidation;

export const transferReservationPrivateBatchTripFormValidation =
  Yup.object().shape({
    fechaYHoraDeLlegada: Yup.mixed().required(
      'La fecha de llegada es requerida'
    ),
    minutosPreviosASalida: Yup.mixed().required(
      'Los minutos de salida son requeridos'
    ),
    puntos: Yup.array()
      .min(2, 'Debe haber al menos dos puntos')
      .of(transferReservationPrivateBatchLocationFormValidation)
  });

export const transferReservationPrivateBatchFormValidation = Yup.object().shape(
  {
    cliente: Yup.mixed().required('El cliente es obligatorio'),
    canalIngreso: Yup.string()
      .required('El canal de ingreso es obligatorio')
      .oneOf(CANALES_DE_VIAJE),
    prioridad: Yup.boolean(),
    hasOutSite: Yup.boolean(),
    tipoServicio: Yup.mixed()
      .required('El tipo de servicio es obligatorio')
      .oneOf(TIPOS_SERVICIO_VIAJE_TRASLADO),
    viajes: Yup.array()
      .min(1, 'Debe haber al menos un viaje')
      .of(transferReservationPrivateBatchTripFormValidation)
  }
);
