import React from 'react';
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {TextField} from "formik-material-ui";
import {Button, Modal} from "@vadiun/react-components";

interface Props {
    isOpen: boolean,
    onClose: () => void,
    cancel: (reservationId: number, cancelObservation: string) => void,
    reservationId: number
}

function CancelViajeTrasladoModal({
                                      isOpen,
                                      onClose,
                                      cancel,
                                      reservationId
                                  }: Props) {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            body={
                <Formik
                    initialValues={{cancelObservation: ""}}
                    onSubmit={async (values, formikHelpers) => {
                        cancel(reservationId!, values.cancelObservation)
                        formikHelpers.resetForm()
                    }}
                    validationSchema={Yup.object().shape({
                        cancelObservation: Yup.string().required('Ingrese una observacion')
                    })}
                >
                    {({touched, errors, submitForm}) => (
                        <Form>
                            <Field
                                name="cancelObservation"
                                component={TextField}
                                fullWidth
                                placeholder="Ingrese el motivo de cancelacion"
                            />
                            <div className="flex justify-end mt-8">
                                <Button type="submit" variant="contained">
                                    Guardar
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        />
    );
}

export default CancelViajeTrasladoModal;
