import ExcelJS from 'exceljs';
import {
  ColumnIdType,
  ColumnMapping,
  TripCompletionType
} from '../utils/types';

const headerGenerator = (initialLength: number) => {
  const letters = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
  const result: string[] = [];

  for (let i = 0; i < initialLength; i++) {
    const letterIndex = i % 26;
    const letter = letters[letterIndex];
    const suffix = Math.floor(i / 26) + 1;
    const duplicatedLetters = letter.repeat(suffix);
    result.push(duplicatedLetters + '1');
  }

  return result;
};

export const exportTripsToExcel = (
  excellData: TripCompletionType[],
  columns: ColumnMapping[],
  client: string,
  type: 'OK' | 'DISCARDED'
) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Trips');
  sheet.properties.defaultRowHeight = 35;

  sheet.getRow(1).border = {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } }
  };

  sheet.getRow(1).alignment = {
    vertical: 'middle'
  };

  sheet.getRow(1).font = {
    name: 'Arial',
    family: 4,
    size: 16,
    bold: false,
    color: { argb: 'FFFFFF' }
  };

  sheet.columns = columns.reduce((acc, { id, label }) => {
    if (type === 'DISCARDED' && id === ColumnIdType.ID) {
      return [
        ...acc,
        {
          header: 'Orden',
          key: 'Orden',
          width: 20
        }
      ];
    }
    return [
      ...acc,
      {
        header: label,
        key: id,
        width: 20
      }
    ];
  }, [] as Partial<ExcelJS.Column>[]);

  const excelRows = headerGenerator(columns.length);

  sheet.mergeCells(excelRows[0], excelRows[excelRows.length - 1]);

  sheet.getCell('A1').value = `Viajes ${
    type === 'DISCARDED' ? 'Descartados' : ''
  } del cliente ${client}`;

  excelRows.map(
    (key) =>
      (sheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '66CCFF' }
      })
  );

  sheet.addRow(2).values = columns.map((column) => {
    if (column.id === ColumnIdType.ID && type === 'DISCARDED') {
      return 'Orden';
    }
    return column.label;
  });

  sheet.getRow(2).border = {
    top: { style: 'thin', color: { argb: '000000' } },
    left: { style: 'thin', color: { argb: '000000' } },
    bottom: { style: 'thin', color: { argb: '000000' } },
    right: { style: 'thin', color: { argb: '000000' } }
  };
  excellData?.map(async (reservation, index) => {
    if (type === 'DISCARDED') {
      sheet.addRow({
        ...reservation,
        Orden: `${index + 1}`
      });
    } else {
      sheet.addRow(reservation);
    }
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `Viajes-${client}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
