import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import React from 'react';
import ClientBusinessFormik, { ClientBusinessReservationRequirementType } from './ClientBusinessFormik';

interface Props {
  onSubmit: (client: ClientBusinessReservationRequirementType) => void;
  initialValues?: ClientBusinessReservationRequirementType;
  submitLabel?: string;
  onGoBack?: () => void;
}

function ClientBusinessRequerimentsForm({
  onSubmit,
  onGoBack,
  submitLabel = 'Guardar',
  initialValues = ClientBusinessFormik.reservationRequirements.initialValues
}: Props) {
  return (
    <Formik<ClientBusinessReservationRequirementType>
      initialValues={initialValues}
      validationSchema={ClientBusinessFormik.reservationRequirements.schema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {() => (
        <Form className="w-full grid gap-6 grid-cols-2">
          <Field
            name="needsCostCenter"
            Label={{ label: 'Centro de costos' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsSubCostCenter"
            Label={{ label: 'Sub Centro de costos' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsSector"
            Label={{ label: 'Sector' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsAuthorized"
            Label={{ label: 'Autorizado' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsBuyOrder"
            Label={{ label: 'Order de compra' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsEmployeeNumber"
            Label={{ label: 'Legajo' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsPlatformApplication"
            Label={{ label: 'Solicitud de plataforma' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsTripNumber"
            Label={{ label: 'Número de viaje' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsData1"
            Label={{ label: 'Dato 1' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsData2"
            Label={{ label: 'Dato 2' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <Field
            name="needsData3"
            Label={{ label: 'Dato 3' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
            type="checkbox"
          />
          <div className="flex justify-between col-span-2">
            {onGoBack && (
              <Button onClick={onGoBack} variant="light">
                Volver
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="green"
              className="ml-auto"
            >
              {submitLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ClientBusinessRequerimentsForm;
