import { Dialog } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { UserChangePassword } from '../components/UserChangePassword';
import { UserCreate } from '../components/UserCreate';
import { UserEdit } from '../components/UserEdit';
import {
  userFormBuilder,
  UserFormType
} from '../components/UserForm/UserFormType';
import { UserTable } from '../components/UserTable';
import { UserModel } from '../models/User';
import useUsersRepository from '../services/UserService';

export function UserCrudPage() {
  const userRepository = useUsersRepository();
  const usersQuery = useSuperQuery(userRepository.getAll, {
    showSpinner: true
  });
  const rolesQuery = useSuperQuery(userRepository.getRoles, {
    showSpinner: true
  });
  const updateUserMutation = useSuperMutation(userRepository.updateUser);
  const addUserMutation = useSuperMutation(userRepository.addUser);
  const changePasswordMutation = useSuperMutation(
    userRepository.changePassword
  );
  const removeUserMutation = useSuperMutation(userRepository.removeUser, {
    showSpinner: true
  });
  const restoreUserMutation = useSuperMutation(userRepository.restoreUser, {
    showSpinner: true
  });
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );

  const [updatingUser, setEditingUser] = useState<UserModel>();
  const [changingPasswordUser, setChangingPasswordUser] = useState<UserModel>();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  async function update(userValues: UserFormType) {
    await updateUserMutation.mutate({ ...userValues, id: updatingUser!.id });
    setVisiblePage('table');
    usersQuery.reload();
  }

  async function create(userValues: UserFormType) {
    await addUserMutation.mutate(userValues);
    setVisiblePage('table');
    usersQuery.reload();
  }

  async function remove(user: UserModel) {
    await removeUserMutation.mutate(user.id);
    usersQuery.reload();
  }

  async function restore(user: UserModel) {
    await restoreUserMutation.mutate(user.id);
    usersQuery.reload();
  }

  function selectToEdit(user: UserModel) {
    setEditingUser(user);
    setVisiblePage('update');
  }

  function selectToChangePassword(user: UserModel) {
    setChangingPasswordUser(user);
    setIsChangePasswordModalOpen(true);
  }

  const onChangePassword = async (values: { password: string }) => {
    await changePasswordMutation.mutate({
      password: values.password,
      id: changingPasswordUser!.id
    });
    setIsChangePasswordModalOpen(false);
    setChangingPasswordUser(undefined);
  };

  return (
    <>
      <PageHeader
        title="Usuarios"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Usuarios'
            : visiblePage === 'create'
            ? 'Nuevo Usuario'
            : 'Editar Usuario'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Usuario
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <UserTable
            users={usersQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
            changePassword={selectToChangePassword}
          />
        )}

        {visiblePage === 'create' && (
          <UserCreate create={create} roles={rolesQuery.data ?? []} />
        )}
        {visiblePage === 'update' && updatingUser && (
          <UserEdit
            update={update}
            roles={rolesQuery.data ?? []}
            initialValues={userFormBuilder.fromModel(updatingUser)}
          />
        )}
      </PageContainer>
      <Dialog
        open={isChangePasswordModalOpen}
        onBackdropClick={() => setIsChangePasswordModalOpen(false)}
      >
        <UserChangePassword onSubmit={onChangePassword} />
      </Dialog>
    </>
  );
}
