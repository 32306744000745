export interface DomicilioGoogle {
  latitud: number;
  longitud: number;
  calle: string;
  numero: null | number | string;
  partido: string;
  localidad: string;
  provincia: string;
  pais?: string;
  codigoPostal?: string;
  text?: string;
}

export const mockedDomicilioGoogle = (): DomicilioGoogle => {
  return {
    latitud: 0,
    longitud: 0,
    calle: '',
    numero: '',
    partido: '',
    localidad: '',
    provincia: '',
    pais: '',
    codigoPostal: '',
    text: ''
  };
};

export const createDomicilioText = (
  domicilio: Pick<
    DomicilioGoogle,
    'calle' | 'numero' | 'localidad' | 'provincia'
  > & { departamento: string | null; piso: string | null }
) => {
  let dom = '';
  if (domicilio.calle) {
    dom = domicilio.calle;
  }
  if (domicilio.numero) {
    dom = `${dom} ${domicilio.numero}`;
  }
  if (domicilio.localidad) {
    dom = `${dom}, ${domicilio.localidad}`;
  }
  if (domicilio.piso || domicilio.departamento) {
    if (domicilio.piso && domicilio.departamento) {
      dom = `${dom} (piso: ${domicilio.piso}, depto: ${domicilio.departamento})`;
    } else if (domicilio.piso) {
      dom = `${dom} (piso: ${domicilio.piso})`;
    } else {
      dom = `${dom} (depto: ${domicilio.departamento})`;
    }
  }
  return dom;
};

export const createDomicilioGoogleAutocompleteText = (
  domicilio: Pick<
    DomicilioGoogle,
    'calle' | 'numero' | 'localidad' | 'provincia'
  >
) => {
  let dom = '';
  if (domicilio.calle) {
    dom = domicilio.calle;
  }
  if (domicilio.numero) {
    dom = `${dom} ${domicilio.numero}`;
  }
  if (domicilio.localidad) {
    dom = `${dom}, ${domicilio.localidad}`;
  }
  return dom;
};
