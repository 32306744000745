import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from 'yup';
interface Props {
  onSubmit: (values: {
    amountValue: string;
    observationValue: string;
    movementType: string;
  }) => void;
}

export const MovimientoFacturaForm = ({ onSubmit }: Props) => {
  return (
    <Formik
      initialValues={{
        amountValue: '',
        observationValue: '',
        movementType: 'credito'
      }}
      validationSchema={Yup.object({
        amountValue: Yup.string().required('La cantidad es requerida'),
        observationValue: Yup.string().required('Incluya una obsevación')
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="w-full grid gap-6 grid-cols-6 p-8">
          <Field
            name="amountValue"
            label="Cantidad"
            variant="outlined"
            className="col-span-6"
            component={TextField}
            fullWidth
          />
          <Field
            name="observationValue"
            label="Observación"
            variant="outlined"
            className="col-span-6"
            component={TextField}
            fullWidth
            multiline
            rows={2}
          />
          <div
            role="group"
            aria-labelledby="my-radio-group"
            className="w-full flex flex-row space-x-5 col-span-6"
          >
            <label className="flex flex-row space-x-2 items-center w-full">
              <Field type="radio" name="movementType" value="credito" />
              <span>Nota de crédito</span>
            </label>
            <label className="flex flex-row space-x-2 items-center w-full">
              <Field type="radio" name="movementType" value="debito" />
              <span>Nota de débito</span>
            </label>
          </div>
          <div className="flex flex-row justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Crear Movimiento
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
