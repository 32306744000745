import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';
import { ClientPrivateRateCategoryType } from '../../models/ClientPrivateRateModel';

export interface ClientPrivateRateFormType {
  outSitePrice: string | number;
  nightPlusPercentage: string | number;
  minNumberKilometers: number | string;
  categories: {
    kmServiceCanceled: number | string;
    hourPrice: number | string;
    name: ClientPrivateRateCategoryType;
    linearKilometerPrices: {
      numberKilometersFrom: number | string;
      numberKilometersTo: number | string;
      price: number | string;
    }[];
    returnKilometerPrices: {
      numberKilometersFrom: number | string;
      numberKilometersTo: number | string;
      price: number | string;
    }[];
    fixedPrices: {
      name: string;
      price: number | string;
    }[];
    priced: {
      address1: DomicilioModel;
      address2: DomicilioModel;
      kilometersRadio: string | number;
      price: string | number;
    }[];
  }[];
}

export const clientPrivateRateFormTypeBuilder = {
  toBackEnd: (rate: ClientPrivateRateFormType) => {
    return {
      out_site_price: rate.outSitePrice,
      night_plus_percentage: rate.nightPlusPercentage,
      min_kilometers: rate.minNumberKilometers,
      categories: rate.categories.map((category) => ({
        km_service_canceled: category.kmServiceCanceled,
        minute_price: (Number(category.hourPrice) / 60).toFixed(2),
        name: category.name,
        linearKilometerPrices: category.linearKilometerPrices.map((price) => ({
          number_kilometers_from: price.numberKilometersFrom,
          number_kilometers_to: price.numberKilometersTo,
          price: price.price
        })),
        returnKilometerPrices: category.returnKilometerPrices.map((price) => ({
          number_kilometers_from: price.numberKilometersFrom,
          number_kilometers_to: price.numberKilometersTo,
          price: price.price
        })),
        fixedPrices: category.fixedPrices.map((price) => ({
          name: price.name,
          price: price.price
        })),
        priced: category.priced.map((price) => ({
          address1: domicilioBuilder.toBackEnd(price.address1),
          address2: domicilioBuilder.toBackEnd(price.address2),
          kilometers_radio: price.kilometersRadio,
          price: price.price
        }))
      }))
    };
  }
};
