import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikImageUpload } from '@vadiun/react-components';

import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import {
  choferVencimientoFormSchemaInitialValues,
  ChoferVencimientosFormSchema
} from '../../models/ChoferFormSchema';
import { Moment } from 'moment';
import moment from 'moment';

const Schema = Yup.object().shape({
  fechaLicencia: Yup.mixed()
    .required('La fecha de vencimiento es requerida')
    .test({
      test: (date: Moment | undefined | null) => {
        if (date === undefined || date === null) {
          return true;
        }
        return date.isAfter(moment());
      },
      message: 'La fecha debe ser posterior la fecha actual',
      name: 'invalidDate'
    }),
  licence_front_photo: Yup.mixed().required(
    'La foto de la licencia es requerida'
  ),
  licence_back_photo: Yup.mixed().required(
    'La foto de la licencia es requerida'
  )
});

interface Props {
  onSubmit: (value: ChoferVencimientosFormSchema) => void;
  onGoBack?: () => void;
  initialValues?: ChoferVencimientosFormSchema;
  submitButtonTitle?: string;
  goBackButtonTitle?: string;
}

const ChoferFormVencimientos = ({
  goBackButtonTitle = 'Volver',
  submitButtonTitle = 'Guardar',
  onSubmit,
  onGoBack,
  initialValues = choferVencimientoFormSchemaInitialValues()
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ submitForm, touched, errors }) => (
        <Form className="grid w-full gap-6">
          <div className="flex gap-6">
            <div className="w-1/3">
              <Field
                component={FormikImageUpload}
                className="aspect-w-2 aspect-h-1"
                name="licence_front_photo"
              />
              <p className="mt-2 text-center text-xs text-gray-700">
                Frente licencia
              </p>
            </div>
            <div className="w-1/3">
              <Field
                component={FormikImageUpload}
                className="aspect-w-2 aspect-h-1"
                name="licence_back_photo"
              />
              <p className="mt-2 text-center text-xs text-gray-700">
                Dorso licencia
              </p>
            </div>
          </div>
          <Field
            component={KeyboardDatePicker}
            label="Fecha Vto. Licencia"
            name="fechaLicencia"
            format="DD/MM/YYYY"
            inputVariant="outlined"
          />
          <div className="mt-8 flex justify-between">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="red"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button type="submit" variant="contained" color="green">
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChoferFormVencimientos;
