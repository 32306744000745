export interface ApplicantModel {
  id: number;
  name: string;
  phone: string;
  email: string;
}

export interface ApplicantCrud extends Omit<ApplicantModel, 'id'> {
  id?: number;
}

export const applicantBuilder = {
  fromBackend: (applicant): ApplicantModel => ({
    ...applicant
  })
};
