import { AuthorizedModelCRUD } from '../../models/Authorized';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface AuthorizedFormType extends AuthorizedModelCRUD {}

const authorizedFormSchema: YupShape<AuthorizedFormType> = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  phone: Yup.string().nullable(),
  email: Yup.string().nullable().email('El formato es inválido')
});

const authorizedInitialValues: AuthorizedFormType = {
  name: '',
  phone: '',
  email: ''
};

const AuthorizedFormik = {
  schema: authorizedFormSchema,
  initialValues: authorizedInitialValues
};

export default AuthorizedFormik;
