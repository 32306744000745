import React from 'react';
import AuthorizedForm from './AuthorizedForm/AuthorizedForm';
import { AuthorizedFormType } from './AuthorizedForm/AuthorizedFormik';

interface Props {
  update: (authorizedValues: AuthorizedFormType, reset) => void;
  initialValues: AuthorizedFormType;
}
function AuthorizedEdit({ update, initialValues }: Props) {
  return <AuthorizedForm onSubmit={update} initialValues={initialValues} />;
}

export default AuthorizedEdit;
