import { Badge, Tooltip } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RestoreIcon from '@material-ui/icons/Restore';
import { Button, Modal } from '@vadiun/react-components';
import { useVerifyAction } from "@vadiun/react-hooks";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useSnackbar } from "notistack";
import { forwardRef, ReactNode, useImperativeHandle, useMemo, useReducer, useState } from 'react';
import { Loading, Table } from "shared/components";
import { v4 as uuidv4 } from 'uuid';
import { importBookings } from "../../../services/bulk";
import { ImperativeStepMovementType } from "../stepper/types";
import ColumnTypeAssignation from "../table/ColumnTypeAssignation";
import DatePicker from "../table/DatePicker";
import MappingAdvancedHours from "../table/MappingAdvancedHours";
import MappingCheckbox from "../table/MappingCheckbox";
import MappingDropdown from "../table/MappingDropdown";
import MappingGoogleAddress from "../table/MappingGoogleAddress";
import TextMatchingInput from "../table/TextMatchingInput";
import { getDropdownOption } from "../utils/columnMapping";
import { BEValidationType, ColumnDataType, ColumnIdType, ColumnMapping, MappingBEType, MappingType, VerificationDataType, VerificationReducerActionType } from '../utils/types';

interface TravelFormVerificationProps {
  matchingData: MappingBEType[];
  mappingColumns: ColumnMapping[];
  isMappingTable?: boolean;
  onVerificationCompleted: (okTrips: BEValidationType[], discardTrips: BEValidationType[]) => Promise<void>;
  clientMoreInfo: Record<string, { label: string, value: string }[]>
  onStepReady: (isReady: boolean) => void;
  clientId: number;
}

const TravelFormVerification = forwardRef<ImperativeStepMovementType, TravelFormVerificationProps>(({
  matchingData: md,
  mappingColumns,
  onVerificationCompleted,
  clientMoreInfo,
  onStepReady,
  clientId
}, ref) => {

  const verifyAction = useVerifyAction();
  const snackbar = useSnackbar();

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [matchingData, setMatchingData] = useState<BEValidationType[]>(JSON.parse(JSON.stringify(md)));
  const [isBEVerifying, setIsBEVerifying] = useState<boolean>(false);
  const [isTripCreationInitiated, setIsTripCreationInitiated] = useState<boolean>(false);
  const [overrideColumnInfo, setOverrideColumnInfo] = useState<{
    label: string;
    type: ColumnDataType;
    id: ColumnIdType
  }>();

  const verificationReducer = (state: VerificationDataType, action: VerificationReducerActionType) => {
    let nextState = JSON.parse(JSON.stringify(state)) as VerificationDataType;

    if (action.type === 'SELECT_TRAVEL_ROW_TYPE') {
      nextState.selectedRow = action.payload;
    }

    if (action.type === 'UN_SELECT_TRAVEL_ROW_TYPE') {
      nextState.selectedRow = undefined;
    }

    if (action.type === 'RESET') {
      nextState = {
        selectedRowTrips: []
      };
    }

    if (action.type === 'SET_SELECTED_ROWS_TRIPS_TYPE') {
      nextState.selectedRowTrips = action.payload;
    }

    return nextState;
  }

  const [verificationState, verificationDispatch] = useReducer(verificationReducer, {
    selectedRow: undefined,
    selectedRowTrips: []
  });

  const handleOpenColumnOverride = (label: string, type: ColumnDataType, id: ColumnIdType) => {
    verificationDispatch({
      type: 'UN_SELECT_TRAVEL_ROW_TYPE',
    });
    setOverrideColumnInfo({ label, type, id });
  }
  const columns = useMemo(() => {
    const mappingDataColumns = mappingColumns.map(({ id, label, mandatory, type }): MUIDataTableColumnDef => {
      return {
        name: id,
        label: label,
        options: {
          customBodyRender: ({ data, have_error }: {
            data: string;
            have_error: boolean;
          }, table) => {
            if (verificationState.selectedRow?.rowNumber === table.rowIndex) {
              let component: ReactNode;
              switch (type) {
                case ColumnDataType.DATE_FIELD:
                  component = <DatePicker
                    dateString={data}
                    hasError={have_error}
                    onChange={(dateString: string) => {
                      setMatchingData((nextMd) => {
                        nextMd[table.rowIndex][id] = {
                          data: dateString,
                          have_error: false
                        }
                        return nextMd;
                      });
                    }}
                  />
                  break;
                case ColumnDataType.BOOLEAN_FIELD:
                  component = (
                    <MappingCheckbox
                      checkStatus={data}
                      label={label}
                      hasError={have_error}
                      onChange={(booleanString: '1' | '0') => {
                        setMatchingData((nextMd) => {
                          nextMd[table.rowIndex][id] = {
                            data: booleanString,
                            have_error: false
                          }
                          return nextMd;
                        });
                      }}
                    />
                  );
                  break;
                case ColumnDataType.DROPDOWN_FIELD:
                  component = (ColumnIdType.ADVANCED_HOURS === id || ColumnIdType.HOUR === id) ?
                    (
                      <MappingAdvancedHours
                        range={ColumnIdType.HOUR === id ? 24 : 8}
                        value={data}
                        hasError={have_error}
                        onChange={(value: string) => {
                          setMatchingData((nextMd) => {
                            nextMd[table.rowIndex][id] = {
                              data: value,
                              have_error: false
                            }
                            return nextMd;
                          });
                        }}
                      />

                    ) :
                    (<MappingDropdown
                      value={data}
                      label={label}
                      hasError={have_error}
                      options={getDropdownOption(id, clientMoreInfo)}
                      onChange={(value: string) => {
                        setMatchingData((nextMd) => {
                          nextMd[table.rowIndex][id] = {
                            data: value,
                            have_error: false
                          }
                          return nextMd;
                        });
                      }}
                    />
                    );
                  break;
                case ColumnDataType.ADDRESS_FIELD:
                  component = (
                    <MappingGoogleAddress
                      value={data}
                      isFloating
                      hasError={have_error}
                      onChange={(value: string) => {
                        setMatchingData((nextMd) => {
                          nextMd[table.rowIndex][id] = {
                            data: value,
                            have_error: false
                          }
                          return nextMd;
                        });
                      }}
                    />
                  );
                  break;
                default:
                  component =
                    <TextMatchingInput
                      data={data}
                      label={label}
                      hasError={have_error}
                      onChange={(e) => {
                        setMatchingData((nextMd) => {
                          nextMd[table.rowIndex][id] = {
                            data: e.target.value,
                            have_error: false
                          }
                          return nextMd;
                        });
                      }}
                    />
              }
              return component;
            }

            return <div className={have_error ? 'hasError' : ''}>{data}</div>;
          },
          customHeadRender: () => {
            return (
              <th style={{ paddingRight: '20px', textAlign: 'left', minWidth: '200px' }} key={uuidv4()}>
                <Button
                  variant="outlined"
                  color="gray"
                  className="w-full"
                  onClick={() => handleOpenColumnOverride(label, type, id)}
                >
                  <span className="text-xs">
                    {label}{mandatory && <span className="text-red-500">{' *'}</span>}
                  </span>
                </Button>
              </th>
            );
          }
        }
      }
    });
    return mappingDataColumns;
  }, [clientMoreInfo, mappingColumns, verificationState.selectedRow?.rowNumber]);

  const handleResetMapping = () => {
    setMatchingData(JSON.parse(JSON.stringify(md)));

    verificationDispatch({
      type: 'RESET',
    });
  }

  const handleVerifyBulkTripsSubmition = () => {

    const { discardTrips, okTrips } = matchingData.reduce((acc, data, index) => {
      if (verificationState.selectedRowTrips.includes(index)) {
        Object.values(data).some(({ have_error }) => have_error) ? acc.discardTrips.push(data) : acc.okTrips.push(data);
      } else {
        acc.discardTrips.push(data);
      }
      return acc;
    }, { okTrips: [], discardTrips: [] } as { okTrips: BEValidationType[], discardTrips: BEValidationType[] });

    if (!okTrips.length) {
      return snackbar.enqueueSnackbar('No existen viajes correctos', {
        variant: 'error'
      });
    }

    verifyAction({
      onAccept: async () => {
        try {
          setIsBEVerifying(true);
          setIsTripCreationInitiated(true);
          await onVerificationCompleted(okTrips, discardTrips);
        } finally {
          setIsBEVerifying(false);
          setIsTripCreationInitiated(false)
        }
      },
      title: (
        <div className="py-4">
          <span className="text-lg text-black">Confirmación de creación de viajes</span>
        </div>
      ),
      body: (
        <div className="flex flex-col gap-y-4">
          <h2>De los <span className="font-bold text-black">{matchingData.length} viajes</span> contenidos en el archivo:</h2>
          <ul className="list-disc pl-8">
            <li>Viajes no cargados: <span className="text-red-500">{discardTrips.length}</span></li>
          </ul>
          <div>
            <span>Al confirmar se crearán <span className="text-black font-bold">{okTrips.length} viajes</span></span>
          </div>
        </div>
      )
    });
  }

  useImperativeHandle(ref, () => ({
    onClick: () => {
      handleVerifyBulkTripsSubmition();
    }
  }));

  const handleRowSelection = async (dataIndex: number) => {
    if (verificationState.selectedRow?.rowNumber !== dataIndex) {
      onStepReady(false);
      if (verificationState.selectedRow) {
        const prevHasError = Object.values(verificationState.selectedRow.data).some(({ have_error }) => have_error);
        const hasFixedErrors = Object.values(matchingData[verificationState.selectedRow.rowNumber]).every(({ have_error }) => !have_error);
        if (prevHasError && hasFixedErrors) {

          const rowIndex = verificationState.selectedRow.rowNumber;
          const rowData = matchingData[rowIndex];

          const dataToTest = Object.keys(rowData).reduce((acc, key) => ({
            ...acc,
            [key]: rowData[key].data
          }), {} as MappingType)


          try {
            setIsBEVerifying(true);
            const { data: beData } = await importBookings(clientId, [dataToTest]);
            setMatchingData((smd) => {
              return smd.fill(beData[0], rowIndex, rowIndex + 1)
            });
          } finally {
            setIsBEVerifying(false);
          }
        }
        onStepReady(!!verificationState.selectedRowTrips.length);
      }
      if (dataIndex !== -1) {
        verificationDispatch({
          type: 'SELECT_TRAVEL_ROW_TYPE',
          payload: {
            rowNumber: dataIndex,
            data: JSON.parse(JSON.stringify(matchingData[dataIndex]))
          }
        });
      }
    }
  }

  const handleRemoveRows = () => {
    verifyAction({
      onAccept: () => {
        setMatchingData(matchingData.filter((_, index) => {
          return !verificationState.selectedRowTrips.includes(index);
        }));
        onStepReady(false);

        verificationDispatch({
          type: 'SET_SELECTED_ROWS_TRIPS_TYPE',
          payload: []
        })
      },
      title: (
        <div className="py-4">
          <span className="text-lg text-black">Desea eliminar los viajes seleccionados</span>
        </div>
      ),
    });
  }


  return (
    <div className="relative">
      <Table
        title="Verificación de datos"
        options={{
          elevation: 0,
          selectableRows: 'multiple',
          selectToolbarPlacement: 'none',
          onRowClick: (_, { dataIndex }) => handleRowSelection(dataIndex),
          onRowSelectionChange: (_, all, rowSelected?: number[]) => {
            if (typeof verificationState.selectedRow?.rowNumber !== 'undefined') {
              handleRowSelection(-1);
            }
            verificationDispatch({
              type: 'UN_SELECT_TRAVEL_ROW_TYPE',
            });
            onStepReady(!!rowSelected?.length);

            verificationDispatch({
              type: 'SET_SELECTED_ROWS_TRIPS_TYPE',
              payload: rowSelected || []
            });
          },
          search: false,
          filter: false,
          sort: false,
          download: false,
          print: false,
          fixedSelectColumn: true,
          pagination: true,
          rowsPerPage,
          rowsSelected: verificationState.selectedRowTrips,
          rowsPerPageOptions: matchingData.length > 30 ? [10, 30, matchingData.length] : [10, 30],
          onChangeRowsPerPage: (currentNumberOfRows) => {
            setRowsPerPage(currentNumberOfRows)
          },
          customToolbar: () => {
            return (
              <div className="flex gap-x-2 custom-toolbar">
                {verificationState.selectedRowTrips.length > 0 &&
                  <Button
                    onClick={handleRemoveRows}
                    shape="circle"
                    color="gray"
                    className="hover:bg-primary-400"
                  >
                    <Badge
                      badgeContent={verificationState.selectedRowTrips.length}
                      color="error"
                      variant="dot"
                      max={9}
                    >
                      <Tooltip title="Eliminar viajes">
                        <DeleteOutlineIcon fontSize="medium" />
                      </Tooltip>
                    </Badge>
                  </Button>

                }
                <Button
                  onClick={handleResetMapping}
                  shape="circle"
                  color="gray"
                  className="hover:bg-primary-400"
                >
                  <Tooltip title="Descartar todo">
                    <RestoreIcon fontSize="medium" />
                  </Tooltip>
                </Button>
              </div>
            )
          }
        }}
        columns={columns}
        data={matchingData}
      />
      {overrideColumnInfo && (
        <Modal
          open
          size="sm"
          title={<span>Asignación de datos de <span className="text-gray-500">{overrideColumnInfo.label}</span></span>}
          onClose={() => setOverrideColumnInfo(undefined)}
          body={(
            <div className="p-4 flex flex-wrap gap-x-4 gap-y-6">
              <ColumnTypeAssignation
                onClose={() => setOverrideColumnInfo(undefined)}
                onAssignValue={(nextColValue: string) => {
                  setMatchingData((tableData) => {
                    return tableData.map((tableRow) => ({
                      ...tableRow,
                      [overrideColumnInfo.id]: {
                        data: nextColValue,
                        have_error: false
                      }
                    }))
                  })
                  setOverrideColumnInfo(undefined);
                }}
                clientMoreInfo={clientMoreInfo}
                label={overrideColumnInfo.label}
                type={overrideColumnInfo.type}
                columnId={overrideColumnInfo.id}
              />
            </div>
          )}
        />
      )}
      {isBEVerifying &&
        <div className="fixed w-full h-full flex top-0 left-0 justify-center items-center" style={{
          backgroundColor: '#cbc3c3a1',
          zIndex: 1000,
        }}>
          <div className="bg-white rounded px-4">
            <Loading label={isTripCreationInitiated ? (
              <div className="mr-4">
                <p className="text-black">Creando Viajes</p>
                <p className="text-sm">El proceso puede demorar unos instantes</p>
              </div>
            ) : "Verificando viaje"} />
          </div>
        </div>}
    </div>
  )
});

export default TravelFormVerification;