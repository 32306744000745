import { Button } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { Card } from 'shared/components';
import { ExcelFileType } from '../components/bulk/file/useFile';
import Stepper, { CompletionStatus } from '../components/bulk/stepper/Stepper';
import { ImperativeStepMovementType } from '../components/bulk/stepper/types';
import TravelComplete from '../components/bulk/steps/TravelComplete';
import TravelFormMapping from '../components/bulk/steps/TravelFormMapping';
import TravelFormMatching from '../components/bulk/steps/TravelFormVerification';
import TravelInitialSelection from '../components/bulk/steps/TravelInitialSelection';
import { fixMissingRows } from '../components/bulk/transformation/transformExcelRows';
import { getColumnMapping, idColumn } from '../components/bulk/utils/columnMapping';
import { BEMappingType, BEValidationType, BulkClient, ClientRequirements, ColumnMapping, MappingBEType, MappingType, TripCompletionType } from '../components/bulk/utils/types';
import { createBulkTrips, importBookings } from '../services/bulk';

export const TransferReservationClientBulkTripCreation = () => {

  const verifyAction = useVerifyAction();
  const snackbar = useSnackbar();

  const [bulkStep, setBulkStep] = useState<CompletionStatus>(CompletionStatus.FILE);
  const [isNextStepLoading, setIsNextStepLoading] = useState<boolean>(false);
  const [isNextStepReady, setIsNextStepReady] = useState<boolean>(false);

  // step-1 states
  const [excelBodyData, setExcelBodyData] = useState<{
    maxColSize: number,
    mappedColumns: Record<string, string>[]
  }>();
  const [selectedClient, setSelectedClient] = useState<BulkClient>();
  const [currentExcelFile, setCurrentExcelFile] = useState<ExcelFileType>();
  const [columnMapping, setColumnMapping] = useState<ColumnMapping[]>();

  // step-2 states
  const [currentColumnMapping, setCurrentColumnMapping] = useState<ColumnMapping[]>();
  const [currentMappingData, setCurrentMappingData] = useState<Record<string, string>[]>();
  const [checkedMappingData, setCheckedMappingData] = useState<MappingBEType[]>();

  // step-4 states

  const [createdTrips, setCreatedTrips] = useState<TripCompletionType[]>();
  const [discardedTrips, setDiscardedTrips] = useState<TripCompletionType[]>();

  /// confirmation

  const handleDiscardStepData = (status: CompletionStatus, onDiscard: () => void) => {
    if (status === CompletionStatus.FILE) {
      setCurrentColumnMapping(undefined);
      setCurrentMappingData(undefined);
      setCheckedMappingData(undefined);
      setCurrentExcelFile(undefined);
    }

    if (status === CompletionStatus.MAPPING) {
      setCurrentColumnMapping(undefined);
      setCurrentMappingData(undefined);
      setCheckedMappingData(undefined);
    }
    onDiscard();
  }


  const handleFileRemoved = (onPerformRemove: () => void) => {
    if (currentColumnMapping) {
      return verifyAction({
        onAccept: () => handleDiscardStepData(CompletionStatus.FILE, onPerformRemove),
        body: '¿Desea descartar los mapeos actuales?'
      })
    }
    onPerformRemove();
  }

  const handleClientChange = (onClientChange: () => void) => {
    if (currentColumnMapping) {
      return verifyAction({
        onAccept: () => handleDiscardStepData(CompletionStatus.FILE, onClientChange),
        body: '¿Desea descartar los mapeos actuales?'
      })
    }
    onClientChange();
  }

  const handleMappingReset = (onPerformResetMapping: () => void) => {

    if (checkedMappingData) {
      return verifyAction({
        onAccept: () => handleDiscardStepData(CompletionStatus.MAPPING, onPerformResetMapping),
        body: '¿Desea descartar las equivalencias actuales?'
      })
    }
    onPerformResetMapping();

  }

  const handleRetrieveSelectionData = (
    fileData: {
      parsedFile: {
        maxColSize: number,
        mappedColumns: Record<string, string>[]
      }
      rawFile: ExcelFileType
    },
    client: BulkClient) => {
    const nextColumnMapping = getColumnMapping(client.reservationRequirements);
    setColumnMapping(nextColumnMapping);
    setExcelBodyData(fixMissingRows(fileData.parsedFile, nextColumnMapping.length));
    setSelectedClient(client);
    setCurrentExcelFile(fileData.rawFile);
    setBulkStep(CompletionStatus.MAPPING);
  }

  const handleStepReady = (isReady: boolean) => {
    setIsNextStepReady(isReady)
  }

  const stepperRef = useRef<ImperativeStepMovementType>(null);

  const handleStepForward = async () => {
    setIsNextStepLoading(true);
    await stepperRef.current?.onClick();
    setIsNextStepLoading(false);
  }

  const handleGoBack = () => {
    if (bulkStep === CompletionStatus.MAPPING) {
      return setBulkStep(CompletionStatus.FILE);
    }
    if (bulkStep === CompletionStatus.CHECKING) {
      setIsNextStepReady(true);
      return setBulkStep(CompletionStatus.MAPPING);
    }
  }

  const handleMappingCompleted = async (
    data: MappingType[],
    selectedColumnMapping: ColumnMapping[],
    currentMappingData: Record<string, string>[],
  ) => {
    setCurrentColumnMapping(selectedColumnMapping);
    setCurrentMappingData(currentMappingData);
    try {
      const { data: beData } = await importBookings(selectedClient?.info.id!, data, selectedClient?.requirements, selectedClient?.event);
      setCheckedMappingData(beData);
      setIsNextStepReady(false);
      setBulkStep(CompletionStatus.CHECKING);
    } catch {
      snackbar.enqueueSnackbar('Error al mapear los datos', {
        variant: 'error'
      });
    }
  }

  const handlePerformVerification = async (okTrips: BEValidationType[], discardTrips: BEValidationType[]) => {
    if (selectedClient) {
      const objectToBe = okTrips.map((okTrip) => {
        return Object.keys(okTrip).reduce((acc, key) => ({
          ...acc,
          [key]: okTrip[key].data
        }), {} as BEMappingType)
      });
      try {
        const { created: tripResult, errors: errorTripResult } = await createBulkTrips(selectedClient.info.id!, objectToBe, selectedClient.requirements!, selectedClient.event!);
        const discardMappedTrips = discardTrips.map((discardTrip) => {
          return Object.keys(discardTrip).reduce((acc, key) => ({
            ...acc,
            [key]: discardTrip[key].data
          }), {} as TripCompletionType)
        });
        if (tripResult.length === 0) {
          snackbar.enqueueSnackbar('No se crearon los viajes', {
            variant: 'error'
          });
          return;
        }

        if (tripResult.length !== objectToBe.length) {
          snackbar.enqueueSnackbar(`Hubo un error al crear ${errorTripResult.length} viaje/s`, {
            variant: 'error',
            persist: true,
            onClose: () => snackbar.closeSnackbar()
          });
          setDiscardedTrips([...discardMappedTrips, ...errorTripResult.reduce((acc, { booking }) => {
            return [...acc, booking]
          }, [] as TripCompletionType[])]);
        } else {
          setDiscardedTrips(discardMappedTrips);
        }

        setCreatedTrips(tripResult);
        setBulkStep(CompletionStatus.COMPLETE);
        return;
      } catch (e) {
        console.log(e)
        snackbar.enqueueSnackbar('Error al crear los viajes', {
          variant: 'error'
        });

      }
    }
  }

  return (
    <>
      <PageHeader title="Viajes" subtitle={'Cargar nuevos traslados'} />
      <PageContainer>
        <Card className="w-full" title="Nuevos traslados">
          <div className="p-8">
            <Stepper stepsCompletion={bulkStep} forceCompletion={bulkStep === CompletionStatus.COMPLETE} />
            {bulkStep === CompletionStatus.FILE && (
              <TravelInitialSelection
                ref={stepperRef}
                currentClient={selectedClient}
                onStepReady={handleStepReady}
                onRetrieveSelectionData={handleRetrieveSelectionData}
                currentExcelFile={currentExcelFile}
                onCurrentFileRemoved={handleFileRemoved}
                onClientChange={handleClientChange}
              />
            )}
            {bulkStep === CompletionStatus.MAPPING && excelBodyData && columnMapping && (
              <div className="mapping-table">
                <TravelFormMapping
                  mappingData={excelBodyData.mappedColumns}
                  currentColSize={excelBodyData.maxColSize}
                  ref={stepperRef}
                  currentColumnMapping={currentColumnMapping}
                  currentMappingData={currentMappingData}
                  onMappingComplete={handleMappingCompleted}
                  onResetMapping={handleMappingReset}
                  columnMapping={columnMapping}
                />
              </div>
            )}
            {bulkStep === CompletionStatus.CHECKING && checkedMappingData && currentColumnMapping && (
              <div className="mapping-table">
                <TravelFormMatching
                  matchingData={checkedMappingData}
                  mappingColumns={currentColumnMapping}
                  ref={stepperRef}
                  clientId={selectedClient?.info.id!}
                  clientMoreInfo={{
                    ...(selectedClient?.authorizedPersonal && {
                      [ClientRequirements.AUTHORIZED_ID]: selectedClient.authorizedPersonal.map((name) => ({ value: name, label: name }))
                    }),
                    ...(selectedClient?.subCostElements && {
                      [ClientRequirements.SUB_COST_CENTER]: selectedClient.subCostElements.map((name) => ({ value: name, label: name }))
                    })
                  }
                  }
                  onStepReady={handleStepReady}
                  onVerificationCompleted={handlePerformVerification}
                />
              </div>
            )}
            {bulkStep === CompletionStatus.COMPLETE && currentColumnMapping && createdTrips && (
              <TravelComplete
                mappingColumns={[idColumn, ...currentColumnMapping]}
                createdTrips={createdTrips}
                discardedTrips={discardedTrips || []}
                client={selectedClient?.info!}
              />
            )}
          </div>
          {bulkStep !== CompletionStatus.COMPLETE && (
            <div className="flex w-full justify-end px-8 pt-0 pb-6 gap-x-4">
              {bulkStep !== CompletionStatus.FILE &&
                <Button
                  disabled={isNextStepLoading}
                  variant="light"
                  color="gray"
                  onClick={handleGoBack}
                >
                  Anterior
                </Button>
              }

              <Button
                isLoading={isNextStepLoading}
                disabled={!isNextStepReady}
                variant="contained"
                color="primary"
                onClick={handleStepForward}
              >
                Siguiente
              </Button>
            </div>
          )}
        </Card>
      </PageContainer>
    </>
  );
};
