import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import { ClientPrivateForm } from 'app/client/private/private/forms/ClientPrivateForm';
import useClientPrivates from 'app/client/private/private/services/ClientPrivateService';
import {
  TravelEstimationsWithPrice,
  useDistanceAndPriceTransferReservationClientPrivateCalculator
} from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import ReservaTrasladoEstimationDialog from 'app/viaje/viajeTraslado/viajeTraslado/components/ReservaTrasladoEstimationDialog';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { Card } from 'shared/components';
import { ReservaTrasladoPrivateBatchForm } from '../forms/batch/TransferReservationPrivateBatchForm';
import { TransferReservationPrivateBatchFormType } from '../forms/batch/TransferReservationPrivateBatchFormType';
import { useTransferReservationPrivateRepository } from '../services/repositories/transferReservationPrivateRepository';
import { CreatedReservationsModal } from '../../viajeTraslado/components/CreatedReservationsModal';

export const TransferReservationPrivateBatchPage = () => {
  const [createdReservations, setCreatedReservations] = useState<number[] | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const clientPrivateRepository = useClientPrivates();
  const transferReservationPrivateRepo =
    useTransferReservationPrivateRepository();
  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientPrivateCalculator();

  const addTrasladoMutation = useSuperMutation(
    transferReservationPrivateRepo.createBatch,
    { 
      showSpinner: true,
      onMutationFinish:(response: any) => { 
        setCreatedReservations(response.data.map(el => el.id))
      }
    }
  );
  const addClientPrivateMutation = useSuperMutation(
    clientPrivateRepository.addClientPrivate
  );
  const [isEstimationOpen, setIsEstimationOpen] = useState(false);
  const [isCreatePrivateClientOpen, setIsCreatePrivateClientOpen] =
    useState(false);
  const [distanceAndCost, setDistanceAndCost] = useState<
    TravelEstimationsWithPrice[]
  >([]);

  async function createViajeTraslado(
    trasladoFormValues: TransferReservationPrivateBatchFormType
  ) {
    await addTrasladoMutation.mutate(trasladoFormValues)
    .then(() => setModalOpen(true));
    /* history.push('/main/Viaje/traslado/pendiente'); */
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationPrivateBatchFormType
  ) {
    const estimationsPromise = trasladoFormValues.viajes.map((viaje) =>
      calculateDistanceAndCost(
        {
          arrivalDate: viaje.fechaYHoraDeLlegada,
          hasOutSite: trasladoFormValues.hasOutSite,
          points: viaje.puntos.map((p) => ({
            lat: p.domicilio.latitud,
            lng: p.domicilio.longitud,
            minutesWaiting: Number(p.espera)
          })),
          serviceType: trasladoFormValues.tipoServicio
        },
        trasladoFormValues.cliente!.id
      )
    );
    setDistanceAndCost(await Promise.all(estimationsPromise));
    setIsEstimationOpen(true);
  }

  return (
    <>
      <PageHeader title="Viaje particular" subtitle={'Nuevo traslado'} />
      <PageContainer>
        <Card className="w-full" title="Nuevo traslado particular">
          <div className="p-8">
            <ReservaTrasladoPrivateBatchForm
              onOpenCreatePrivateClient={() =>
                setIsCreatePrivateClientOpen(true)
              }
              getClientesByName={clientPrivateRepository.findByName}
              onCalculateDistanceAndCost={estimatePrice}
              onSubmit={createViajeTraslado}
            />
          </div>
          <CreatedReservationsModal 
            open={modalOpen}
            setOpen={setModalOpen}
            reservations={createdReservations}
          />
        </Card>
      </PageContainer>
      <ReservaTrasladoEstimationDialog
        isOpen={isEstimationOpen}
        handleClose={() => setIsEstimationOpen(false)}
        distanceAndCost={distanceAndCost}
      />
      <Dialog
        open={isCreatePrivateClientOpen}
        onBackdropClick={() => setIsCreatePrivateClientOpen(false)}
      >
        <DialogTitle>Nuevo Cliente Particular</DialogTitle>
        <DialogContent className="mb-4">
          <ClientPrivateForm
            onSubmit={async (values) => {
              await addClientPrivateMutation.mutate(values);
              setIsCreatePrivateClientOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
