import {
  choferPartialBuilder,
  ChoferPartialModel
} from 'app/chofer/models/Chofer';
import {
  passengerBuilder,
  PassengerModel
} from 'app/client/clientBusiness/Passenger/models/Passenger';
import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';
import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoEstado,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import { TransferReservationPrices } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPrices';
import {
  TransferReservationState,
  transferReservationStateBuilder
} from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationState';
import moment from 'moment';
import { Moment } from 'moment';

export interface TransferReservationPrivateModel {
  id: number;
  client: {
    id: number;
    name: string;
    hasPriority: boolean;
  };
  hasPriority: false;
  entryChannel: ViajeCanalesDeIngreso;
  serviceType: ViajeTrasladoTipoServicio;
  coordinationObservation: string;
  reservationDatetime: Moment;
  departureDatetime: Moment;
  advanceHours: number;
  arrivalDateTime: Moment;
  state: ViajeTrasladoEstado;
  clientType: string;
  contretemps: string;
  deviation_observation: string | null;
  locations: [
    {
      observation: string;
      minutesWaiting: number;
      address: DomicilioModel;
      passengers: PassengerModel[];
    }
  ];
  state_datetime: Moment;
  estimated_prices: TransferReservationPrices | null;
  calculated_prices: TransferReservationPrices | null;
  final_prices: TransferReservationPrices;
  pre_assigned_driver: ChoferPartialModel | null;
  assigned_driver: ChoferPartialModel | null;
  has_out_site: boolean;
  setback: string | null;
  states: TransferReservationState[];
  pay_with_cash: boolean;
  is_event: boolean;
  event_name?: string;
  lltpais?: any;
}

export const transferReservationPrivateBuilder = {
  fromBackEnd: (reservation: any): TransferReservationPrivateModel => ({
    ...reservation,
    reservationDatetime: moment(reservation.reservationDatetime),
    arrivalDateTime: moment(reservation.arrivalDateTime),
    departureDatetime: moment(reservation.departureDateTime),
    contretemps: reservation.setback,
    locations: reservation.locations.map((location) => ({
      ...location,
      address: domicilioBuilder.fromBackEnd(location.address),
      passengers: location.passengers.map(passengerBuilder.fromBackEnd)
    })),
    state_datetime: moment(reservation.state_datetime),
    pre_assigned_driver: reservation.pre_assigned_driver
      ? choferPartialBuilder.fromBackEnd(reservation.pre_assigned_driver)
      : null,
    assigned_driver: reservation.assigned_driver
      ? choferPartialBuilder.fromBackEnd(reservation.assigned_driver)
      : null,
    has_out_site: reservation.has_out_site,
    setback: reservation.setback,
    states: reservation.states.map((s) =>
      transferReservationStateBuilder.fromBackEnd(s)
    ),
    is_event: reservation.is_event,
    event_name: reservation.event_name
  })
};
