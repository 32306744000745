import { ClientBusinessModel } from '../../../../../../client/clientBusiness/models/ClientBusiness';
import { clientRequirement } from '../utils/clientRequirement';
import { ClientRequirementType } from '../utils/types';

export const transformReservationRequirements = (
  data: Omit<ClientBusinessModel['reservationRequirement'], 'id'>
) => {
  return clientRequirement.reduce((acc, value) => {
    if (data[value.mappingKey]) {
      return [...acc, value];
    }
    return acc;
  }, [] as ClientRequirementType[]);
};
