import {
  FormControlLabel,
  Radio,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Button, FormRepeater } from '@vadiun/react-components';
import { BillerModel } from 'app/biller/models/BillerModel';
import { BusinessUnitType } from 'app/business/services/useBusinessUnit';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, RadioGroup, TextField } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';
import {
  VehiculoModel,
  VehiculoPartialModel
} from '../../../vehiculos/models/Vehiculo';
import { categoriasChofer } from '../../models/ChoferCategoria';
import {
  ChoferGeneralFormSchema,
  choferGeneralFormSchemaInitialValues
} from '../../models/ChoferFormSchema';


const Schema: YupShape<ChoferGeneralFormSchema> = Yup.object().shape({
  codigo: Yup.string().required('El codigo es requerido'),
  nombreCompleto: Yup.string().required('El nombre es requerido'),
  vehiculo: Yup.mixed().required('El vehiculo es requerido'),
  proveedores: Yup.array(
    Yup.mixed().required('Es necesario que elija una opcion')
  ).min(1, 'Es necesario como minimo un facturador'),
  categoria: Yup.string()
    .required('La categoria es requerida')
    .oneOf(categoriasChofer()),
  tienePosnet: Yup.bool(),
  tieneCursoDeDefensa: Yup.bool(),
  comision: Yup.number()
    .max(100, 'La comision maxima es 100%')
    .min(0, 'La comision minima es 100')
    .required('La comision es obligatoria'),
  fechaIngreso: Yup.mixed().required('La fecha de ingreso es requerida'),
  businessUnit: Yup.mixed().required('La Unidad de Negocio es requerida')
});

interface Props {
  onSubmit: (value: ChoferGeneralFormSchema, reset) => void;
  initialValues?: ChoferGeneralFormSchema;
  vehiculos: VehiculoPartialModel[];
  proveedores: BillerModel[];
  goBackButtonTitle?: string;
  submitButtonTitle?: string;
  onGoBack?: () => void;
  businessUnit: BusinessUnitType[];
}

const ChoferFormGeneral = ({
  onSubmit,
  onGoBack,
  goBackButtonTitle = 'Volver',
  submitButtonTitle = 'Guardar',
  vehiculos,
  proveedores,
  initialValues = choferGeneralFormSchemaInitialValues(vehiculos, proveedores),
  businessUnit
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ submitForm, touched, errors, values, submitCount }) => (
        <Form className="w-full grid grid-cols-2 gap-6">
          <Field
            name="codigo"
            label="Código"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            name="nombreCompleto"
            label="Nombre"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            name="vehiculo"
            component={Autocomplete}
            className="col-span-2"
            fullWidth
            options={vehiculos}
            getOptionLabel={(vehiculo: VehiculoModel) =>
              vehiculo.modelo.nombre +
              ' - ' +
              vehiculo.modelo.fabricante.nombre +
              ' - ' +
              vehiculo.patente
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMaterial
                {...params}
                error={touched['vehiculo'] && !!errors['vehiculo']}
                helperText={errors['vehiculo']}
                label="Vehículo"
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Field
            name="businessUnit"
            component={Autocomplete}
            className="col-span-2"
            fullWidth
            options={businessUnit}
            getOptionLabel={(businessUnit: BusinessUnitType) =>
              `${businessUnit.name} - (${businessUnit.description})`
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMaterial
                {...params}
                error={(touched['businessUnit'] || !!submitCount) && !!errors['businessUnit']}
                helperText={(touched['businessUnit'] || !!submitCount) && errors['businessUnit']}
                label="Unidad de negocio"
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Field
            name="comision"
            label="Comisión"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            component={KeyboardDatePicker}
            label="Fecha ingreso"
            name="fechaIngreso"
            format="DD/MM/YYYY"
            inputVariant="outlined"
          />
          <div className="col-span-2">
            <p className="text-gray-700">Categoría</p>
            <Field component={RadioGroup} name="categoria">
              <div className="flex">
                <FormControlLabel
                  value="Bronce"
                  control={<Radio color="primary" />}
                  label="Bronce"
                />
                <FormControlLabel
                  value="Plata"
                  control={<Radio color="primary" />}
                  label="Plata"
                />
                <FormControlLabel
                  value="Oro"
                  control={<Radio color="primary" />}
                  label="Oro"
                />
              </div>
            </Field>
          </div>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="tienePosnet"
            color="primary"
            className="col-span-1"
            Label={{ label: 'Posnet' }}
          />
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="tieneCursoDeDefensa"
            color="primary"
            className="col-span-1"
            Label={{ label: 'Curso defensa' }}
          />
          <div className="col-span-2">
            <p className="text-gray-700 mb-4">Facturador</p>
            <FieldArray
              name={'proveedores'}
              render={(arrayHelpers) => (
                <FormRepeater
                  arrayHelpers={arrayHelpers}
                  addLabel="+ Añadir"
                  createField={() => null}
                  className="col-span-2"
                >
                  {values.proveedores.map((value, index) => (
                    <Field
                      key={index}
                      name={`proveedores.${index}`}
                      component={Autocomplete}
                      className="w-full"
                      fullWidth
                      options={proveedores}
                      getOptionLabel={(proveedor: BillerModel) =>
                        proveedor.name + ' - ' + proveedor.cuit
                      }
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={
                            touched['proveedores'] && !!errors['proveedores']
                          }
                          helperText={errors['proveedores']}
                          label="Proveedor"
                          variant="outlined"
                        />
                      )}
                    />
                  ))}
                </FormRepeater>
              )}
            />
            <p className="text-red-500">
              {Array.isArray(errors.proveedores) ? '' : errors.proveedores}
            </p>
          </div>
          <div className="flex justify-end col-span-2">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="red"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button type="submit" variant="contained" color="green">
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChoferFormGeneral;
