import React from 'react';
import AuthorizedForm from './AuthorizedForm/AuthorizedForm';
import { AuthorizedFormType } from './AuthorizedForm/AuthorizedFormik';

interface Props {
  create: (authorizedValues: AuthorizedFormType, reset) => void;
}
function AuthorizedCreate({ create }: Props) {
  return <AuthorizedForm onSubmit={create} />;
}

export default AuthorizedCreate;
