import React from 'react';
import { Table } from 'shared/components';
import {
  VehiculoModeloModel,
  VehiculoPartialModel
} from 'app/vehiculos/models';

interface Props {
  vehiculos: VehiculoPartialModel[];
  onVehicleModifyButton: (id: number) => React.ReactNode;
}

const VehiculoTable = (props: Props) => {
  const columns = [
    {
      name: 'foto',
      label: 'Foto',
      options: {
        filter: false,
        customBodyRender: (fotoAuto) => (
          <img
            src={fotoAuto}
            className="h-28 rounded-lg w-40 object-cover object-center"
          />
        )
      }
    },
    {
      name: 'patente',
      label: 'Patente',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'categoria',
      label: 'Categoria',
      options: {
        filter: false
      }
    },
    {
      name: 'modelo',
      label: 'Modelo',
      options: {
        filter: false,
        customBodyRender: (modelo: VehiculoModeloModel) => (
          <div style={{ display: 'flex' }}>
            {modelo.nombre} - {modelo.fabricante.nombre}
          </div>
        )
      }
    },
    {
      name: 'isDeleted',
      label: 'Borrado',
      options: {
        display: false,
        filterOptions: {
          names: ['Si', 'No']
        },
        filterList: ['No'],
        customFilterListOptions: { render: (v) => `Borrado: ${v}` }
      }
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRender: (id: number) => (
          <div style={{ display: 'flex' }}>
            {props.onVehicleModifyButton(id)}
          </div>
        )
      }
    }
  ];

  const data = props.vehiculos.map((x) => ({
    ...x,
    isDeleted: x.isDeleted ? 'Si' : 'No'
  }));

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Vehiculos'}
        data={data}
        columns={columns}
      />
    </div>
  );
};
export default VehiculoTable;
