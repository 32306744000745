import { useState } from 'react';
import { mapBaseFilters } from '../../viajeTraslado/services/ViajeTrasladoService';
import environment from 'environment/environment';

export const useExport = () => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const getExportData = async (filters?: any): Promise<any> => {
    let filtersString = '';
    if (filters) {
      const mappedFilters = {
        ...mapBaseFilters(filters)
      };
      filtersString = Object.entries(mappedFilters)
        .filter(([_, value]) => !!value)
        // Remove field "event_name" to export travels from api
        .filter(([key]) => key !== 'event_name')
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }

    try {
      setLoading(true);
      const responseApi = await fetch(
        `${environment.export}/transfer-reservations/export?${filtersString}`,
        {
          method: 'POST',
          body: JSON.stringify({
            columns: [
              'id',
              'state',
              'client_name',
              'payment_type',
              'date',
              'hour',
              'first_address',
              'last_address',
              'kilometers',
              'km_price',
              'waiting_minutes',
              'waiting_minutes_price',
              'transfer_price',
              'expenses_price',
              'applicant_name',
              'passengers_names',
              'driver_name',
              'deviation_observation',
              'coordination_observation',
              'service_type',
              'entry_channel',
              'platform_application',
              'observation_billed',
              'unit_km_price',
              'accepted_datetime',
              'fecha_creacion',
              'demora_notificada',
              'is_event',
              'is_llt_pais',
              'provider_price',
              'customer_price',
              'created_by',
              'cancelled_by',
              'cancellation_date',
              'cancellation_reason',
              'last_changed_by',
              'last_change_date',
              'last_change_reason',
              'business_unit_name',
              'accepted_datetime',
              'acceptance_time',
              'announcement_date',
              'announcement_time',
              'trip_start_time',
              'assigned_by_operator',
              'time_creation',
              'trip_start_date',
              'audit_reason',
              'vehicle_id',
              'vehicle_patent',
              'vehicle_year',
              'vehicle_model_name',
              'chassis_number',
              'brand_name',
              'requirement_id',
              'cost_center',
              'event_name',
              'sub_cost_center',
              'sector',
              'buy_order',
              'employee_number',
              'trip_number',
              'data_1',
              'data_2',
              'data_3',
              'authorized_id',
              'authorized_name',
              'authorized_phone',
              'authorized_email',
              'authorized_clientable_id',
              'authorized_clientable_type',
              'applicant_id',
              'applicant_phone',
              'applicant_email',
              'applicant_clientable_id',
              'applicant_clientable_type',
              'observation_lltpais',
              'observation_last_change_audit',
              'is_utilitarian_messaging'
            ]
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            accept: 'application/octet-stream'
          }
        }
      );

      const result = await responseApi.json();
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getExportData,
    isLoading
  };
};
