import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';

export interface TitularModel {
  id: number;
  nombre: string;
  telefono: number;
  domicilio: DomicilioModel;
  email: string;
  isDeleted: boolean;
}

export type TitularModelCRUD = Omit<TitularModel, 'isDeleted' | 'id'> & {
  id?: number;
};

export const titularBuilder = {
  fromBackEnd: (titular): TitularModel => {
    return {
      id: titular.id,
      nombre: titular.name,
      telefono: titular.phone,
      domicilio: domicilioBuilder.fromBackEnd(titular.address),
      email: titular.email,
      isDeleted: titular.isDeleted
    };
  },
  toBackEnd: (titular: TitularModelCRUD) => {
    return {
      id: titular.id,
      name: titular.nombre,
      phone: titular.telefono,
      address: domicilioBuilder.toBackEnd(titular.domicilio),
      email: titular.email
    };
  }
};
