export const TableCard = ({
  title,
  children
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <div className="rounded-md bg-white p-4">
    <h1 className="text-bold mb-3 text-sm text-gray-700">{title}</h1>
    <div className="flex flex-col gap-1">{children}</div>
  </div>
);

export const TableCardItem = ({
  label,
  value
}: {
  label: string;
  value: string | number;
}) => {
  return (
    <div className="flex justify-between">
      <p className="text-bold mr-8 text-sm text-gray-500">{label}</p>
      <p className="text-sm text-green-500">{value}</p>
    </div>
  );
};
