import { TableColumn } from '@vadiun/react-components';
import { Table } from 'shared/components';
import { DriverExpirationModel } from '../models/DriverExpirationModel';

interface Props {
  tableProps: DriverExpirationModel[];
}

export const DriverExpirationTable = ({ tableProps }: Props) => {
  const data = tableProps;
  const columns: TableColumn<DriverExpirationModel>[] = [
    {
      name: 'code',
      label: 'Código',
      options: {
        filter: false
      }
    },
    {
      name: 'name',
      label: 'Nombre',
      options: {
        filter: false
      }
    },
    {
      name: 'expirados',
      label: 'Documentos',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex flex-col">
            {data[index].expirados?.map((vencimiento) => {
              return (
                <div>
                  {vencimiento.documento} (
                  {vencimiento.vencimiento.format('DD-MM-YYYY')})
                </div>
              );
            })}
          </div>
        )
      }
    }
  ];
  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none',
          print: false,
          download: false
        }}
        title={'Choferes y sus vencimientos'}
        data={data}
        columns={columns}
      />
    </div>
  );
};
