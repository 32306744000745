import React from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@vadiun/react-components';
import moment from 'moment';
import { FormRepeater } from '@vadiun/react-components';

import { KeyboardTimePicker } from 'formik-material-ui-pickers';
import { Horario } from '../../models/Chofer';
import {
  ChoferTurnoFormSchema,
  choferTurnoFormSchemaInitialValues
} from '../../models/ChoferFormSchema';
import FormikAddress, {
  domicilioValidation
} from 'shared/components/FormikAddress';

const validacionHorarios = () => {
  return Yup.array(
    Yup.object().shape({
      desde: Yup.mixed().required('Es necesaria una hora de inicio'),
      hasta: Yup.mixed().required('Es necesaria una hora de finalizacion')
    })
  ).test(
    'horariosSuperpuestos',
    'Los horarios no deben superponerse',
    (value, context) => {
      return (
        !!value &&
        value.every((horario: Horario, index: number, self: Horario[]) => {
          if (index + 1 !== self.length)
            return horario.hasta.isBefore(self[index + 1].desde, 'minutes');
          else return true;
        })
      );
    }
  );
};

const Schema = Yup.object().shape({
  domicilio: domicilioValidation,
  horariosLunes: validacionHorarios(),
  horariosMartes: validacionHorarios(),
  horariosMiercoles: validacionHorarios(),
  horariosJueves: validacionHorarios(),
  horariosViernes: validacionHorarios(),
  horariosSabado: validacionHorarios(),
  horariosDomingo: validacionHorarios()
});

interface Props {
  onSubmit: (value: ChoferTurnoFormSchema) => void;
  onGoBack?: () => void;
  initialValues?: ChoferTurnoFormSchema;
  submitButtonTitle?: string;
  goBackButtonTitle?: string;
}

const ChoferFormTurno = ({
  goBackButtonTitle = 'Volver',
  submitButtonTitle = 'Guardar',
  onSubmit,
  onGoBack,
  initialValues = choferTurnoFormSchemaInitialValues()
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ submitForm, touched, errors, values }) => (
        <Form className="w-full grid gap-6">
          <div className="grid-cols-2">
            <Field
              name="domicilio"
              component={FormikAddress}
              className="col-span-2"
            />
          </div>
          <p className="text-gray-700 font-bold text-lg">Horarios</p>
          <div className="space-y-2">
            {[
              'Lunes',
              'Martes',
              'Miercoles',
              'Jueves',
              'Viernes',
              'Sabado',
              'Domingo'
            ].map((dia) => (
              <div
                className="p-8 pt-0 border-b-2 border-gray-100 border-dashed"
                key={dia}
              >
                <p className="text-gray-700 font-bold text-md mb-6">{dia}</p>
                <FieldArray
                  name={`horarios${dia}`}
                  render={(arrayHelpers) => (
                    <FormRepeater
                      arrayHelpers={arrayHelpers}
                      addLabel="+ Añadir"
                      createField={() => ({ desde: moment(), hasta: moment() })}
                    >
                      {values[`horarios${dia}`].map((value, index) => (
                        <div key={index} className="flex">
                          <Field
                            component={KeyboardTimePicker}
                            label="Hora inicio turno"
                            name={`horarios${dia}.${index}.desde`}
                            inputVariant="outlined"
                            ampm={false}
                            format="HH:mm"
                            fullWidth
                          />
                          <Field
                            component={KeyboardTimePicker}
                            label="Hora fin turno"
                            name={`horarios${dia}.${index}.hasta`}
                            inputVariant="outlined"
                            ampm={false}
                            fullWidth
                          />
                        </div>
                      ))}
                    </FormRepeater>
                  )}
                />
                <p className="text-red-500">
                  {Array.isArray(errors[dia]) ? '' : errors[dia]}
                </p>
              </div>
            ))}
          </div>
          <div className="flex justify-between mt-8">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="red"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button variant="contained" color="green" onClick={submitForm}>
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChoferFormTurno;
