import React, { useEffect, useState } from 'react';
import CustomRow from './CustomRow';
import { useResponsiveBreakpoint } from '@vadiun/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import PersonIcon from '@material-ui/icons/Person';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  TravelTaked,
  useTravelCordinate
} from 'app/viaje/services/TravelsCordinate';
import { IconButton, Tooltip } from '@material-ui/core';

type Props = {
  data: any;
  idTravelSelected: number | undefined;
  setIdTravelSelected: (
    value: React.SetStateAction<number | undefined>
  ) => void;
  travelTaked: TravelTaked | undefined;
  onCloseFilters: () => void;
};

const Row = ({
  data,
  idTravelSelected,
  setIdTravelSelected,
  travelTaked,
  onCloseFilters
}: Props): JSX.Element => {
  const { takeOrLeaveTravelServiceApi } = useTravelCordinate();
  const [email, setEmail] = useState<string | undefined>();
  const [isTaked, setIsTaked] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const [
    schedule,
    client,
    points,
    coordinationObservation,
    driversRecomended,
    serviceType,
    event,
    idTravel,
    platformId,
    preAssignedDriver,
    passengers,
    actions
  ] = data;

  const idTravelRow = idTravel.props.children[1].props.children;
  const isTravelSelected = idTravelSelected === idTravelRow;
  const isTravelTaked = travelTaked ?? false;
  const isNotTravelTaked = travelTaked === undefined;
  const takedByOtherUser = isTravelTaked && travelTaked?.email !== email;
  const travelTakedByUserLogged = travelTaked?.email === email;

  const display = useResponsiveBreakpoint();
  const isMobileXs = display === 'xs';
  const isMobileSm = display === 'sm';
  const isMobileMd = display === 'md';

  const takeTravel = async () => {
    let responseCheckTravelTaked: any;
    if (isNotTravelTaked || takedByOtherUser || !isTaked) {
      responseCheckTravelTaked = await takeOrLeaveTravelServiceApi(
        idTravelRow,
        true
      );
    }

    if (responseCheckTravelTaked?.status === 400) {
      snackbar.enqueueSnackbar(responseCheckTravelTaked.message, {
        variant: 'error'
      });
      return;
    }

    setIsTaked(true);
    setIdTravelSelected(
      isTravelSelected ? undefined : idTravel.props.children[1].props.children
    );
  };

  const removeTakeTravel = async () => {
    const responseCheckTravelTaked = await takeOrLeaveTravelServiceApi(
      idTravelRow,
      false
    );
    if (responseCheckTravelTaked?.status === 400) {
      snackbar.enqueueSnackbar(responseCheckTravelTaked.message, {
        variant: 'error'
      });
      return;
    }
    setIsTaked(false);
    setIdTravelSelected(undefined);
  };

  useEffect(() => {
    setIsTaked(false);
  }, [isTravelSelected]);

  useEffect(() => {
    const email = localStorage.getItem('email') ?? '';
    setEmail(email);
  }, []);

  useEffect(() => {
    if (takedByOtherUser) {
      onCloseFilters();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takedByOtherUser]);

  return (
    <>
      {takedByOtherUser && isTravelTaked ? (
        <tr>
          <td colSpan={13} className="bg-red-50">
            <div className="flex items-center gap-2 px-4 py-1 text-xs text-red-500 bg-red-100 w-fit rounded-br-lg">
              <FontAwesomeIcon icon={faLock} size="1x" />
              <p>En uso por: {travelTaked?.name}</p>
            </div>
          </td>
        </tr>
      ) : null}
      {travelTakedByUserLogged && isTravelTaked ? (
        <tr>
          <td colSpan={13} className="bg-primary-50">
            <div className="flex items-center gap-2 px-4 py-1 text-xs text-gray-500 bg-primary-100 w-fit rounded-br-lg">
              <PersonIcon style={{ fontSize: 14 }} />
              <p className="">En uso por: {travelTaked?.name}</p>
              <Tooltip title="Desasignar traslado">
                <IconButton size="small" onClick={removeTakeTravel}>
                  <HighlightOffIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </div>
          </td>
        </tr>
      ) : null}
      <tr
        className={`${
          isMobileSm || isMobileXs || isMobileMd ? 'grid' : 'cursor-pointer'
        } ${
          takedByOtherUser && isTravelTaked
            ? 'bg-red-50'
            : travelTakedByUserLogged
            ? 'bg-primary-50'
            : ''
        }`}
        style={{
          width: '100%',
          overflow:
            isMobileSm || isMobileXs || isMobileMd ? 'hidden' : 'inherith'
        }}
        onClick={takeTravel}
      >
        <CustomRow isRowSelected={isTravelSelected}>{schedule}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{client}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{points}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>
          {coordinationObservation}
        </CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>
          {takedByOtherUser ? null : driversRecomended}
        </CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{serviceType}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{event}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{idTravel}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{platformId}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>
          {preAssignedDriver}
        </CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{passengers}</CustomRow>
        <CustomRow isRowSelected={isTravelSelected}>{actions}</CustomRow>
      </tr>
    </>
  );
};

export default Row;
