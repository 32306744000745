import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { ClientBusinessContactFormType } from './ClientBusinessFormik';
import ClientBusinessFormik from './ClientBusinessFormik';

interface Props {
  onSubmit: (client: ClientBusinessContactFormType, reset) => void;
  onGoBack?: () => void;
  initialValues?: ClientBusinessContactFormType;
  submitLabel?: string;
}

function ClientBusinessPersonalForm({
  onSubmit,
  onGoBack,
  submitLabel = 'Continuar',
  initialValues = ClientBusinessFormik.contact.initialValues
}: Props) {
  return (
    <Formik<ClientBusinessContactFormType>
      initialValues={initialValues}
      validationSchema={ClientBusinessFormik.contact.schema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {() => (
        <Form className="w-full grid gap-6 grid-cols-6">
          <h1 className="text-md text-gray-600 col-span-6 text-bold">
            Facturación
          </h1>
          <Field
            name="billingContact.contactName"
            label="Nombre*"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="billingContact.phone"
            label="Teléfono"
            className="col-span-3"
            variant="outlined"
            type="number"
            component={TextField}
            fullWidth
          />
          <Field
            name="billingContact.mail"
            label="Mail"
            variant="outlined"
            component={TextField}
            className="col-span-3"
            fullWidth
          />
          <Field
            name="billingContact.accountOfficer"
            label="Oficial de cuentas"
            className="col-span-3"
            type="number"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            className="col-span-6"
            name="billingContact.observation"
            variant="outlined"
            label="Observación"
            component={TextField}
            fullWidth
          />

          <h1 className="text-md text-gray-600 col-span-6 text-bold">
            Cobranza
          </h1>

          <Field
            name="collectionContact.contactName"
            label="Nombre*"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="collectionContact.phone"
            label="Teléfono"
            className="col-span-3"
            variant="outlined"
            type="number"
            component={TextField}
            fullWidth
          />
          <Field
            name="collectionContact.mail"
            label="Mail"
            variant="outlined"
            component={TextField}
            className="col-span-3"
            fullWidth
          />
          <Field
            name="collectionContact.paymentMethod"
            label="Medio de pago"
            className="col-span-3"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            className="col-span-6"
            name="collectionContact.observation"
            variant="outlined"
            label="Observación"
            component={TextField}
            fullWidth
          />

          <h1 className="text-md text-gray-600 col-span-6 text-bold">
            Responsable comercial
          </h1>

          <Field
            name="salesManager.contactName"
            label="Nombre*"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="salesManager.phone"
            label="Teléfono*"
            className="col-span-3"
            variant="outlined"
            type="number"
            component={TextField}
            fullWidth
          />
          <Field
            name="salesManager.mail"
            label="Mail*"
            variant="outlined"
            component={TextField}
            className="col-span-3"
            fullWidth
          />
          <Field
            className="col-span-6"
            name="salesManager.observation"
            variant="outlined"
            label="Observación"
            component={TextField}
            fullWidth
          />

          <div className="flex justify-between col-span-6">
            {onGoBack && (
              <Button onClick={onGoBack} variant="light">
                Volver
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="green"
              className="ml-auto"
            >
              {submitLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ClientBusinessPersonalForm;
