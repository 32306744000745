import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { Button, FormikImageUpload } from '@vadiun/react-components';
import * as Yup from 'yup';
import { YupShape } from 'shared/models/YupShape';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Bank } from 'app/biller/models/Bank';
import { BillerCRUD } from 'app/biller/models/BillerModel';
import moment from 'moment';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';

export interface BillerFormType extends Omit<BillerCRUD, 'cuit' | 'id'> {
  cuit: string | number;
}

interface Props {
  onSubmit: (value: BillerFormType, reset) => void;
  initialValues?: BillerFormType;
  banks: Bank[];
}

const Schema: YupShape<BillerFormType> = Yup.object().shape({
  name: Yup.string().required('El nombre es obligatorio'),
  mail: Yup.string()
    .email('Ingresa un email valido')
    .required('El mail es obligatorio'),
  cuit: Yup.string().required('El cuit es obligatorio'),
  photo: Yup.mixed().required('La foto es requerida'),
  monotributo_start_date: Yup.mixed().required(
    'La fecha de alta de monotributo es requerida'
  ),
  ingresos_brutos_start_date: Yup.mixed().required(
    'La fecha de alta de ingresos brutos es requerida'
  ),
  bankAccount: Yup.object().shape({
    bankName: Yup.string(),
    holderName: Yup.string(),
    cbu: Yup.string(),
    accountNumber: Yup.string(),
    accountAlias: Yup.string()
  })
});

const baseValues: BillerFormType = {
  name: '',
  mail: '',
  cuit: '',
  photo: '',
  monotributo_start_date: moment(),
  ingresos_brutos_start_date: moment(),
  bankAccount: {
    bankName: '',
    holderName: '',
    cbu: '',
    accountNumber: '',
    accountAlias: ''
  }
};

function BillerForm({ onSubmit, initialValues = baseValues, banks }: Props) {
  return (
    <Formik<BillerFormType>
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={(values, actions) => onSubmit(values, actions.resetForm)}
    >
      {() => (
        <Form className="w-full grid gap-6 grid-cols-6">
          <div className="col-span-6 grid gap-6 grid-cols-6 ">
            <div className="col-span-1 row-span-2">
              <Field
                component={FormikImageUpload}
                className="aspect-w-1 aspect-h-1"
                name="photo"
              />
            </div>
            <div className="col-span-5 grid gap-5 grid-cols-4 ">
              <Field
                name="name"
                label="Nombre*"
                variant="outlined"
                component={TextField}
                className="col-span-2"
                fullWidth
              />
              <Field
                name="mail"
                label="Email"
                variant="outlined"
                component={TextField}
                className="col-span-2"
                fullWidth
              />
              <Field
                name="monotributo_start_date"
                label="Alta monotributo*"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                className="col-span-2"
                ampm={false}
                format="DD/MM/YYYY"
                fullWidth
              />
              <Field
                name="ingresos_brutos_start_date"
                label="Ingresos brutos*"
                inputVariant="outlined"
                component={KeyboardDatePicker}
                className="col-span-2"
                ampm={false}
                format="DD/MM/YYYY"
                fullWidth
              />
              <Field
                name="cuit"
                label="Cuit*"
                variant="outlined"
                type="number"
                component={TextField}
                className="col-span-2"
                fullWidth
              />
            </div>
          </div>
          <h2 className="col-span-6 text-md">Información bancaria</h2>
          <FormControl variant="outlined" className="col-span-2">
            <InputLabel>Banco</InputLabel>
            <Field component={Select} name="bankAccount.bankName" label="Banco">
              {banks.map((banco) => (
                <MenuItem value={banco} key={banco}>
                  {banco}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <Field
            name="bankAccount.holderName"
            label="Titular"
            variant="outlined"
            component={TextField}
            className="col-span-2"
            fullWidth
          />
          <Field
            name="bankAccount.cbu"
            label="CBU"
            variant="outlined"
            component={TextField}
            className="col-span-2"
            fullWidth
          />
          <Field
            name="bankAccount.accountNumber"
            label="Número de cuenta"
            variant="outlined"
            component={TextField}
            className="col-span-2"
            fullWidth
          />
          <Field
            name="bankAccount.accountAlias"
            label="Alias"
            variant="outlined"
            component={TextField}
            className="col-span-2"
            fullWidth
          />

          <div className="flex justify-end col-span-6">
            <Button type="submit" variant="contained" color="green">
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default BillerForm;
