import React from 'react';
import { TitularFormSchema } from '../models/TitularFormSchema';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import * as Yup from 'yup';
import MUIGooglePlaces, {
  domicilioGoogleValidation
} from 'shared/components/MUIGooglePlaces';

interface Props {
  onSubmit: (value: TitularFormSchema, reset) => void;
  initialValues?: TitularFormSchema;
}

const Schema = Yup.object().shape({
  nombre: Yup.string().required('El nombre es obligatorio'),
  telefono: Yup.number().required('El telefono es obligatorio'),
  email: Yup.string()
    .required('El email es obligatorio')
    .email('El email es inválido'),
  domicilio: domicilioGoogleValidation,
  piso: Yup.string().nullable(),
  departamento: Yup.string().nullable()
});

function TitularForm({
  onSubmit,
  initialValues = {
    nombre: '',
    domicilio: {
      latitud: 0,
      longitud: 0,
      calle: '',
      numero: '',
      partido: '',
      localidad: '',
      provincia: '',
      pais: '',
      codigoPostal: '',
      text: ''
    },
    telefono: '',
    email: '',
    piso: '',
    departamento: ''
  }
}: Props) {
  return (
    <Formik<TitularFormSchema>
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={(values, actions) => onSubmit(values, actions.resetForm)}
    >
      {({ isSubmitting }) => (
        <Form className="w-full grid gap-6 grid-cols-4">
          <Field
            name="nombre"
            label="Nombre completo *"
            variant="outlined"
            component={TextField}
            className="col-span-2"
            fullWidth
          />
          <Field
            name="email"
            label="Email *"
            variant="outlined"
            component={TextField}
            className="col-span-2"
            fullWidth
          />

          <Field
            name="domicilio"
            label="Domicilio *"
            variant="outlined"
            component={MUIGooglePlaces}
            className="col-span-4"
            fullWidth
          />
          <Field
            name="telefono"
            label="Telefono *"
            type="number"
            variant="outlined"
            component={TextField}
            className="col-span-2"
            fullWidth
          />
          <Field
            name="piso"
            label="Piso"
            type="number"
            variant="outlined"
            component={TextField}
            className="col-span-1"
            fullWidth
          />
          <Field
            name="departamento"
            label="Depto"
            variant="outlined"
            component={TextField}
            className="col-span-1"
            fullWidth
          />
          <div className="flex justify-end col-span-4">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default TitularForm;
