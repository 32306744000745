import React from 'react';
import { Card } from 'shared/components';
import { ClientPrivateForm } from '../forms/ClientPrivateForm';
import { ClientPrivateFormType } from '../forms/ClientPrivateFormType';

interface Props {
  update: (ClientPrivateValues: ClientPrivateFormType) => Promise<void>;
  initialValues: ClientPrivateFormType;
}

export function ClientPrivateEdit({ update, initialValues }: Props) {
  return (
    <Card title="Nuevo ClientPrivate" className="w-full">
      <div className="p-8">
        <ClientPrivateForm onSubmit={update} initialValues={initialValues} />
      </div>
    </Card>
  );
}
