import environment from 'environment/environment';
import {
  BEMappingType,
  BEValidationType,
  MappingType,
  TripCompletionType,
  TravelApplicantForm,
  TravelEventForm
} from '../components/bulk/utils/types';

export const importBookings = async (
  clientId: number,
  mappingData: MappingType[],
  requirements?: TravelApplicantForm,
  event?: TravelEventForm
): Promise<{ data: BEValidationType[] }> => {
  const result = await fetch(
    `${environment.booking}/business-client/${clientId}/import-bookings`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...(requirements && { requirements }),
        ...(event && { event }),
        rows: mappingData
      })
    }
  );

  if (!result.ok) {
    try {
      console.log(await result.json());
      throw new Error('Error importing');
    } catch {
      throw new Error('Error importing');
    }
  }
  return result.json();
};

export const createBulkTrips = async (
  clientId: number,
  mappingData: BEMappingType[],
  requirements: TravelApplicantForm,
  event: TravelEventForm
): Promise<{
  created: TripCompletionType[];
  errors: { reason: string; booking: TripCompletionType }[];
}> => {
  const result = await fetch(
    `${environment.booking}/business-client/${clientId}/confirm-import`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        requirements: requirements,
        event: event,
        bookings: mappingData
      })
    }
  );
  if (!result.ok) {
    try {
      console.log(await result.json());
      throw new Error('Error importing');
    } catch {
      throw new Error('Error importing');
    }
  }
  return result.json();
};
