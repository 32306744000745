import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { DraggablePassengers } from './DraggablePassengers';

import { Chip } from '@vadiun/react-components';

import { FaRegClock } from 'react-icons/fa';

import { TransferReservationBatchLocationFormType } from '../TransferReservationBatchFormType';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Props {
  index: number;
  punto: TransferReservationBatchLocationFormType & { id: string };
  actions: React.ReactNode;
}

export const DraggablePoint = (props: Props) => {
  const { punto, index, actions } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: punto.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      style={style}
      className="col-span-6 my-8"
      ref={setNodeRef}
      key={index}
      id={punto.id}
      {...attributes}
      {...listeners}
    >
      <div className="flex items-center">
        <Chip>{index + 1}</Chip>
        <span className="ml-4">{createDomicilioText(punto.domicilio)}</span>
        <div className="ml-auto flex space-x-2">
          <div className="text-color-700 mr-2 flex items-center">
            <FaRegClock className="mr-4" />
            <span>{punto.espera} min.</span>
          </div>
          {actions}
        </div>
      </div>
      {punto.pasajeros?.length > 0 && (
        <DraggablePassengers punto={punto} index={index} />
      )}
    </div>
  );
};
