import React from 'react';
import { Bar } from 'react-chartjs-2';
import { withColor } from './chartHelper';

export const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

interface Props {
  bars: {
    label: string;
    stack: {
      label: string;
      value: number;
    }[];
  }[];
}

export const BarStackedChartComponent = ({ bars }: Props) => {
  if (bars.length === 0) {
    return null;
  }

  const labels = bars.map((b) => b.label);
  const datasetLabels = bars[0].stack.map((item) => item.label);
  const data = {
    labels,
    datasets: withColor<'bar'>(
      datasetLabels.map((label) => ({
        label: label,
        data: bars.map((bar) => {
          const foundItem = bar.stack.find(
            (stackItem) => stackItem.label === label
          );
          return foundItem!.value;
        })
      }))
    )
  };

  return <Bar options={options} data={data} />;
};
