import { Button, FormikAutocompleteAsync } from '@vadiun/react-components';

import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Query } from '@vadiun/react-hooks-legacy';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import {
  ClientBusinessModel,
  ClientBusinessPartialModel
} from 'app/client/clientBusiness/models/ClientBusiness';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import {
  CANALES_DE_VIAJE,
  TIPOS_SERVICIO_VIAJE_TRASLADO,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import { Field, FormikProps } from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { useRef, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';
import ReservaTrasladoSearchSolicitante from '../../components/ReservaTrasladoSearchSolicitante';
import { TransferReservationClientRequeriments } from './TransferReservationClientRequeriments';

interface Props extends FormikProps<any> {
  getClientesByName: (name: string) => Promise<ClientBusinessPartialModel[]>;
  onClienteSelect: (cliente: ClientBusinessPartialModel) => void;
  onServiceTypeSelect: (type: ViajeTrasladoTipoServicio) => void;
  autorizadosQuery: Query<AuthorizedModel[]>;
  clientBusiness: ClientBusinessModel | undefined;
  fixedRates: RateModel['categories'][0]['fixedPrices'];
  solicitantes: ApplicantModel[];
}

export const TransferReservationBaseForm = ({
  values,
  setFieldValue,
  touched,
  errors,
  onClienteSelect,
  onServiceTypeSelect,
  getClientesByName,
  clientBusiness,
  fixedRates,
  autorizadosQuery,
  solicitantes
}: Props) => {
  const [isSearchSolicitanteOpen, setIsSearchSolicitanteOpen] = useState(false);
  const firstLoad = useRef(true);
  const [showFieldNameEvent, setShowFieldNameEvent] = useState<boolean>(false);
  const [showFieldsLLTPais, setShowFieldsLLTPais] = useState<boolean>(false);

  return (
    <>
      <FormikOnFieldChange
        field="cliente"
        onChange={(cliente: ClientBusinessPartialModel | null) => {
          if (cliente === null) return;
          onClienteSelect(cliente);
        }}
      />
      <FormikOnFieldChange
        field="tipoServicio"
        onChange={(tipo: ViajeTrasladoTipoServicio | null) => {
          if (tipo === null) return;
          if (firstLoad.current) {
            firstLoad.current = false;
            return;
          }
          setFieldValue('fixedRate', null);
          onServiceTypeSelect(tipo);
        }}
      />
      <div className="flex gap-6 pt-8 pb-4">
        <Field
          name="cliente"
          component={FormikAutocompleteAsync}
          fetchOptions={getClientesByName}
          fullWidth
          getOptionLabel={(cliente: ClientBusinessPartialModel) =>
            cliente.fantasyName
          }
          className="w-1/2"
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextFieldMaterial
              {...params}
              error={touched['cliente'] && !!errors['cliente']}
              helperText={errors['cliente']}
              label="Cliente"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <div className="w-1/3">
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="pay_with_cash"
            color="primary"
            Label={{ label: 'Pago en efectivo' }}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 grid-rows-1 gap-6 border-b-2 border-dashed pb-4">
        <div className="py-2 flex items-center">
          <FormikOnFieldChange
            field="isLLTPais"
            onChange={(isLLTPais: null) => {
              if (isLLTPais === null) return;

              if (isLLTPais === false) {
                setFieldValue('providerPrice', '');
                setFieldValue('customerPrice', '');
              }

              setShowFieldsLLTPais(isLLTPais);
            }}
          />
          <div className="w-32">
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="isLLTPais"
              color="primary"
              Label={{ label: 'LLT País' }}
            />
          </div>
          {showFieldsLLTPais ? (
            <div className="flex gap-4">
              <Field
                name="providerPrice"
                label="Costo proveedor"
                variant="outlined"
                component={TextField}
                type="number"
              />
              <Field
                name="customerPrice"
                label="Costo cliente"
                variant="outlined"
                component={TextField}
                type="number"
              />
            </div>
          ) : null}
        </div>
        <div className="py-2 flex items-center">
          <FormikOnFieldChange
            field="isEvent"
            onChange={(isEvent: null) => {
              if (isEvent === null) return;

              if (isEvent === false) {
                setFieldValue('nameEvent', '');
              }

              setShowFieldNameEvent(isEvent);
            }}
          />
          <div className="w-32">
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="isEvent"
              color="primary"
              Label={{ label: 'Evento' }}
            />
          </div>
          {showFieldNameEvent ? (
            <Field
              name="nameEvent"
              label="Nombre del evento"
              variant="outlined"
              component={TextField}
            />
          ) : null}
        </div>
      </div>

      <TransferReservationClientRequeriments
        autorizadosQuery={autorizadosQuery}
        errors={errors}
        client={clientBusiness}
      />
      <div className="grid grid-cols-2 gap-6 border-b-2 border-dashed py-8">
        <FormControl variant="outlined" fullWidth className="col-span-1">
          <InputLabel>Tipo de servicio</InputLabel>
          <Field
            component={Select}
            name="tipoServicio"
            label="Tipo de servicio"
          >
            {TIPOS_SERVICIO_VIAJE_TRASLADO.map((canal) => (
              <MenuItem value={canal} key={canal}>
                {canal}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <Field
          name="fixedRate"
          component={Autocomplete}
          fullWidth
          getOptionLabel={(
            rate: RateModel['categories'][0]['fixedPrices'][0]
          ) => `${rate.name} - $${rate.price}`}
          noOptionsText="No se han encontrado resultados"
          loadingText="Cargando..."
          options={fixedRates}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextFieldMaterial
              {...params}
              error={!!errors.fixedRate}
              helperText={errors.fixedRate}
              label="Tarifa fija"
              variant="outlined"
              InputProps={{
                ...params.InputProps
              }}
              fullWidth
            />
          )}
        />
      </div>

      <div className="grid grid-cols-12 gap-6 border-b-2 border-dashed py-8">
        <Button
          variant="light"
          shape="circle"
          onClick={() => setIsSearchSolicitanteOpen(true)}
          className="col-span-1 self-center"
        >
          <FaSearch />
        </Button>
        <ReservaTrasladoSearchSolicitante
          isOpen={isSearchSolicitanteOpen}
          handleClose={() => setIsSearchSolicitanteOpen(false)}
          solicitantes={solicitantes}
          onSelect={(solicitante) => {
            setFieldValue('solicitante.name', solicitante.name);
            setFieldValue('solicitante.phone', solicitante.phone);
            setFieldValue('solicitante.email', solicitante.email);
            setIsSearchSolicitanteOpen(false);
          }}
        />
        <Field
          name="solicitante.name"
          label="Solicitante"
          variant="outlined"
          component={TextField}
          className="col-span-5"
          fullWidth
        />
        <Field
          name="solicitante.phone"
          label="Telefono"
          variant="outlined"
          component={TextField}
          className="col-span-2"
          fullWidth
        />
        <Field
          name="solicitante.email"
          label="Mail"
          variant="outlined"
          component={TextField}
          fullWidth
          className="col-span-4"
        />
      </div>
      <div className="grid grid-cols-12 gap-6 border-b-2 border-dashed pt-8 pb-4">
        <FormControl variant="outlined" fullWidth className="col-span-3">
          <InputLabel>Canal de ingreso</InputLabel>
          <Field
            component={Select}
            name="canalIngreso"
            label="Canal de ingreso"
          >
            {CANALES_DE_VIAJE.map((canal) => (
              <MenuItem value={canal} key={canal}>
                {canal}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <Field
          name="plataformaId"
          label="ID plataforma"
          variant="outlined"
          component={TextField}
          className="col-span-3"
          fullWidth
        />
        <div className="col-span-2">
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="prioridad"
            color="primary"
            Label={{ label: 'Prioritario' }}
            fullWidth
          />
        </div>
        <div className="col-span-2">
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="hasOutSite"
            color="primary"
            Label={{ label: 'Out Site' }}
          />
        </div>
      </div>
      <div className="border-b-2 border-dashed py-8 ">
        <Field
          name="observacionCoordinacion"
          label="Observacion coordinacion"
          variant="outlined"
          component={TextField}
          fullWidth
        />
      </div>
    </>
  );
};
