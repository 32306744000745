import { Menu, MenuItem } from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { TransferReservationManagmentModel } from 'app/viaje/viajeTraslado/gestion/models/TransferReservationManagment';
import { TransferReservationPendingModel } from 'app/viaje/viajeTraslado/pendiente/models/TransferReservationPending';
import {
  copyReservationPublicLinkToClipboard,
  copyReservationPublicNewLinkToClipboard
} from 'app/viaje/viajeTraslado/viajeTraslado/services/copyReservationPublicLinkToClipboard';
import { useSnackbar } from 'notistack';
// import { useSnackbar } from '@vadiun/react-hooks';
import React from 'react';

type Props = {
  current: any;
  index: number;
  onAssignDriver: (driver: any) => void;
  onPreAssignDriver: (driver: any) => void;
  onRemovePreAssigned: (driver: any) => void;
  onReservationShowDetails: (id: number) => React.ReactNode;
  onEditReservation: (travel: TransferReservationPendingModel) => void;
  onReservationCopy: (reservation: TransferReservationManagmentModel) => void;
  onCancelReservation: (reservation: TransferReservationPendingModel) => void;
  onDelayNotify: (travel: TransferReservationPendingModel) => void;
  onStartTravel: (selectedReservation: number, chofer: number) => void;
};

const ActionsItemCordinate = ({
  current,
  index,
  onAssignDriver,
  onPreAssignDriver,
  onRemovePreAssigned,
  onReservationShowDetails,
  onCancelReservation,
  onEditReservation,
  onReservationCopy,
  onDelayNotify,
  onStartTravel
}: Props) => {
  const snackbar = useSnackbar();

  const eventIndex = React.useRef<number | null>(null);
  const [driverButtonElement, setDriverButtonElement] = React.useState<
    (EventTarget & HTMLElement) | null
  >(null);
  const [reservationButtonElement, setReservationButtonElement] =
    React.useState<(EventTarget & HTMLElement) | null>(null);

  return (
    <>
      <Button
        onClick={(ev) => {
          ev.stopPropagation();
          eventIndex.current = index;
          setDriverButtonElement(ev.currentTarget);
        }}
        variant="light"
        color="green"
        className="mb-2 w-full"
      >
        Chofer
      </Button>
      <Menu
        anchorEl={driverButtonElement}
        open={eventIndex.current === index && Boolean(driverButtonElement)}
        onClose={(ev: any) => {
          ev.stopPropagation();
          setDriverButtonElement(null);
        }}
      >
        <MenuItem
          onClick={(ev) => {
            ev.stopPropagation();
            setDriverButtonElement(null);
            onPreAssignDriver(current);
          }}
        >
          Pre-asignar
        </MenuItem>
        <MenuItem
          onClick={(ev) => {
            ev.stopPropagation();
            setDriverButtonElement(null);
            onAssignDriver(current);
          }}
        >
          Asignar
        </MenuItem>
        {current.preAssignedDriver?.codigo ? (
          <MenuItem
            onClick={(ev) => {
              ev.stopPropagation();
              setDriverButtonElement(null);
              onRemovePreAssigned(current.id);
            }}
          >
            Remover chofer
          </MenuItem>
        ) : null}
      </Menu>
      <Button
        onClick={(ev) => {
          ev.stopPropagation();
          eventIndex.current = index;
          setReservationButtonElement(ev.currentTarget);
        }}
        variant="light"
        className="mb-2 w-full"
        color="blue"
      >
        Reserva
      </Button>
      <Menu
        anchorEl={reservationButtonElement}
        open={eventIndex.current === index && Boolean(reservationButtonElement)}
        onClose={() => setReservationButtonElement(null)}
      >
        {onReservationShowDetails(current.id)}
        <MenuItem onClick={() => onEditReservation(current)}>
          Modificar
        </MenuItem>
        <MenuItem onClick={() => onCancelReservation(current)}>
          Cancelar
        </MenuItem>
        <MenuItem onClick={() => onReservationCopy(current)}>Copiar</MenuItem>
        <MenuItem
          onClick={async () => {
            await copyReservationPublicLinkToClipboard(current.id);
            setReservationButtonElement(null);
            snackbar.enqueueSnackbar('Link copiado', {
              variant: 'info'
            });
          }}
        >
          Copiar Link
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await copyReservationPublicNewLinkToClipboard(current.id);
            setReservationButtonElement(null);
            snackbar.enqueueSnackbar('Nuevo link de seguimiento copiado', {
              variant: 'info'
            });
          }}
        >
          Copiar nuevo link de seguimiento
        </MenuItem>
        <MenuItem
          onClick={() => {
            eventIndex.current = null;
            setReservationButtonElement(null);
            onDelayNotify(current);
          }}
        >
          Notificar Demora
        </MenuItem>
      </Menu>
      {current?.preAssignedDriver ? (
        <Button
          onClick={() => {
            onStartTravel(current.id, current.preAssignedDriver?.id!);
          }}
          variant="light"
          className="w-full"
          color="blue"
        >
          Pasar a Abierto
        </Button>
      ) : null}
    </>
  );
};

export default ActionsItemCordinate;
