function appendToFormData(formData: FormData, key: string, value: unknown) {
  if (value instanceof File || value instanceof Blob) {
    formData.append(key, value);
    return;
  }
  if (value === null || value === undefined) {
    formData.append(key, '');
    return;
  }
  if (Array.isArray(value)) {
    value.forEach((elem, index) => {
      appendToFormData(formData, `${key}[${index}]`, elem);
    });
    return;
  }
  if (typeof value === 'boolean') {
    formData.append(key, value ? String(1) : String(0));
    return;
  }
  if (value instanceof Object) {
    Object.entries(value).forEach(([childKey, childValue]) => {
      appendToFormData(formData, `${key}[${childKey}]`, childValue);
    });
    return;
  }
  formData.append(key, String(value));
}

export function jsonToFormData(json) {
  const formData = new FormData();
  Object.entries(json).forEach(([key, value]) => {
    appendToFormData(formData, key, value);
  });
  return formData;
}
