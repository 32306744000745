import {
  passengerBuilder,
  PassengerModel
} from 'app/client/clientBusiness/Passenger/models/Passenger';
import { ClientType } from 'app/client/main/models/ClientType';
import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';
import { ViajeCanalesDeIngreso, ViajeTrasladoEstado } from 'app/viaje/models';
import moment, { Moment } from 'moment';
import { TransferReservationPrices } from './TransferReservationPrices';
import { ViajeTrasladoTipoServicio } from './ViajeTrasladoTipoServicio';

export interface ViajeTrasladosGestionEstado {
  datetime: string;
  observation: string;
  type: string;
  user: {
    email: string;
    id: number;
    isDeleted: boolean;
    name: string;
  };
}

export interface TransferReservationPartialModel {
  id: number;
  client: {
    id: number;
    name: string;
    hasPriority: boolean;
  };
  platformId: number;
  hasPriority: false;
  serviceType: ViajeTrasladoTipoServicio;
  coordinationObservation: string;
  reservationDatetime: Moment;
  departureDatetime: Moment;
  advanceHours: number;
  arrivalDateTime: Moment;
  state: ViajeTrasladoEstado;
  clientType: ClientType;
  contretemps: string;
  deviation_observation: string | null;
  entryChannel: ViajeCanalesDeIngreso;
  locations: {
    observation: string;
    minutesWaiting: number;
    address: DomicilioModel;
    passengers: PassengerModel[];
  }[];
  state_datetime: Moment;
  estimated_prices: TransferReservationPrices | null;
  calculated_prices: TransferReservationPrices | null;
  final_prices: TransferReservationPrices | null;
  pay_with_cash: boolean;
  is_event: boolean;
  event_name?: string;
  states?: ViajeTrasladosGestionEstado[];
  cancel_observation?: string;
  lltpais?: any;
}

export const transferReservationPartialBuilder = {
  fromBackEnd: (reservation): TransferReservationPartialModel => ({
    ...reservation,
    reservationDatetime: moment(reservation.reservationDatetime),
    arrivalDateTime: moment(reservation.arrivalDateTime),
    departureDatetime: moment(reservation.departureDateTime),
    contretemps: reservation.setback,
    locations: reservation.locations.map((location) => ({
      ...location,
      address: domicilioBuilder.fromBackEnd(location.address),
      passengers: location.passengers.map(passengerBuilder.fromBackEnd)
    })),
    state_datetime: moment(reservation.state_datetime)
  })
};
