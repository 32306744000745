import { Button } from '@vadiun/react-components';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';

import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from '../../../../layout/components/PageContainer';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ClientBusinessCreate from '../components/ClientBusinessCreate';
import { ClientBusinessFormType } from '../components/ClientBusinessForm/ClientBusinessFormik';
import ClientBusinessTable from '../components/ClientBusinessTable';
import { ClientBusinessPartialModel } from '../models/ClientBusiness';
import useClientBusiness from '../services/useClientBusiness';

const ClientBusinessCrudPage = () => {
  const ClientBusinessService = useClientBusiness();
  const ivaConditions = useSuperQuery(ClientBusinessService.getIvaConditions);

  const clientsBusinessQuery = useSuperQuery(
    ClientBusinessService.getAllWithDeleted,
    {
      showSpinner: true
    }
  );
  const clientCreateMutation = useSuperMutation(ClientBusinessService.create, {
    showSpinner: true
  });
  const clientRemoveMutation = useSuperMutation(ClientBusinessService.remove, {
    showSpinner: true
  });
  const clientRestoreMutation = useSuperMutation(
    ClientBusinessService.restore,
    {
      showSpinner: true
    }
  );
  
  const printClientMutation = useSuperMutation(ClientBusinessService.exportBusinessClient, {
    onMutationFinish: (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data as any]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.click()
    }
  })

  const [visiblePage, setVisiblePage] = useState<'table' | 'create'>('table');

  async function create(values: ClientBusinessFormType, reset: () => void) {
    await clientCreateMutation.mutate(values);
    clientsBusinessQuery.reload();
    reset();
  }

  async function remove(cliente: ClientBusinessPartialModel) {
    await clientRemoveMutation.mutate(cliente.id);
    clientsBusinessQuery.reload();
  }

  async function restore(cliente: ClientBusinessPartialModel) {
    await clientRestoreMutation.mutate(cliente.id);
    clientsBusinessQuery.reload();
  }

  return (
    <>
      <PageHeader
        title="Cliente CC"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Clientes CC'
            : visiblePage === 'create'
            ? 'Nuevo Cliente CC'
            : 'Editar Cliente CC'
        }
        toolbar={
          <>
            <Button
              variant="light"
              color="green"
              onClick={printClientMutation.mutate}
            >
              Imprimir
            </Button>
            {visiblePage === 'table' ? (
              <Button onClick={() => setVisiblePage('create')} variant="light">
                Crear cliente
              </Button>
            ) : (
              <Button onClick={() => setVisiblePage('table')} variant="light">
                Volver
              </Button>
            )}
          </>
        }
      />
      <PageContainer>
        {visiblePage === 'table' && (
          <ClientBusinessTable
            clients={clientsBusinessQuery.data || []}
            delete={remove}
            restore={restore}
            onClientModifyButton={(id) => (
              <Link
                to={'/main/clientBusiness/' + id}
                className="text-xs text-gray-400 hover:underline"
              >
                <FontAwesomeIcon
                  size="1x"
                  color="dodgerblue"
                  icon={faPencilAlt}
                />
              </Link>
            )}
          />
        )}

        {visiblePage === 'create' && (
          <ClientBusinessCreate
            ivaConditions={ivaConditions.data!}
            create={create}
          />
        )}
      </PageContainer>
    </>
  );
};

export default ClientBusinessCrudPage;
