import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import useClientPrivates from 'app/client/private/private/services/ClientPrivateService';
import {
  TravelEstimationsWithPrice,
  useDistanceAndPriceTransferReservationClientPrivateCalculator
} from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import ReservaTrasladoEstimationDialog from 'app/viaje/viajeTraslado/viajeTraslado/components/ReservaTrasladoEstimationDialog';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'shared/components';
import { TrasnferReservationPrivateSingleForm } from '../../private/forms/single/TransferReservationPrivateSingleForm';
import {
  transferReservationPrivateSingleFormBuilder,
  TransferReservationPrivateSingleFormType
} from '../../private/forms/single/TransferReservationSingleFormType';
import { useTransferReservationPrivateRepository } from '../../private/services/repositories/transferReservationPrivateRepository';

export const TransferReservationClientPrivateToValidateModifyPage = () => {
  const history = useHistory();
  const transferReservationPrivateRepo =
    useTransferReservationPrivateRepository();
  const clientPrivateRepository = useClientPrivates();
  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientPrivateCalculator();
  const validateTrasladoMutation = useSuperMutation(
    transferReservationPrivateRepo.approveTransferReservationToValidate,
    { showSpinner: true }
  );
  let { id } = useParams<{ id: string }>();

  const reservationQuery = useSuperQuery(() =>
    transferReservationPrivateRepo.getTransferReservationToValidate(Number(id))
  );
  const clientQuery = useSuperQuery(
    async () => {
      if (reservationQuery.data?.client.id === undefined) {
        return;
      }
      return clientPrivateRepository.getClientPrivate(
        reservationQuery.data.client.id
      );
    },
    { showSpinner: true },
    [reservationQuery.data]
  );

  const [isEstimationOpen, setIsEstimationOpen] = useState(false);
  const [distanceAndCost, setDistanceAndCost] = useState<
    TravelEstimationsWithPrice[]
  >([]);

  async function validateViajeTraslado(
    trasladoFormValues: TransferReservationPrivateSingleFormType
  ) {
    const ViajeTraslado = transferReservationPrivateSingleFormBuilder.toModel(
      trasladoFormValues,
      reservationQuery.data!.id
    );
    await validateTrasladoMutation.mutate(ViajeTraslado);
    history.push('/main/Viaje/traslado/pendiente/coordinar');
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationPrivateSingleFormType
  ) {
    const estimationsPromise = calculateDistanceAndCost(
      {
        arrivalDate: trasladoFormValues.fechaYHoraDeLlegada,
        hasOutSite: trasladoFormValues.hasOutSite,
        points: trasladoFormValues.puntos.map((p) => ({
          lat: p.domicilio.latitud,
          lng: p.domicilio.longitud,
          minutesWaiting: Number(p.espera)
        })),
        serviceType: trasladoFormValues.tipoServicio
      },
      trasladoFormValues.cliente!.id
    );
    return [await estimationsPromise];
  }

  if (reservationQuery.data === undefined || clientQuery.data === undefined) {
    return (
      <>
        <PageHeader title="Viaje particular" subtitle={'Nuevo traslado'} />
        <PageContainer>
          <Card className="w-full" title="Nuevo traslado particular">
            <div className="p-8">Cargando...</div>
          </Card>
        </PageContainer>
      </>
    );
  }

  const initialValues: TransferReservationPrivateSingleFormType =
    transferReservationPrivateSingleFormBuilder.fromModel(
      reservationQuery.data,
      clientQuery.data
    );

  return (
    <>
      <PageHeader title="Validación" />
      <PageContainer>
        <Card className="w-full" title="Validar traslado">
          <div className="p-8">
            <TrasnferReservationPrivateSingleForm
              getClientesByName={clientPrivateRepository.findByName}
              onCalculateDistanceAndCost={estimatePrice}
              initialValues={initialValues}
              onSubmit={validateViajeTraslado}
            />
          </div>
        </Card>
      </PageContainer>
      <ReservaTrasladoEstimationDialog
        isOpen={isEstimationOpen}
        handleClose={() => setIsEstimationOpen(false)}
        distanceAndCost={distanceAndCost}
      />
    </>
  );
};
