import { MappingDataType, MappingReducerActionType } from './types';

export const mappingReducer = (
  state: MappingDataType,
  action: MappingReducerActionType
) => {
  let nextState = JSON.parse(JSON.stringify(state)) as MappingDataType;
  if (action.type === 'SET_COLUMN_TYPE') {
    const prevCol = nextState.columns.find(({ columnId }) =>
      columnId?.includes(action.payload.columnId)
    );
    if (prevCol) {
      prevCol.columnId = prevCol.columnId.filter(
        (cid) => cid !== action.payload.columnId
      );
      prevCol.inUse = !!prevCol.columnId.length;
    }
    const nextCol = nextState.columns.find(
      ({ id }) => id === action.payload.id
    );
    if (nextCol) {
      nextCol.columnId = [...nextCol.columnId, action.payload.columnId];
      nextCol.inUse = true;
    }
  }
  if (action.type === 'REMOVE_COLUMN_TYPE') {
    const nextCol = nextState.columns.find(
      ({ id }) => id === action.payload.id
    );
    if (nextCol) {
      nextCol.columnId = nextCol.columnId.filter(
        (val) => val !== action.payload.columnId
      );
      nextCol.inUse = !!nextCol.columnId.length;
    }
  }

  if (action.type === 'DELETE_COLUMN') {
    const dataToRemove = nextState.columns.find(({ columnId }) =>
      columnId.includes(action.payload.columnId)
    );
    if (dataToRemove) {
      dataToRemove.columnId = dataToRemove.columnId.filter(
        (colId) => colId !== action.payload.columnId
      );
      dataToRemove.inUse = !!dataToRemove.columnId.length;
    }
    --nextState.columnNumber;
  }

  if (action.type === 'RESET') {
    nextState = action.payload;
  }
  return nextState;
};
