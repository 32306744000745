import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { useSuperMutation, useVerifyAction } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Table } from 'shared/components';
import { useCustomTablePagination } from 'shared/hooks/useCustomTablePagination';
import DriverShiftsFilters, {
  DriverShiftFiltersFormType
} from '../components/DriverShiftsFilters';
import { ShiftClosePetitionStatus } from '../models/DriverShift';
import useChoferes from '../services/ChoferService';

const DriverShiftsPage = () => {
  const driverRepository = useChoferes();
  const approveMutation = useSuperMutation(
    driverRepository.approveShiftClosure
  );
  const verifyAction = useVerifyAction();

  const denyMutation = useSuperMutation(driverRepository.denyShiftClosure);

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<DriverShiftFiltersFormType>({
    driver: undefined,
    openFrom: moment().add(-1, 'days'),
    openTo: moment().add(1, 'day'),
    approval: 'pending'
  });
  const pagination = useCustomTablePagination(() =>
    driverRepository.getShifts(filters)
  );

  useEffect(() => {
    pagination.reload();
  }, [filters]);

  const approve = async (id: number) => {
    await approveMutation.mutate(id);
    pagination.reload();
  };

  const deny = async (id: number) => {
    await denyMutation.mutate(id);
    pagination.reload();
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'driverName',
      label: 'Chofer',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex flex-col items-center">
            <p>{pagination.data[index].driverCode}</p>
            <p className="text-xs">{pagination.data[index].driverName}</p>
          </div>
        )
      }
    },
    {
      name: 'openDateTime',
      label: 'Apertura',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div>
            <p>
              {pagination.data[index].openDateTime.format('DD/MM - HH:mm') +
                ' hs.'}
            </p>
            <p className="text-xs text-gray-700">
              {pagination.data[index].openType}
            </p>
          </div>
        )
      }
    },
    {
      name: 'observation',
      label: 'Observación',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <p className="text-xs"> {pagination.data[index].observation}</p>
        )
      }
    },
    {
      name: 'closeDateTime',
      label: 'Cierre',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div className="flex items-center">
            {pagination.data[index].state ===
              ShiftClosePetitionStatus.NO_CLOSE_PETITION && <span>-</span>}
            <div className="mr-4">
              <p>
                {pagination.data[index].closeDateTime?.format('DD/MM - HH:mm') +
                  ' hs.'}
              </p>
              <p className="text-xs text-gray-700">
                {pagination.data[index].closeType === 'Inicio descanso'
                  ? 'Descanso de ' +
                    (pagination.data[index].closeMinutes! > 0
                      ? `${moment
                          .duration(
                            pagination.data[index].closeMinutes,
                            'minutes'
                          )
                          .humanize()}`
                      : '')
                  : pagination.data[index].closeType}
              </p>
            </div>
            <div className="ml-auto">
              {pagination.data[index].state ===
                ShiftClosePetitionStatus.PENDING && (
                <div className="flex">
                  <Button
                    variant="light"
                    color="green"
                    className="mr-1"
                    onClick={() =>
                      verifyAction({
                        onAccept: () => approve(pagination.data[index].id),
                        body: '¿Está seguro que desea aprobar esta solicitud?'
                      })
                    }
                    isLoading={approveMutation.isLoading}
                    disabled={approveMutation.isLoading}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    color="red"
                    variant="light"
                    onClick={() =>
                      verifyAction({
                        onAccept: () => deny(pagination.data[index].id),
                        body: '¿Está seguro que desea rechazar esta solicitud?'
                      })
                    }
                    isLoading={denyMutation.isLoading}
                    disabled={denyMutation.isLoading}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </div>
              )}
              {pagination.data[index].state ===
                ShiftClosePetitionStatus.APPROVED && (
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-100">
                  <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                </div>
              )}
              {pagination.data[index].state ===
                ShiftClosePetitionStatus.DENIED && (
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-100">
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
            </div>
          </div>
        )
      }
    }
  ];

  return (
    <>
      <PageHeader
        title="Turnos"
        subtitle="Listado de Turnos"
        toolbar={
          <Button variant="light" onClick={() => setShowFilters(true)}>
            Filtros
          </Button>
        }
      />

      <PageContainer>
        <div className="w-full">
          <Table {...pagination} title="Turnos" columns={columns} />
        </div>
      </PageContainer>

      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <DriverShiftsFilters
            onSubmit={async (filters) => setFilters(filters)}
            initialValues={filters}
          />
        </div>
      </Drawer>
    </>
  );
};

export default DriverShiftsPage;
