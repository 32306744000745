import moment from 'moment';
import { Moment } from 'moment';

export interface BillerModel {
  id: number;
  name: string;
  cuit: number;
  isDeleted: boolean;
  photo: string;
  monotributo_start_date: Moment;
  ingresos_brutos_start_date: Moment;
  mail: string | null;
  bankAccount: {
    bankName: string | null;
    holderName: string | null;
    cbu: string | null;
    accountNumber: string | null;
    accountAlias: string | null;
  };
}

export interface BillerCRUD
  extends Omit<BillerModel, 'id' | 'isDeleted' | 'photo'> {
  id?: number;
  photo: File | string;
}

export const billerBuilder = {
  fromBackEnd: (x): BillerModel => {
    return {
      photo: x.photo,
      mail: x.mail,
      monotributo_start_date: moment(x.monotributo_start_date),
      ingresos_brutos_start_date: moment(x.ingresos_brutos_start_date),
      id: x.id,
      name: x.name,
      cuit: x.cuit,
      isDeleted: x.isDeleted,
      bankAccount: {
        bankName: x.bankName,
        holderName: x.accountOwnerName,
        cbu: x.cbu,
        accountNumber: x.accountNumber,
        accountAlias: x.alias
      }
    };
  },
  toBackEnd: (x: BillerCRUD) => {
    return {
      photo: x.photo,
      monotributo_start_date: x.monotributo_start_date.format('YYYY-MM-DD'),
      ingresos_brutos_start_date:
        x.ingresos_brutos_start_date.format('YYYY-MM-DD'),
      name: x.name,
      mail: x.mail,
      cuit: x.cuit,
      bank_name: x.bankAccount.bankName,
      account_owner_name: x.bankAccount.holderName,
      cbu: x.bankAccount.cbu,
      account_number: x.bankAccount.accountNumber,
      alias: x.bankAccount.accountAlias
    };
  }
};
