import environment from '../../../environment/environment';

export const deleteDraftTravel = async (idTravel: string) => {
  const response = await fetch(
    `${environment.booking}/reserves-drafts/${idTravel}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return response;
};
