import { Dialog, Divider, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Button } from '@vadiun/react-components';
import { DriverRecomended } from '../../types/Driver';
import moment from 'moment';

type Props = {
  onPreAssignDriver: () => void;
  onAssignDriver: () => void;
  onCloseModal: () => void;
  driverSelected: DriverRecomended;
  isLoading: boolean;
  isOpen: boolean;
};

const ConfirmDriverRecomended = ({
  isOpen,
  isLoading,
  onCloseModal,
  onPreAssignDriver,
  onAssignDriver,
  driverSelected
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseModal}
      maxWidth="md"
      className="rounded-xl"
      PaperProps={{
        style: {
          borderRadius: 15
        }
      }}
    >
      <div className="px-4 py-4" style={{ width: 450, overflow: 'hidden' }}>
        <div className="mb-3 flex justify-between">
          <p className="text-lg text-gray-800">Asignación de viaje</p>
          <IconButton
            size="small"
            aria-label="close modal"
            onClick={onCloseModal}
          >
            <ClearIcon fontSize="medium" />
          </IconButton>
        </div>
        <div className="mt-8 mb-3 flex gap-8">
          <div className="flex flex-col gap-1">
            <p className="text-gray-500">Móvil</p>
            <p
              className={
                driverSelected.state === 'FREE'
                  ? 'text-green-500'
                  : 'text-red-500'
              }
            >
              {driverSelected?.code}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-gray-500">Chofer</p>
            <p className="text-gray-700">{driverSelected.name}</p>
          </div>
        </div>
        <Divider />
        <div className="mt-3 mb-4 flex gap-8">
          <div className="flex flex-col gap-1">
            <p className="text-gray-500">Distancia</p>
            <p className="text-gray-700">
              {Math.ceil(driverSelected.distance ?? 0)} km
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-gray-500">Plata/hora</p>
            <p className="text-gray-700">
              {'$'}
              {Number(driverSelected.ixh ?? 0).toFixed(0)}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-gray-500">Llegada</p>
            <p className="text-gray-700">
              {' '}
              {moment(driverSelected?.arrival_datetime ?? new Date()).format(
                'HH:mm'
              )}{' '}
              hs
            </p>
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 pt-4">
          <Button onClick={onCloseModal} variant="light" color="gray">
            Cancelar
          </Button>
          <Button
            onClick={onPreAssignDriver}
            variant="light"
            color="blue"
            isLoading={isLoading}
          >
            Pre-asignar
          </Button>
          <Button
            onClick={onAssignDriver}
            variant="contained"
            color="blue"
            isLoading={isLoading}
          >
            Asignar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDriverRecomended;
