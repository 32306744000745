import * as Yup from 'yup';

import { transferReservationBaseLocationFormValidation } from '../../viajeTraslado/forms/base/TransferReservationBaseFormValidation';
import {
  CANALES_DE_VIAJE,
  TIPOS_SERVICIO_VIAJE_TRASLADO
} from 'app/viaje/models';
import { YupShape } from 'shared/models/YupShape';
import { TransferReservationRepetitivePrivateFormType } from './TransferReservationRepetitivePrivateFormType';

export const transferReservationRepetitiveLocationFormValidation =
  transferReservationBaseLocationFormValidation;

export const transferReservationRepetitivePrivateFormValidation =
  (): YupShape<TransferReservationRepetitivePrivateFormType> =>
    Yup.object().shape({
      cliente: Yup.mixed().required('El cliente es obligatorio'),
      canalIngreso: Yup.string()
        .required('El canal de ingreso es obligatorio')
        .oneOf(CANALES_DE_VIAJE),
      prioridad: Yup.boolean(),
      hasOutSite: Yup.boolean(),
      tipoServicio: Yup.mixed()
        .required('El tipo de servicio es obligatorio')
        .oneOf(TIPOS_SERVICIO_VIAJE_TRASLADO),
      puntos: Yup.array()
        .min(2, 'Debe haber al menos dos puntos')
        .of(transferReservationRepetitiveLocationFormValidation),
      fechaYHoraDeLlegada: Yup.mixed().required(
        'La fecha de llegada es requerida'
      ),
      minutosPreviosASalida: Yup.string().required(
        'Los minutos de salida son requeridos'
      ),
      repetitionFinishDate: Yup.mixed().required(
        'La fecha de terminación es requerida'
      ),
      repetitionInitDate: Yup.mixed().required(
        'La fecha de inicio es requerida'
      ),
      repeatOnFriday: Yup.boolean(),
      repeatOnMonday: Yup.boolean(),
      repeatOnSaturday: Yup.boolean(),
      repeatOnSunday: Yup.boolean(),
      repeatOnThursday: Yup.boolean(),
      repeatOnTuesday: Yup.boolean(),
      repeatOnWednesday: Yup.boolean(),
      observacionCoordinacion: Yup.string().nullable(),
      pay_with_cash: Yup.boolean()
    });
