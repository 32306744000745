import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { Card } from 'shared/components';
import { BarStackedChartComponent } from '../components/BarStackedChartComponent';
import { useTransferReservationStatisticsRepository } from '../services/transferReservationStatisticsRepository';

export const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

export const TransferReservationPerDayPage = () => {
  const repo = useTransferReservationStatisticsRepository();
  const perDayQuery = useSuperQuery(repo.perDay);

  if (perDayQuery.data === undefined) {
    return <p>Cargando...</p>;
  }

  const rawData = perDayQuery.data.perDay;
  const bars = rawData.map((data) => ({
    label: data.name,
    stack: data.amounts.map((amount) => ({
      label: amount.entryChannel,
      value: amount.amount
    }))
  }));
  return (
    <div>
      <PageHeader title="Reservas por día" />
      <PageContainer>
        <div className="w-full">
          <Card>
            <div className="p-8">
              <BarStackedChartComponent bars={bars} />
            </div>
          </Card>
        </div>
      </PageContainer>
    </div>
  );
};
