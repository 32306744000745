import { domicilioBuilder } from 'app/domicilio/models/DomicilioModel';
import {
  locationBuilder,
  passangerBuilder,
  ViajeCanalesDeIngreso,
  ViajeTrasladoPunto,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import moment, { Moment } from 'moment';
import { TransferReservationBaseLocationFormType } from '../../viajeTraslado/forms/base/TransferReservationBaseFormTypes';

export interface TransferReservationRepetitivePrivateModel {
  advanceHours: number;
  client: { id: number; name: string; hasPriority: boolean };
  client_type: string;
  service_type: ViajeTrasladoTipoServicio;
  coordination_observation: string | null;
  entry_channel: ViajeCanalesDeIngreso;
  has_out_site: boolean;
  has_priority: boolean;
  id: number;
  platform_id: number | null;
  puntos: ViajeTrasladoPunto[];
  to_date: Moment;
  from_date: Moment;
  monday: boolean;
  saturday: boolean;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  friday: boolean;
  reservation_hours: number;
  reservation_minutes: number;
  fixed_price_name: string;
  pay_with_cash: boolean;
}

export interface ReservaTrasladoRepetitivePrivateCreateModel {
  clienteId: number;
  canalIngreso: ViajeCanalesDeIngreso;
  prioridad: boolean;
  tipoServicio: ViajeTrasladoTipoServicio;
  observacionCoordinacion: string | null;
  hasOutSite: boolean;
  pay_with_cash: boolean;
  puntos: TransferReservationBaseLocationFormType[];
  fechaYHoraDeLlegada: Moment;
  minutosPreviosASalida: Moment;
  repeatOnMonday: boolean;
  repeatOnTuesday: boolean;
  repeatOnWednesday: boolean;
  repeatOnThursday: boolean;
  repeatOnFriday: boolean;
  repeatOnSaturday: boolean;
  repeatOnSunday: boolean;
  repetitionFinishDate: Moment;
  repetitionInitDate: Moment;
}

export const transferReservationRepetitivePrivateBuilder = {
  fromBackEnd: (
    reservation: any
  ): TransferReservationRepetitivePrivateModel => ({
    ...reservation,
    to_date: moment(reservation.to_date),
    from_date: moment(reservation.from_date),
    monday: Boolean(reservation.monday),
    saturday: Boolean(reservation.saturday),
    sunday: Boolean(reservation.sunday),
    thursday: Boolean(reservation.thursday),
    tuesday: Boolean(reservation.tuesday),
    wednesday: Boolean(reservation.wednesday),
    friday: Boolean(reservation.friday),
    puntos: reservation.locations.map((l) => ({
      ...locationBuilder.fromBackEnd(l),
      minutosEspera: l.minutes_waiting
    }))
  }),
  toBackEnd: (x: ReservaTrasladoRepetitivePrivateCreateModel) => ({
    has_out_site: x.hasOutSite,
    entry_channel: x.canalIngreso,
    has_priority: x.prioridad,
    service_type: x.tipoServicio,
    coordination_observation: x.observacionCoordinacion,
    reservation_datetime:
      x.fechaYHoraDeLlegada.format('YYYY-MM-DD HH:mm') + ':00',
    advance_hours:
      x.fechaYHoraDeLlegada.diff(x.minutosPreviosASalida, 'minutes') / 60,
    hasOutSite: x.hasOutSite,
    pay_with_cash: x.pay_with_cash,
    locations: x.puntos.map((point) => ({
      address: domicilioBuilder.toBackEnd(point.domicilio),
      observation: point.observacion,
      minutes_waiting: point.espera,
      passengers: point.pasajeros.map(passangerBuilder.toBackEnd)
    })),
    client_type: 'Private Client',
    client_id: x.clienteId,
    monday: x.repeatOnMonday,
    tuesday: x.repeatOnTuesday,
    wednesday: x.repeatOnWednesday,
    thursday: x.repeatOnThursday,
    friday: x.repeatOnFriday,
    saturday: x.repeatOnSaturday,
    sunday: x.repeatOnSunday,
    reservation_hours: x.fechaYHoraDeLlegada.hours(),
    reservation_minutes: x.fechaYHoraDeLlegada.minutes(),
    from_date: x.repetitionInitDate.format('YYYY-MM-DD'),
    to_date: x.repetitionFinishDate.format('YYYY-MM-DD')
  })
};
