import { TextField } from '@material-ui/core';
import { ComponentProps, useEffect, useState } from 'react';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete';
import {
  createDomicilioGoogleAutocompleteText, DomicilioGoogle
} from '../models/DomicilioGoogle';

interface Props {
  value: DomicilioGoogle;
  onChange: (domicilio: DomicilioGoogle) => void;
  autocompleteFloating?: boolean;
}

const buscarTipoEnComponente = (tipo, componentes) => {
  return componentes.find((c) => c.types.includes(tipo))?.long_name;
};
const getAddresFromComponents = (
  componentes: any,
  types: string[],
  addressText: string
): Omit<DomicilioGoogle, 'latitud' | 'longitud' | 'text'> => {
  let streetNameAndNumber: { calle: string; numero: null | number } = {
    calle: '',
    numero: null
  };
  const isStreetAddress = types.some((x) => x === 'street_address');
  const isPremise = types.some((x) => x === 'premise');
  const isEstablishment = types.some((x) => x === 'establishment');
  if (isStreetAddress || isPremise) {
    streetNameAndNumber = {
      calle: buscarTipoEnComponente('route', componentes),
      numero: buscarTipoEnComponente('street_number', componentes)
    };
  }
  if (isEstablishment) {
    streetNameAndNumber = {
      calle:
        buscarTipoEnComponente('establishment', componentes) ||
        addressText.split(',')[0],
      numero: null
    };
  }
  if (!isStreetAddress && !isPremise && !isEstablishment) {
    const matchedType = types
      .map((type) => buscarTipoEnComponente(type, componentes))
      .find((component) => component !== undefined);
    streetNameAndNumber = {
      calle: matchedType || addressText.split(',')[0],
      numero: null
    };
  }
  const result = {
    ...streetNameAndNumber,
    partido: buscarTipoEnComponente('administrative_area_level_2', componentes),
    localidad:
      buscarTipoEnComponente('locality', componentes) ||
      buscarTipoEnComponente('sublocality', componentes),
    provincia: buscarTipoEnComponente(
      'administrative_area_level_1',
      componentes
    ),
    pais: buscarTipoEnComponente('country', componentes),
    codigoPostal: buscarTipoEnComponente('postal_code', componentes)
  };
  return result;
};

function GooglePlacesInput(
  props: Omit<ComponentProps<typeof TextField>, 'value' | 'onChange'> & Props
) {
  const [text, setText] = useState('');

  useEffect(() => {
    const domicilioText =
      props.value.text || createDomicilioGoogleAutocompleteText(props.value);
    if (!!domicilioText) {
      setText(domicilioText);
    }
  }, [props]);

  const handleChange = (e: string) => {
    props.onChange({
      ...domicilioGoogleInitialValues,
      text: e
    });
    setText(e);
  };

  const handleSelect = async (address: string, placeId: number) => {
    const results = await geocodeByPlaceId(placeId);
    const latLng = await getLatLng(results[0]);
    const domWithoutLatLng = getAddresFromComponents(
      results[0].address_components,
      results[0].types,
      address
    );
    const text = address;
    setText(text);
    const _domicilio: DomicilioGoogle = {
      ...domWithoutLatLng,
      latitud: latLng.lat,
      longitud: latLng.lng,
      text
    };
    props.onChange(_domicilio);
  };

  const { value, onChange, ...textInputProps } = props;
  return (
    <PlacesAutocomplete
      value={text}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: {
          country: ['ar']
        }
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={props.className}>
          <TextField
            {...getInputProps({
              placeholder: textInputProps.placeholder,
              fullWidth: textInputProps.fullWidth,
              variant: textInputProps.variant,
              label: textInputProps.label,
              ...textInputProps
            })}
          />

          {suggestions.length > 0 && (
            <div className={`border-2 border-gray-100 p-1.5 ${props.autocompleteFloating ? 'absolute bg-white' : ''}`}>
              {loading && <div>Cargando...</div>}
              {suggestions.map((suggestion) => {
                return (
                  <div
                    style={props.style}
                    key={suggestion.key}
                    {...getSuggestionItemProps(suggestion, {
                      className:
                        'p-1.5 bg-white hover:bg-gray-100 cursor-pointer'
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export const domicilioGoogleInitialValues: DomicilioGoogle = Object.freeze({
  latitud: 0,
  longitud: 0,
  calle: '',
  numero: '',
  partido: '',
  localidad: '',
  provincia: '',
  pais: '',
  codigoPostal: '',
  text: ''
});

export default GooglePlacesInput;
