import { httpClient } from 'shared/services/http/httpClient';
import { applicantBuilder } from '../models/ApplicantModel';

const useApplicant = () => {
  const getAll = async (idClient: number) => {
    const res = await httpClient.get(`business-clients/${idClient}/applicants`);
    return res.map(applicantBuilder.fromBackend);
  };

  return {
    getAll
  };
};

export default useApplicant;
