import { Bank } from '../models/Bank';
import Card from 'shared/components/Card/Card';
import React from 'react';
import BillerForm, { BillerFormType } from './BillerForm/BillerForm';

interface Props {
  create: (proveedorValues: BillerFormType, reset) => void;
  banks: Bank[];
}
function ProveedorCreate({ create, banks }: Props) {
  return (
    <Card title="Nuevo Facturador" className="w-full">
      <div className="p-8">
        <BillerForm onSubmit={create} banks={banks} />
      </div>
    </Card>
  );
}

export default ProveedorCreate;
