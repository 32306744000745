import { CompletionStatus } from '../stepper/Stepper';

export const getStepperCompletion = (cps: CompletionStatus) => {
  if (cps === CompletionStatus.MAPPING) {
    return 1;
  }

  if (cps === CompletionStatus.CHECKING) {
    return 2;
  }

  if (cps === CompletionStatus.COMPLETE) {
    return 3;
  }
  return 0;
};
