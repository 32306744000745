import moment, { Moment } from 'moment';
import { titularBuilder, TitularModel } from '../titulares/models/Titular';
import { VehiculoCombustible } from './VehiculoCombustible';
import { VehiculoTipo } from './VehiculoTipo';
import { VehiculoCategoria } from './VehiculoCategoria';
import { VehiculoCompaniaSeguro } from './VehiculoCompaniaSeguro';
import { VehiculoMunicipio } from './VehiculoMunicipio';
import {
  vehiculoModeloBackModelToModel,
  VehiculoModeloModel
} from '../modeloVehiculo/models/VehiculoModelo';
import { VehiculoColor } from './VehiculoColor';
import { jsonToFormData } from 'shared/helpers/jsonToFormData';

export interface VehiculoPartialModel {
  id: number;
  foto: string;
  modelo: VehiculoModeloModel;
  categoria: VehiculoCategoria;
  tieneMicrocentro: boolean;
  isDeleted: boolean;
  patente: string;
}

export interface VehiculoModel {
  id: number;
  fechaIngreso: Moment;
  foto: string;
  patente: string;
  titular: TitularModel;
  modelo: VehiculoModeloModel;
  color: VehiculoColor;
  anio: number;
  chasis: string;
  combustible: VehiculoCombustible;
  tipo: VehiculoTipo;
  categoria: VehiculoCategoria;
  municipio: VehiculoMunicipio;
  desvinculacion: VehiculoDesvinculacion;
  carbono: number | null;
  vtv: {
    fechaVencimiento: Moment;
    foto: string;
  };
  habilitacion: {
    fechaVencimiento: Moment;
    foto: string;
  };
  seguro: {
    compania: VehiculoCompaniaSeguro;
    poliza: {
      fechaVencimiento: Moment;
      foto: string;
    };
    cuota: {
      fechaVencimiento: Moment;
      foto: File | string;
    };
  };
  microcentro: {
    fechaVencimiento: Moment;
    foto: string;
  } | null;
  isDeleted: boolean;
}

export interface VehiculoDesvinculacion {
  fecha: Moment | null;
  observacion: string | null;
}

export interface VehiculoCRUD
  extends Omit<
    VehiculoModel,
    | 'desvinculacion'
    | 'vtv'
    | 'habilitacion'
    | 'seguro'
    | 'microcentro'
    | 'id'
    | 'foto'
    | 'isDeleted'
    | 'carbono'
  > {
  id?: number;
  foto: File | string;
  vtv: Omit<VehiculoModel['vtv'], 'foto'> & {
    foto: File | string;
  };
  habilitacion: Omit<VehiculoModel['habilitacion'], 'foto'> & {
    foto: File | string;
  };
  seguro: Omit<VehiculoModel['seguro'], 'poliza' | 'cuota'> & {
    poliza: Omit<VehiculoModel['seguro']['poliza'], 'foto'> & {
      foto: File | string;
    };
    cuota: Omit<VehiculoModel['seguro']['cuota'], 'foto'> & {
      foto: File | string;
    };
  };
  microcentro: {
    foto: File | string;
    fechaVencimiento: Moment;
  } | null;
  carbono: string | number;
}

export const vehiculoBuilder = {
  toBackEnd: (vehiculo: VehiculoCRUD): FormData => {
    const value = {
      photo: vehiculo.foto,
      patent: vehiculo.patente,
      owner_id: vehiculo.titular.id,
      model_id: vehiculo.modelo.id,
      color: vehiculo.color,
      year: vehiculo.anio,
      chassis: vehiculo.chasis,
      fuel: vehiculo.combustible,
      type: vehiculo.tipo,
      category: vehiculo.categoria,
      admission_date: vehiculo.fechaIngreso.format('YYYY-MM-DD'),
      municipality: vehiculo.municipio,
      carbon: vehiculo.carbono,
      qualification: {
        expiration_date:
          vehiculo.habilitacion.fechaVencimiento.format('YYYY-MM-DD'),
        photo: vehiculo.habilitacion.foto
      },
      downtown_permit: {
        expiration_date: vehiculo.microcentro
          ? vehiculo.microcentro.fechaVencimiento?.format('YYYY-MM-DD')
          : null,
        photo: vehiculo.microcentro ? vehiculo.microcentro.foto : null
      },
      vtv: {
        expiration_date: vehiculo.vtv.fechaVencimiento.format('YYYY-MM-DD'),
        photo: vehiculo.vtv.foto
      },
      insurance: {
        policy_expiration_date:
          vehiculo.seguro.poliza.fechaVencimiento.format('YYYY-MM-DD'),
        policy_photo: vehiculo.seguro.poliza.foto,
        company_name: vehiculo.seguro.compania,
        fee_expiration_date:
          vehiculo.seguro.cuota.fechaVencimiento.format('YYYY-MM-DD'),
        payment_receipt_photo: vehiculo.seguro.cuota.foto
      }
    };
    return jsonToFormData(value);
  },
  fromBackEnd: (vehiculo): VehiculoModel => {
    return {
      id: vehiculo.id,
      fechaIngreso: moment(vehiculo.admissionDate),
      foto: vehiculo.photo,
      patente: vehiculo.patent,
      titular: titularBuilder.fromBackEnd(vehiculo.owner),
      modelo: vehiculoModeloBackModelToModel(vehiculo.model),
      color: vehiculo.color,
      anio: vehiculo.year,
      chasis: vehiculo.chassis,
      combustible: vehiculo.fuel,
      tipo: vehiculo.type,
      categoria: vehiculo.category,
      municipio: vehiculo.municipality,
      desvinculacion: {
        fecha: vehiculo.eliminationDate
          ? moment(vehiculo.eliminationDate)
          : null,
        observacion: vehiculo.eliminationObservation
      },
      carbono: vehiculo.carbon,
      vtv: {
        fechaVencimiento: moment(vehiculo.vtv.expirationDate),
        foto: vehiculo.vtv.photo
      },
      habilitacion: {
        fechaVencimiento: moment(vehiculo.qualification.expirationDate),
        foto: vehiculo.qualification.photo
      },
      seguro: {
        compania: vehiculo.insurance.companyName,
        poliza: {
          fechaVencimiento: moment(vehiculo.insurance.policyExpirationDate),
          foto: vehiculo.insurance.policyPhoto
        },
        cuota: {
          fechaVencimiento: moment(vehiculo.insurance.feeExpirationDate),
          foto: vehiculo.insurance.paymentReceiptPhoto
        }
      },
      microcentro:
        vehiculo.downtownPermit !== null
          ? {
              fechaVencimiento: moment(vehiculo.downtownPermit.expirationDate),
              foto: vehiculo.downtownPermit.photo
            }
          : null,
      isDeleted: vehiculo.isDeleted
    };
  }
};

export const vehiculoPartialBuilder = {
  fromBackEnd: (vehiculo): VehiculoPartialModel => {
    return {
      id: vehiculo.id,
      foto: vehiculo.photo,
      modelo: vehiculoModeloBackModelToModel(vehiculo.model),
      categoria: vehiculo.category,
      tieneMicrocentro: vehiculo.has_downtown_permit,
      isDeleted: vehiculo.isDeleted,
      patente: vehiculo.patent
    };
  }
};
