import {
  applicantBuilder,
  ApplicantModel
} from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import {
  authorizedBuilder,
  AuthorizedModel
} from 'app/client/clientBusiness/Authorized/models/Authorized';
import {
  locationBuilder,
  ViajeCanalesDeIngreso,
  viajeTrasladoBuilderLegacyDontUse,
  ViajeTrasladoCreateModelLegacyDontUse,
  ViajeTrasladoPunto,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import moment, { Moment } from 'moment';

export interface TransferReservationRepetitiveModel {
  advanceHours: number;
  advance_hours: number;
  client: { id: number; name: string; hasPriority: boolean };
  client_type: string;
  service_type: ViajeTrasladoTipoServicio;
  coordination_observation: string | null;
  entry_channel: ViajeCanalesDeIngreso;
  has_out_site: boolean;
  has_priority: boolean;
  id: number;
  platform_id: number | null;
  puntos: ViajeTrasladoPunto[];
  requirements: {
    applicant: ApplicantModel | null;
    authorized: AuthorizedModel | null;
    buyOrder: string;
    costCenter: string;
    subCostCenter: string;
    data1: string;
    data2: string;
    data3: string;
    employeeNumber: string;
    platformApplication: string;
    sector: string;
    tripNumber: string;
  };
  to_date: Moment;
  from_date: Moment;
  monday: boolean;
  saturday: boolean;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  friday: boolean;
  reservation_hours: number;
  reservation_minutes: number;
  fixed_price_name: string;
  pay_with_cash: boolean;
  is_event: boolean;
  name_event?: string;
  customer_price?: number;
  provider_price?: number;
  isLLTPais?: any;
}

export interface ReservaTrasladoRepetitiveCreateModel
  extends ViajeTrasladoCreateModelLegacyDontUse {
  repeatOnMonday: boolean;
  repeatOnTuesday: boolean;
  repeatOnWednesday: boolean;
  repeatOnThursday: boolean;
  repeatOnFriday: boolean;
  repeatOnSaturday: boolean;
  repeatOnSunday: boolean;
  repetitionFinishDate: Moment;
  repetitionInitDate: Moment;
}

export const transferReservationRepetitiveBuilder = {
  fromBackEnd: (reservation: any): TransferReservationRepetitiveModel => ({
    ...reservation,
    to_date: moment(reservation.to_date),
    from_date: moment(reservation.from_date),
    monday: Boolean(reservation.monday),
    saturday: Boolean(reservation.saturday),
    sunday: Boolean(reservation.sunday),
    thursday: Boolean(reservation.thursday),
    tuesday: Boolean(reservation.tuesday),
    wednesday: Boolean(reservation.wednesday),
    friday: Boolean(reservation.friday),
    requirements: {
      ...reservation.requirements,
      applicant:
        reservation.requirements.applicant &&
        applicantBuilder.fromBackend(reservation.requirements.applicant),
      authorized:
        reservation.requirements?.authorized &&
        authorizedBuilder.fromBackEnd(reservation.requirements.authorized)
    },
    puntos: reservation.locations.map((l) => ({
      ...locationBuilder.fromBackEnd(l),
      minutosEspera: l.minutes_waiting
    }))
  }),
  toBackEnd: (x: ReservaTrasladoRepetitiveCreateModel) => ({
    ...viajeTrasladoBuilderLegacyDontUse.toBackEnd(x),
    client_type: 'Business Client',
    client_id: x.cliente!.id,
    monday: x.repeatOnMonday,
    tuesday: x.repeatOnTuesday,
    wednesday: x.repeatOnWednesday,
    thursday: x.repeatOnThursday,
    friday: x.repeatOnFriday,
    saturday: x.repeatOnSaturday,
    sunday: x.repeatOnSunday,
    reservation_hours: x.fechaYHoraDeLlegada.hours(),
    reservation_minutes: x.fechaYHoraDeLlegada.minutes(),
    from_date: x.repetitionInitDate.format('YYYY-MM-DD'),
    to_date: x.repetitionFinishDate.format('YYYY-MM-DD'),
    fixed_price_name: x.fixedRate?.name
  })
};
