import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { AntenaCreate } from 'app/antena/antena/components/AntenaCreate';
import { AntenaEdit } from 'app/antena/antena/components/AntenaEdit';
import {
  antenaFormBuilder,
  AntenaFormType
} from 'app/antena/antena/components/AntenaForm/AntenaFormType';
import { AntenaTable } from 'app/antena/antena/components/AntenaTable';
import { AntenaModel } from 'app/antena/antena/models/Antena';
import useAntenas from 'app/antena/antena/services/AntenaService';
import useAntenaGrupos from 'app/antena/grupo/services/AntenaGrupoService';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';

export function AntenaCrudPage(props: any) {
  const AntenaService = useAntenas();
  const grupoAntenasRepo = useAntenaGrupos();
  const antenasQuery = useSuperQuery(AntenaService.getAntenas, {
    showSpinner: true
  });
  const gruposAntenasQuery = useSuperQuery(grupoAntenasRepo.getAntenaGrupos, {
    showSpinner: true
  });
  const updateAntenaMutation = useSuperMutation(AntenaService.updateAntena);
  const addAntenaMutation = useSuperMutation(AntenaService.addAntena);
  const removeAntenaMutation = useSuperMutation(AntenaService.removeAntena, {
    showSpinner: true
  });
  const restoreAntenaMutation = useSuperMutation(AntenaService.restoreAntena, {
    showSpinner: true
  });
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );
  const [updatingAntena, setEditingAntena] = useState<AntenaModel>();

  async function update(antenaValues: AntenaFormType) {
    antenaValues.id = updatingAntena!.id;
    await updateAntenaMutation.mutate(antenaValues);
    setVisiblePage('table');
    antenasQuery.reload();
  }

  async function create(antenaValues: AntenaFormType) {
    await addAntenaMutation.mutate(antenaValues);
    setVisiblePage('table');
    antenasQuery.reload();
  }

  async function remove(antena: AntenaModel) {
    await removeAntenaMutation.mutate(antena);
    antenasQuery.reload();
  }

  async function restore(antena: AntenaModel) {
    await restoreAntenaMutation.mutate(antena);
    antenasQuery.reload();
  }

  function selectToEdit(antena: AntenaModel) {
    setEditingAntena(antena);
    setVisiblePage('update');
  }
  return (
    <>
      <PageHeader
        title="Antenas"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Antenas'
            : visiblePage === 'create'
            ? 'Nuevo Antena'
            : 'Editar Antena'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Antena
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <AntenaTable
            antenas={antenasQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
          />
        )}

        {visiblePage === 'create' && (
          <AntenaCreate
            create={create}
            grupos={gruposAntenasQuery.data ?? []}
          />
        )}
        {visiblePage === 'update' && updatingAntena && (
          <AntenaEdit
            update={update}
            initialValues={antenaFormBuilder.fromModel(updatingAntena)}
            grupos={gruposAntenasQuery.data ?? []}
          />
        )}
      </PageContainer>
    </>
  );
}
