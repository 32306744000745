import React from 'react';
import PassengerForm from './PassengerForm/PassengerForm';
import { PassengerFormType } from './PassengerForm/PassengerFormik';

interface Props {
  create: (passengerValues: PassengerFormType, reset) => void;
}
function PassengerCreate({ create }: Props) {
  return <PassengerForm onSubmit={create} />;
}

export default PassengerCreate;
