import React, { useRef } from 'react';
import { Button, Modal } from '@vadiun/react-components';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
// import { TextField } from '@material-ui/core';

export type CancelDraftActionType = {
  reason: string;
};

type Props = {
  isOpen: boolean;
  onCloseShowCancelTravel: () => void;
  onCancelTravel: any;
  initialValues: CancelDraftActionType;
};

export const CancelDraftSchema = Yup.object({
  reason: Yup.string()
});

const CancelTravel = ({
  isOpen,
  onCloseShowCancelTravel,
  onCancelTravel,
  initialValues
}: Props) => {
  // const initialValues = {} as any;

  const formRef = useRef<FormikProps<CancelDraftActionType>>(null);

  const onCloseModal = () => {
    formRef.current?.resetForm();
    onCloseShowCancelTravel();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      size="sm"
      title={
        <p className="text-lg text-gray-500">Motivo de cancelación de viaje</p>
      }
      body={
        <div className="mt-4 h-full">
          <Formik<CancelDraftActionType>
            initialValues={{ reason: '' }}
            innerRef={formRef}
            enableReinitialize
            validationSchema={CancelDraftSchema}
            onSubmit={onCancelTravel}
          >
            {({ submitForm, resetForm, setFieldValue, ...formik }) => (
              <Form className="h-full flex flex-col">
                <div>
                  <Field
                    name="reason"
                    className="w-full"
                    component={TextField}
                    label="Ingresa el motivo de cancelación"
                    inputVariant="outlined"
                  />
                </div>
                <div className="mt-6 flex gap-6 justify-end">
                  <Button
                    onClick={onCloseModal}
                    variant="outlined"
                    color="gray"
                  >
                    Cancelar
                  </Button>
                  <Button onClick={submitForm} variant="contained">
                    Ok , confirmar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  );
};

export default CancelTravel;
