import { useSuperMutation } from '@vadiun/react-hooks';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';

import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, Modal, Switch } from '@vadiun/react-components';
import { ClientPrivateForm } from 'app/client/private/private/forms/ClientPrivateForm';
import useClientPrivates from 'app/client/private/private/services/ClientPrivateService';
import {
  TravelEstimationsWithPrice,
  useDistanceAndPriceTransferReservationClientPrivateCalculator
} from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import LocationDetails from 'app/viaje/viajeTraslado/viajeTraslado/components/LocationDetails';
import { useCustomTablePagination } from 'shared/hooks/useCustomTablePagination';
import ReservaTrasladoEstimationDialog from '../../viajeTraslado/components/ReservaTrasladoEstimationDialog';
import useViajesTraslado from '../../viajeTraslado/services/ViajeTrasladoService';
import { ReservaTrasladoRepetitivePrivateCreate } from '../components/ReservaTrasladoRepetitivePrivateCreate';
import { ReservaTrasladoRepetitivePrivateEdit } from '../components/ReservaTrasladoRepetitivePrivateEdit';
import { ReservaTrasladoRepetitiveTable } from '../components/ReservaTrasladoRepetitiveTable';
import {
  TransferReservationRepetitivePrivateFormType,
  transferReservationRepetitivePrivateFormBuilder
} from '../form/TransferReservationRepetitivePrivateFormType';
import { TransferReservationRepetitivePrivateModel } from '../models/TransferReservationRepetitivePrivateModel';
import { useTransferReservationRepetitivePrivateRepository } from '../services/transferReservationRepetitivePrivateRepository';

export const TransferReservationRepetitivePrivatePage = () => {
  const repository = useTransferReservationRepetitivePrivateRepository();
  const ViajeTrasladoService = useViajesTraslado();
  const clientPrivateRepository = useClientPrivates();
  const verifyAction = useVerifyAction();
  const reservaRepetitivePagination = useCustomTablePagination(
    repository.getAll
  );

  const deleteReservationMutation = useSuperMutation(
    ViajeTrasladoService.deleteRepetitiveReservation,
    {
      onSuccess: () => {
        reservaRepetitivePagination.reload();
      }
    }
  );

  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );
  const [isEstimationOpen, setIsEstimationOpen] = useState(false);
  const [distanceAndCost, setDistanceAndCost] = useState<
    TravelEstimationsWithPrice[]
  >([]);

  const addClientPrivateMutation = useSuperMutation(
    clientPrivateRepository.addClientPrivate
  );
  const [isCreatePrivateClientOpen, setIsCreatePrivateClientOpen] =
    useState(false);

  const [editing, setEditing] = useState<
    (TransferReservationRepetitivePrivateFormType & { id: number }) | undefined
  >(undefined);

  const [reservationDetail, setReservationDetail] = useState<{
    isOpen: boolean;
    details: undefined | TransferReservationRepetitivePrivateModel;
  }>({ isOpen: false, details: undefined });

  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientPrivateCalculator();

  const createViaje = useSuperMutation(repository.create);
  const editViaje = useSuperMutation(repository.edit);

  async function createReservationRepetitive(
    trasladoFormValues: TransferReservationRepetitivePrivateFormType
  ) {
    const ViajeTraslado =
      transferReservationRepetitivePrivateFormBuilder.toModel(
        trasladoFormValues
      );
    await createViaje.mutate({
      ...ViajeTraslado,
      repeatOnMonday: trasladoFormValues.repeatOnMonday,
      repeatOnTuesday: trasladoFormValues.repeatOnTuesday,
      repeatOnWednesday: trasladoFormValues.repeatOnWednesday,
      repeatOnThursday: trasladoFormValues.repeatOnThursday,
      repeatOnFriday: trasladoFormValues.repeatOnFriday,
      repeatOnSaturday: trasladoFormValues.repeatOnSaturday,
      repeatOnSunday: trasladoFormValues.repeatOnSunday,
      repetitionFinishDate: trasladoFormValues.repetitionFinishDate,
      repetitionInitDate: trasladoFormValues.repetitionInitDate
    });
    reservaRepetitivePagination.reload();
    setVisiblePage('table');
  }

  async function editReservationRepetitive(
    trasladoFormValues: TransferReservationRepetitivePrivateFormType
  ) {
    await editViaje.mutate({
      ...transferReservationRepetitivePrivateFormBuilder.toModel(
        trasladoFormValues
      ),
      id: editing!.id
    });
    reservaRepetitivePagination.reload();
    setVisiblePage('table');
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationRepetitivePrivateFormType
  ) {
    const estimations = await calculateDistanceAndCost(
      {
        arrivalDate: trasladoFormValues.fechaYHoraDeLlegada,
        hasOutSite: trasladoFormValues.hasOutSite,
        points: trasladoFormValues.puntos.map((p) => ({
          lat: p.domicilio.latitud,
          lng: p.domicilio.longitud,
          minutesWaiting: Number(p.espera)
        })),
        serviceType: trasladoFormValues.tipoServicio
      },
      trasladoFormValues.cliente!.id
    );
    setDistanceAndCost([estimations]);
    setIsEstimationOpen(true);
  }

  async function selectToEdit(
    reservation: TransferReservationRepetitivePrivateModel
  ) {
    const client = await clientPrivateRepository.getClientPrivate(
      reservation.client!.id
    );
    const formReservation =
      transferReservationRepetitivePrivateFormBuilder.toForm(
        reservation,
        client
      );
    setEditing({ ...formReservation, id: reservation.id });
    setVisiblePage('update');
  }

  return (
    <>
      <PageHeader
        title="Reservas repetitivas particulares"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de reservas'
            : visiblePage === 'create'
            ? 'Nueva Reserva'
            : 'Editar Reserva'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear reserva
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />
      <Switch value={visiblePage}>
        <Switch.Case value="table">
          <ReservaTrasladoRepetitiveTable
            tableProps={reservaRepetitivePagination}
            onLocationsDetails={(details) =>
              setReservationDetail({ isOpen: true, details })
            }
            selectToEdit={selectToEdit}
            onDelete={(id) => (
              <Button
                onClick={() =>
                  verifyAction({
                    title: 'Estas por eliminar una reserva!',
                    body: 'Esta accion va a eliminar una reserva. ¿Estas seguro?',
                    onAccept: () => deleteReservationMutation.mutate(id)
                  })
                }
                variant="text"
                shape="circle"
                color="red"
                disabled={deleteReservationMutation.isLoading}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          />
        </Switch.Case>
        <Switch.Case value="create">
          <ReservaTrasladoRepetitivePrivateCreate
            onOpenCreatePrivateClient={() => setIsCreatePrivateClientOpen(true)}
            onSubmit={createReservationRepetitive}
            estimatePrice={estimatePrice}
            getClientesByName={clientPrivateRepository.findByName}
          />
        </Switch.Case>
        <Switch.Case value="update">
          <ReservaTrasladoRepetitivePrivateEdit
            initialValues={editing!}
            onOpenCreatePrivateClient={() => setIsCreatePrivateClientOpen(true)}
            onSubmit={editReservationRepetitive}
            estimatePrice={estimatePrice}
            getClientesByName={clientPrivateRepository.findByName}
          />
        </Switch.Case>
      </Switch>
      {reservationDetail.details ? (
        <Modal
          open={reservationDetail.isOpen}
          onClose={() =>
            setReservationDetail({ isOpen: false, details: undefined })
          }
          size="md"
          title={reservationDetail.details?.client.name}
          body={
            <LocationDetails
              locations={reservationDetail.details.puntos.map((p) => ({
                address: p.domicilio,
                observation: p.observacion ?? '',
                passengers: p.pasajeros.map((p) => ({
                  name: p.nombreCompleto,
                  phone: String(p.telefono)
                }))
              }))}
            />
          }
        />
      ) : null}
      <ReservaTrasladoEstimationDialog
        isOpen={isEstimationOpen}
        handleClose={() => setIsEstimationOpen(false)}
        distanceAndCost={distanceAndCost}
      />
      <Dialog
        open={isCreatePrivateClientOpen}
        onBackdropClick={() => setIsCreatePrivateClientOpen(false)}
      >
        <DialogTitle>Nuevo Cliente Particular</DialogTitle>
        <DialogContent className="mb-4">
          <ClientPrivateForm
            onSubmit={async (values) => {
              await addClientPrivateMutation.mutate(values);
              setIsCreatePrivateClientOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
