import { Button, Modal } from '@vadiun/react-components';

interface PlatformReservationsProps {
  isOpen: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  reservationsId: number[];
  hasValidReservationsSelected: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSendReservationsToPlatform: (reservationsId: number[]) => Promise<void>;
}

const PlatformReservations = ({
  isDisabled,
  reservationsId,
  isOpen,
  isLoading,
  hasValidReservationsSelected,
  onOpen,
  onClose,
  onSendReservationsToPlatform
}: PlatformReservationsProps): JSX.Element => {
  return (
    <>
      <Button
        disabled={isDisabled}
        variant="light"
        color="blue"
        onClick={onOpen}
      >
        Cargar en plataforma
      </Button>
      <Modal
        open={isOpen}
        onClose={onClose}
        size="md"
        body={
          <div className="flex flex-col gap-4">
            <p className="text-lg">
              {hasValidReservationsSelected
                ? '¿Estas seguro que las reservas seleccionadas fueron enviadas a plataforma?'
                : 'Has seleccionado reservas que ya fueron enviadas a plataforma.'}
            </p>
            <p className="text-gray-500">
              {hasValidReservationsSelected
                ? `Reservas seleccionadas: ${reservationsId.join(' , ')}`
                : 'Solo puedes enviar a plataforma reservas que no fueron enviadas anteriormente.'}
            </p>
            <div className="mt-4 flex w-full flex-row justify-end gap-2">
              <Button variant="outlined" color="gray" onClick={onClose}>
                {hasValidReservationsSelected ? 'Cancelar' : 'Salir'}
              </Button>
              {hasValidReservationsSelected && (
                <Button
                  variant="contained"
                  onClick={() => {
                    onSendReservationsToPlatform(reservationsId);
                  }}
                  isLoading={isLoading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        }
      />
    </>
  );
};

export default PlatformReservations;
