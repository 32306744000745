import { Button } from '@vadiun/react-components';
import { Query, useVerifyAction } from '@vadiun/react-hooks-legacy';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import {
  ClientBusinessModel,
  ClientBusinessPartialModel
} from 'app/client/clientBusiness/models/ClientBusiness';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProps
} from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import {
  KeyboardDatePicker,
  KeyboardTimePicker
} from 'formik-material-ui-pickers';
import { useEffect, useMemo, useRef } from 'react';
import { StaticMap } from 'shared/components';
import { TransferReservationLocationFormModal } from '../../viajeTraslado/components/TransferReservationLocationFormModal';
import { TransferReservationBaseForm } from '../../viajeTraslado/forms/base/TransferReservationBaseForm';
import { createTransferReservationRepetitiveInitalValues } from './TransferReservationRepetitiveFormInitialValues';
import { TransferReservationRepetitiveFormType } from './TransferReservationRepetitiveFormType';
import { transferReservationRepetitiveFormValidation } from './TransferReservationRepetitiveFormValidation';

interface Props {
  initialValues?: TransferReservationRepetitiveFormType;
  onSubmit: (
    viajeFormValues: TransferReservationRepetitiveFormType,
    reset
  ) => void;
  getClientesByName: (name: string) => Promise<ClientBusinessPartialModel[]>;
  onClienteSelect: (cliente: ClientBusinessPartialModel) => void;
  solicitantes: ApplicantModel[];
  autorizadosQuery: Query<AuthorizedModel[]>;
  pasajeros: PassengerModel[];
  clientBusiness: ClientBusinessModel | undefined;
  fixedRates: RateModel['categories'][0]['fixedPrices'];
  onServiceTypeSelect: (type: ViajeTrasladoTipoServicio) => void;
  onCalculateDistanceAndCost: (
    viajeFormValues: TransferReservationRepetitiveFormType
  ) => void;
}

export function TransferReservationRepetitiveForm({
  initialValues: _initialValues,
  onSubmit,
  getClientesByName,
  solicitantes,
  autorizadosQuery,
  pasajeros,
  onClienteSelect,
  clientBusiness,
  onServiceTypeSelect,
  onCalculateDistanceAndCost,
  fixedRates
}: Props) {
  const initialValues = useMemo(
    () => _initialValues ?? createTransferReservationRepetitiveInitalValues(),
    [_initialValues]
  );
  const formRef =
    useRef<FormikProps<TransferReservationRepetitiveFormType>>(null);
  const firstLoad = useRef(true);
  const verifyAction = useVerifyAction();

  useEffect(() => {
    if (clientBusiness === undefined) return;
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    formRef.current?.setFieldValue('prioridad', clientBusiness.hasPriority);
    formRef.current?.setFieldValue('autorizado', undefined);
  }, [clientBusiness]);

  return (
    <Formik<TransferReservationRepetitiveFormType>
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={transferReservationRepetitiveFormValidation(
        clientBusiness
      )}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="w-full">
          <TransferReservationBaseForm
            {...formik}
            getClientesByName={getClientesByName}
            solicitantes={solicitantes}
            autorizadosQuery={autorizadosQuery}
            onClienteSelect={onClienteSelect}
            clientBusiness={clientBusiness}
            onServiceTypeSelect={onServiceTypeSelect}
            fixedRates={fixedRates}
          />
          <div className="my-8 flex space-x-8">
            <div className="flex-grow">
              <div className="flex space-x-8">
                <Field
                  component={KeyboardTimePicker}
                  label="Hora de llegada"
                  name={`fechaYHoraDeLlegada`}
                  ampm={false}
                  inputVariant="outlined"
                  margin="normal"
                  className="flex-1"
                  format="HH:mm"
                />
                <Field
                  component={KeyboardTimePicker}
                  label="Tiempo previo salida"
                  name={`minutosPreviosASalida`}
                  ampm={false}
                  inputVariant="outlined"
                  margin="normal"
                  className="flex-1"
                  format="HH:mm"
                />
              </div>

              <FieldArray
                name={`puntos`}
                render={() => (
                  <TransferReservationLocationFormModal
                    pasajeros={pasajeros}
                    puntos={formik.values.puntos}
                    onEditPuntos={(puntos) =>
                      formik.setFieldValue(`puntos`, puntos)
                    }
                  />
                )}
              />
              <ErrorMessage name={`puntos`}>
                {(msg) => (
                  <p className="mt-4 text-sm text-red-500">
                    {JSON.stringify(msg)}
                  </p>
                )}
              </ErrorMessage>
            </div>
            <div
              className={`w-1/4 transition-all delay-300 duration-300 ease-in-out hover:w-2/5 ${
                formik.values.puntos.length === 0 ? 'opacity-0' : 'opactiy-1'
              }`}
            >
              <StaticMap
                markers={formik.values.puntos.map((punto) => ({
                  lat: punto.domicilio.latitud,
                  lng: punto.domicilio.longitud
                }))}
                size="600x600"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 border-b-2 border-t-2  border-dashed py-8">
            <div className="col-span-3">
              <Field
                component={KeyboardDatePicker}
                label="Fecha de Inicio"
                name={`repetitionInitDate`}
                ampm={false}
                inputVariant="outlined"
                margin="normal"
                className="flex-1"
                format="DD/MM/YYYY"
              />
            </div>
            <div className="col-span-3">
              <Field
                component={KeyboardDatePicker}
                label="Fecha de Finalización"
                name={`repetitionFinishDate`}
                ampm={false}
                inputVariant="outlined"
                margin="normal"
                className="flex-1"
                format="DD/MM/YYYY"
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnMonday"
                color="primary"
                Label={{ label: 'Lunes' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnTuesday"
                color="primary"
                Label={{ label: 'Martes' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnWednesday"
                color="primary"
                Label={{ label: 'Miercoles' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnThursday"
                color="primary"
                Label={{ label: 'Jueves' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnFriday"
                color="primary"
                Label={{ label: 'Viernes' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnSaturday"
                color="primary"
                Label={{ label: 'Sabado' }}
                fullWidth
              />
            </div>
            <div className="col-span-2">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="repeatOnSunday"
                color="primary"
                Label={{ label: 'Domingo' }}
                fullWidth
              />
            </div>
          </div>

          <div className="mt-8 flex justify-between">
            <Button
              variant="contained"
              color="primary"
              disabled={formik.values.puntos.length < 2}
              onClick={() => onCalculateDistanceAndCost(formik.values)}
            >
              Calcular costos y distancias
            </Button>

            <Button
              variant="contained"
              color="green"
              className="ml-auto"
              onClick={formik.submitForm}
            >
              Guardar Viaje
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
