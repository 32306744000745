import { Line } from 'react-chartjs-2';
import { withColorLine } from './chartHelper';

export const options = {
  responsive: true,
  maintainAspectRatio: false
};

interface Props {
  lines: {
    label: string;
    values: { label: string; value: number }[];
  }[];
  labels: string[];
}

export const LineChartComponent = ({ lines, labels }: Props) => {
  const linesOrganized = lines.map((line) => ({
    label: line.label,
    value: line.values.map((value) => value.value)
  }));
  if (lines.length === 0) {
    return null;
  }
  const data = {
    labels,
    datasets: withColorLine<'bar'>(linesOrganized)
  };

  return <Line options={options} data={data} />;
};
