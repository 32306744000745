export interface VehiculoFabricanteModel {
  id: number;
  isDeleted: boolean;
  nombre: string;
}

export interface VehiculoFabricanteCRUD
  extends Omit<VehiculoFabricanteModel, 'id' | 'isDeleted'> {
  id?: number;
}

export interface VehiculoFabricanteBackModel {
  id: number;
  name: string;
  isDeleted: boolean;
}

export function fabricanteModelToBack(fabricante: VehiculoFabricanteCRUD) {
  return {
    id: fabricante.id,
    name: fabricante.nombre
  };
}

export function fabricanteBackModelToModel(
  fabricante: VehiculoFabricanteBackModel
): VehiculoFabricanteModel {
  return {
    id: fabricante.id,
    nombre: fabricante.name,
    isDeleted: fabricante.isDeleted
  };
}
