import { FunctionComponent, useCallback } from 'react';
import { useExport } from '../hooks/useExport';
import { Button } from '@vadiun/react-components';
import { CircularProgress } from '@material-ui/core';

type Props = {
  filters: Record<string, any>;
};

const DownloadTravelsXlsx: FunctionComponent<Props> = ({ filters }) => {
  const { getExportData, isLoading } = useExport();

  const onDownloadFile = useCallback(async () => {
    const response = await getExportData(filters);
    const arrayBuffer = Buffer.from(response.data);
    const blob = new Blob([arrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'listado-reservas.xlsx');
    document.body.appendChild(link);
    link.click();
  }, [filters, getExportData]);

  return (
    <Button
      variant="light"
      color="green"
      disabled={isLoading}
      onClick={onDownloadFile}
    >
      {isLoading ? (
        <CircularProgress size="10px" color="primary" />
      ) : (
        'Imprimir'
      )}
    </Button>
  );
};

export default DownloadTravelsXlsx;
