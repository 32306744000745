import { transferReservationPartialBuilder } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPartial';
import { Moment } from 'moment';
import { LaravelPaginated } from 'shared/hooks/useCustomTablePagination';
import { httpClient } from 'shared/services/http/httpClient';
import { DriverSettlementListFiltersType } from '../components/DriverSettlementsListFiltersComponent';
import {
  driverSettlementBuilder,
  DriverSettlementModel
} from '../models/DriverSettlementModel';
import { DriverSettlementMovimientoModel } from '../models/DriverSettlementMovimientoModel';
import { DriverTransferReservationsWithPricesAndResume } from '../models/DriverTransferReservationsWithSettlementInfo';
export const useDriverSettlementRepository = () => {
  const createBatch = (args: DriverSettlementCreateBatchType): Promise<any> =>
    httpClient.post(
      'driver-settlements/batch',
      {
        to_date: args.date_to.format('YYYY-MM-DD'),
        driver_ids: args.driver_ids
      },
      {
        responseType: 'blob'
      }
    );

  const getAll = async (
    params: string,
    filters: DriverSettlementListFiltersType
  ): Promise<LaravelPaginated<DriverSettlementModel>> => {
    const filterParams: string[] = [];
    if (filters.biller) filterParams.push(`biller_name=${filters.biller}`);
    if (filters.dateFrom)
      filterParams.push(`from=${filters.dateFrom.format('YYYY-MM-DD')}`);
    if (filters.dateTo)
      filterParams.push(`to=${filters.dateTo.format('YYYY-MM-DD')}`);
    if (filters.driverCode)
      filterParams.push(`driver_code=${filters.driverCode}`);

    const res = await httpClient.get<LaravelPaginated<any>>(
      `driver-settlements?${params}&${filterParams.join('&')}`
    );
    return {
      ...res,
      data: res.data.map((r) => driverSettlementBuilder.fromBackEnd(r))
    };
  };

  const printAll = async (
    filters: DriverSettlementListFiltersType
  ): Promise<LaravelPaginated<DriverSettlementModel>> => {
    const filterParams: string[] = [];
    if (filters.biller) filterParams.push(`biller_name=${filters.biller}`);
    if (filters.dateFrom)
      filterParams.push(`from=${filters.dateFrom.format('YYYY-MM-DD')}`);
    if (filters.dateTo)
      filterParams.push(`to=${filters.dateTo.format('YYYY-MM-DD')}`);
    if (filters.driverCode)
      filterParams.push(`driver_code=${filters.driverCode}`);

    return httpClient.get(
      `driver-settlement/export?${filterParams.join('&')}`,
      {
        responseType: 'blob'
      }
    );
  };

  const getDriverSettlementDetail = async (
    id: string | number,
    driverCode: string | number
  ) => {
    const res = await httpClient.get<LaravelPaginated<any>>(
      `driver-settlements?driver_code=${driverCode}`
    );
    const settlement = res.data.find((r) => r.id === Number(id));
    if (settlement === undefined) {
      throw new Error(
        `No settlement with id ${id} was found to the driver code ${driverCode}`
      );
    }
    return driverSettlementBuilder.fromBackEnd(settlement);
  };

  const get = async (
    id: number
  ): Promise<DriverTransferReservationsWithPricesAndResume> => {
    const res = await httpClient.get(
      `driver-settlements/transfer-reservations?driver_settlement_id=${id}`
    );
    return {
      data: res.data.map(transferReservationPartialBuilder.fromBackEnd),
      resumen: res.resumen
    };
  };
  //

  const getPendingSettlement = async (filters?: {
    dateFrom: Moment;
    dateTo: Moment;
  }): Promise<DriverTransferReservationsWithPricesAndResume> => {
    const params = new URLSearchParams();
    if (filters) {
      params.append('from', filters.dateFrom.format('YYYY-MM-DD'));
      params.append('to', filters.dateTo.format('YYYY-MM-DD'));
    }

    const res = await httpClient.get(
      `transfer-reservations/pending-driver-settlement?` + params
    );
    return {
      data: res.data.map(transferReservationPartialBuilder.fromBackEnd),
      resumen: res.resumen
    };
  };

  const getPendingSettlementCoordinator = async (filters: {
    from: Moment;
    to: Moment;
    driver_id: number | null;
  }): Promise<DriverTransferReservationsWithPricesAndResume> => {
    const filterParams = {
      from: filters.from.format('YYYY-MM-DD'),
      to: filters.to.format('YYYY-MM-DD'),
      driver_id: filters.driver_id
    };
    const paramsString = Object.entries(filterParams)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const res = await httpClient.get(
      `transfer-reservations/pending-driver-settlement?${paramsString}`
    );
    return {
      data: res.data.map(transferReservationPartialBuilder.fromBackEnd),
      resumen: res.resumen
    };
  };

  const getSettlementMovimientos = async (
    settlementId: number
  ): Promise<DriverSettlementMovimientoModel[]> => {
    const res = await httpClient.get(
      `driver-settlements/${settlementId}/movimientos`
    );
    return res;
  };

  const createSettlementMovimientos = async (
    params: DriverSettlementMovimientoModel & { settlementId: number }
  ) => {
    const res = await httpClient.post(
      `driver-settlements/${params.settlementId}/movimientos`,
      params
    );
    return res;
  };

  return {
    createBatch,
    getAll,
    get,
    getPendingSettlement,
    getPendingSettlementCoordinator,
    getDriverSettlementDetail,
    printAll,
    getSettlementMovimientos,
    createSettlementMovimientos
  };
};

interface DriverSettlementCreateBatchType {
  driver_ids: number[];
  date_to: Moment;
}
