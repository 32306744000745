import {
  faCloudDownloadAlt,
  faFileExcel,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { Button } from "@vadiun/react-components";
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Loading } from 'shared/components';
import Dropzone from './Dropzone';
import FileInput from './FileInput';
import { useDragDrop } from './useDragDrop';
import { ExcelFileType, useFile } from './useFile';

interface ExcelInputProps {
  onFileSelected: (excelData?: ExcelFileType) => void;
  currentExcelFile?: ExcelFileType;
  onCurrentFileRemoved: (onPerformRemove: () => void) => void;
}

const ExcelInput: FC<ExcelInputProps> = ({
  onFileSelected: onExcelFileSelected,
  currentExcelFile,
  onCurrentFileRemoved
}) => {
  const [dragEntered, setDragEntered] = useState<boolean>(false);
  const snackbar = useSnackbar();

  const handleError = (message: string) => {
    snackbar.enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  const {
    fileUploadEl,
    onFileUpload,
    onFileAdd: handleFileAdded,
    excelFile,
    onFileRemove,
    isFileParsing
  } = useFile(handleError, onExcelFileSelected, currentExcelFile);

  const handleFileRemove = () => {
    onCurrentFileRemoved(onFileRemove);
  }

  const { handleDragOver, handleDrop, handlePaste } = useDragDrop({
    onFileAdded: handleFileAdded,
    onDragEntered: setDragEntered,
  });

  const handleDropFile = async (e: React.DragEvent<HTMLDivElement>) => {
    await handleDrop(e);
  };

  const handleFileUpload = () => {
    onFileUpload();
  };

  return (
    <div className="flex flex-row items-center pb-4 gap-y-4" style={{
      height: '250px'
    }}
    >
      <div className="w-full" style={{ height: '100%' }}>
        <div
          className="h-full relative overflow-hidden"
          onDrop={handleDropFile}
          onPaste={handlePaste}
          onDragOver={handleDragOver}
          onDragEnter={() => setDragEntered(true)}
          onDragEnd={() => {
            setDragEntered(false);
          }}
          onDragExit={() => {
            setDragEntered(false);
          }}
        >
          {dragEntered && <Dropzone onDragEntered={setDragEntered} />}

          {excelFile ? (
            <div
              style={{
                height: '100%',
                borderWidth: '1px',
                borderStyle: 'dashed',
                borderColor: 'rgba(0, 0, 0, 0.20)',
              }}
              className="flex justify-start items-center w-full h-full relative pl-10">
              <div className="flex gap-x-4 justify-center items-center">
                <FontAwesomeIcon icon={faFileExcel} size="6x" color="#21a366" />
                <div className="flex flex-col gap-y-4">
                  <div>
                    Archivo seleccionado: <span className="text-gray-400">{excelFile.url}</span>
                  </div>
                  <div>
                    Cantidad de viajes: <span className="text-gray-400">{excelFile.body.length}</span>
                  </div>
                </div>
              </div>
              <div className="absolute top-0 right-2">
                <Button
                  variant="text"
                  shape="circle"
                  className="mt-2"
                  color='gray'
                  onClick={handleFileRemove}
                >
                  <FontAwesomeIcon icon={faTimesCircle} size="2x" />
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div
                onClick={handleFileUpload}
                style={{
                  height: '100%',
                  borderWidth: '1px',
                  borderStyle: 'dashed',
                  borderColor: 'rgba(0, 0, 0, 0.20)',
                }}
                className="overflow-hidden rounded hover:border-secondary-600 hover:cursor-pointer"
              >

                <div className="flex justify-center items-center flex-col h-full gap-y-3 hover:text-primary-400 cursor-pointer">
                  <Typography>
                    Arrastre y suelte el archivo de viajes
                  </Typography>
                  <FontAwesomeIcon icon={faCloudDownloadAlt} size="4x" color="#0284c7" />
                  <Typography>
                    Haz click para adjuntar el archivo de viajes
                  </Typography>
                </div>

              </div>
              <FileInput
                acceptedFileTypes={[
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                  '.csv',
                ].join(',')}
                onFileAdded={handleFileAdded}
                ref={fileUploadEl}
              />
            </>
          )}
        </div>
      </div>
      {isFileParsing && (
        <div className="fixed top-0 left-0  w-screen h-screen bg-gray-400 flex items-center justify-center" style={{ backgroundColor: '#dbd6d69c' }}>
          <Loading label="Procesando archivo" />
        </div>
      )}
    </div>
  );
};

export default ExcelInput;
