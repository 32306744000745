import { ChartDataset, ChartType } from 'chart.js';

const colors = [
  '#67e8f9',
  '#06b6d4',
  '#0e7490',
  '#164e63',
  '#a5b4fc',
  '#6366f1',
  '#4338ca',
  '#312e81',
  '#86efac',
  '#22c55e',
  '#15803d',
  '#14532d'
];

export function withColor<TType extends ChartType>(
  datasets: ChartDataset<TType>[]
) {
  return datasets.map((dataset, index) => ({
    ...dataset,
    backgroundColor: colors[index]
  }));
}

export function withColorLine<TType extends ChartType>(datasets: any) {
  return datasets.map((dataset, index) => ({
    ...dataset,
    label: dataset.label,
    data: dataset.value,
    backgroundColor: colors[index],
    borderColor: colors[index]
  }));
}
