import * as Yup from 'yup';
import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import {
  transferReservationBaseFormValidation,
  transferReservationBaseLocationFormValidation
} from '../../../viajeTraslado/forms/base/TransferReservationBaseFormValidation';
/* import moment from 'moment'; */

export const transferReservationBatchLocationFormValidation =
  transferReservationBaseLocationFormValidation;

export const transferReservationBatchTripFormValidation = Yup.object().shape({
  fechaYHoraDeLlegada: Yup.mixed().required('La fecha de llegada es requerida'),
  minutosPreviosASalida: Yup.mixed().required(
    'Los minutos de salida son requeridos'
  ),
  puntos: Yup.array()
    .min(2, 'Debe haber al menos dos puntos')
    .of(transferReservationBatchLocationFormValidation)
});

export const transferReservationBatchFormValidation = (
  cliente: ClientBusinessModel | undefined,
  isEvent: boolean,
  isLLTPais: boolean
) =>
  transferReservationBaseFormValidation(cliente).shape({
    viajes: Yup.array()
      .min(1, 'Debe haber al menos un viaje')
      .of(transferReservationBatchTripFormValidation),
    nameEvent: isEvent
      ? Yup.string().required('El nombre del evento es requerido')
      : Yup.string().nullable(),
    providerPrice: isLLTPais
      ? Yup.number().required('El costo del proveedor es requerido')
      : Yup.number().nullable(),
    customerPrice: isLLTPais
      ? Yup.number().required('El costo del cliente es requerido')
      : Yup.number().nullable()
  });
