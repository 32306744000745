import { httpClient } from 'shared/services/http/httpClient';
import {
  passengerBuilder,
  PassengerModel,
  PassengerModelCRUD
} from '../models/Passenger';

const usePassengers = () => {
  const addPassenger = (x: PassengerModelCRUD, idClientBusiness: number) => {
    return httpClient.post(
      `business-clients/${idClientBusiness}/passengers`,
      passengerBuilder.toBackEnd(x)
    );
  };

  const removePassenger = (passenger: PassengerModel) => {
    return httpClient.delete('passengers/' + passenger.id);
  };

  const updatePassenger = (passenger: PassengerModelCRUD) => {
    return httpClient.put(
      'passengers/' + passenger.id,
      passengerBuilder.toBackEnd(passenger)
    );
  };

  const getPassengers = async (
    idClientBusiness: number
  ): Promise<PassengerModel[]> => {
    const response = await httpClient.get(
      `business-clients/${idClientBusiness}/passengers`
    );
    return response.map(passengerBuilder.fromBackEnd);
  };

  return {
    addPassenger,
    removePassenger,
    updatePassenger,
    getPassengers
  };
};

export default usePassengers;
