import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Table, TableColumn } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import { ViajeTrasladoEstado } from 'app/viaje/models';
import LocationDetails from 'app/viaje/viajeTraslado/viajeTraslado/components/LocationDetails';
import { TransferReservationPartialModel } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPartial';
import PageContainer from 'layout/components/PageContainer';
import PageContainerMobile from 'layout/components/PageContainerMobile';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useState } from 'react';
import { useViewport } from 'shared/hooks/useViewport';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { DriverPendingSettlementsFiltersComponent } from '../components/DriverPendingSettlementsFiltersComponent';
import { ResumenPreciosViajesComponent } from '../components/ResumenPreciosViajesComponent';
import { DriverTransferReservationsWithPrices } from '../models/DriverTransferReservationsWithSettlementInfo';
import { useDriverSettlementRepository } from '../services/driverSettlementsRepository';
import DriverPendingSettlementMobile from './DriverPendingSettlementMobile';

export interface IParsedInfo
  extends Omit<DriverTransferReservationsWithPrices, 'client'> {
  client: string;
  locations: {
    observation: string;
    minutesWaiting: number;
    address: DomicilioModel;
    passengers: PassengerModel[];
  }[];
  status: ViajeTrasladoEstado;
  dateTime: string;
}

export const DriverTransferReservationPendingSettlement = () => {
  const driverSettlementRepo = useDriverSettlementRepository();

  const [filters, setFilters] = useState({
    dateFrom: moment().subtract(2, 'week'),
    dateTo: moment()
  });

  const pendingSettlementQuery = useSuperQuery(
    ['pending_settlement', filters],
    () => driverSettlementRepo.getPendingSettlement(filters)
  );

  const { breaks } = useViewport(720);

  const [locationsDetail, setLocationsDetail] = useState<{
    isOpen: boolean;
    details?: {
      address: DomicilioModel;
      observation: string;
    }[];
  }>({ isOpen: false, details: undefined });

  const onLocationDetails = (
    locations: TransferReservationPartialModel['locations']
  ) => {
    setLocationsDetail({
      isOpen: true,
      details: locations.map((l) => ({
        address: l.address,
        observation: l.observation
      }))
    });
  };

  const data = (pendingSettlementQuery.data?.data ?? []).map((d) => {
    return {
      ...d,
      client: d.client.name,
      locations: d.locations,
      status: d.state,
      dateTime: d.reservationDatetime.format('DD/MM/YYYY HH:mm [hs.]')
    };
  });

  const columns: TableColumn<typeof data[0]>[] = [
    { name: 'id', label: 'ID' },
    { name: 'dateTime', label: 'Fecha' },
    { name: 'client', label: 'Cliente' },
    {
      name: 'locations',
      label: 'Recorrido',
      options: {
        customBodyRenderLite: (index) => {
          const firstPoint = data[index].locations[0];
          const lastPoint =
            data[index].locations[data[index].locations.length - 1];
          return (
            <div className="flex flex-col items-center">
              <span className="title text-center text-xs">
                {createDomicilioText(firstPoint.address)}
              </span>
              <Button
                shape="circle"
                className="p-2"
                onClick={() => onLocationDetails(data[index].locations)}
                color={data[index].locations.length > 2 ? 'blue' : 'gray'}
              >
                <div className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faArrowDown} />
                  {data[index].locations.length > 2
                    ? data[index].locations.length
                    : null}
                </div>
              </Button>

              <span className="title text-center text-xs">
                {createDomicilioText(lastPoint.address)}
              </span>
            </div>
          );
        }
      }
    },
    { name: 'state', label: 'Estado' },
    {
      name: 'contado',
      label: 'Contado'
    },
    {
      name: 'cuenta_corriente',
      label: 'Cuenta Corriente'
    },
    {
      name: 'total_precio_espera',
      label: 'Espera'
    },
    {
      name: 'total_gastos',
      label: 'Gastos'
    },
    {
      name: 'total_a_facturar',
      label: 'Total'
    }
  ];

  const Filters = (
    <DriverPendingSettlementsFiltersComponent
      onSubmit={async (values) => {
        setFilters(values);
      }}
      filters={filters}
    />
  );

  const Resume =
    pendingSettlementQuery.data &&
    pendingSettlementQuery.data.data.length > 0 ? (
      <ResumenPreciosViajesComponent
        resumen={pendingSettlementQuery.data.resumen}
      />
    ) : null;

  return (
    <>
      <PageHeader title="Pendientes de liquidación" />
      {!breaks ? (
        <PageContainer>
          <div className="w-full">
            {Filters}
            {Resume}
            <Table
              columns={columns}
              data={data}
              title={undefined}
              options={{
                responsive: 'standard',
                filter: false,
                search: false,
                print: false,
                download: false
              }}
            />
          </div>
          <Modal
            open={locationsDetail.isOpen}
            onClose={() =>
              setLocationsDetail({ isOpen: false, details: undefined })
            }
            size="md"
            body={
              <div style={{ height: 300 }}>
                <LocationDetails
                  locations={(locationsDetail.details ?? []).map((l) => ({
                    ...l,
                    passengers: []
                  }))}
                />
              </div>
            }
          />
        </PageContainer>
      ) : (
        <PageContainerMobile>
          {Filters}
          {Resume}
          <DriverPendingSettlementMobile
            data={pendingSettlementQuery.data?.data ?? []}
            isLoading={pendingSettlementQuery.isLoading}
          />
        </PageContainerMobile>
      )}
    </>
  );
};
