export interface AuthorizedModel {
  id: number;
  name: string;
  phone: string | null;
  email: string | null;
}

export type AuthorizedModelCRUD = Omit<AuthorizedModel, 'id'> & {
  id?: number;
};

export const authorizedBuilder = {
  fromBackEnd: (authorized): AuthorizedModel => {
    return {
      id: authorized.id,
      name: authorized.name,
      phone: authorized.phone,
      email: authorized.email
    };
  },
  toBackEnd: (authorized: AuthorizedModelCRUD) => {
    return {
      id: authorized.id,
      name: authorized.name,
      phone: authorized.phone,
      email: authorized.email
    };
  }
};
