import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { Card } from 'shared/components';
import { BarChartComponent } from '../components/BarChartComponent';
import { useTransferReservationStatisticsRepository } from '../services/transferReservationStatisticsRepository';

export const TransferReservationAssignedPerDayPage = () => {
  const repo = useTransferReservationStatisticsRepository();
  const perDayQuery = useSuperQuery(repo.assignedPerDay);

  if (perDayQuery.data === undefined) {
    return <p>Cargando...</p>;
  }

  const rawData = perDayQuery.data.perDay;
  const bars = rawData.map((data) => ({
    label: data.name,
    value: data.amounts
  }));
  return (
    <div>
      <PageHeader title="Reservas asignadas por día" />
      <PageContainer>
        <div className="w-full">
          <Card>
            <div className="p-8">
              <BarChartComponent bars={bars} />
            </div>
          </Card>
        </div>
      </PageContainer>
    </div>
  );
};
