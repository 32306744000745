import { useCallback, useState } from 'react';
import useChoferes from '../services/ChoferService';

export const useChofer = () => {
  const ChoferService = useChoferes();

  const [drivers, setDrivers] = useState<any>([]);
  const [filters, setFilters] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const onLoadDrivers = useCallback(
    async (searchText: string = '') => {
      console.log(searchText);
      const search = searchText.trim().length > 0 ? searchText.trim() : null;

      const params = new URLSearchParams();

      if (filters?.code) {
        params.append('code', filters.code);
      }

      if (filters?.isDeleted) {
        params.append('isDeleted', filters.isDeleted === 'No' ? '0' : '1');
      }

      if (search) {
        params.append('search', search);
      }

      if (filters?.sort_by && filters?.sort_order) {
        params.append('sort_by', filters.sort_by);
        params.append('sort_order', filters.sort_order);
      }

      // Generar la cadena de consulta
      const parsedParams = params.toString() ? `?${params.toString()}` : '';
      console.log(parsedParams);
      try {
        setLoading(true);
        const data = await ChoferService.getChoferesWithDeleted(
          parsedParams ?? null
        );
        setDrivers(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [ChoferService, filters]
  );

  const onChangeFilters = (flters: any) => setFilters(flters);

  return {
    drivers,
    filters,
    loading,
    onChangeFilters,
    onLoadDrivers
  };
};
