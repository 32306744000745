import React from 'react';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { ClientBusinessPersonalFormType } from './ClientBusinessFormik';
import ClientBusinessFormik from './ClientBusinessFormik';
import FormikAddress from 'shared/components/FormikAddress';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';

interface Props {
  onSubmit: (client: ClientBusinessPersonalFormType, reset) => void;
  initialValues?: ClientBusinessPersonalFormType;
  submitLabel?: string;
  ivaConditions: string[];
}

function ClientBusinessPersonalForm({
  onSubmit,
  ivaConditions,
  submitLabel = 'Continuar',
  initialValues = ClientBusinessFormik.personal.initialValues
}: Props) {
  return (
    <Formik<ClientBusinessPersonalFormType>
      initialValues={initialValues}
      validationSchema={ClientBusinessFormik.personal.schema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {() => (
        <Form className="w-full grid gap-6 grid-cols-6">
          <Field
            name="businessName"
            label="Razón Social*"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="fantasyName"
            label="Nombre fantasía*"
            className="col-span-3"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            name="code"
            label="Código*"
            variant="outlined"
            component={TextField}
            className="col-span-3"
            fullWidth
          />
          <Field
            name="cuit"
            label="Cuit*"
            className="col-span-3"
            type="number"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            className="col-span-6"
            name="address"
            variant="outlined"
            component={FormikAddress}
            fullWidth
          />

          <Field
            name="phone"
            label="Teléfono*"
            className="col-span-3"
            variant="outlined"
            type="number"
            component={TextField}
            fullWidth
          />
          <Field
            name="mail"
            label="Mail*"
            className="col-span-3"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            name="web"
            label="Web"
            className="col-span-3"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            name="socialMedia"
            label="Redes sociales"
            className="col-span-3"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <FormControl variant="outlined" fullWidth className="col-span-3">
            <InputLabel>Condición IVA*</InputLabel>
            <Field
              component={Select}
              name="ivaCondition"
              label="Condición IVA*"
            >
              {ivaConditions.map((ivaCondition) => (
                <MenuItem value={ivaCondition} key={ivaCondition}>
                  {ivaCondition}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <Field
            name="gross_income"
            className="col-span-3"
            label="Ingresos brutos*"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <Field
            name="hasPriority"
            className="col-span-3"
            Label={{ label: 'Tiene Prioridad*' }}
            variant="outlined"
            component={CheckboxWithLabel}
            fullWidth
          />

          <div className="flex justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              className="ml-auto"
            >
              {submitLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ClientBusinessPersonalForm;
