import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { Button } from '@vadiun/react-components';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import useAuthorizeds from 'app/client/clientBusiness/Authorized/services/AuthorizedService';
import { ClientBusinessPartialModel } from 'app/client/clientBusiness/models/ClientBusiness';
import useClientBusiness from 'app/client/clientBusiness/services/useClientBusiness';
import {
  TIPOS_SERVICIO_VIAJE_TRASLADO,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { useState } from 'react';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';
import * as Yup from 'yup';
import { FilterCategoryTitle } from './FilterCategoryTitle';

export type RepetitiveReservationFiltersFormType = {
  client_id?: number | null;
  service_type: ViajeTrasladoTipoServicio | '';
  hasPriority?: boolean;
  passangerName?: string;
  street?: string;
  entry_channel: string;
  phone?: string;
  reservationId?: string;
  authorized_id?: number | null;
  cost_center?: string;
  buy_order?: string;
  sector?: string;
  platform_id?: string;
};

export const RepetitiveReservationFiltersSchema = Yup.object({
  client_id: Yup.number().nullable(),
  service_type: Yup.string(),
  entry_channel: Yup.string(),
  hasPriority: Yup.boolean(),
  passangerName: Yup.string(),
  street: Yup.string(),
  phone: Yup.string(),
  cost_center: Yup.string(),
  buy_order: Yup.string(),
  sector: Yup.string(),
  platform_id: Yup.string()
});

interface Props {
  onSubmit: (values: RepetitiveReservationFiltersFormType) => Promise<void>;
  initialValues: RepetitiveReservationFiltersFormType;
}

const RepetitiveReservationFilters = ({ onSubmit, initialValues }: Props) => {
  const authorizadosService = useAuthorizeds();
  const clientBusinessRepository = useClientBusiness();

  const [client_idSelected, setclient_idSelected] = useState<
    number | undefined
  >(undefined);

  const clientesQuery = useSuperQuery(clientBusinessRepository.getAll);

  const autorizadosQuery = useSuperQuery(
    async () => {
      return client_idSelected
        ? await authorizadosService.getAuthorizeds(client_idSelected)
        : [];
    },
    undefined,
    [client_idSelected]
  );

  return (
    <>
      <Formik<RepetitiveReservationFiltersFormType>
        initialValues={initialValues}
        enableReinitialize
        validationSchema={RepetitiveReservationFiltersSchema}
        onSubmit={onSubmit}
      >
        {({ submitForm, touched, errors, ...formik }) => (
          <Form className="max-w-lg gap-2 p-8">
            <FormikOnFieldChange<{
              client: ClientBusinessPartialModel | null;
            }>
              field="client_id"
              onChange={(client) => {
                setclient_idSelected(client);
                formik.setFieldValue('authorizedId', null);
              }}
            />
            <div>
              <div className="mb-2 border-b-2 border-dashed border-gray-100 pb-2">
                <FilterCategoryTitle title="Reserva" />
                <div className="grid grid-cols-2 gap-x-3">
                  {clientesQuery.data && (
                    <Field
                      name="client_id"
                      component={Autocomplete}
                      noOptionsText="No se han encontrado resultados"
                      loadingText="Cargando..."
                      fullWidth
                      margin="dense"
                      options={(clientesQuery.data ?? []).map((x) => x.id)}
                      getOptionLabel={(option: number) =>
                        (clientesQuery.data ?? []).find((x) => x.id === option)
                          ?.fantasyName ?? ''
                      }
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldMaterial
                          {...params}
                          error={touched['client_id'] && !!errors['client_id']}
                          helperText={errors['client_id']}
                          label="Cliente"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                    />
                  )}

                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>Tipo de servicio</InputLabel>
                    <Field
                      component={Select}
                      name="service_type"
                      label="Tipo de servicio"
                    >
                      {TIPOS_SERVICIO_VIAJE_TRASLADO.map((canal) => (
                        <MenuItem value={canal} key={canal}>
                          {canal}
                        </MenuItem>
                      ))}
                      <MenuItem value={''}>Todos</MenuItem>
                    </Field>
                  </FormControl>
                  <Field
                    name="street"
                    label="Calle"
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    name="entry_channel"
                    label="Canal de ingreso"
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    name="reservationId"
                    label="Reserva ID"
                    variant="outlined"
                    component={TextField}
                    margin="dense"
                    type="number"
                    fullWidth
                  />
                  <div className="col-span-1">
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="hasPriority"
                      color="primary"
                      Label={{ label: 'Prioritario' }}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-2 border-b-2 border-dashed border-gray-100 pb-2">
              <FilterCategoryTitle title="Requerimientos" />
              <div className="grid grid-cols-2 gap-x-3">
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel>Autorizado</InputLabel>
                  <Field
                    component={Select}
                    disabled={autorizadosQuery.data?.length === 0}
                    name="authorized_id"
                    label="Autorizado"
                  >
                    <MenuItem value={undefined}>Todos</MenuItem>
                    {autorizadosQuery.data?.map((authorized) => (
                      <MenuItem value={authorized.id} key={authorized.id}>
                        {authorized.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <div className="col-span-1">
                  <Field
                    component={TextField}
                    type="text"
                    name="cost_center"
                    color="primary"
                    label="Centro de costos"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className="col-span-1">
                  <Field
                    component={TextField}
                    type="text"
                    name="sector"
                    label="Sector"
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className="col-span-1">
                  <Field
                    component={TextField}
                    type="text"
                    label="Orden de compra"
                    variant="outlined"
                    name="buy_order"
                    color="primary"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <Field
                  name="platform_id"
                  label="ID plataforma"
                  variant="outlined"
                  component={TextField}
                  margin="dense"
                  fullWidth
                />
              </div>
            </div>

            <div className="mb-2 border-b-2 border-dashed border-gray-100 pb-2">
              <FilterCategoryTitle title="Pasajero" />
              <div className="grid grid-cols-2 gap-x-3">
                <Field
                  name="passangerName"
                  label="Pasajero"
                  variant="outlined"
                  component={TextField}
                  margin="dense"
                  fullWidth
                />
                <Field
                  name="phone"
                  label="Teléfono pasajero"
                  variant="outlined"
                  component={TextField}
                  margin="dense"
                  fullWidth
                />
              </div>
            </div>

            <div className="col-span-1 flex justify-end pt-4">
              <Button onClick={submitForm} variant="contained">
                Buscar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RepetitiveReservationFilters;
