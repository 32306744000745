import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import { ChangeEvent, FC, useState } from 'react';

interface MappingAdvancedHoursProps {
  value: string;
  onChange: (value: string) => void;
  hasError: boolean;
  className?: string;
  range: 8 | 24
}

const getInitialData = (value: string) => {
  const timePart = value.match(/\d{2}:\d{2}/);

  if (timePart) {
    const timeArray = timePart[0].split(':');
    return {
      hours: timeArray[0],
      minutes: timeArray[1]
    }
  } else {
    const timeArray = value.split(':');
    if (timeArray.length !== 2 || isNaN(parseInt(timeArray[0])) || isNaN(parseInt(timeArray[1]))) {
      return {
        hours: '00',
        minutes: '00'
      }
    }
    return {
      hours: timeArray[0].length === 1 ? `0${timeArray[0]}` : timeArray[0].length > 2 ? '00' : timeArray[0],
      minutes: timeArray[1].length === 1 ? `0${timeArray[1]}` : timeArray[1].length > 2 ? '00' : timeArray[1],
    }
  }
}

const HOURS = Array.from({ length: 24 }).map((_, index) => ({
  value: index <= 9 ? `0${index}` : index,
  label: index <= 9 ? `0${index}` : index,
}));

const MINUTES = Array.from({ length: 12 }).map((_, index) => {
  const nextMinute = index * 5;
  const nextMinuteStr = nextMinute < 10 ? `0${nextMinute}` : `${nextMinute}`;

  return ({
    value: nextMinuteStr,
    label: nextMinuteStr,
  })
});

const MappingAdvancedHours: FC<MappingAdvancedHoursProps> = ({
  value: tableValue,
  hasError,
  onChange,
  className,
  range
}) => {
  const [hasFieldError, setHasFieldError] = useState<boolean>(hasError);
  const [currentValue, setCurrentValue] = useState<{ hours: string, minutes: string }>(getInitialData(tableValue));

  const handleOnChangeHours = (e: ChangeEvent<{ value: unknown }>) => {
    const nextValue = e.target.value as string;
    setHasFieldError(false);
    onChange(`${nextValue}:${currentValue.minutes}`);
    setCurrentValue({
      ...currentValue,
      hours: nextValue
    });
  }

  const handleOnChangeMinutes = (e: ChangeEvent<{ value: unknown }>) => {
    const nextValue = e.target.value as string;
    setHasFieldError(false);
    onChange(`${currentValue.hours}:${nextValue}`);
    setCurrentValue({
      ...currentValue,
      minutes: nextValue
    });
  }

  const hourRange = HOURS.slice(0, range);

  return (
    <div className="flex gap-x-2">
      <div className={`${className}`}>
        <FormControl fullWidth variant="outlined" style={{ marginTop: '6px' }}>
          <InputLabel error={hasFieldError}>
            Hora
          </InputLabel>
          <Select
            error={hasFieldError}
            style={{
              minWidth: '140px',
              paddingRight: '0px'
            }}
            value={currentValue.hours}
            label={'Hora'}
            onChange={handleOnChangeHours}
          >
            {hourRange.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={`${className}`}>
        <FormControl fullWidth variant="outlined" style={{ marginTop: '6px' }}>
          <InputLabel error={hasFieldError}>
            Minutos
          </InputLabel>
          <Select
            error={hasFieldError}
            style={{
              minWidth: '140px',
              paddingRight: '0px'
            }}
            value={currentValue.minutes}
            label="Minutos"
            onChange={handleOnChangeMinutes}
          >
            {MINUTES.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );

}

export default MappingAdvancedHours;