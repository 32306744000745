import { CSSProperties, FC, useState } from 'react';
import { DomicilioGoogle } from 'shared/models/DomicilioGoogle';
import GooglePlacesInput from '../../../../../../../shared/components/GooglePlacesInput';
interface MappingGoogleAddressProps {
  value: string;
  hasError: boolean;
  onChange: (newAddress: string) => void;
  style?: CSSProperties;
  isFloating?: boolean;
}

const MappingGoogleAddress: FC<MappingGoogleAddressProps> = ({
  value,
  hasError,
  onChange,
  style,
  isFloating = false
}) => {
  const [googleAddres, setGoogleAddress] = useState<DomicilioGoogle>({
    calle: value,
    latitud: 0,
    localidad: '',
    longitud: 0,
    numero: '',
    partido: '',
    provincia: '',
    pais: '',
    codigoPostal: ''
  });
  const [hasFieldError, setHasFieldError] = useState<boolean>(hasError);
  const [errorLabel, setErrorLabel] = useState<boolean>(false);

  const handleChange = (nextGoogleAddress: DomicilioGoogle) => {
    const hasSelected = !!nextGoogleAddress.calle;
    setHasFieldError(!hasSelected);
    setGoogleAddress(nextGoogleAddress);
    if (hasSelected) {
      onChange(nextGoogleAddress.text!);
      setErrorLabel(false);
    } else {
      setErrorLabel(true)
    }
  }

  return (
    <GooglePlacesInput
      className="w-full relative"
      variant="outlined"
      style={style}
      autocompleteFloating={isFloating}
      value={googleAddres}
      fullWidth
      onChange={handleChange}
      error={hasFieldError}
      helperText={errorLabel ? 'Seleccione dirección' : ''}
      size={"medium"}
    />
  )
};

export default MappingGoogleAddress;