import { domicilioBuilder } from 'app/domicilio/models/DomicilioModel';
import { passangerBuilder } from 'app/viaje/models';
import {
  TransferReservationPrivateCreateBatchType,
  TransferReservationPrivateEditType
} from './transferReservationPrivateRepositoryTypes';

export const transferReservationPrivateAddMapper = (
  x: TransferReservationPrivateCreateBatchType
) => ({
  reservations: x.viajes.map((viaje) => ({
    has_out_site: x.hasOutSite,
    fixed_price_name: undefined,
    entry_channel: x.canalIngreso,
    has_priority: x.prioridad,
    service_type: x.tipoServicio,
    coordination_observation: x.observacionCoordinacion,
    reservation_datetime:
      viaje.fechaYHoraDeLlegada.format('YYYY-MM-DD HH:mm') + ':00',
    advance_hours:
      viaje.fechaYHoraDeLlegada.diff(viaje.minutosPreviosASalida, 'minutes') /
      60,
    hasOutSite: x.hasOutSite,
    pay_with_cash: x.pay_with_cash,
    locations: viaje.puntos.map((point) => ({
      address: domicilioBuilder.toBackEnd(point.domicilio),
      observation: point.observacion,
      minutes_waiting: point.espera,
      passengers: point.pasajeros.map((p) => ({
        name: p.nombreCompleto,
        phone: p.telefono,
        email: p.mail
      }))
    }))
  }))
});

export const transferReservationPrivateModifyMapper = (
  viaje: TransferReservationPrivateEditType
) => ({
  has_out_site: viaje.hasOutSite,
  entry_channel: viaje.canalIngreso,
  has_priority: viaje.prioridad,
  service_type: viaje.tipoServicio,
  coordination_observation: viaje.observacionCoordinacion,
  reservation_datetime:
    viaje.fechaYHoraDeLlegada.format('YYYY-MM-DD HH:mm') + ':00',
  advance_hours:
    viaje.fechaYHoraDeLlegada.diff(viaje.minutosPreviosASalida, 'minutes') / 60,
  hasOutSite: viaje.hasOutSite,
  pay_with_cash: viaje.pay_with_cash,
  locations: viaje.puntos.map((point) => ({
    address: domicilioBuilder.toBackEnd(point.domicilio),
    observation: point.observacion,
    minutes_waiting: point.minutosEspera,
    passengers: point.pasajeros.map(passangerBuilder.toBackEnd)
  })),
  client_type: 'ParticularClient',
  client_id: viaje.clienteId
});
