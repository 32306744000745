import {
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { tw } from 'utils/tailwindUtils';


interface StepProps {
  isCompleted?: boolean;
  textClassName?: string;
  stepName: string;
  isCurrent: boolean;
}
const Step: FC<StepProps> = ({ isCompleted = false, stepName, textClassName, isCurrent }) => {
  return (
    <div className="flex gap-x-2">
      <div
        style={{
          borderWidth: isCompleted ? '0' : '1px',

        }}
        className={tw(
          'flex h-7 w-7 items-center justify-center rounded-full border-gray-300',
          {
            'hidden': isCompleted,
            'border-gray-500': isCurrent
          }
        )}
      >
      </div>
      {isCompleted && <FontAwesomeIcon icon={faCheckCircle} size="2x" color="#2dd4be" />}

      <span
        style={{
          fontSize: '14px',
          top: '2px',
          left: '30px',
        }}
        className={tw(
          'overflow-hidden whitespace-pre pt-1 text-gray-300 px-2 bg-white',
          textClassName,
          {
            'text-secondary-900': isCompleted,
            'text-gray-700': isCurrent
          }
        )}
      >
        {stepName}
      </span>

    </div >
  );
};

export default Step;
