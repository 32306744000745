import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import { domicilioInitialValues } from 'shared/components/FormikAddress';
import {
  TransferReservationBaseFormType,
  TransferReservationBaseLocationFormType
} from './TransferReservationBaseFormTypes';

export const createTransferReservationBaseInitialValues: () => TransferReservationBaseFormType =
  () => ({
    cliente: null,
    hasOutSite: false,
    canalIngreso: ViajeCanalesDeIngreso.TELEFONO,
    plataformaId: null,
    prioridad: false,
    fixedRate: null,
    solicitante: {
      name: '',
      phone: '',
      email: ''
    },
    costCenter: '',
    subCostCenter: '',
    sector: '',
    buyOrder: '',
    employeeNumber: '',
    platformApplication: '',
    tripNumber: '',
    autorizado: null,
    tipoServicio: ViajeTrasladoTipoServicio.EJECUTIVO,
    observacionCoordinacion: '',
    data1: '',
    data2: '',
    data3: '',
    pay_with_cash: false,
    isEvent: false,
    isLLTPais: false,
    customerPrice: 0,
    providerPrice: 0,
    nameEvent: ''
  });

export const createTrasferReservationBaseLocationInitialValues: () => TransferReservationBaseLocationFormType =
  () => ({
    domicilio: domicilioInitialValues,
    pasajeros: [],
    observacion: null,
    espera: ''
  });
