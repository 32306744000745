import React, { FC } from 'react';

interface Props {
  label: string | number;
  value: string | number;
}

export const ProfileDescriptionItem = ({ label, value }: Props) => {
  return (
    <div className="flex justify-between mt-2 overflow-hidden">
      <span className="text-gray-600 font-medium mr-2">{label}</span>
      <span className="text-gray-400 text-sm">{value}</span>
    </div>
  );
};

const ProfileDescriptionContainer: FC = (props) => {
  return <div className="my-8">{props.children}</div>;
};

export default ProfileDescriptionContainer;
