import { IconButton, Popover } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useState } from 'react';

type Props = {
  onShowFiltersDriversRecomended: () => void;
};

const EmptyDriverRecomended = ({
  onShowFiltersDriversRecomended
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="flex h-32 flex-col gap-7">
      <div className="flex justify-center">
        <IconButton
          size="small"
          aria-label="filter by driver"
          onClick={(e) => {
            e.stopPropagation();
            onShowFiltersDriversRecomended();
          }}
        >
          <FilterListIcon fontSize="medium" />
        </IconButton>
      </div>
      {/* <p className="text-center text-gray-500">-</p> */}
      <IconButton size="small" onMouseOver={handleClick}>
        <ErrorOutlineIcon className="mt-auto text-gray-500" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        id={id}
      >
        <div className="flex w-72 flex-col items-center gap-2 p-5">
          <ErrorOutlineIcon className="mt-auto text-gray-500" />
          <p className="text-center text-sm text-gray-800">
            ¡Ups! No se encuentran vehículos para recomendarte.
          </p>
          <p className="text-center text-sm text-gray-500">
            Puedes modificar los filtros{' '}
            <IconButton
              size="small"
              aria-label="edit filter by driver"
              onClick={(e) => {
                e.stopPropagation();
                onShowFiltersDriversRecomended();
              }}
            >
              <FilterListIcon fontSize="small" />
            </IconButton>{' '}
            para encontrar nuevas opciones.
          </p>
        </div>
      </Popover>
    </div>
  );
};

export default EmptyDriverRecomended;
