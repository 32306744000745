import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useState } from 'react';
import { AntenaGrupoCreate } from '../components/AntenaGrupoCreate';
import { AntenaGrupoEdit } from '../components/AntenaGrupoEdit';
import {
  antenagrupoFormBuilder,
  AntenaGrupoFormType
} from '../components/AntenaGrupoForm/AntenaGrupoFormType';
import { AntenaGrupoTable } from '../components/AntenaGrupoTable';
import { AntenaGrupoModel } from '../models/AntenaGrupo';
import useAntenaGrupos from '../services/AntenaGrupoService';

export function AntenaGrupoCrudPage(props: any) {
  const AntenaGrupoService = useAntenaGrupos();
  const antenagruposQuery = useSuperQuery(AntenaGrupoService.getAntenaGrupos, {
    showSpinner: true
  });
  const updateAntenaGrupoMutation = useSuperMutation(
    AntenaGrupoService.updateAntenaGrupo
  );
  const addAntenaGrupoMutation = useSuperMutation(
    AntenaGrupoService.addAntenaGrupo
  );
  const removeAntenaGrupoMutation = useSuperMutation(
    AntenaGrupoService.removeAntenaGrupo,
    {
      showSpinner: true
    }
  );
  const restoreAntenaGrupoMutation = useSuperMutation(
    AntenaGrupoService.restoreAntenaGrupo,
    {
      showSpinner: true
    }
  );
  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'update'>(
    'table'
  );
  const [updatingAntenaGrupo, setEditingAntenaGrupo] =
    useState<AntenaGrupoModel>();

  async function update(antenagrupoValues: AntenaGrupoFormType) {
    antenagrupoValues.id = updatingAntenaGrupo!.id;
    await updateAntenaGrupoMutation.mutate(antenagrupoValues);
    setVisiblePage('table');
    antenagruposQuery.reload();
  }

  async function create(antenagrupoValues: AntenaGrupoFormType) {
    await addAntenaGrupoMutation.mutate(antenagrupoValues);
    setVisiblePage('table');
    antenagruposQuery.reload();
  }

  async function remove(antenagrupo: AntenaGrupoModel) {
    await removeAntenaGrupoMutation.mutate(antenagrupo);
    antenagruposQuery.reload();
  }

  async function restore(antenagrupo: AntenaGrupoModel) {
    await restoreAntenaGrupoMutation.mutate(antenagrupo);
    antenagruposQuery.reload();
  }

  function selectToEdit(antenagrupo: AntenaGrupoModel) {
    setEditingAntenaGrupo(antenagrupo);
    setVisiblePage('update');
  }
  return (
    <>
      <PageHeader
        title="Grupos de antenas"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de Grupos de antenas'
            : visiblePage === 'create'
            ? 'Nuevo Grupo de antenas'
            : 'Editar Grupo de antenas'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Grupo de antenas
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <AntenaGrupoTable
            antenagrupos={antenagruposQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restore={restore}
          />
        )}

        {visiblePage === 'create' && <AntenaGrupoCreate create={create} />}
        {visiblePage === 'update' && updatingAntenaGrupo && (
          <AntenaGrupoEdit
            update={update}
            initialValues={antenagrupoFormBuilder.fromModel(
              updatingAntenaGrupo
            )}
          />
        )}
      </PageContainer>
    </>
  );
}
