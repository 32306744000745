import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import moment, { Moment } from 'moment';

export interface RateModel {
  id: number;
  businessClientId: number | string;
  baseCommission: number | string;
  surplusCommission: number | string;
  limitPriceBaseCommission: number | string;
  expirationDate: Moment;
  outSitePrice: string | number;
  nightPlusPercentage: string | number;
  minNumberKilometers: number | string;
  categories: RateCategoryModel[];
  fantasyName?: string;
  previous_rate?: Moment;
}

export interface RatePartialModel {
  baseCommission: number;
  expirationDate: Moment;
  id: number;
  kilometerPrice: number;
  limitPriceBaseCommission: number;
  minNumberKilometers: number;
  minute_price: number;
  nightPlusPercentage: number;
  outSitePrice: number;
  surplusCommission: number;
}

// Workaround to extend enums https://github.com/microsoft/TypeScript/issues/17592
export const RateCategoryType = {
  ...ViajeTrasladoTipoServicio,

  FLETE: 'Flete'
};
export type RateCategoryType =
  typeof RateCategoryType[keyof typeof RateCategoryType];

export const RATE_CATEGORIES = Object.values(RateCategoryType);

export interface RateCategoryModel {
  kmServiceCanceled: number | string;
  minutePrice: number | string;
  name: RateCategoryType;
  linearKilometerPrices: {
    numberKilometersFrom: number | string;
    numberKilometersTo: number | string;
    price: number | string;
  }[];
  returnKilometerPrices: {
    numberKilometersFrom: number | string;
    numberKilometersTo: number | string;
    price: number | string;
  }[];
  fixedPrices: {
    name: string;
    price: number | string;
  }[];
  priced: {
    address1: DomicilioModel;
    address2: DomicilioModel;
    kilometersRadio: string | number;
    price: string | number;
  }[];
}

export interface RateModelCRUD extends Omit<RateModel, 'categories' | 'id'> {
  id?: number;
  categories: (Omit<RateCategoryModel, 'minutePrice'> & {
    hourPrice: number | string;
  })[];
}

export const rateBuilder = {
  fromBackEnd: (rate): RateModel => {
    return {
      ...rate,
      fantasyName: rate.fantasyName,
      previous_rate: rate.previous_rate
        ? moment(rate.previous_rate)
        : undefined,
      categories: rate.categories.map((category) => ({
        ...category,
        priced: category.priced.map((elem) => ({
          address1: domicilioBuilder.fromBackEnd(elem.address1),
          address2: domicilioBuilder.fromBackEnd(elem.address2),
          kilometersRadio: elem.kilometersRadio,
          price: elem.price
        }))
      })),
      expirationDate: moment(rate.expirationDate),
      minNumberKilometers: rate.min_kilometers
    };
  },
  toBackEnd: (rate: RateModelCRUD) => {
    return {
      business_client_id: rate.businessClientId,
      base_commission: rate.baseCommission,
      surplus_commission: rate.surplusCommission,
      limit_km_base_commission: rate.limitPriceBaseCommission,
      expiration_date: rate.expirationDate.format('YYYY-MM-DD'),
      out_site_price: rate.outSitePrice,
      night_plus_percentage: rate.nightPlusPercentage,
      min_kilometers: rate.minNumberKilometers,
      categories: rate.categories.map((category) => ({
        km_service_canceled: category.kmServiceCanceled,
        minute_price: (Number(category.hourPrice) / 60).toFixed(2),
        name: category.name,
        linearKilometerPrices: category.linearKilometerPrices.map((price) => ({
          number_kilometers_from: price.numberKilometersFrom,
          number_kilometers_to: price.numberKilometersTo,
          price: price.price
        })),
        returnKilometerPrices: category.returnKilometerPrices.map((price) => ({
          number_kilometers_from: price.numberKilometersFrom,
          number_kilometers_to: price.numberKilometersTo,
          price: price.price
        })),
        fixedPrices: category.fixedPrices.map((price) => ({
          name: price.name,
          price: price.price
        })),
        priced: category.priced.map((price) => ({
          address1: domicilioBuilder.toBackEnd(price.address1),
          address2: domicilioBuilder.toBackEnd(price.address2),
          kilometers_radio: price.kilometersRadio,
          price: price.price
        }))
      }))
    };
  }
};

export const ratePartialBuilder = {
  fromBackEnd: (rate): RatePartialModel => ({
    ...rate,
    expirationDate: moment(rate.expirationDate)
  })
};
