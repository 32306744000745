import React from 'react';
import PageContainer from 'layout/components/PageContainer';
import { ReservaTrasladoFormWrapper } from 'app/viaje/viajeTraslado/viajeTraslado/components/ViajeTrasladoFormWrapper';
import { Card } from 'shared/components';
import { TransferReservationRepetitiveForm } from '../form/TransferReservationRepetitiveForm';
import { TransferReservationRepetitiveFormType } from '../form/TransferReservationRepetitiveFormType';

interface Props {
  onSubmit: (
    reservation: TransferReservationRepetitiveFormType,
    clientBusinessId: number,
    reset: any
  ) => void;
  estimatePrice: (
    trasladoFormValues: TransferReservationRepetitiveFormType,
    clientBusinessId: number
  ) => Promise<
    {
      price: number;
      distance: number;
      duration: number;
      durationTraffic: number;
    }[]
  >;
}

export const ReservaTrasladoRepetitiveCreate = ({
  onSubmit,
  estimatePrice
}: Props) => {
  return (
    <PageContainer>
      <Card className="w-full" title="Crear reserva repetitiva">
        <div className="p-8">
          <ReservaTrasladoFormWrapper<TransferReservationRepetitiveFormType>
            onSubmit={onSubmit}
            calculateDistanceAndCost={estimatePrice}
          >
            {(params) => <TransferReservationRepetitiveForm {...params} />}
          </ReservaTrasladoFormWrapper>
        </div>
      </Card>
    </PageContainer>
  );
};
