import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, TableColumn } from '@vadiun/react-components';
import { useVerifyAction } from '@vadiun/react-hooks-legacy';
import { AntenaModel } from 'app/antena/antena/models/Antena';

interface Props {
  antenas: AntenaModel[];
  selectToEdit: (x: AntenaModel) => void;
  delete: (x: AntenaModel) => void;
  restore: (x: AntenaModel) => void;
}

export const AntenaTable = (props: Props) => {
  const verifyAction = useVerifyAction();

  const data = props.antenas.map((a) => ({
    ...a,
    grupo: a.grupo.nombre
  }));
  const columns: TableColumn<typeof data[0]>[] = [
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'latitud',
      label: 'Latitud',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'longitud',
      label: 'Longitud',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'grupo',
      label: 'Grupo'
    },

    {
      label: 'Acciones',
      name: 'id',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) => (
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => props.selectToEdit(props.antenas[index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            <Button
              onClick={() =>
                verifyAction({
                  title: 'Estas por eliminar un antena!',
                  body: 'Esta accion va a eliminar el antena. ¿Estas seguro?',
                  onAccept: () => props.delete(props.antenas[index])
                })
              }
              shape="circle"
              color="red"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none'
        }}
        title={'Antenas'}
        data={data}
        columns={columns}
      />
    </div>
  );
};
