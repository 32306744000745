import { Dialog, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Warning from '@material-ui/icons/Warning';
import { Button } from '@vadiun/react-components';
import React from 'react';

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  isLoading: boolean;
  textOkButton: string;
  onCloseModal: () => void;
  onAccept: () => void;
};

const AssignOrPreAssignDriver = ({
  isOpen,
  title,
  description,
  isLoading,
  textOkButton,
  onCloseModal,
  onAccept
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCloseModal}
      maxWidth="md"
      className="rounded-xl"
      PaperProps={{
        style: {
          borderRadius: 15
        }
      }}
    >
      <div className="px-4 py-4" style={{ width: 450, overflow: 'hidden' }}>
        <div className="mb-3 flex justify-between">
          <div className="flex items-center gap-1">
            <Warning style={{ color: '#F59E0B' }} />
            <p className="text-lg text-gray-800">{title}</p>
          </div>
          <IconButton
            size="small"
            aria-label="close modal"
            onClick={onCloseModal}
          >
            <Clear fontSize="medium" />
          </IconButton>
        </div>
        <div className="mt-6 mb-5">
          <p className="w-80 text-gray-700">{description}</p>
        </div>

        <div className="flex items-center justify-end gap-3 pt-4">
          <Button onClick={onCloseModal} variant="light" color="gray">
            Cancelar
          </Button>
          <Button
            onClick={onAccept}
            variant="contained"
            color="blue"
            isLoading={isLoading}
          >
            {textOkButton}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AssignOrPreAssignDriver;
