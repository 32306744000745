import {
  faAddressBook,
  faCar,
  faCarAlt,
  faCarSide,
  faChartBar,
  faClipboardList,
  faCoins,
  faEllipsisH,
  faExclamationCircle,
  faFile,
  faFileContract,
  faFileInvoiceDollar,
  faHandshake,
  faIdCard,
  faList,
  faMapPin,
  faMoneyBill,
  faMoneyCheck,
  faReceipt,
  faRedo,
  faSearch,
  faSignOutAlt,
  faStream,
  faTag,
  faTags,
  faToolbox,
  faTrash,
  faUser,
  faUserCheck,
  faUserCircle,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import { UserRole } from 'app/user/models/User';
import { useViajesTraslado } from 'app/viaje/services';
import { useAuth } from 'auth/services/AuthService';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import SidebarHeader from './SidebarHeader';
import SidebarItem from './SidebarItem';

export type SideBarStatus =
  | {
      platform: 'mobile';
      status: 'open' | 'closed';
    }
  | {
      platform: 'desktop';
      status: 'collapsed' | 'expanded';
    };

interface Props {
  status: SideBarStatus;
  toggleCollapse: () => void;
}

interface PropsSidebarSection {
  titulo: string;
  isCollapsed: boolean;
}

const SidebarSection = ({ titulo, isCollapsed }: PropsSidebarSection) => {
  return (
    <h1 className="mt-7 mb-4 px-8 text-xs font-semibold text-blueGray-700">
      {!isCollapsed ? titulo : <FontAwesomeIcon icon={faEllipsisH} size="1x" />}
    </h1>
  );
};

const Sidebar = ({ status, toggleCollapse }: Props) => {
  const authService = useAuth();
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [isTemporarilyExpanded, setIsTemporarilyExpanded] = useState(false);
  const history = useHistory();

  const isCollapsed =
    status.platform === 'desktop' &&
    status.status === 'collapsed' &&
    !isTemporarilyExpanded;

  const { getAmountOfTransferReservationToValidate } = useViajesTraslado();
  const amountOfTransferReservationToValidateQuery = useSuperQuery(
    getAmountOfTransferReservationToValidate,
    { showErrorMessage: false }
  );
  useEffect(() => {
    const interval = setInterval(
      () => amountOfTransferReservationToValidateQuery.reload(),
      60000
    );
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSelectedItem(history.location.pathname);
    history.listen((hist) => {
      setSelectedItem(hist.pathname);
    });
  }, []);

  const colapsedClass = () => {
    const baseClass =
      'bg-blueGray-800 min-h-full shadow-md flex-shrink-0 transition-all duration-300 ease-in-out overflow-y-auto hide-scroll';
    if (status.platform === 'mobile') {
      const mobileBaseClass = `${baseClass} fixed`;
      const mobileClosedClass = `${mobileBaseClass} hidden hide-sidebar`;
      return status.status === 'closed' ? mobileClosedClass : mobileBaseClass;
    }
    if (status.platform === 'desktop') {
      const desktopBaseClass = `${baseClass} block show-sidebar relative`;
      const desktopCollapsedClass = `${desktopBaseClass} w-20`;
      const desktopExpandedClass = `${desktopBaseClass} w-60`;
      return !isCollapsed ? desktopExpandedClass : desktopCollapsedClass;
    }
  };

  const onMouseOver = () => {
    if (status.platform === 'desktop' && status.status === 'collapsed') {
      setIsTemporarilyExpanded(true);
    }
  };

  const onMouseOut = () => {
    if (status.platform === 'desktop' && status.status === 'collapsed') {
      setIsTemporarilyExpanded(false);
    }
  };

  const hasAnyRole = (roles: UserRole[]) =>
    authService.roles.some((role) => roles.includes(role));

  return (
    <>
      <div
        className={colapsedClass()}
        style={{ zIndex: 500, backgroundColor: '#1e1e2d' }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseOut}
      >
        <SidebarHeader
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
          isMobile={status.platform === 'mobile'}
          logo={Logo}
        />
        <div
          className=" bg-blueGray-900 py-1"
          style={{ backgroundColor: 'rgb(26, 26, 39)' }}
        >
          <button onClick={authService.logout}>
            <SidebarItem
              icon={<FontAwesomeIcon icon={faSignOutAlt} size="1x" />}
              label="Salir"
              isSelected={false}
              isCollapsed={isCollapsed}
            />
          </button>
          <div>
            <SidebarSection titulo="Viajes" isCollapsed={isCollapsed} />
            {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faCarAlt} size="1x" />}
                label="Viajes"
                isCollapsed={isCollapsed}
              >
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faCarAlt} size="1x" />}
                  label="Crear un Viaje"
                  isSelected={
                    selectedItem === '/main/Viaje/create' ||
                    selectedItem?.includes('/main/Viaje/create/')
                  }
                  redirectTo="/main/Viaje/create"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={
                    <div className="flex relative gap-x-0.5">
                      <FontAwesomeIcon icon={faCarAlt} size="1x" />
                      <div
                        className="absolute"
                        style={{ right: '-3px', top: '-7px' }}
                      >
                        <FontAwesomeIcon icon={faCarAlt} size="1x" />
                      </div>
                    </div>
                  }
                  label="Múltiples reservas"
                  isSelected={selectedItem === '/main/Viaje/bulk-create'}
                  redirectTo="/main/Viaje/bulk-create"
                  isCollapsed={isCollapsed}
                />

                <SidebarItem
                  icon={<FontAwesomeIcon icon={faFile} size="1x" />}
                  label="Borradores"
                  isSelected={selectedItem === '/main/Viaje/drafts'}
                  redirectTo="/main/Viaje/drafts"
                  isCollapsed={isCollapsed}
                />
              </SidebarItem>
            ) : null}
            {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faUser} size="1x" />}
                label="Particular"
                isSelected={selectedItem === '/main/Viaje/traslado/privado'}
                redirectTo="/main/Viaje/traslado/privado"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={
                  <div className="flex w-full items-center justify-center rounded-full bg-primary-500 px-1 py-1 text-xs text-white">
                    <span>
                      {amountOfTransferReservationToValidateQuery.data ===
                      undefined
                        ? '...'
                        : amountOfTransferReservationToValidateQuery.data
                            .amount}
                    </span>
                  </div>
                }
                label="A validar"
                isSelected={selectedItem === '/main/solicitados/tabla'}
                redirectTo="/main/solicitados/tabla"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {/* {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.RESPONSABLE_CHOFERES,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faPauseCircle} size="1x" />}
                label="Pendientes"
                isSelected={selectedItem === '/main/Viaje/traslado/pendiente'}
                redirectTo="/main/Viaje/traslado/pendiente"
                isCollapsed={isCollapsed}
              />
            ) : null} */}
            {hasAnyRole([
              UserRole.COORDINADOR,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<DepartureBoardIcon />}
                label="Coordinar"
                isSelected={
                  selectedItem === '/main/Viaje/traslado/pendiente/coordinar'
                }
                redirectTo="/main/Viaje/traslado/pendiente/coordinar"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {/* {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faMap} size="1x" />}
                label="Mapa"
                isSelected={selectedItem === '/main/Viaje/traslado/mapa'}
                redirectTo="/main/Viaje/traslado/mapa"
                isCollapsed={isCollapsed}
              />
            ) : null} */}
            {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faCarSide} size="1x" />}
                label="Abiertos"
                isSelected={selectedItem === '/main/Viaje/traslado/enCurso'}
                redirectTo="/main/Viaje/traslado/enCurso"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.ANALISTA_CUENTAS_A_PAGAR,
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.RESPONSABLE_COBRANZA,
              UserRole.ANALISTA_FACTURACION,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faClipboardList} size="1x" />}
                label="Gestión"
                isSelected={selectedItem === '/main/Viaje/traslado/gestion'}
                redirectTo="/main/Viaje/traslado/gestion"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([UserRole.SUPER_ADMIN]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faTrash} size="1x" />}
                label="Eliminar Reserva"
                isSelected={selectedItem === '/main/Viaje/traslado/eliminar'}
                redirectTo="/main/Viaje/traslado/eliminar"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.RESPONSABLE_CHOFERES,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.GERENTE_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faMoneyCheck} size="1x" />}
                label="Recaudación choferes"
                isSelected={selectedItem === '/driver/incomes'}
                redirectTo="/main/driver/incomes"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faRedo} size="1x" />}
                label="Repetitivo"
                isSelected={selectedItem === '/main/viajes/repeticion'}
                redirectTo="/main/viajes/repeticion"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faRedo} size="1x" />}
                label="Repetitivo Particular"
                isSelected={selectedItem === '/main/viajes/repetitivo/privado'}
                redirectTo="/main/viajes/repetitivo/privado"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.ANALISTA_FACTURACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faSearch} size="1x" />}
                label="A Auditar"
                isSelected={
                  selectedItem === '/main/Viaje/traslado/pendiente-auditoria'
                }
                redirectTo="/main/Viaje/traslado/pendiente-auditoria"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.ANALISTA_FACTURACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faSearch} size="1x" />}
                label="A Auditar Contado"
                isSelected={
                  selectedItem ===
                  '/main/Viaje/traslado/pendiente-auditoria-contado'
                }
                redirectTo="/main/Viaje/traslado/pendiente-auditoria-contado"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([UserRole.SUPER_ADMIN]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faToolbox} size="1x" />}
                label="Corregir CC"
                isSelected={selectedItem === '/main/Viaje/traslado/a-corregir'}
                redirectTo="/main/Viaje/traslado/a-corregir"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([UserRole.SUPER_ADMIN]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faToolbox} size="1x" />}
                label="Corregir contado"
                isSelected={
                  selectedItem === '/main/Viaje/traslado/a-corregir-contado'
                }
                redirectTo="/main/Viaje/traslado/a-corregir-contado"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.ANALISTA_FACTURACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faMoneyBill} size="1x" />}
                label="A Liquidar"
                isSelected={
                  selectedItem === '/main/Viaje/traslado/pendiente-liquidacion'
                }
                redirectTo="/main/Viaje/traslado/pendiente-liquidacion"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.RESPONSABLE_COBRANZA,
              UserRole.ANALISTA_FACTURACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faCoins} size="1x" />}
                label="Liquidaciones"
                isSelected={selectedItem === '/main/liquidaciones/tabla'}
                redirectTo="/main/liquidaciones/tabla"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.RESPONSABLE_COBRANZA,
              UserRole.ANALISTA_FACTURACION,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faFileInvoiceDollar} size="1x" />}
                label="Facturados"
                isSelected={selectedItem === '/main/facturados/tabla'}
                redirectTo="/main/facturados/tabla"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.ANALISTA_CUENTAS_A_PAGAR,
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faFileContract} size="1x" />}
                label="Liquidación choferes"
                isCollapsed={isCollapsed}
              >
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faHandshake} size="1x" />}
                  label="Liquidar"
                  isSelected={
                    selectedItem === '/main/driver/settlements/create'
                  }
                  redirectTo="/main/driver/settlements/create"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faList} size="1x" />}
                  label="Historial"
                  isSelected={selectedItem === '/main/driver/settlements'}
                  redirectTo="/main/driver/settlements"
                  isCollapsed={isCollapsed}
                />
              </SidebarItem>
            ) : null}
            {hasAnyRole([
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.RESPONSABLE_COBRANZA,
              UserRole.ANALISTA_FACTURACION,
              UserRole.GERENTE_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                label="Estadisticas"
                isCollapsed={isCollapsed}
              >
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Por operador"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/per-operator'
                  }
                  redirectTo="/main/transfer-reservation/statistics/per-operator"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Por día"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/per-day'
                  }
                  redirectTo="/main/transfer-reservation/statistics/per-day"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Por chofer"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/per-driver'
                  }
                  redirectTo="/main/transfer-reservation/statistics/per-driver"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Por coordinador"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/per-coordinator'
                  }
                  redirectTo="/main/transfer-reservation/statistics/per-coordinator"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Asignados por día"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/assigned-per-day'
                  }
                  redirectTo="/main/transfer-reservation/statistics/assigned-per-day"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Por operador y cliente"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/per-operator-and-client'
                  }
                  redirectTo="/main/transfer-reservation/statistics/per-operator-and-client"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Reservas por cliente"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/reservation-amount-per-client'
                  }
                  redirectTo="/main/transfer-reservation/statistics/reservation-amount-per-client"
                  isCollapsed={isCollapsed}
                />

                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Auditadas"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/reservations-per-month'
                  }
                  redirectTo="/main/transfer-reservation/statistics/reservations-per-month"
                  isCollapsed={isCollapsed}
                />

                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Valor de viaje por cliente"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/reservation-price-per-client'
                  }
                  redirectTo="/main/transfer-reservation/statistics/reservation-price-per-client"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Consumo por cliente"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/average-spent-per-client'
                  }
                  redirectTo="/main/transfer-reservation/statistics/average-spent-per-client"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Precios por cliente"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/price-per-client'
                  }
                  redirectTo="/main/transfer-reservation/statistics/price-per-client"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faChartBar} size="1x" />}
                  label="Analisis comercial"
                  isSelected={
                    selectedItem ===
                    '/main/transfer-reservation/statistics/analisis-comercial'
                  }
                  redirectTo="/main/transfer-reservation/statistics/analisis-comercial"
                  isCollapsed={isCollapsed}
                />
              </SidebarItem>
            ) : null}
            {hasAnyRole([
              UserRole.RESPONSABLE_CHOFERES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                label="Vencimientos"
                isSelected={selectedItem === '/main/vencimientos/tabla'}
                redirectTo="/main/vencimientos/tabla"
                isCollapsed={isCollapsed}
              />
            ) : null}
            <SidebarSection titulo="Inventarios" isCollapsed={isCollapsed} />
            {hasAnyRole([
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faMapPin} size="1x" />}
                label="Antenas"
                isCollapsed={isCollapsed}
              >
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faMapPin} size="1x" />}
                  label="Antena"
                  redirectTo={'/main/antenas/antena'}
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faMapPin} size="1x" />}
                  label="Grupos"
                  redirectTo={'/main/antenas/grupos'}
                  isCollapsed={isCollapsed}
                />
              </SidebarItem>
            ) : null}
            {hasAnyRole([UserRole.SUPER_ADMIN]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faUserCircle} size="1x" />}
                label="Usuarios"
                isSelected={selectedItem === '/main/users'}
                redirectTo="/main/users"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.RESPONSABLE_COBRANZA,
              UserRole.ANALISTA_FACTURACION,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN,
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.COORDINADOR,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faAddressBook} size="1x" />}
                label="Clientes"
                isCollapsed={isCollapsed}
              >
                {hasAnyRole([
                  UserRole.OPERADOR_CALL_CENTER,
                  UserRole.COORDINADOR,
                  UserRole.SUPERVISOR_COORDINACION,
                  UserRole.GERENTE_OPERACIONES
                ]) &&
                !hasAnyRole([
                  UserRole.GERENTE_ADMINISTRACION,
                  UserRole.RESPONSABLE_COBRANZA,
                  UserRole.ANALISTA_FACTURACION,
                  UserRole.GERENTE_COMERCIAL,
                  UserRole.ANALISTA_COMERCIAL,
                  UserRole.SUPER_ADMIN
                ]) ? null : (
                  <SidebarItem
                    icon={<FontAwesomeIcon icon={faUserTie} size="1x" />}
                    label="Empresa"
                    isSelected={selectedItem === '/main/clientBusiness'}
                    redirectTo="/main/clientBusiness"
                    isCollapsed={isCollapsed}
                  />
                )}

                <SidebarItem
                  icon={<FontAwesomeIcon icon={faUser} size="1x" />}
                  label="Particular"
                  isCollapsed={isCollapsed}
                >
                  <SidebarItem
                    icon={<FontAwesomeIcon icon={faList} size="1x" />}
                    label="Listado"
                    isSelected={
                      selectedItem === '/main/clientParticular/listado'
                    }
                    redirectTo="/main/clientParticular/listado"
                    isCollapsed={isCollapsed}
                  />
                  {hasAnyRole([
                    UserRole.OPERADOR_CALL_CENTER,
                    UserRole.COORDINADOR,
                    UserRole.SUPERVISOR_COORDINACION,
                    UserRole.GERENTE_OPERACIONES
                  ]) &&
                  !hasAnyRole([
                    UserRole.GERENTE_ADMINISTRACION,
                    UserRole.RESPONSABLE_COBRANZA,
                    UserRole.ANALISTA_FACTURACION,
                    UserRole.GERENTE_COMERCIAL,
                    UserRole.ANALISTA_COMERCIAL,
                    UserRole.SUPER_ADMIN
                  ]) ? null : (
                    <SidebarItem
                      icon={<FontAwesomeIcon icon={faTag} size="1x" />}
                      label="Tarifa"
                      isSelected={
                        selectedItem === '/main/clientParticular/tarifa'
                      }
                      redirectTo="/main/clientParticular/tarifa"
                      isCollapsed={isCollapsed}
                    />
                  )}
                </SidebarItem>
              </SidebarItem>
            ) : null}
            {hasAnyRole([
              UserRole.RESPONSABLE_CHOFERES,
              UserRole.ANALISTA_CUENTAS_A_PAGAR,
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faHandshake} size="1x" />}
                label="Facturadores"
                isSelected={selectedItem === '/main/billers'}
                redirectTo="/main/billers"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.RESPONSABLE_CHOFERES,
              UserRole.GERENTE_OPERACIONES,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faCar} size="1x" />}
                label="Vehiculos"
                isCollapsed={isCollapsed}
              >
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faCar} size="1x" />}
                  label="Vehiculos"
                  isSelected={selectedItem === '/main/vehiculo'}
                  redirectTo="/main/vehiculo"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faUserCheck} size="1x" />}
                  label="Titulares"
                  isSelected={selectedItem === '/main/titulares'}
                  redirectTo="/main/titulares"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faTags} size="1x" />}
                  label="Fabricantes"
                  isSelected={selectedItem === '/main/fabricantes'}
                  redirectTo="/main/fabricantes"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faStream} size="1x" />}
                  label="Modelos"
                  isSelected={selectedItem === '/main/modelos'}
                  redirectTo="/main/modelos"
                  isCollapsed={isCollapsed}
                />
              </SidebarItem>
            ) : null}
            {hasAnyRole([
              UserRole.RESPONSABLE_CHOFERES,
              UserRole.SUPERVISOR_COORDINACION,
              UserRole.GERENTE_OPERACIONES,
              UserRole.ANALISTA_CUENTAS_A_PAGAR,
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faIdCard} size="1x" />}
                label="Choferes"
                isSelected={selectedItem === '/main/chofer'}
                redirectTo="/main/chofer"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([
              UserRole.GERENTE_ADMINISTRACION,
              UserRole.ANALISTA_FACTURACION,
              UserRole.GERENTE_COMERCIAL,
              UserRole.ANALISTA_COMERCIAL,
              UserRole.SUPER_ADMIN
            ]) ? (
              <SidebarItem
                icon={<FontAwesomeIcon icon={faReceipt} size="1x" />}
                label="Peajes"
                isSelected={selectedItem === '/main/tolls'}
                redirectTo="/main/tolls"
                isCollapsed={isCollapsed}
              />
            ) : null}
            {hasAnyRole([UserRole.CHOFER]) ? (
              <>
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faCar} size="1x" />}
                  label="A Liquidar"
                  isSelected={
                    selectedItem ===
                    '/main/driver/me/travels-pending-settlement'
                  }
                  redirectTo="/main/driver/me/travels-pending-settlement"
                  isCollapsed={isCollapsed}
                />
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faList} size="1x" />}
                  label="Liquidaciones"
                  isSelected={selectedItem === '/main/driver/me/settlements'}
                  redirectTo="/main/driver/me/settlements"
                  isCollapsed={isCollapsed}
                />
              </>
            ) : null}
            {hasAnyRole([
              UserRole.COORDINADOR,
              UserRole.SUPER_ADMIN,
              UserRole.OPERADOR_CALL_CENTER,
              UserRole.GERENTE_OPERACIONES,
              UserRole.SUPERVISOR_COORDINACION
            ]) ? (
              <>
                <SidebarItem
                  icon={<FontAwesomeIcon icon={faCar} size="1x" />}
                  label="Control Liquidaciones"
                  isSelected={
                    selectedItem ===
                    '/main/driver/me/pending-settlement-managment'
                  }
                  redirectTo="/main/driver/me/pending-settlement-managment"
                  isCollapsed={isCollapsed}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
