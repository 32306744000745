import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import { Moment } from 'moment';

interface Props {
  onSubmit: (values: DriverPendingSettlementsFiltersType) => Promise<any>;
  filters: DriverPendingSettlementsFiltersType;
}

export interface DriverPendingSettlementsFiltersType {
  dateFrom: Moment;
  dateTo: Moment;
}

export const DriverPendingSettlementsFiltersComponent = ({
  onSubmit,
  filters
}: Props) => {
  return (
    <div>
      <Formik<DriverPendingSettlementsFiltersType>
        initialValues={filters}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form className="mb-8 flex items-center gap-2 px-2">
            <Field
              component={DatePicker}
              label="Desde"
              name="dateFrom"
              inputVariant="outlined"
              margin="dense"
              className="col-span-2 bg-white"
              format="DD/MM/YYYY"
            />
            <Field
              component={DatePicker}
              label="Hasta"
              name="dateTo"
              inputVariant="outlined"
              margin="dense"
              className="col-span-2 bg-white"
              format="DD/MM/YYYY"
            />
            <div className="col-span-1 flex justify-end">
              <Button
                onClick={formik.submitForm}
                className="mt-2 mb-1 h-9"
                variant="contained"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
