import React from 'react';
import { Card } from 'shared/components';
import { AntenaForm } from 'app/antena/antena/components/AntenaForm/AntenaForm';
import { AntenaFormType } from 'app/antena/antena/components/AntenaForm/AntenaFormType';
import { AntenaGrupoModel } from 'app/antena/grupo/models/AntenaGrupo';

interface Props {
  create: (AntenaValues: AntenaFormType) => Promise<void>;
  grupos: AntenaGrupoModel[];
}
export function AntenaCreate({ create, grupos }: Props) {
  return (
    <Card title="Nuevo Antena" className="w-full">
      <div className="p-8">
        <AntenaForm onSubmit={create} grupos={grupos} />
      </div>
    </Card>
  );
}
