import { httpClient } from 'shared/services/http/httpClient';

const useCostCenter = () => {
  const addCostCenter = (idClientBusiness: number, name: string) => {
    return httpClient.post(
      `business-clients/cost_centers`, {
      business_client_id: idClientBusiness,
      name: name
    }
    );
  };

  const removeCostCenter = (id: number) => {
    return httpClient.delete(`business-clients/cost_centers/${id}`);
  };

  const updateCostCenter = (id: number, name: string) => {
    return httpClient.put(`business-clients/cost_centers/${id}`, { name: name });
  };
 

  return {
    addCostCenter,
    removeCostCenter,
    updateCostCenter
  };
};

export default useCostCenter;