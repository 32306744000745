import React from 'react';
import { VehiculoFabricanteFormSchema } from '../models/VehiculoFabricanteFormSchema';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import * as Yup from 'yup';

interface Props {
  onSubmit: (value: VehiculoFabricanteFormSchema, reset) => void;
  initialValues?: VehiculoFabricanteFormSchema;
}

const Schema = Yup.object().shape({
  nombre: Yup.string().required('El nombre es obligatorio')
});

function VehiculoFabricanteForm({
  onSubmit,
  initialValues = {
    nombre: ''
  }
}: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={(values, actions) => onSubmit(values, actions.resetForm)}
    >
      {({ submitForm, touched, errors, values }) => (
        <Form className="w-full grid gap-6">
          <Field
            name="nombre"
            label="Nombre fabricante"
            variant="outlined"
            component={TextField}
            fullWidth
          />
          <div className="flex justify-end">
            <Button type="submit" variant="contained" color="green">
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default VehiculoFabricanteForm;
