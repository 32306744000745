import React from 'react';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { createClientBusinessUserFormInitialValues } from './ClientBusinessUserFormInitialValues';
import { ClientBusinessUserFormType } from './ClientBusinessUserFormType';
import { userFormValidation } from './ClientBusinessUserFormValidation';

interface Props {
  onSubmit: (value: ClientBusinessUserFormType) => Promise<void>;
  initialValues?: ClientBusinessUserFormType;
}

export function ClientBusinessUserForm({
  onSubmit,
  initialValues = createClientBusinessUserFormInitialValues()
}: Props) {
  return (
    <Formik<ClientBusinessUserFormType>
      initialValues={initialValues}
      validationSchema={userFormValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, setFieldValue }) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="email"
            label="Email *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />
          <Field
            name="name"
            label="Nombre *"
            variant="outlined"
            className="col-span-3"
            component={TextField}
            fullWidth
          />

          <div className="col-span-6 flex justify-start">
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="is_admin"
              color="primary"
              className="col-span-1"
              Label={{ label: 'Es administrador' }}
            />
          </div>

          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
