import React, { CSSProperties } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';
import CardHeader from './CardHeader';
interface Props {
  children: React.ReactNode;
  title?: string | React.ReactNode;
  toolbar?: string | React.ReactNode;
  cardHeader?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  subtitle?: string | React.ReactNode;
  styles?: CSSProperties;
}

const Card = ({
  children,
  title,
  toolbar,
  subtitle,
  cardHeader = '',
  className = '',
  headerClassName = '',
  styles,
}: Props) => {
  const cardHeaderElement = () => {
    if (title || toolbar)
      return (
        <CardHeader className={headerClassName}>
          <div className="flex items-center space-x-2">
            {React.isValidElement(title) ? (
              { title }
            ) : (
              <h1 className="font-semibold">{title}</h1>
            )}
            {React.isValidElement(subtitle) ? (
              { subtitle }
            ) : (
              <h1 className="font-medium text-xs text-gray-400">{subtitle}</h1>
            )}
          </div>

          {toolbar}
        </CardHeader>
      );

    return cardHeader ? cardHeader : null;
  };

  const classNameElem = `rounded-md bg-white text-gray-600 ${className}`;
  return (
    <div className={overrideTailwindClasses(classNameElem)} style={styles}>
      {cardHeaderElement()}
      {children}
    </div>
  );
};

export default Card;
