import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { TableColumn, Table } from '@vadiun/react-datatable';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useState } from 'react';
import { useTransferReservationStatisticsRepository } from '../services/transferReservationStatisticsRepository';

export const TransferReservationPeroOperatorAndClientPage = () => {
  const { getPerOperatorAndClient } =
    useTransferReservationStatisticsRepository();

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    fromDate: moment(),
    toDate: moment()
  });
  const perOperatorAndCleintQuery = useSuperQuery(
    () => getPerOperatorAndClient(filters),
    {
      showSpinner: true
    },
    [filters]
  );

  if (perOperatorAndCleintQuery.data === undefined) {
    return <p>Cargando...</p>;
  }

  const data = Object.values(perOperatorAndCleintQuery.data?.perOperatorAndClient)

  const capitalize = (val: string) => {
    const firstLetter = val.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = val.slice(1)

    return firstLetterCap + remainingLetters
  }

  const columns: TableColumn<
    typeof perOperatorAndCleintQuery.data['perOperatorAndClient'][0]
  >[] = [
    {
      name: 'operatorName',
      label: 'Operador'
    },
    {
      name: 'clientName',
      label: 'Cliente'
    },
    {
      name: 'manualAudited',
      label: 'Audit. manual'
    },
    {
      name: 'autoAudited',
      label: 'Audit. auto'
    },
    {
      name: 'approved',
      label: 'Aprobados'
    },
    {
      name: 'corrected',
      label: 'Corregidos'
    },
    {
      name: 'observation_corrected',
      label: 'Observacion corregidos',
      options: {
        customBodyRenderLite: (index) => (
          <div className='flex flex-col'>
            {data[index].observation_corrected && Object.keys(data[index]?.observation_corrected).map(key => (
              <div className='flex items-center mb-1 whitespace-nowrap'>
               <span
                 className='bg-primary-300 p-1 rounded-xl whitespace-nowrap'
               >
                {key.includes('_') ? capitalize(key.replace("_", " ")) : capitalize(key)}
               </span> 
               <span>: {data[index].observation_corrected[key]}%</span>
              </div> 
            ))}
          </div>
        )
      }
    },
    {
      name: 'observation_approved',
      label: 'Observacion aprobados',
      options: {
        customBodyRenderLite: (index) => (
          <div className='flex flex-col'>
            {data[index].observation_approved && Object.keys(data[index].observation_approved).map(key => (
              <div className='flex items-center mb-1 whitespace-nowrap'>
               <span
                 className='bg-primary-300 p-1 rounded-xl whitespace-nowrap'
               >
                {key.includes('_') ? capitalize(key.replace("_", " ")) : capitalize(key)}
               </span> 
               <span>: {data[index].observation_approved[key]}%</span>
              </div> 
            ))}
          </div>
        )
      }
    },
    {
      name: 'settled',
      label: 'Liquidados'
    }
  ];

  return (
    <>
      <PageHeader
        title="Por operador y cliente"
        toolbar={
          <Button variant="contained" onClick={() => setShowFilters(true)}>
            Filtrar
          </Button>
        }
      />
      <PageContainer>
        <div className="h-full w-full">
          <Table
            options={{
              filter: false
            }}
            title={''}
            data={data}
            columns={columns}
          />
        </div>
      </PageContainer>

      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <Formik
            onSubmit={async (filters) => {
              await setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
          >
            {(formik) => (
              <Form className="m-8 grid grid-cols-1 gap-8">
                <Field
                  name="fromDate"
                  label="Desde*"
                  inputVariant="outlined"
                  component={KeyboardDatePicker}
                  ampm={false}
                  format="DD/MM/YYYY"
                  fullWidth
                />
                <Field
                  name="toDate"
                  label="Hasta*"
                  inputVariant="outlined"
                  component={KeyboardDatePicker}
                  ampm={false}
                  format="DD/MM/YYYY"
                  fullWidth
                />
                <Button variant="contained" onClick={formik.submitForm}>
                  Buscar
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  );
};
