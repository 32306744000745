import ItemStatusDriver from './ItemStatusDriver';

type Props = {
  busy: number;
  free: number;
  onBrake: number;
  statusActive: string;
  isLoadingDrivers: boolean;
  onFilterByStatusDriver: (state: string) => void;
};

const StatusDrivers = ({
  free,
  busy,
  onBrake,
  statusActive,
  isLoadingDrivers,
  onFilterByStatusDriver
}: Props): JSX.Element => {
  return (
    <div className="gap-4 flex justify-start py-2 pl-2 pr-2">
      <ItemStatusDriver
        status="FREE"
        onFilterByStatusDriver={onFilterByStatusDriver}
        bgColor="bg-green-100"
        isActive={statusActive === 'FREE'}
        borderColor="border-green-500"
        label="Libres"
        value={free}
        color="text-green-500"
        loadingColor="#59B9A6"
        isLoadingDrivers={isLoadingDrivers}
      />
      <ItemStatusDriver
        status="BUSY"
        onFilterByStatusDriver={onFilterByStatusDriver}
        bgColor="bg-red-100"
        isActive={statusActive === 'BUSY'}
        borderColor="border-red-500"
        label="Ocupados"
        value={busy}
        color="text-red-500"
        isLoadingDrivers={isLoadingDrivers}
        loadingColor="#E94B5C"
      />
      <ItemStatusDriver
        status="UNAVAILABLE"
        onFilterByStatusDriver={onFilterByStatusDriver}
        bgColor="bg-orange-100"
        isActive={statusActive === 'UNAVAILABLE'}
        borderColor="border-orange-500"
        label="No disponibles"
        value={onBrake}
        color="text-orange-500"
        isLoadingDrivers={isLoadingDrivers}
        loadingColor="#F39E2B"
      />
    </div>
  );
};

export default StatusDrivers;
