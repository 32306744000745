import environment from 'environment/environment';

export type TravelTaked = {
  transfer_reservation_id: number;
  name: string;
  user_id: number;
  email: string;
};

export const useTravelCordinate = () => {
  const takeOrLeaveTravelServiceApi = async (
    idTravel: number,
    take: boolean
  ): Promise<any> => {
    return fetch(
      `${environment.matching}/transfer-reservations/${idTravel}/take`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Api-Key': environment.matchingKeys
        },
        body: JSON.stringify({ take })
      }
    ).then((res) => res.json());
  };

  const getTravelsTaked = async (): Promise<TravelTaked[]> => {
    return fetch(`${environment.matching}/transfer-reservations-takes`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'X-Api-Key': environment.matchingKeys
      }
    }).then((res) => res.json());
  };

  return {
    takeOrLeaveTravelServiceApi,
    getTravelsTaked
  };
};
