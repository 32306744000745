import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { Card } from 'shared/components';
import { ClientPrivateRateEdit } from '../components/ClientPrivateRateEdit';
import { useClientPrivateRate } from '../services/useClientPrivateRate';

export const ClientPrivateRatePage = () => {
  const RateService = useClientPrivateRate();

  const editMutation = useSuperMutation(RateService.edit);

  const rateQuery = useSuperQuery(RateService.get, { showSpinner: true });

  return (
    <>
      <PageHeader title="Tarifa" subtitle="Cliente particular" />
      <PageContainer>
        <div className="w-full">
          <Card>
            <div className="p-8">
              {rateQuery.data === undefined ? null : (
                <ClientPrivateRateEdit
                  edit={editMutation.mutate}
                  initialValue={{
                    ...rateQuery.data,
                    categories: rateQuery.data.categories.map((cat) => ({
                      ...cat,
                      hourPrice: (Number(cat.minutePrice) * 60).toFixed(2)
                    }))
                  }}
                />
              )}
            </div>
          </Card>
        </div>
      </PageContainer>
    </>
  );
};
