import { Button } from '@vadiun/react-components';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import { useEffect, useState } from 'react';
import VehiculoFabricanteCreate from '../components/VehiculoFabricanteCreate';
import VehiculoFabricanteEdit from '../components/VehiculoFabricanteEdit';
import VehiculoFabricanteTable from '../components/VehiculoFabricanteTable';
import { VehiculoFabricanteModel } from '../models/VehiculoFabricante';
import {
  VehiculoFabricanteFormSchema,
  VehiculoFabricanteFormSchemaToModel,
  VehiculoFabricanteModelToFormSchema
} from '../models/VehiculoFabricanteFormSchema';
import useVehiculoFabricantes from '../services/VehiculoFabricanteService';

function VehiculoFabricanteCrudPage(props: any) {
  const VehiculoFabricanteService = useVehiculoFabricantes();
  const editVehiculoFabricanteMutation = useSuperMutation(
    VehiculoFabricanteService.editVehiculoFabricante,
    { showSpinner: true }
  );
  const addVehiculoFabricanteMutation = useSuperMutation(
    VehiculoFabricanteService.addVehiculoFabricante,
    { showSpinner: true }
  );
  const removeVehiculoFabricanteMutation = useSuperMutation(
    VehiculoFabricanteService.removeVehiculoFabricante,
    { showSpinner: true }
  );
  const restoreVehiculoFabricanteMutation = useSuperMutation(
    VehiculoFabricanteService.restaurarVehiculoFabricante,
    { showSpinner: true }
  );
  const fabricantesQuery = useSuperQuery(
    VehiculoFabricanteService.getVehiculoFabricantesWithDeleted,
    { showSpinner: true }
  );

  const [visiblePage, setVisiblePage] = useState<'table' | 'create' | 'edit'>(
    'table'
  );
  const [editingVehiculoFabricante, setEditingVehiculoFabricante] =
    useState<VehiculoFabricanteModel>();

  useEffect(() => {
    if (visiblePage === 'table') fabricantesQuery.reload();
  }, [visiblePage]);

  async function edit(vehiculoFormValues: VehiculoFabricanteFormSchema, reset) {
    const vehiculoFabricante =
      VehiculoFabricanteFormSchemaToModel(vehiculoFormValues);
    vehiculoFabricante.id = editingVehiculoFabricante!.id;
    await editVehiculoFabricanteMutation.mutate(vehiculoFabricante);
    reset();
    setVisiblePage('table');
  }

  async function create(
    VehiculoFabricanteValues: VehiculoFabricanteFormSchema,
    reset
  ) {
    const VehiculoFabricante = VehiculoFabricanteFormSchemaToModel(
      VehiculoFabricanteValues
    );
    await addVehiculoFabricanteMutation.mutate(VehiculoFabricante);
    reset();
  }

  async function remove(VehiculoFabricante: VehiculoFabricanteModel) {
    await removeVehiculoFabricanteMutation.mutate(VehiculoFabricante);
    fabricantesQuery.reload();
  }

  async function restore(VehiculoFabricante: VehiculoFabricanteModel) {
    await restoreVehiculoFabricanteMutation.mutate(VehiculoFabricante);
    fabricantesQuery.reload();
  }

  function selectToEdit(VehiculoFabricante: VehiculoFabricanteModel) {
    setEditingVehiculoFabricante(VehiculoFabricante);
    setVisiblePage('edit');
  }

  return (
    <>
      <PageHeader
        title="Fabricantes"
        subtitle={
          visiblePage === 'table'
            ? 'Listado de fabricantes'
            : visiblePage === 'create'
            ? 'Nueva Fabricante'
            : 'Editar Fabricante'
        }
        toolbar={
          visiblePage === 'table' ? (
            <Button onClick={() => setVisiblePage('create')} variant="light">
              Crear Fabricante
            </Button>
          ) : (
            <Button onClick={() => setVisiblePage('table')} variant="light">
              Volver
            </Button>
          )
        }
      />

      <PageContainer>
        {visiblePage === 'table' && (
          <VehiculoFabricanteTable
            VehiculoFabricantes={fabricantesQuery.data || []}
            selectToEdit={selectToEdit}
            delete={remove}
            restaurar={restore}
          />
        )}

        {visiblePage === 'create' && (
          <VehiculoFabricanteCreate create={create} />
        )}
        {visiblePage === 'edit' && editingVehiculoFabricante && (
          <VehiculoFabricanteEdit
            editar={edit}
            initialValues={VehiculoFabricanteModelToFormSchema(
              editingVehiculoFabricante
            )}
          />
        )}
      </PageContainer>
    </>
  );
}

export default VehiculoFabricanteCrudPage;
