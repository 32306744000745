import { TableColumn } from '@vadiun/react-components';
import { Table } from 'shared/components';
import { TransferReservationState } from '../models/TransferReservationState';

interface Props {
  reservationStates: TransferReservationState[];
}

export const ViajeTrasladoStatesTable = (props: Props) => {
  const columns: TableColumn<TransferReservationState>[] = [
    {
      name: 'type',
      label: 'Estado',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          props.reservationStates[index].type
      }
    },
    {
      name: 'datetime',
      label: 'Fecha',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          props.reservationStates[index].datetime.format('DD/MM/YYYY HH:mm')
      }
    },
    {
      name: 'user',
      label: 'Usuario',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          props.reservationStates[index].user.name
      }
    },
    {
      name: 'observation',
      label: 'Observación',
      options: {
        filter: false,
        customBodyRenderLite: (index: number) =>
          props.reservationStates[index].observation
      }
    }
  ];

  return (
    <div className="h-full w-full">
      <Table
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          download: false,
          viewColumns: false,
          filter: false,
          print: false
        }}
        title={''}
        data={props.reservationStates}
        columns={columns}
      />
    </div>
  );
};
