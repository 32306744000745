import React from 'react';
import { Modal } from '@vadiun/react-components';
import moment from 'moment';
import { TravelEstimationsWithPrice } from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';

interface Props {
  distanceAndCost: TravelEstimationsWithPrice[];
  isOpen: boolean;
  handleClose: () => void;
}

const ReservaTrasladoEstimationDialog = ({
  distanceAndCost,
  isOpen,
  handleClose
}: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      body={
        <div>
          {distanceAndCost.length > 0 && (
            <table className="text-center w-full mt-8">
              <thead className="border-b-2">
                <tr>
                  <th>Viaje</th>
                  <th>Distancia</th>
                  <th>Duración</th>
                  <th>Costo</th>
                </tr>
              </thead>
              <tbody>
                {distanceAndCost.map((result, index) => (
                  <tr key={index}>
                    <td className="p-2">{index + 1}</td>
                    <td className="p-2">{result.distance.toFixed(2)} km</td>
                    <td className="p-2">
                      {moment('2020-01-01 00:00:00')
                        .minutes(Math.ceil(result.durationTraffic))
                        .format('HH:mm')}{' '}
                      hs.
                    </td>
                    <td className="p-2">$ {result.price.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      }
    />
  );
};

export default ReservaTrasladoEstimationDialog;
