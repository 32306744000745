import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import { Moment } from 'moment';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';

export interface CoordinadorChoferTableFiltersType {
  from: Moment;
  to: Moment;
  driver_id: number | null;
}

interface Props {
  onSubmit(filters: CoordinadorChoferTableFiltersType);
  initialValues: CoordinadorChoferTableFiltersType;
  drivers: ChoferPartialModel[]
}

export const CoordinadorChoferFiltersComponent = ({
  onSubmit,
  initialValues,
  drivers
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ touched, errors }) => (
        <Form className="flex max-w-md flex-col gap-2 p-8">
          <Field
            component={KeyboardDatePicker}
            label="Fecha desde"
            name="from"
            inputVariant="outlined"
            margin="dense"
            format="DD/MM/YYYY"
          />
          <Field
            component={KeyboardDatePicker}
            label="Fecha hasta"
            name="to"
            inputVariant="outlined"
            margin="dense"
            format="DD/MM/YYYY"
          />
          <Field
            name="driver_id"
            component={Autocomplete}
            fullWidth
            options={(drivers ?? []).map((x) => x.id)}
            getOptionLabel={(option: number) => {
              const driver = drivers?.find(
                (x) => x.id === option
              );
              return driver
                ? `#${driver.codigo} - ${driver.nombreCompleto}`
                : '';
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextFieldMaterial
                {...params}
                error={touched['driver_id'] && !!errors['driver_id']}
                helperText={errors['driver_id']}
                label="Chofer"
                variant="outlined"
                margin="dense"
                fullWidth
              />
            )}
          />
          <div className="flex justify-end">
            <Button type="submit" variant="contained">
              Buscar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
