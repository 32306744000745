import {
  TransferReservationPrivateBatchFormType,
  TransferReservationPrivateBatchLocationFormType,
  TransferReservationPrivateBatchTripFormType
} from './TransferReservationPrivateBatchFormType';
import moment from 'moment';
import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import { createTrasferReservationBaseLocationInitialValues } from 'app/viaje/viajeTraslado/viajeTraslado/forms/base/TransferReservationBaseFormInitialValues';

export const createTransferReservationPrivateBatchLocationInitialValues: () => TransferReservationPrivateBatchLocationFormType =
  createTrasferReservationBaseLocationInitialValues;

export const createTransferReservationPrivateBatchTripInitialValues =
  (): TransferReservationPrivateBatchTripFormType => ({
    fechaYHoraDeLlegada: moment(),
    minutosPreviosASalida: moment(),
    puntos: []
  });

export const createTransferReservationPrivateBatchInitialValues =
  (): TransferReservationPrivateBatchFormType => ({
    cliente: null,
    hasOutSite: false,
    canalIngreso: ViajeCanalesDeIngreso.TELEFONO,
    prioridad: false,
    tipoServicio: ViajeTrasladoTipoServicio.EJECUTIVO,
    observacionCoordinacion: '',
    pay_with_cash: true,
    viajes: [createTransferReservationPrivateBatchTripInitialValues()]
  });
