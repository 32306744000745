import { Moment } from 'moment';
import { httpClient } from 'shared/services/http/httpClient';
import { KmAverageOfAllDriversModel } from '../models/KilometersOfAllDriversModel';
import { TurnosPerDayModel } from '../models/TurnosPerDayModel';

export const useDriverInformationRepository = () => {
  const mock2 = [
    {
      periodo: '01/22',
      cant_ociosos: 500,
      cant_con_pasajero: 1500
    },
    {
      periodo: '02/22',
      cant_ociosos: 600,
      cant_con_pasajero: 1400
    },
    {
      periodo: '03/22',
      cant_ociosos: 700,
      cant_con_pasajero: 1300
    },
    {
      periodo: '04/22',
      cant_ociosos: 800,
      cant_con_pasajero: 1200
    },
    {
      periodo: '05/22',
      cant_ociosos: 900,
      cant_con_pasajero: 1100
    },
    {
      periodo: '06/22',
      cant_ociosos: 1000,
      cant_con_pasajero: 1000
    }
  ];

  const getKmAverageOfAllDriversModel = async (filters: {
    fromDate: Moment;
    toDate: Moment;
    groupBy: 'month' | 'day';
  }): Promise<KmAverageOfAllDriversModel[]> => {
    const params = new URLSearchParams();
    params.append('from', filters.fromDate.format());
    params.append('to', filters.toDate.format());
    params.append('group_by', filters.groupBy);
    return httpClient.get('choferes/evolucion-promedio-kilometros?' + params);
  };

  const getTurnosPerDay = async (): Promise<TurnosPerDayModel> => {
    return await httpClient.get(`/drivers/work-during-the-day`);
  };

  return {
    /*getKilometersPerDriver,*/
    getKmAverageOfAllDriversModel,
    getTurnosPerDay
  };
};
