import { ClientPrivateModel } from 'app/client/private/private/models/ClientPrivate';
import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import moment, { Moment } from 'moment';
import { TransferReservationBaseLocationFormType } from '../../../viajeTraslado/forms/base/TransferReservationBaseFormTypes';
import { TransferReservationPrivateModel } from '../../models/TransferReservatinPrivateModel';
import { TransferReservationPrivateEditType } from '../../services/repositories/transferReservationPrivateRepositoryTypes';

export interface TransferReservationPrivateSingleFormType {
  cliente: ClientPrivateModel | null;
  canalIngreso: ViajeCanalesDeIngreso;
  prioridad: boolean;
  tipoServicio: ViajeTrasladoTipoServicio;
  observacionCoordinacion: string | null;
  hasOutSite: boolean;
  pay_with_cash: boolean;
  puntos: TransferReservationBaseLocationFormType[];
  fechaYHoraDeLlegada: Moment;
  minutosPreviosASalida: Moment;
}

export const transferReservationPrivateSingleFormBuilder = {
  fromModel: (
    reservation: TransferReservationPrivateModel,
    client: ClientPrivateModel
  ): TransferReservationPrivateSingleFormType => ({
    cliente: client,
    canalIngreso: reservation.entryChannel,
    prioridad: reservation.hasPriority,
    tipoServicio: reservation.serviceType,
    observacionCoordinacion: reservation.coordinationObservation,
    hasOutSite: reservation.has_out_site,
    pay_with_cash: reservation.pay_with_cash,
    puntos: reservation.locations.map((l) => ({
      domicilio: l.address,
      pasajeros: l.passengers.map((p) => ({
        nombreCompleto: p.name,
        telefono: p.phone ?? '',
        mail: p.email ?? ''
      })),
      observacion: l.observation,
      espera: String(l.minutesWaiting)
    })),
    fechaYHoraDeLlegada: reservation.reservationDatetime,
    minutosPreviosASalida: moment(reservation.reservationDatetime).subtract(
      reservation.advanceHours,
      'hours'
    )
  }),
  toModel: (
    form: TransferReservationPrivateSingleFormType,
    reservationId: number
  ): TransferReservationPrivateEditType => ({
    id: reservationId,
    clienteId: form.cliente!.id,
    canalIngreso: form.canalIngreso,
    prioridad: form.prioridad,
    tipoServicio: form.tipoServicio,
    puntos: form.puntos.map((punto) => ({
      domicilio: punto.domicilio,
      pasajeros: punto.pasajeros,
      observacion: punto.observacion,
      minutosEspera: Number(punto.espera)
    })),
    fechaYHoraDeLlegada: form.fechaYHoraDeLlegada,
    minutosPreviosASalida: form.minutosPreviosASalida,
    observacionCoordinacion: form.observacionCoordinacion,
    hasOutSite: form.hasOutSite,
    pay_with_cash: form.pay_with_cash
  })
};
