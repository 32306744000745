import { Button } from '@vadiun/react-components';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as Yup from 'yup';
interface Props {
  onSubmit: (values: { password: string }) => Promise<void>;
}

export const ClientBusinessUserChangePassword = ({ onSubmit }: Props) => {
  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={Yup.object({
        password: Yup.string().required('La contraseña es requerida')
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="w-full grid gap-6 grid-cols-6 p-8">
          <Field
            name="password"
            label="Contraseña *"
            variant="outlined"
            className="col-span-6"
            type="password"
            component={TextField}
            fullWidth
          />
          <div className="flex justify-end col-span-6">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
