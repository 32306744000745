import { faBed, faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusDrivers from './StatusDrivers';
import ItemStatusDriver from './ItemStatusDriver';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

type Props = {
  amountPerState: {
    free: number;
    busy: number;
    onBrake: number;
    workdayNotStarted: number;
    workdayStarted: number;
  };
  statusActive: string;
  isLoadingDrivers: boolean;
  onFilterByStatusDriver: (state: string) => void;
  reloadDriverMonitorInfo: () => Promise<void>;
};

const FirstPanelStatusDrivers = ({
  amountPerState: { busy, free, onBrake, workdayNotStarted, workdayStarted },
  statusActive,
  isLoadingDrivers,
  onFilterByStatusDriver,
  reloadDriverMonitorInfo
}: Props): JSX.Element => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const onRefresh = async () => {
    setIsRefreshing(true);
    await reloadDriverMonitorInfo();
    setIsRefreshing(false);
  };

  return (
    <div className="text-xs flex items-center mt-2 mb-1">
      <div className="flex gap-2 md:flex-col lg:flex-col xl:flex-row 2xl:flex-row flex-1">
        <div className="flex gap-1 bg-white rounded-2xl py-1">
          <div
            className="flex items-center gap-3 justify-center text-gray-400 pl-4 pr-4 rounded-l-2xl bg-white"
            style={{ borderRight: '2px solid #f1f1f1' }}
          >
            <div className="flex w-36 items-center gap-2 py-2">
              <FontAwesomeIcon icon={faCar} size="1x" />
              <p className="leading-none text-xs">Turno abierto</p>
            </div>
            <span className={'text-xs text-gray-700'}>
              {workdayStarted ?? 0}
            </span>
          </div>
          <StatusDrivers
            busy={busy}
            free={free}
            onBrake={onBrake}
            onFilterByStatusDriver={onFilterByStatusDriver}
            statusActive={statusActive}
            isLoadingDrivers={isLoadingDrivers}
          />
        </div>
        <div className="flex gap-3 rounded-2xl bg-white py-2">
          <div
            className="flex items-center justify-center gap-2 rounded-l-2xl text-gray-400 pl-4 pr-4"
            style={{ borderRight: '2px solid #f1f1f1' }}
          >
            <div className="flex w-36 items-center gap-2 py-2">
              <FontAwesomeIcon icon={faBed} size="1x" />
              <p className="leading-none">Turno cerrado</p>
            </div>
            <span className={'text-xs text-gray-700'}>
              {workdayNotStarted ?? 0}
            </span>
          </div>
          <div className="py-1 pr-2 flex justify-start">
            <ItemStatusDriver
              status="ON_BRAKE"
              onFilterByStatusDriver={onFilterByStatusDriver}
              borderColor="border-gray-400"
              bgColor="bg-gray-200"
              isActive={statusActive === 'ON_BRAKE'}
              label="Fuera de turno"
              value={''}
              color="text-gray-500"
              loadingColor="#6B7280"
              isLoadingDrivers={isLoadingDrivers}
            />
          </div>
        </div>

        <button
          className="bg-transparent py-4 px-2 rounded-lg text-gray-400 flex gap-2 hover:underline duration-200"
          onClick={onRefresh}
        >
          {isRefreshing ? (
            <CircularProgress
              size={17}
              style={{
                color: 'gray'
              }}
              thickness={4}
            />
          ) : (
            <RefreshIcon fontSize="medium" />
          )}
        </button>
      </div>
    </div>
  );
};

export default FirstPanelStatusDrivers;
