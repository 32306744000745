import { ClientRequirements, ClientRequirementType } from './types';

export const clientRequirement: ClientRequirementType[] = [
  {
    type: ClientRequirements.AUTHORIZED_ID,
    label: 'Autorizado',
    mappingKey: 'needsAuthorized'
  },
  {
    type: ClientRequirements.COST_CENTER,
    label: 'Centro de costos',
    mappingKey: 'needsCostCenter'
  },
  {
    type: ClientRequirements.SUB_COST_CENTER,
    label: 'Sub Centro de costos',
    mappingKey: 'needsSubCostCenter'
  },
  {
    type: ClientRequirements.SECTOR,
    label: 'Sector',
    mappingKey: 'needsSector'
  },
  {
    type: ClientRequirements.BUY_ORDER,
    label: 'Orden de compra',
    mappingKey: 'needsBuyOrder'
  },
  {
    type: ClientRequirements.EMPLOYEE_NUMBER,
    label: 'Legajo',
    mappingKey: 'needsEmployeeNumber'
  },
  {
    type: ClientRequirements.PLATFORM_APPLICATION,
    label: 'Solicitud en plataforma',
    mappingKey: 'needsPlatformApplication'
  },
  {
    type: ClientRequirements.TRIP_NUMBER,
    label: 'Número de viaje',
    mappingKey: 'needsTripNumber'
  },
  {
    type: ClientRequirements.DATA_1,
    label: 'Dato 1',
    mappingKey: 'needsData1'
  },
  {
    type: ClientRequirements.DATA_2,
    label: 'Dato 2',
    mappingKey: 'needsData2'
  },
  {
    type: ClientRequirements.DATA_3,
    label: 'Dato 3',
    mappingKey: 'needsData3'
  }
];
