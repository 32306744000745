import moment from 'moment';
import { httpClient } from 'shared/services/http/httpClient';
import { DriverIncomeFiltersFormType } from '../components/DriverIncomeFiltersForm';
import { DriverIncomeModel } from '../models/DriverIncomeModel';

export const useDriverIncomeRepository = () => {
  const getIncomeReport = async (
    filters: DriverIncomeFiltersFormType
  ): Promise<DriverIncomeModel[]> => {
    const params: string[] = [];
    if (filters.from) {
      params.push(`from=${filters.from.format('YYYY-MM-DD')}`);
    }
    if (filters.to) {
      params.push(`to=${filters.to.format('YYYY-MM-DD')}`);
    }
    if (filters.driver_id) {
      params.push(`driver_id=${filters.driver_id}`);
    }
    if (filters.turnoAbierto) {
      params.push(`turno_abierto=${filters.turnoAbierto ? 1 : 0}`);
    }
    const res = await httpClient.get(
      `drivers/income-report?${params.join('&')}`
    );

    return res.map((r) => ({
      ...r,
      shift_open_date_time:
        r.shift_open_date_time === null ? null : moment(r.shift_open_date_time)
    }));
  };

  return { getIncomeReport };
};
