import moment, { Moment } from 'moment';
import { ChoferCategoria } from './ChoferCategoria';
import { ChoferEstado } from './ChoferEstado';

export interface ChoferTrabajando {
  id: number;
  code: number;
  name: string;
  phone: number;
  cell_phone: number;
  category: ChoferCategoria;
  income_since_work_day_started: number;
  income_for_worked_hours: number;
  minutes_since_work_day_started: number;
  worked_minutes: number;
  finished_break_date_time: Moment | null;
  state: ChoferEstado;
  current_latitude: number;
  current_longitude: number;
  current_locality: string;
  current_district: string;
  vehicle_brand: string;
  vehicle_color: string;
  vehicle_model: string;
  vehicle_patent: string;
  has_posnet: boolean;
  downtown_permit_expiration: Moment | null;
  prom_income_current_month: number;
  prom_income_per_hour: number;
  shift_init_hour: number;
  shift_end_hour: number;
  last_finished_reservation_datetime: Moment | null;
}

export const choferTrabajandoBuilder = {
  fromBackEnd: (chofer): ChoferTrabajando => {
    return {
      ...chofer,
      last_finished_reservation_datetime:
        chofer.last_finished_reservation_datetime
          ? moment(chofer.last_finished_reservation_datetime)
          : null,
      finished_break_date_time: chofer.finished_break_date_time
        ? moment(chofer.finished_break_date_time)
        : null,
      downtown_permit_expiration: chofer.downtown_permit_expiration
        ? moment(chofer.downtown_permit_expiration)
        : null
    };
  }
};
