export enum ChoferMotivoDesvinculacion {
  INCUMPLE_NORMAS = 'Incumple normas',
  CAMBIO_ACTIVIDAD = 'Cambio de actividad',
  NO_ALCANZO_EXPECTATIVAS = 'No alcanzo las expectativas',
  NO_ALCANZO_RECAUDACION = 'No alcanzo la recaudacion pretendida/esperada',
  DECISION_TITULAR = 'Por decision del titular'
}

export function motivosDesvinculacionChofer() {
  return Object.values(ChoferMotivoDesvinculacion).sort();
}
