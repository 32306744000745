import { TransferReservationPrices } from '../../viajeTraslado/models/TransferReservationPrices';

interface Props {
  tarifaInfo: TransferReservationPrices | undefined;
}

function TarifaDetalle({ tarifaInfo }: Props) {
  return (
    <>
      {tarifaInfo !== undefined ? (
        <div className="w-full">
          <div className="my-1 flex justify-between border-b-2 border-gray-50">
            <span className=" text-xs text-gray-400">KM</span>
            <span className=" font-semibold text-green-400">
              ${tarifaInfo.km_price}
            </span>
          </div>
          <div className="my-1 flex justify-between border-b-2 border-gray-50">
            <span className=" text-xs text-gray-400">Espera</span>
            <span className=" font-semibold text-green-400">
              ${tarifaInfo.waiting_minutes_price}
            </span>
          </div>
          <div className="my-1 flex justify-between border-b-2 border-gray-50">
            <span className=" text-xs text-gray-400">Out-Site</span>
            <span className=" font-semibold text-green-400">
              ${tarifaInfo.out_site_price}
            </span>
          </div>
          <div className="my-1 flex justify-between border-b-2 border-gray-50">
            <span className=" text-xs text-gray-400">Plus-nocturno</span>
            <span className=" font-semibold text-green-400">
              ${tarifaInfo.night_plus_price}
            </span>
          </div>
          <div className="my-1 flex justify-between border-b-2 border-gray-50">
            <span className=" text-xs text-gray-400">Gastos</span>
            <span className=" font-semibold text-red-400">
              ${tarifaInfo.expenses_price}
            </span>
          </div>
          <div className="my-1 flex justify-between border-b-2 border-gray-50">
            <span className=" text-xs text-gray-400">Total</span>
            <span className=" font-semibold text-red-400">
              ${tarifaInfo.transfer_price}
            </span>
          </div>
        </div>
      ) : (
        <span className="text-sm">Cargando...</span>
      )}
    </>
  );
}

export default TarifaDetalle;
