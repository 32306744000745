// @flow
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ServerSidePagination,
  TableColumn
} from '@vadiun/react-components';
import PageContainer from 'layout/components/PageContainer';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationRepetitivePrivateModel } from '../models/TransferReservationRepetitivePrivateModel';

const completeHour = (hour: number) => (hour >= 10 ? hour : `0${hour}`);

interface Props {
  tableProps: ServerSidePagination<TransferReservationRepetitivePrivateModel[]>;
  onDelete: (id: number) => React.ReactNode;
  onLocationsDetails: (
    reservation: TransferReservationRepetitivePrivateModel
  ) => void;
  selectToEdit: (x: TransferReservationRepetitivePrivateModel) => void;
}

export function ReservaTrasladoRepetitiveTable({
  tableProps,
  onLocationsDetails,
  selectToEdit,
  onDelete
}: Props) {
  const data = tableProps.data;
  const columns: TableColumn<TransferReservationRepetitivePrivateModel>[] = [
    {
      name: 'reservation_hours',
      label: 'Horarios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <span>
              {`${data[index].reservation_hours}:${completeHour(
                data[index].reservation_minutes
              )}`}
            </span>
          );
        }
      }
    },
    {
      name: 'from_date',
      label: 'Desde',
      options: {
        customBodyRenderLite: (index) => {
          return <span>{`${data[index].from_date.format('DD/MM/YYYY')}`}</span>;
        }
      }
    },
    {
      name: 'to_date',
      label: 'Hasta',
      options: {
        customBodyRenderLite: (index) => {
          return <span>{`${data[index].to_date.format('DD/MM/YYYY')}`}</span>;
        }
      }
    },
    {
      name: 'client',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => data[index].client.name
      }
    },
    {
      name: 'puntos',
      label: 'Domicilios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col items-start">
                {data[index].puntos.map((location, index) => (
                  <div className='flex items-start mb-1'>
                    <div 
                      className='flex justify-center items-center w-4 h-4 rounded-full bg-primary-500 text-white p-1'
                    >
                      {index + 1}
                    </div>
                    <span className='ml-1 text-center'>
                      {createDomicilioText(location.domicilio)}
                    </span>
                  </div>
                ))}
                <Button
                  shape="circle"
                  className="p-2 w-full"
                  onClick={() => onLocationsDetails(data[index])}
                  color="blue"
                >
                  <div className="flex items-center gap-1">
                    Info
                  </div>
                </Button>
            </div>
          );
        }
      }
    },

    {
      name: 'id',
      label: 'ID'
    },

    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex space-x-2">
            <Button
              onClick={() => selectToEdit(tableProps.data[index])}
              shape="circle"
              color="primary"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
            {onDelete(data[index].id)}
          </div>
        )
      }
    }
  ];

  return (
    <PageContainer lessMargin>
      <div className="w-full">
        <Table
          {...tableProps}
          options={{
            elevation: 0,
            selectableRows: 'none',
            search: false,
            filter: false,
            sort: false,
            print: false,
            download: false,
            ...tableProps.serverSideProps.options
          }}
          title={''}
          columns={columns}
        />
      </div>
    </PageContainer>
  );
}
