import React from 'react';
import { Card } from 'shared/components';
import { UserRole } from '../models/User';
import { UserForm } from './UserForm/UserForm';
import { UserFormType } from './UserForm/UserFormType';

interface Props {
  create: (UserValues: UserFormType) => Promise<void>;
  roles: UserRole[];
}
export function UserCreate({ create, roles }: Props) {
  return (
    <Card title="Nuevo Usuario" className="w-full">
      <div className="p-8">
        <UserForm onSubmit={create} roles={roles} editing={false} />
      </div>
    </Card>
  );
}
