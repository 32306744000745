import { PassengerModelCRUD } from '../../models/Passenger';
import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface PassengerFormType extends PassengerModelCRUD {}

const passengerFormSchema: YupShape<PassengerFormType> = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  phone: Yup.number().nullable(),
  email: Yup.string().nullable().email('El formato no es válido'),
  has_priority: Yup.boolean().required('El campo tiene prioridad es requerido'),
  dni: Yup.number().nullable(),
  nationality: Yup.string().nullable()
});

const passengerInitialValues: PassengerFormType = {
  name: '',
  phone: '',
  email: '',
  has_priority: false,
  dni: '',
  nationality: ''
};

const PassengerFormik = {
  schema: passengerFormSchema,
  initialValues: passengerInitialValues
};

export default PassengerFormik;
