import { Moment } from 'moment';

export interface TravelEstimationClientBusinessParams {
  fixedPriceName?: string;
  kilometers: number;
  reservationDatetime: Moment;
  totalMinutesWaiting: number | string;
  originLatitude: number | string;
  originLongitude: number | string;
  finalLatitude: number | string;
  finalLongitude: number | string;
  categoryName: string;
  hasOutSite: boolean;
  payWithCash: boolean;
}

export const estimationClientBusinessBuilder = {
  toBackEnd: (estimation: TravelEstimationClientBusinessParams) => ({
    fixed_price_name: estimation.fixedPriceName,
    kilometers: estimation.kilometers,
    reservation_datetime: estimation.reservationDatetime.format(
      'YYYY-MM-DD HH:mm:ss'
    ),
    total_minutes_waiting: estimation.totalMinutesWaiting,
    origin_latitude: estimation.originLatitude,
    origin_longitude: estimation.originLongitude,
    final_latitude: estimation.finalLatitude,
    final_longitude: estimation.finalLongitude,
    category_name: estimation.categoryName,
    has_out_site: estimation.hasOutSite,
    pay_with_cash: estimation.payWithCash
  })
};

export interface TravelEstimationClientPrivateParams {
  kilometers: number;
  reservationDatetime: Moment;
  totalMinutesWaiting: number | string;
  originLatitude: number | string;
  originLongitude: number | string;
  finalLatitude: number | string;
  finalLongitude: number | string;
  categoryName: string;
  hasOutSite: boolean;
}

export const estimationClientPrivateBuilder = {
  toBackEnd: (estimation: TravelEstimationClientPrivateParams) => ({
    kilometers: estimation.kilometers,
    reservation_datetime: estimation.reservationDatetime.format(
      'YYYY-MM-DD HH:mm:ss'
    ),
    total_minutes_waiting: estimation.totalMinutesWaiting,
    origin_latitude: estimation.originLatitude,
    origin_longitude: estimation.originLongitude,
    final_latitude: estimation.finalLatitude,
    final_longitude: estimation.finalLongitude,
    category_name: estimation.categoryName,
    has_out_site: estimation.hasOutSite,
    pay_with_cash: true
  })
};
