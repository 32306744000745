import { billerBuilder, BillerModel } from 'app/biller/models/BillerModel';
import { BusinessUnitType } from 'app/business/services/useBusinessUnit';
import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';
import moment, { Moment } from 'moment';
import { jsonToFormData } from 'shared/helpers/jsonToFormData';
import {
  vehiculoBuilder,
  VehiculoModel,
  vehiculoPartialBuilder,
  VehiculoPartialModel
} from '../../vehiculos/models/Vehiculo';
import { ChoferCategoria } from './ChoferCategoria';
import { ChoferConceptoNovedad } from './ChoferConceptoNovedad';
import { ChoferIdioma } from './ChoferIdioma';
import { ChoferMotivoDeshabilitacion } from './ChoferMotivoDeshabilitacion';

export interface ChoferPartialModel
  extends Pick<ChoferModel, 'id' | 'isDeleted'>,
    Pick<
      ChoferModel['general'],
      'codigo' | 'nombreCompleto' | 'categoria' | 'tienePosnet' | 'businessUnit'
    >,
    Pick<ChoferModel['personal'], 'foto'> {
  vehiculo: VehiculoPartialModel;
  turnoDomicilio: ChoferModel['turno']['domicilio'];
  billers: BillerModel[];
}

export interface ChoferModel {
  id: number;
  general: ChoferGeneral;
  personal: ChoferPersonal;
  turno: ChoferTurno;
  vencimientos: ChoferVencimiento;
  desvinculacion: ChoferDesvinculacion;
  preDesvinculacion: ChoferDesvinculacion;
  deshabilitaciones: ChoferDeshabilitacion[];
  isDeleted: boolean;
}

export interface ChoferGeneral {
  codigo: number;
  nombreCompleto: string;
  proveedores: BillerModel[];
  vehiculo: VehiculoModel;
  categoria: ChoferCategoria;
  tienePosnet: boolean;
  fechaIngreso: Moment;
  comision: number;
  businessUnit?: BusinessUnitType | null;
}

export interface ChoferPersonal {
  foto: string;
  fechaNacimiento: Moment;
  dni: number;
  dni_front_photo: string;
  dni_back_photo: string;
  cuit: number;
  domicilio: DomicilioModel;
  telefonoEmergencia: number | string;
  celular: number | string;
  mail: string;
  idiomas: ChoferIdioma[];
  tieneCursoDeDefensa: boolean;
}

export interface ChoferTurno {
  domicilio: DomicilioModel;
  horariosLunes: Horario[];
  horariosMartes: Horario[];
  horariosMiercoles: Horario[];
  horariosJueves: Horario[];
  horariosViernes: Horario[];
  horariosSabado: Horario[];
  horariosDomingo: Horario[];
}

export interface ChoferDeshabilitacion {
  id?: number;
  fechaYHoraInicio: Moment;
  fechaYHoraFin: Moment;
  motivo: ChoferMotivoDeshabilitacion;
  observacion: string | null;
  finalizada: boolean;
  aceptada: boolean | null;
}

export interface ChoferNovedad {
  concepto: ChoferConceptoNovedad;
  fecha: Moment;
  monto: string;
}

export interface ChoferVencimiento {
  fechaLicencia: Moment;
  licence_front_photo: string;
  licence_back_photo: string;
}

export interface Horario {
  desde: Moment;
  hasta: Moment;
}

export interface ChoferDesvinculacion {
  fecha: null | Moment;
  motivo: null | string;
  observacion: null | string;
}

export interface ChoferCRUD
  extends Omit<
    ChoferModel,
    | 'desvinculacion'
    | 'preDesvinculacion'
    | 'deshabilitaciones'
    | 'isDeleted'
    | 'id'
    | 'general'
    | 'personal'
    | 'vencimientos'
  > {
  id?: number;
  general: Omit<ChoferGeneral, 'vehiculo'> & {
    vehiculo: VehiculoPartialModel;
  };
  personal: Omit<
    ChoferPersonal,
    'foto' | 'dni_front_photo' | 'dni_back_photo'
  > & {
    foto: File | string;
    dni_front_photo: File | string;
    dni_back_photo: File | string;
  };
  vencimientos: Omit<
    ChoferVencimiento,
    'licence_front_photo' | 'licence_back_photo'
  > & {
    licence_front_photo: File | string;
    licence_back_photo: File | string;
  };
}

const horariosBuilder = {
  fromBackEnd: (schedules: any[]): Omit<ChoferTurno, 'domicilio'> => {
    const horarios: Omit<ChoferTurno, 'domicilio'> = {
      horariosLunes: [],
      horariosMartes: [],
      horariosMiercoles: [],
      horariosJueves: [],
      horariosViernes: [],
      horariosSabado: [],
      horariosDomingo: []
    };
    const nombresDias = {
      monday: 'horariosLunes',
      tuesday: 'horariosMartes',
      wednesday: 'horariosMiercoles',
      thursday: 'horariosJueves',
      friday: 'horariosViernes',
      saturday: 'horariosSabado',
      sunday: 'horariosDomingo'
    };
    schedules.forEach((schedule) => {
      horarios[nombresDias[schedule.day]].push({
        desde: moment(
          `${schedule.start_hour}:${schedule.start_minute}`,
          'HH:mm'
        ),
        hasta: moment(`${schedule.end_hour}:${schedule.end_minute}`, 'HH:mm')
      });
    });
    return horarios;
  },
  toBackEnd: (horarios: Omit<ChoferTurno, 'domicilio'>) => {
    const mapHorario = (day: string) => (horario: Horario) => ({
      day,
      from_schedule: horario.desde.format('HH:mm'),
      to_schedule: horario.hasta.format('HH:mm')
    });
    return [
      ...horarios.horariosLunes.map(mapHorario('monday')),
      ...horarios.horariosMartes.map(mapHorario('tuesday')),
      ...horarios.horariosMiercoles.map(mapHorario('wednesday')),
      ...horarios.horariosJueves.map(mapHorario('thursday')),
      ...horarios.horariosViernes.map(mapHorario('friday')),
      ...horarios.horariosSabado.map(mapHorario('saturday')),
      ...horarios.horariosDomingo.map(mapHorario('sunday'))
    ];
  }
};

export const choferBuilder = {
  fromBackEnd: (_chofer): ChoferModel => {
    const { personalInfo, ...chofer } = _chofer;
    return {
      id: chofer.id,
      general: {
        codigo: chofer.code,
        nombreCompleto: chofer.name,
        vehiculo: vehiculoBuilder.fromBackEnd(chofer.vehicle),
        categoria: chofer.category,
        tienePosnet: chofer.hasPosnet,
        fechaIngreso: moment(personalInfo.admissionDate),
        comision: personalInfo.commission,
        proveedores: chofer.billers.map(billerBuilder.fromBackEnd),
        businessUnit: chofer.business_units
      },
      personal: {
        foto: personalInfo.photo,
        dni_front_photo: personalInfo.dni_front_photo,
        dni_back_photo: personalInfo.dni_back_photo,
        fechaNacimiento: moment(personalInfo.birthdate),
        dni: personalInfo.dni,
        cuit: personalInfo.cuit,
        telefonoEmergencia: personalInfo.phone,
        celular: personalInfo.cellphone,
        mail: personalInfo.email,
        tieneCursoDeDefensa: !!personalInfo.hasDefensiveCourse,
        idiomas: chofer.languages.map(({ name }) => name),
        domicilio: domicilioBuilder.fromBackEnd(chofer.address)
      },
      desvinculacion: {
        fecha: chofer.eliminationDate,
        motivo: chofer.eliminationReason,
        observacion: chofer.eliminationObservation
      },
      preDesvinculacion: {
        fecha: chofer.disassociateDate,
        motivo: chofer.disassociateReason,
        observacion: chofer.disassociateObservation
      },
      turno: {
        domicilio: domicilioBuilder.fromBackEnd(chofer.shifts.address),
        ...horariosBuilder.fromBackEnd(chofer.shifts.schedules)
      },
      vencimientos: {
        licence_front_photo: chofer.licence_front_photo,
        licence_back_photo: chofer.licence_back_photo,
        fechaLicencia: moment(chofer.licenceExpirationDate)
      },
      deshabilitaciones: [],
      isDeleted: chofer.isDeleted
    };
  },
  toBackEnd: (chofer: ChoferCRUD) => {
    const value = {
      code: chofer.general.codigo,
      name: chofer.general.nombreCompleto,
      vehicle_id: chofer.general.vehiculo.id,
      biller_ids: chofer.general.proveedores.map(({ id }) => id),
      category: chofer.general.categoria,
      has_posnet: chofer.general.tienePosnet,
      business_units_id: chofer.general.businessUnit?.id,
      personal_info: {
        photo: chofer.personal.foto,
        dni_front_photo: chofer.personal.dni_front_photo,
        dni_back_photo: chofer.personal.dni_back_photo,
        birthdate: chofer.personal.fechaNacimiento.format('YYYY-MM-DD'),
        dni: chofer.personal.dni,
        cuit: chofer.personal.cuit,
        address: domicilioBuilder.toBackEnd(chofer.personal.domicilio),
        phone: chofer.personal.telefonoEmergencia,
        cellphone: chofer.personal.celular,
        email: chofer.personal.mail,
        admission_date: chofer.general.fechaIngreso.format('YYYY-MM-DD'),
        commission: chofer.general.comision,
        has_defensive_course: chofer.personal.tieneCursoDeDefensa,
        languages: chofer.personal.idiomas
      },
      shift: {
        address: domicilioBuilder.toBackEnd(chofer.turno.domicilio),
        schedules: horariosBuilder.toBackEnd(chofer.turno)
      },
      licence_expiration_date:
        chofer.vencimientos.fechaLicencia.format('YYYY-MM-DD'),
      licence_front_photo: chofer.vencimientos.licence_front_photo,
      licence_back_photo: chofer.vencimientos.licence_back_photo
    };
    return jsonToFormData(value);
  }
};

export const choferPartialBuilder = {
  fromBackEnd: (chofer): ChoferPartialModel => {
    return {
      id: chofer.id,
      codigo: chofer.code,
      nombreCompleto: chofer.name,
      foto: chofer.photo,
      vehiculo: vehiculoPartialBuilder.fromBackEnd(chofer.vehicle),
      turnoDomicilio: domicilioBuilder.fromBackEnd(chofer.shift_address),
      categoria: chofer.category,
      isDeleted: chofer.isDeleted,
      tienePosnet: chofer.hasPosnet,
      billers: chofer.billers.map(billerBuilder.fromBackEnd),
      businessUnit: chofer.business_units
    };
  }
};
