import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button } from '@vadiun/react-components';
import { createAntenaFormInitialValues } from 'app/antena/antena/components/AntenaForm/AntenaFormInitialValues';
import { AntenaFormType } from 'app/antena/antena/components/AntenaForm/AntenaFormType';
import { antenaFormValidation } from 'app/antena/antena/components/AntenaForm/AntenaFormValidation';
import { AntenaGrupoModel } from 'app/antena/grupo/models/AntenaGrupo';
import { Select } from 'formik-material-ui';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
interface Props {
  onSubmit: (value: AntenaFormType) => Promise<void>;
  initialValues?: AntenaFormType;
  grupos: AntenaGrupoModel[];
}

export function AntenaForm({
  onSubmit,
  initialValues = createAntenaFormInitialValues(),
  grupos
}: Props) {
  return (
    <Formik<AntenaFormType>
      initialValues={initialValues}
      validationSchema={antenaFormValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className="grid w-full grid-cols-6 gap-6">
          <Field
            name="nombre"
            label="Nombre *"
            variant="outlined"
            className="col-span-6"
            component={TextField}
            fullWidth
          />
          <Field
            name="latitud"
            label="Latitud *"
            variant="outlined"
            className="col-span-6"
            component={TextField}
            fullWidth
          />
          <Field
            name="longitud"
            label="Longitud *"
            variant="outlined"
            className="col-span-6"
            component={TextField}
            fullWidth
          />
          <FormControl variant="outlined" fullWidth className="col-span-6">
            <InputLabel>Grupo *</InputLabel>
            <Field component={Select} name="grupo_id" label="Grupo *">
              {grupos.map((grupo) => (
                <MenuItem value={grupo.id} key={grupo.id}>
                  {grupo.nombre}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
          <div className="col-span-6 flex justify-end">
            <Button
              type="submit"
              variant="contained"
              color="green"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
