import * as Yup from 'yup';
import { ClientBusinessModel } from 'app/client/clientBusiness/models/ClientBusiness';
import {
  transferReservationBaseFormValidation,
  transferReservationBaseLocationFormValidation
} from '../base/TransferReservationBaseFormValidation';

export const transferReservationSingleLocationFormValidation =
  transferReservationBaseLocationFormValidation;

export const transferReservationSingleFormValidation = (
  cliente: ClientBusinessModel | undefined,
  isEvent: boolean,
  isLLTPais: boolean
) =>
  transferReservationBaseFormValidation(cliente).shape({
    puntos: Yup.array()
      .min(2, 'Debe haber al menos dos puntos')
      .of(transferReservationSingleLocationFormValidation),
    fechaYHoraDeLlegada: Yup.mixed().required(
      'La fecha de llegada es requerida'
    ),
    minutosPreviosASalida: Yup.mixed().required(
      'Los minutos de salida son requeridos'
    ),
    nameEvent: isEvent
      ? Yup.string().required('El nombre del evento es requerido')
      : Yup.string().nullable(),
    providerPrice: isLLTPais
      ? Yup.number().required('El costo del proveedor es requerido')
      : Yup.number().nullable(),
    customerPrice: isLLTPais
      ? Yup.number().required('El costo del cliente es requerido')
      : Yup.number().nullable()
  });
