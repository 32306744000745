import { FunctionComponent } from 'react';

interface ItemNotificationProps {
  title: string;
  count: number;
}

const ItemNotification: FunctionComponent<ItemNotificationProps> = ({
  title,
  count
}) => {
  return (
    <div className="felx-1 flex items-center gap-6 justify-between py-0 pl-2 pr-5 rounded-md duration-500 cursor-pointer hover:bg-gray-100">
      <p className="text-xs text-gray-400 leading-none w-52 flex items-center">
        <div className="h-3 w-3 ml-2" />
        {title}
      </p>
      <span className="text-sm text-gray-700 w-6 h-6 rounded-2xl flex justify-center items-center">
        {count}
      </span>
    </div>
  );
};

export default ItemNotification;
