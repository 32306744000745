import { YupShape } from 'shared/models/YupShape';
import * as Yup from 'yup';

export interface FacturarLiquidacionesFormType{
    number:string;
    letter:string
};

const facturarLiquidacionesFormSchema: YupShape<FacturarLiquidacionesFormType> = Yup.object().shape({
    number: Yup.string().required('El numero es requerido'),
    letter: Yup.string().required('La letra es requerido')
});

const facturarLiquidacionesInitialValues: FacturarLiquidacionesFormType = {
    number: '',
    letter: ''
};

const FacturarLiquidacionesFormik = {
    schema: facturarLiquidacionesFormSchema,
    initialValues: facturarLiquidacionesInitialValues
};

export default FacturarLiquidacionesFormik;
