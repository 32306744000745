import moment from 'moment';
import {
  ViajeCanalesDeIngreso,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';
import { TransferReservationPrivateSingleFormType } from './TransferReservationSingleFormType';

export const createTransferReservationPrivateSingleInitialValues =
  (): TransferReservationPrivateSingleFormType => ({
    cliente: null,
    hasOutSite: false,
    canalIngreso: ViajeCanalesDeIngreso.TELEFONO,
    prioridad: false,
    tipoServicio: ViajeTrasladoTipoServicio.EJECUTIVO,
    observacionCoordinacion: '',
    pay_with_cash: true,
    fechaYHoraDeLlegada: moment(),
    minutosPreviosASalida: moment(),
    puntos: []
  });
