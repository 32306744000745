import {
  domicilioBuilder,
  DomicilioModel
} from 'app/domicilio/models/DomicilioModel';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';

export interface ClientPrivateRateModel {
  id: number;
  outSitePrice: string | number;
  nightPlusPercentage: string | number;
  minNumberKilometers: number | string;
  categories: ClientPrivateRateCategoryModel[];
}

export interface ClientPrivateRatePartialModel {
  id: number;
  kilometerPrice: number;
  minNumberKilometers: number;
  minute_price: number;
  nightPlusPercentage: number;
  outSitePrice: number;
}

// Workaround to extend enums https://github.com/microsoft/TypeScript/issues/17592
export const ClientPrivateRateCategoryType = {
  ...ViajeTrasladoTipoServicio,
  UTILITARIO_MENSAJERIA: 'Utilitario mensajeria',
  FLETE: 'Flete'
};
export type ClientPrivateRateCategoryType =
  typeof ClientPrivateRateCategoryType[keyof typeof ClientPrivateRateCategoryType];

export const CLIENT_PRIVATE_RATE_CATEGORIES = Object.values(
  ClientPrivateRateCategoryType
);

export interface ClientPrivateRateCategoryModel {
  kmServiceCanceled: number | string;
  minutePrice: number | string;
  name: ClientPrivateRateCategoryType;
  linearKilometerPrices: {
    numberKilometersFrom: number | string;
    numberKilometersTo: number | string;
    price: number | string;
  }[];
  returnKilometerPrices: {
    numberKilometersFrom: number | string;
    numberKilometersTo: number | string;
    price: number | string;
  }[];
  fixedPrices: {
    name: string;
    price: number | string;
  }[];
  priced: {
    address1: DomicilioModel;
    address2: DomicilioModel;
    kilometersRadio: string | number;
    price: string | number;
  }[];
}

/*export interface ClientPrivateRateModelCRUD
  extends Omit<ClientPrivateRateModel, 'categories' | 'id'> {
  id?: number;
  categories: (Omit<ClientPrivateRateCategoryModel, 'minutePrice'> & {
    hourPrice: number | string;
  })[];
}*/

export const clientPrivateRateBuilder = {
  fromBackEnd: (rate): ClientPrivateRateModel => {
    return {
      ...rate,
      categories: rate.categories.map((category) => ({
        ...category,
        priced: category.priced.map((elem) => ({
          address1: domicilioBuilder.fromBackEnd(elem.address1),
          address2: domicilioBuilder.fromBackEnd(elem.address2),
          kilometersRadio: elem.kilometersRadio,
          price: elem.price
        }))
      })),
      minNumberKilometers: rate.min_kilometers
    };
  }
};

export const clientPrivateRatePartialBuilder = {
  fromBackEnd: (rate): ClientPrivateRatePartialModel => ({
    ...rate
  })
};
