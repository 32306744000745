export enum ViajeTrasladoEstado {
  PENDIENTE = 'Pendiente',
  CREADA = 'Creada',
  ASIGNADA = 'Asignada',
  ACEPTADA = 'Aceptada',
  EN_VIAJE_A_ORIGEN = 'En viaje a origen',
  ANUNCIADO = 'Anunciado',
  EN_VIAJE_A_DESTINO = 'En viaje a destino',
  FINALIZADO = 'Viaje Finalizado',
  AUDITORIA_PENDIENTE = 'Auditoria pendiente',
    AUDIATADA_MANUALMENTE = 'Auditada manualmente',
    AUDITADA_AUTOMATICAMENTE = 'Auditada automaticamente',
    CANCELADA = 'Cancelado',
    LIQUIDADA = 'Liquidado',
    FACTURADA = 'Facturado',
}
export const VIAJE_TRASLADO_ESTADOS = Object.values(ViajeTrasladoEstado);
