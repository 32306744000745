import { AxiosResponse } from 'axios';
import { Moment } from 'moment';
import { LaravelPaginated } from 'shared/hooks/useCustomTablePagination';
import {
  httpClient,
  httpClientWithHeaders
} from 'shared/services/http/httpClient';
import { LiquidacionModel } from '../../liquidaciones/models/Liquidacion';
import { facturaBuilder, FacturaModel } from '../models/FacturaModel';

export const useFacturacionRepository = () => {
  function addFacturacion(billingInfo: {
    settlements: LiquidacionModel[];
    number: string;
    letter: string;
  }) {
    const billingInfoToBack = {
      settlement_ids: billingInfo.settlements.map((settlement) => {
        return settlement.id;
      }),
      number: billingInfo.number,
      letter: billingInfo.letter
    };
    return httpClient.post(
      `business-clients/${billingInfo.settlements[0].client.id}/invoices`,
      billingInfoToBack
    );
  }

  async function getFacturas(
    filters: {
      dateFrom: Moment;
      dateTo: Moment;
      clientId: number | null;
      nroFactura: string | number;
      idLiquidacion: string;
    },
    params: string
  ): Promise<LaravelPaginated<FacturaModel>> {
    const filterParams = {
      date_from: filters.dateFrom.format('YYYY-MM-DD'),
      date_to: filters.dateTo.format('YYYY-MM-DD'),
      client_id: filters.clientId,
      invoice_number: filters.nroFactura,
      id_liquidacion: filters.idLiquidacion
    };
    const paramsString = Object.entries(filterParams)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const res = (await httpClient.get(
      `invoices?${params}&${paramsString}`
    )) as LaravelPaginated<any>;
    return {
      ...res,
      data: res.data.map(facturaBuilder.fromBackEnd)
    };
  }

  function crearMovimientoFactura(
    id: number,
    values: { amount: string; observation: string }
  ) {
    return httpClient.post(`/invoices/${id}/movements`, values);
  }

  function getFacturaLiquidacionExcell(
    id: number | string
  ): Promise<AxiosResponse> {
    return httpClientWithHeaders.get(
      `/invoices/${id}/transfer-settlement-excell`,
      {
        responseType: 'blob'
      }
    );
  }

  return {
    addFacturacion,
    getFacturas,
    crearMovimientoFactura,
    getFacturaLiquidacionExcell
  };
};
